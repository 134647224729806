//UTILITIES
import React from "react";
import PropTypes from "prop-types";
//COMPONENT
import * as MUI from "@mui/material";
import { Grid, Skeleton } from "@mui/material";
//CSS
import "./SkeletonsComponent.scss";

export function PostSkeleton( props ) {
  return [ ...Array( props.count ) ].map( ( _, index ) => (
    <div className="post-skeleton-container" key={ index }>
      <div className="profile-skeleton-css">
        <Skeleton animation="wave" variant="circular" width={ 60 } height={ 60 } />
        <div style={ { width: "100%" } }>
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "35px", width: "20%" } }
          />
          <div
            style={ {
              display: "flex",
              gap: "10px",
              alignItems: "center",
            } }
          >
            <Skeleton
              animation="wave"
              variant="text"
              sx={ { fontSize: "1rem", width: "30%" } }
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={ { height: "5px", width: "5px" } }
            />
            <Skeleton
              animation="wave"
              variant="text"
              sx={ { fontSize: "1rem", width: "10%" } }
            />
          </div>
        </div>
      </div>
      <div style={ { marginTop: "20px" } }>
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={ { height: "40vh", width: "100%" } }
        />
      </div>
      <div className="profile-skeleton-css" style={ { marginTop: "20px" } }>
        <div
          style={ {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          } }
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "35px", width: "20%" } }
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "50px", width: "100%" } }
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "35px", width: "30%" } }
          />
        </div>
      </div>
    </div>
  ) );
}
export function RectangularSkeleton( props ) {
  const { height } = props;
  return (
    <MUI.Box sx={ { height: "100%", margin: "0px 10px" } }>
      <MUI.Skeleton animation="wave" height={ height } />
    </MUI.Box>
  );
}
RectangularSkeleton.defaultProps = {
  height: 300,
};

RectangularSkeleton.propTypes = {
  height: PropTypes.number,
};
export function FilterSkeleton( props ) {
  return (
    <MUI.Box className="filter-skeleton ">
      { Array( parseInt( 3 ) )
        .fill()
        .map( () => (
          <>
            <MUI.Skeleton
              variant="rectangular "
              animation="wave"
              width={ 20 }
              height={ 20 }
            />
            <MUI.Skeleton
              variant="rectangular "
              animation="wave"
              width={ 100 }
              height={ 20 }
            />
          </>
        ) ) }
    </MUI.Box>
  );
}
export function ChatSkeleton( props ) {
  return (
    <MUI.Box sx={ { height: "100%", margin: "0px 0px" } }>
      <MUI.Skeleton animation="wave" height={ 100 } />
      <MUI.Skeleton animation="wave" height={ 300 } />
    </MUI.Box>
  );
}
export function OnlyTextPostSkeleton( props ) {
  return [ ...Array( props.count ) ]?.map( ( _, index ) => (
    <div className="post-skeleton-container" key={ index }>
      <div className="profile-skeleton-css">
        <Skeleton animation="wave" variant="circular" width={ 60 } height={ 60 } />
        <div style={ { width: "100%" } }>
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "35px", width: "20%" } }
          />
          <div
            style={ {
              display: "flex",
              gap: "10px",
              alignItems: "center",
            } }
          >
            <Skeleton
              animation="wave"
              variant="text"
              sx={ { fontSize: "1rem", width: "30%" } }
            />
            <Skeleton variant="rounded" sx={ { height: "5px", width: "5px" } } />
            <Skeleton variant="text" sx={ { fontSize: "1rem", width: "10%" } } />
          </div>
        </div>
      </div>
      <div style={ { marginTop: "20px" } }>
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={ { height: "50px", width: "100%" } }
        />
      </div>
      <div className="profile-skeleton-css" style={ { marginTop: "20px" } }>
        <div
          style={ {
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "10px",
          } }
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "35px", width: "20%" } }
          />
        </div>
      </div>
    </div>
  ) );
}
export function CardSkeleton( props ) {
  return [ ...Array( props.count ) ].map( ( _, index ) => (
    <div className="post-skeleton-container" key={ index }>
      <div className="profile-skeleton-css">
        <Skeleton animation="wave" variant="circular" width={ 60 } height={ 55 } />
        <div style={ { width: "100%" } }>
          <div
            style={ {
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-start",
            } }
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={ { height: "25px", width: "35%" } }
            />
            <Skeleton variant="text" sx={ { height: "25px", width: "10%" } } />
          </div>
          <div
            style={ {
              display: "flex",
              gap: "10px",
              alignItems: "center",
            } }
          >
            <Skeleton
              animation="wave"
              variant="text"
              sx={ { fontSize: "1rem", width: "60%" } }
            />
          </div>
        </div>
      </div>

      <div
        style={ {
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "10px",
          marginTop: "20px",
        } }
      >
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={ { height: "20px", width: "30%" } }
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={ { height: "50px", width: "100%" } }
        />
        <Skeleton
          animation="wave"
          variant="rounded"
          sx={ { height: "20px", width: "60%" } }
        />
        <div style={ { display: "flex", gap: "10px" } }>
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "20px", width: "5%" } }
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "20px", width: "50%" } }
          />
        </div>
      </div>
      <div className="profile-skeleton-css" style={ { marginTop: "20px" } }>
        <div
          style={ {
            width: "100%",
            display: "flex",
            gap: "30px",
          } }
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "45px", width: "50%" } }
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "45px", width: "50%" } }
          />
        </div>
      </div>
    </div>
  ) );
}
export function CatalogSkeleton( props ) {
  return (
    <Grid
      container
      spacing={ { xs: 2, md: 2 } }
      columns={ { xs: 2, sm: 15, md: 9, lg: 9, xl: 12 } }
    >
      { Array( props.count )
        .fill()
        .map( ( _, val ) => (
          <Grid item xs={ 2 } sm={ 5 } md={ 3 } key={ val }>
            <div className="catalog-skeleton-container">
              { props.image ? (
                <Skeleton
                  animation="wave"
                  variant="square"
                  sx={ { height: "140px", width: "100%" } }
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="square"
                  sx={ { height: "80px", width: "100%" } }
                />
              ) }

              <div
                style={ {
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "10px",
                  padding: "20px",
                } }
              >
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={ { height: "20px", width: "60%" } }
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={ { height: "20px", width: "40%" } }
                />
                { props.button && (
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={ { height: "40px", width: "100%" } }
                  />
                ) }
              </div>
            </div>
          </Grid>
        ) ) }
    </Grid>
  );
}
export function ProfileCardSkeleton( props ) {
  return Array( props.count )
    .fill()
    .map( ( _, index ) => (
      <Grid item xs={ 4 } key={ index }>
        <div className="post-skeleton-container">
          <div
            style={ {
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            } }
          >
            <Skeleton
              animation="wave"
              variant="circular"
              width={ 110 }
              height={ 110 }
            />
          </div>

          <div
            style={ {
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              gap: "10px",
              marginTop: "20px",
            } }
          >
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={ { height: "10px", width: "30%" } }
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={ { height: "15px", width: "20%" } }
            />
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={ { height: "12px", width: "60%" } }
            />
          </div>
          <div className="profile-skeleton-css" style={ { marginTop: "10px" } }>
            <div
              style={ {
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5px",
              } }
            >
              <Skeleton
                animation="wave"
                variant="rounded"
                sx={ { height: "35px", width: "100%" } }
              />
            </div>
          </div>
        </div>
      </Grid>
    ) );
}
export function GroupProfileSkeleton( props ) {
  return Array( props.count )
    .fill()
    .map( ( _, val ) => (
      <div className="group-skeleton-container" key={ val }>
        { props.image ? (
          <Skeleton
            animation="wave"
            variant="square"
            sx={ { height: "140px", width: "100%" } }
          />
        ) : (
          <Skeleton
            animation="wave"
            variant="square"
            sx={ { height: "140px", width: "100%" } }
          />
        ) }

        <div
          style={ {
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginTop: "10px",
            padding: "20px",
          } }
        >
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "20px", width: "60%" } }
          />
          <Skeleton
            animation="wave"
            variant="rounded"
            sx={ { height: "20px", width: "40%" } }
          />
          { props.button && (
            <Skeleton
              animation="wave"
              variant="rounded"
              sx={ { height: "40px", width: "100%" } }
            />
          ) }
        </div>
      </div>
    ) );
}

export function GroupSkeleton( props ) {
  return (
    <Grid
      container
      rowSpacing={ 1 }
      columnSpacing={ { xs: 1, sm: 2, md: 3 } }
      columns={ { xs: 4, sm: 8, md: 12 } }
    >
      { Array( props.count )
        .fill()
        .map( ( _, val ) => (
          <Grid key={ val } item xs={ 4 }>
            <div className="group-skeleton-container">
              { props.image ? (
                <Skeleton
                  animation="wave"
                  variant="square"
                  sx={ { height: "140px", width: "100%" } }
                />
              ) : (
                <Skeleton
                  animation="wave"
                  variant="square"
                  sx={ { height: "140px", width: "100%" } }
                />
              ) }

              <div
                style={ {
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "10px",
                  padding: "20px",
                } }
              >
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={ { height: "20px", width: "60%" } }
                />

                { props.button && (
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={ { height: "40px", width: "100%" } }
                  />
                ) }
              </div>
            </div>
          </Grid>
        ) ) }
    </Grid>
  );
}
export function TableSkeleton( props ) {
  const { count, component } = props;
  return (
    <MUI.Box sx={ { width: "100%" } }>
      { Array( parseInt( 10 ) )
        .fill()
        .map( ( val, key ) => (
          <MUI.Skeleton animation="wave" height={ 50 } key={ key } />
        ) ) }{ " " }
    </MUI.Box>
  );
}

export function BlogsSkeleton( props ) {
  return (
    <Grid
      container
      rowSpacing={ 1 }
      columnSpacing={ { xs: 1, sm: 2, md: 3 } }
      columns={ { xs: 4, sm: 8, md: 12 } }
    >
      { Array( props.count )
        .fill()
        .map( ( val, index ) => (
          <Grid key={ index } item xs={ 4 }>
            <div style={ { padding: "0px" } } className="post-skeleton-container">
              <Skeleton
                animation="wave"
                variant="square"
                sx={ { height: "200px", width: "100%" } }
              />
              <div
                style={ {
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  marginTop: "10px",
                  padding: "20px",
                } }
              >
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={ { height: "20px", width: "30px" } }
                />
                <Skeleton
                  animation="wave"
                  variant="rounded"
                  sx={ { height: "20px", width: "30%" } }
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={ { height: "10px", width: "100%", marginTop: "10px" } }
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={ { height: "10px", width: "100%", marginTop: "10px" } }
                />
                <Skeleton
                  animation="wave"
                  variant="text"
                  sx={ { height: "10px", width: "100%", marginTop: "10px" } }
                />
                <div
                  style={ {
                    display: "flex",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  } }
                >
                  <div
                    style={ {
                      display: "flex",
                      alignItems: "center",
                      gap: "5px",
                      width: "50%",
                    } }
                  >
                    <Skeleton
                      animation="wave"
                      variant="circular"
                      sx={ {
                        height: "35px",
                        width: "35px",
                      } }
                    />
                    <Skeleton
                      animation="wave"
                      variant="rounded"
                      sx={ {
                        height: "30px",
                        width: "60%",
                      } }
                    />
                  </div>
                  <Skeleton
                    animation="wave"
                    variant="rounded"
                    sx={ {
                      height: "30px",
                      width: "30%",
                    } }
                  />
                </div>
              </div>
            </div>
          </Grid>
        ) ) }
    </Grid>
  );
}
export function QASkeleton( props ) {
  return Array( props.count )
    .fill()
    .map( ( _, index ) => (
      <div
        style={ { padding: "0px" } }
        className="post-skeleton-container"
        key={ index }
      >
        <Skeleton
          animation="wave"
          variant="text"
          sx={ {
            height: "30px",
            width: "20%",
            margin: "0px 0px 0px 10px",
          } }
        />
        <div style={ { marginTop: "5px" } }>
          <Skeleton
            animation="wave"
            variant="text"
            sx={ { height: "20px", width: "98%", marginLeft: "10px" } }
          />
          <Skeleton
            animation="wave"
            variant="text"
            sx={ { height: "20px", width: "98%", marginLeft: "10px" } }
          />
        </div>
        <div
          style={ {
            display: "flex",
            width: "100%",
            justifyContent: "space-between",
            alignItems: "center",
          } }
        >
          <Skeleton
            animation="wave"
            variant="square"
            sx={ {
              height: "20px",
              width: "15%",
              margin: "10px 0px 10px 10px",
            } }
          />
          <Skeleton
            animation="wave"
            variant="square"
            sx={ {
              height: "20px",
              width: "15%",
              margin: "10px 10px 10px 10px",
            } }
          />
        </div>
      </div>
    ) );
}

export function SurveySkeleton( props ) {
  const { count } = props;
  return [ ...Array( count ) ].map( ( _, index ) => (
    <div className="post-skeleton-container" key={ index }>
      <div className="card-top">
        <Skeleton
          animation="wave"
          variant="text"
          sx={ {
            height: "30px",
            width: "30%",
            margin: "0px 0px 0px 10px",
          } }
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={ {
            height: "30px",
            width: "40%",
            margin: "0px 0px 0px 10px",
          } }
        />
      </div>

      <div style={ { padding: "10px" } }>
        <Skeleton
          animation="wave"
          variant="text"
          sx={ { height: "65px", width: "80%", marginLeft: "10px" } }
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={ { height: "20px", width: "30%", marginLeft: "10px" } }
        />
      </div>
    </div>
  ) );
}

export function SurveySkeletonInfo( props ) {
  return (
    <div
      style={ { padding: "0px", width: "100%" } }
      className="post-skeleton-container"
    >
      <div style={ { padding: "10px" } }>
        <Skeleton
          animation="wave"
          variant="text"
          sx={ {
            height: "30px",
            width: "30%",
            margin: "0px 0px 0px 10px",
          } }
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={ {
            height: "30px",
            width: "40%",
            margin: "0px 0px 0px 10px",
          } }
        />
      </div>

      <div style={ { padding: "10px" } }>
        <Skeleton
          animation="wave"
          variant="text"
          sx={ { height: "20px", width: "30%", marginLeft: "10px" } }
        />
        <Skeleton
          animation="wave"
          variant="text"
          sx={ { height: "65px", width: "80%", marginLeft: "10px" } }
        />
      </div>
    </div>
  );
}

export function ListComponentSkeleton( props ) {
  const { count } = props;
  return (
    <>
      <MUI.Box sx={ { width: "100%" } }>
        { Array( parseInt( count ) )
          .fill()
          .map( ( val, key ) => (
            <MUI.Skeleton animation="wave" height={ 50 } key={ key } />
          ) ) }{ " " }
      </MUI.Box>
    </>
  );
}
export const ChooseYourProfessionSkeleton = () => {
  return (
    <MUI.Box className="ChooseYourProfessionSkeleton">
      { Array( parseInt( 4 ) )
        .fill()
        .map( () => (
          <MUI.Skeleton animation="wave" height={ 200 } width={ 200 } />
        ) ) }
    </MUI.Box>
  );
};
export function LandingPageProfessionsSkeleton( props ) {
  const { num } = props;
  return [ ...Array( num || 3 ) ].map( () => (
    <MUI.Skeleton animation="pulse" height={ 350 } />
  ) );
}
