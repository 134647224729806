import * as yup from "yup";
import differenceInYears from "date-fns/differenceInYears";
import { isValidPhoneNumber } from "react-phone-number-input";
//ALL FORM VALIDATION FILE

//REGEX DEFINITION
let today = new Date();
export const fileSizes = { image: 1, file: 10, video: 5 };
export const fileTypes = {
  image: [ "JPG", "PNG", "JPEG" ],
  file: [ "PDF" ],
  video: [ "MP4", "MOV", "OGG", "QT" ],
};

const emailRegex = new RegExp( /\S+@\S+\.\S+/ );
const phoneRegex = new RegExp( /^[0-9 ()+-]+$/ );
const ccardRegex = new RegExp( /^[0-9-]+$/ );
const cvcRegex = new RegExp( /^[0-9]+$/ );
const stringRegex = new RegExp( /\S/ );

export const timeRegex = new RegExp(
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/
);
export const timeRegexValidation =
  /^([0-1]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
export const passRegex =
  /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/;

const linkRegex = new RegExp(
  /[(http(s)?):\/\/(www\.)?a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/
);

export const over18 = today.setFullYear( today.getFullYear() - 18 );

export const dateOfBirthSchema = yup
  .string()
  .nullable()
  .required( "FIELD_IS_REQUIRED" )
  .test( "dob", "SHOULD_BE_OVER_18_YEARS_OLD", function ( value ) {
    return value && differenceInYears( new Date(), new Date( value ) ) >= 18;
  } );

export const phoneNumberSchema = yup
  .string()
  .test( "invalid", "INVALID_FORMAT", ( val ) => val && isValidPhoneNumber( val ) )
  .nullable()
  .required( "FIELD_IS_REQUIRED" );

export const emailSchema = yup
  .string()
  .email( "INVALID_FORMAT" )
  .nullable()
  .required( "FIELD_IS_REQUIRED" );
export const passwordSchema = yup
  .string( "Invalid Field" )
  .matches(
    passRegex,
    "FIELD_SHOULD_BE_MORE_THAN_8_CHARACTERS_LONG_AND_CONTAIN_AT_LEAST_ONE_UPPERCASE_LETTERONE_LOWERCASE_LETTER_,ONE_DIGIT_AND_ONE_SPECIAL_CHARACTER"
  )
  .required( "FIELD_IS_REQUIRED" );

export const fileSchema = ( required, type ) => {
  let schema = yup
    .mixed()
    .nullable()
    .test(
      "fileSize",
      "FILE_TOO_BIG",
      ( value ) => value && value.size <= fileSizes[ type ]
    )
    .test( "fileFormat", "INVALID_FILE_TYPE", ( value ) =>
      fileTypes[ type ].include( value?.type )
    );

  if ( required ) {
    schema = schema.required( "FIELD_IS_REQUIRED" );
  }
  return schema;
};
export const nameRequiredLimited = ( required, char ) => {
  let schema = yup
    .string()
    .nullable()
    .test(
      "len",
      `FIELD_SHOULD_BE_MAXIMUM_${ char }_CHARACTERS_LONG`,
      ( val ) => !val || val === "" || ( val !== "" && val.length < char )
    );

  if ( required ) {
    schema = schema
      .required( "FIELD_IS_REQUIRED" )
      .test( "len", "FIELD_IS_REQUIRED", ( val ) => val && val.length > 0 );
  }
  return schema;
};
