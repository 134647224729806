import { api } from "./main";

export const getReqConn = (payload) => {
  return api
    .get("v1/requested-connections", { params: payload })
    .then((res) => res)
    .catch((err) => err);
};

export const getFriendList = (data, name) => {
  return api
    .get("v1/connections", {
      params: data,
    })
    .then((res) => res)
    .catch((err) => err);
};

//GetFriendsList

export const GetFriendsList = (payload) => {
  let page = payload?.page ? `?page=${payload.page}` : "";
  return api
    .get(`v1/connections${page}`, { params: { ...payload } })
    .then((res) => res)
    .catch((err) => err);
};

export const AcceptReject = (data) => {
  return api
    .patch("v1/connections", data)
    .then((res) => res)
    .catch((err) => err);
};

export const getDiscover = (data) => {
  return api
    .get("v1/discover-users", { params: data })
    .then((res) => res)
    .catch((err) => err);
};

export const createConnection = (data) => {
  return api
    .post("v1/request-connections", data)
    .then((res) => res)
    .catch((err) => err);
};
