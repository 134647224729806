import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { CommentSVG, LikeIconSVG, LikeSVG } from "../../assets/icons/SVG";
import palette from "../../theme/color.scss"
import { Avatar, AvatarGroup, Button, Tooltip } from "@mui/material";
import { ReactionsArray } from "../../core/variables/Variables";
import "./PostReactions.scss"
import ReactionsDetail from "./reaction-details/ReactionsDetails";

export function PostReactions( props ) {
  //props
  const { handleLike, expandComments, data } = props;
  //variables
  const [ t ] = useTranslation( "common" );
  //initially only like icon will be shown if no reactions added
  const [ myReaction, setMyReaction ] = useState( null );
  const [ openDetail, setOpenDetail ] = useState( false );


  //checking the list of available reactions to display them
  const availableReactions = () => {
    let available_reactions = [];

    if ( data?.liked_count >= 1 ) {
      available_reactions.push( { ...ReactionsArray[ 0 ], count: data?.liked_count } );
    }
    if ( data?.loved_count >= 1 ) {
      available_reactions.push( { ...ReactionsArray[ 1 ], count: data?.loved_count } );
    }
    if ( data?.celebrated_count >= 1 ) {
      available_reactions?.push( { ...ReactionsArray[ 2 ], count: data?.celebrated_count } );
    }
    if ( data?.supported_count >= 1 ) {
      available_reactions.push( { ...ReactionsArray[ 3 ], count: data?.supported_count } );
    }

    if ( data?.insightful_count >= 1 ) {
      available_reactions?.push( { ...ReactionsArray[ 4 ], count: data?.insightful_count } );
    }
    return available_reactions;
  }


  const handleAddReaction = ( addedReaction ) => {
    setMyReaction( addedReaction );
    handleLike( false, data?.id, addedReaction?.id, addedReaction?.key, myReaction )
  }

  useEffect( () => {
    /*if the user has already interact with that post show the reaction based on the index
    if not show the like as default 
    */
    let reactionIndex = ReactionsArray?.findIndex( ( val ) => val?.id === ( data?.user_like?.length !== 0 ? data?.user_like?.[ 0 ]?.like_type_id : 1 ) )

    setMyReaction( ReactionsArray?.[ reactionIndex ] );

  }, [ data ] );


  return (
    <div className="reactions-comment-container"> { data?.likes_count ? (
      <div className="all-reactions-container cursor-pointer" onClick={ () => setOpenDetail( true ) }>
        <AvatarGroup max={ 5 }>
          { availableReactions()?.map( ( val, index ) => {
            return ( <Avatar
              key={ index }
              sx={ { width: 22, height: 22 } }
              alt="reaction"
              src={ val?.svg }
            /> )
          } ) }

        </AvatarGroup>
        <div className="likes-count"> { data?.likes_count }</div>
      </div>
    ) : null }

      <div className="post-emotions-content">
        <div className="emotions-actions">
          <Button
            startIcon={ data?.is_reacted === 1 ? myReaction?.svgComponent : <LikeSVG
              fill={ palette.secondaryColor }
            /> }
            onClick={ handleLike ? () => handleLike( data?.is_reacted ? true : false, data?.id, 1, data?.is_reacted ? "liked_count" : "", myReaction ) : null }
          >
            <span className={ data?.is_reacted === 1 ? myReaction?.className : "no-reaction" }>
              { data?.is_reacted === 1 ? myReaction?.name : t( "LIKE" ) }
            </span>
          </Button>



          { handleLike && (
            <div className="post-reaction-container fade-in">
              { ReactionsArray?.map( ( val ) => (
                <Tooltip key={ val?.id } title={ val?.name } placement="top">
                  <div
                    className={ `reaction-icon` }
                    onClick={ () => handleAddReaction( val ) }
                  >
                    <img
                      src={ val?.svg }
                      style={ { width: "26px", height: "26px" } }
                    />
                  </div>
                </Tooltip>
              ) ) }
            </div> ) }

        </div>

        { data?.is_commentable && (
          <div>
            <Button
              className="comment-action"
              onClick={ expandComments ? () => expandComments() : null }
              startIcon={ <CommentSVG /> }>
              <span className="comment-number">{ data?.comments_count }</span>
            </Button>



          </div>
        ) }
      </div>
      {
        openDetail && (
          <ReactionsDetail { ...props } checkAvailableReactions={ () => availableReactions() } onClose={ () => setOpenDetail( false ) } />
        )
      }

    </div >
  )
}

export default PostReactions;