//UTILITIES
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
import { useTranslation } from "react-i18next";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";

//COMPONENT
import { NoData } from "../../components/Fallback/NoData";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../core/functions/Functions";
import { dataPerPage } from "../../core/variables/Variables";
import TablePaginationSection from "../../components/table-pagination/TablePaginationSection";
import { ListComponentSkeleton } from "../../components/skeletons/SkeletonsComponent";
import * as MUI from "@mui/material";
//CSS
import "./Connections.scss";

// APIs
import * as connec from "../../core/services/connections";
import DialogComponent from "../../components/dialog/Dialog";
import Button from "../../components/button/Button";

//groupParamsByKey: this function converts searchParams to an Object
const MyConnections = () => {
  const Alert = useContext( AlertContext );

  const [ t ] = useTranslation( "common" );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { search } = groupParamsByKey( searchParams ) || {};
  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ connectionData, setConnectionData ] = useState( null );
  const [ openDelete, setOpenDelete ] = useState( false );
  const [ filter, setFilter ] = useState( {
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: searchParams.get( "page" ) || 1,
    role_ids: searchParams.getAll( "role_ids" ) || [],
  } );
  const [ isLoading, setIsloading ] = useState( true );

  const [ data, setData ] = useState( [] );

  //FUNCTIONS
  const handleResponse = ( item ) => {
    connec
      .AcceptReject( {
        connection_id: item?.id,
        is_accepted: false,
      } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          setOpenDelete( false );
          getAllConnections( false );
        }
        Alert?.[ res?.data?.success ? "success" : "warning" ](
          res?.data ? res?.data?.message : res?.message
        );
      } );
  };

  const getAllConnections = () => {
    setIsloading( true );
    connec
      .getFriendList( {
        name: search,
        per_page: searchParams.get( "per_page" ) || dataPerPage,
        page: +searchParams.get( "page" ) || 1,
        role_ids: searchParams.getAll( "role_ids" ) || [],
        speciality_ids: searchParams.getAll( "speciality_ids" ) || [],
      } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          setData( res?.data?.data ? res?.data?.data?.data : [] );
          setPageTotal( res.data.data?.last_page );
        } else {
          Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
        }
        setIsloading( false );
      } );
  };

  const handleActivityResponse = ( item ) => {
    setConnectionData( item );
    setOpenDelete( true );
  };

  useEffect( () => {
    getAllConnections();
  }, [ searchParams ] );

  const handlePageChange = ( e, page ) => {
    let newFilters = {
      ...groupParamsByKey( searchParams ),
      page: page,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };

  return (
    <>
      <MUI.Card className="my-connections-component">
        <MUI.CardHeader
          className="my-connections-header"
          title={ t( "CONNECTIONS" ) }
        />
        <MUI.CardContent>
          { !isLoading ? (
            data?.length !== 0 ? (
              <>
                <MUI.List style={ { paddingTop: "0px" } }>
                  { data?.map( ( item, index ) => (
                    <>
                      <MUI.ListItem className="request-list" disableRipple key={ index }>
                        <div className="profile-info">
                          <MUI.ListItemAvatar className="item-avatar">
                            <MUI.Avatar
                              src={
                                item?.user_info?.profile_image
                                  ? REACT_APP_IMAGE_URL +
                                  item?.user_info?.profile_image
                                  : ""
                              }
                              alt={
                                item?.user_info?.first_name +
                                " " +
                                item?.user_info?.last_name
                              }
                              variant={ "rounded" }
                            />
                          </MUI.ListItemAvatar>

                          <MUI.ListItemText
                            primary={
                              <div className="primary-text">
                                { item?.user_info?.first_name +
                                  " " +
                                  item?.user_info?.last_name }
                              </div>
                            }
                            primaryTypographyProps={ {
                              style: { whiteSpace: "normal" },
                            } }
                            secondary={
                              <div className={ "secondary-text" }>
                                { item?.user_info.role.name }{ item?.user_info?.portal_id === 2 ? ` ${ t( 'FROM' ) } ${ item?.user_info?.pharmaceutical_company?.name }` : "" }
                              </div>
                            }
                          />
                        </div>
                        <div className="action-buttons">
                          <Button
                            width="45%"
                            name="REMOVE"
                            style={ { padding: "0px" } }
                            onClick={ () => handleActivityResponse( item ) }
                            className="button"

                          />

                        </div>
                      </MUI.ListItem>

                      <MUI.Divider variant="fullWidth" />
                    </>
                  ) ) }
                </MUI.List>
                <TablePaginationSection
                  page={ filter?.page }
                  totalPage={ pageTotal }
                  data={ data }
                  handlePageChange={ handlePageChange }
                />
              </>
            ) : (
              <NoData
                title={ "NO_CONNECTIONS_AVAILABLE" }
                width="100%"
                NoData={ true }
                loading={ isLoading }
              />
            )
          ) : (
            <ListComponentSkeleton count={ 10 } />
          ) }
        </MUI.CardContent>
      </MUI.Card>
      { openDelete && (
        <DialogComponent
          data={ connectionData }
          message={ "ARE_YOU_SURE_YOU_WANT_TO_REMOVE" }
          handleResponse={ handleResponse }
          actions
          onClose={ () => setOpenDelete( false ) }
        />
      ) }
    </>
  );
};

export default MyConnections;
