import { api } from "./main";
import { useQuery } from "@tanstack/react-query";
export const addBlogs = ( data ) => {
  return api
    .post( "/v1/blogs", data )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const updateBlogs = ( blog_id, payload ) => {
  return api
    .post( `/v1/blogs/${ blog_id }`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getBlogs = ( payload ) => {
  return api
    .get( "v1/blogs", { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getBlogById = ( id ) => {
  return api
    .get( `v1/blogs/${ id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getBlogsComment = ( payload ) => {
  return api
    .get( `v1/blog-comments/${ payload?.blog_id }`, { params: payload } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getUserBlogs = ( payload ) => {
  return api
    .get( "v1/self-blogs", { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const likeBlog = ( payload ) => {
  return api
    .patch( `v1/like-blog/${ payload?.blog_id }`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const commentBlog = ( payload ) => {
  return api
    .patch( `v1/comment-blog/${ payload?.blog_id }`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const deleteBlog = ( blog_id ) => {
  return api
    .delete( `v1/blogs/${ blog_id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getTags = ( data ) => {
  return api
    .get( "v1/tags", { params: data } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getBlogLikes = ( payload ) => {
  return api
    .get( `v1/blog-likes/${ payload?.blog_id }`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getSimilarBlogs = ( data ) => {
  return api
    .get( `v1/similar-blogs/${ data?.blog_id }`, { params: data } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const useSimilarBlogs = ( payload ) =>
  useQuery( {
    queryKey: [ "similar-blogs", payload ],
    queryFn: () => getSimilarBlogs( payload ).then( ( res ) => res?.data ),
  } );