//UTILITIES
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams, Link } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
import { useTranslation } from "react-i18next";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../core/functions/Functions";
import { useInnerWidth } from "../../hooks/useInnerWidth";

//COMPONENT
import * as MUI from "@mui/material";
import { dataPerPage, filterDataPerPage } from "../../core/variables/Variables";

import CardReward from "../../components/cards/CardReward";
import SideComponent from "../../layout/sideBar/SideComponent";
import { CatalogSkeleton } from "../../components/skeletons/SkeletonsComponent";
import { NoData } from "../../components/Fallback/NoData";
import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";
//api
import * as ProductsApi from "../../core/services/products";

//CSS
import "./Catalog.scss";
import FilterPopUp from "../../components/filter-popup/FilterPopUp";

function Catalog() {
  const [ t ] = useTranslation( "common" );
  const Alert = useContext( AlertContext );
  const [ searchParams, setSearchParams ] = useSearchParams( {
    company_ids: [],
    therapeutic_ids: [],
    category_ids: [],
  } );
  const { search, sort } = groupParamsByKey( searchParams ) || {};

  //VARIABLES
  const screenWidth = useInnerWidth();
  const [ products, setProducts ] = useState( [] );
  const [ categoriesData, setCategoriesData ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );
  const [ filter, setFilter ] = useState( {
    company_ids: searchParams.getAll( "company_ids" ) || [],
    category_ids: searchParams.getAll( "category_ids" ) || [],
    therapeutic_ids: searchParams.getAll( "therapeutic_ids" ) || [],
  } );

  const [ isLoadingCategory, setIsLoadingCategory ] = useState( false );
  const [ totalPageCategory, setTotalPageCategory ] = useState( 1 );
  const [ isLoadMoreCategory, setIsLoadMoreCategory ] = useState( false );
  const [ pageCategory, setPageCategory ] = useState( 1 );

  const getAllCategories = ( new_page ) => {
    let handleLoad = new_page ? setIsLoadMoreCategory : setIsLoadingCategory;
    handleLoad( true );
    let current_page =
      new_page && pageCategory + 1 <= totalPageCategory
        ? pageCategory < totalPageCategory
          ? pageCategory + 1
          : pageCategory
        : 1;
    ProductsApi.getAllCategories( {
      page: current_page,
      per_page: filterDataPerPage,
      sort: 2,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setPageCategory( response.data.data?.current_page );
        if ( new_page ) {
          setCategoriesData( [ ...categoriesData, ...response.data.data.data ] );
        } else {
          setCategoriesData( response.data?.data?.data || [] );
        }

        setTotalPageCategory( response.data.data?.last_page );
      }
      handleLoad( false );
    } );
  };


  //GET PRODUCT IF NEW PAGE THEN LOAD MORE
  const getProductData = ( pagination ) => {
    let handleLoad = pagination ? setIsLoadMore : setIsLoading;
    handleLoad( true );

    let current_page =
      pagination && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;


    let payload = {
      page: current_page,
      per_page: dataPerPage,
      name: search,
      company_ids: searchParams.getAll( "company_ids" ) || [],
      category_ids: searchParams.getAll( "category_ids" ) || [],
      sort: sort,
      therapeutic_ids: searchParams.getAll( "therapeutic_ids" ) || [],
    };
    ProductsApi.getAllProducts( objectCleaner( payload ) ).then( ( res ) => {
      if ( res?.data?.success ) {
        setPage( res?.data?.data?.current_page );
        if ( pagination ) {
          setProducts( [ ...products, ...res?.data?.data?.data ] );
        } else {
          setProducts( res.data?.data?.data || [] );
        }

        setTotalPage( res.data.data?.last_page );
      } else {
        Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      }
      handleLoad( false );
    } );
  };

  const handleCategoryFilter = ( category ) => {
    let url = {
      ...groupParamsByKey( searchParams ),
      category_ids: category,
      page: 1,
    };

    setSearchParams( objectCleaner( url ) );
  };

  useEffect( () => {
    setFilter( {
      company_ids: searchParams.getAll( "company_ids" ) || [],
      category_ids: searchParams.getAll( "category_ids" ) || [],
      therapeutic_ids: searchParams.getAll( "therapeutic_ids" ) || [],
    } );
  }, [ searchParams ] );

  useEffect( () => {
    getAllCategories( false );
  }, [] );

  useEffect( () => {
    getProductData( false );
  }, [ search, sort, filter ] );

  return (
    <div className="globalStructure">
      <SideComponent
        style={ { maxHeight: "60vh" } }
        sortBy
        filter
        title={ t( "PRODUCT_CATALOG" ) }
        placeholder={ t( "SEARCH_BY_NAME" ) }
        addFilter={
          <FilterPopUp filterByCompanies
            filterByTherapeuticalAreas
            filterByCategories={ screenWidth < 900 } /> }

      >
        { screenWidth > 900 && (
          <>
            <MUI.List>
              <MUI.ListItemButton
                selected={ !searchParams.get( "category_ids" ) }
                onClick={ () => handleCategoryFilter( [] ) }
              >
                { t( "ALL" ) }
              </MUI.ListItemButton>
              <MUI.Divider className="product-category-list-divider" />
              { !isLoadingCategory &&
                categoriesData?.map( ( category ) => (
                  <React.Fragment key={ category?.id }>
                    <MUI.ListItemButton
                      onClick={ () => handleCategoryFilter( [ category?.id ] ) }
                      selected={ searchParams
                        .getAll( "category_ids" )
                        ?.includes( category?.id?.toString() ) }
                    >
                      { category?.name }
                    </MUI.ListItemButton>
                    <MUI.Divider className="product-category-list-divider" />
                  </React.Fragment>
                ) ) }
            </MUI.List>
            { pageCategory < totalPageCategory && !isLoading && (
              <LoadMoreButton
                isLoadMore={ isLoadMoreCategory }
                title="LOAD_MORE"
                onClick={ () => getAllCategories( true ) }
              />
            ) }
          </>
        ) }
      </SideComponent>
      <div className="middleSide">
        <div className="middleNoRightComponent">
          <MUI.Grid
            container
            rowSpacing={ 3 }
            columns={ { xs: 4, sm: 6, md: 10, lg: 13 } }
            columnSpacing={ { xs: 1, sm: 2, md: 1 } }
          >
            { isLoading ? (
              <CatalogSkeleton image count={ 10 } />
            ) : products?.length === 0 ? (
              <NoData title={ "NO_PRODUCTS_FOUND" } />
            ) : (
              products?.map( ( e, index ) => (
                <MUI.Grid
                  key={ index }
                  item
                  xs={ 3 }
                  className="cursor-pointer"
                  component={ Link }
                  to={ `${ e?.id }` }
                >
                  <CardReward
                    title={ e?.name }
                    description={ e.pharmaceutical_company?.name }
                    image={ e.product_media?.[ 0 ]?.path }
                    withDetails={ false }
                  />
                </MUI.Grid>
              ) )
            ) }
          </MUI.Grid>
          { page < totalPage && !isLoading && (
            <LoadMoreButton
              isLoadMore={ isLoadMore }
              title="LOAD_MORE"
              onClick={ () => getProductData( true ) }
            />
          ) }
        </div>
      </div>
    </div>
  );
}

export default Catalog;
