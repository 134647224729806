//utilities
import React, { useContext, useState, useEffect } from "react";
import * as yup from "yup";
import { useFormik } from "formik";
//COMPOENTN
import Button from "../../components/button/Button";
import { FormInput } from "../../components/form/form-componenrt/FormComponent";
import LinearLoaderComponent from "../../components/loader/LinearLoaderComponent";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import * as MUI from "@mui/material";
import { AlertContext } from "../../context/AlertContext";
import ViewMorePagination from "../../components/view-more-pagination/ViewMorePagination";
import { CommentItem } from "./CommentItem";
import { commentPerPage } from "../../core/variables/Variables";
//CSS
import "./CommentComponent.scss";

const HandleSchema = yup.object( {
  description: yup.string().nullable().required( "FIELD_IS_REQUIRED" ),
} );

export function CommentComponent( props ) {
  const {
    handleGetComment,
    post,
    blog,
    handleAddComment,
    updateCommentCount,
    user,
  } = props;
  const [ commentReplyId, setCommentReplyId ] = useState( null );
  const Alert = useContext( AlertContext );
  const [ data, setData ] = useState( [] );
  const [ loading, setLoading ] = useState( false );
  const [ page, setPage ] = useState( 1 );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ totalComments, setTotalComments ] = useState( 0 );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ loadMore, setLoadMore ] = useState( false );
  //INITIATE FORM FOR ADD COMMENT
  //INITIATE FORM:
  const formiks = useFormik( {
    initialValues: {
      description: "",
    },
    validationSchema: HandleSchema,
    onSubmit: ( payload ) => handleSubmitForm( payload ),
  } );

  const handleLoadChange = ( type ) => {
    if ( type === "more" ) {
      setPage( page + 1 );
      GetPaginatedData( page + 1 );
    } else {
      setPage( page - 1 );
      GetPaginatedData( page - 1 );
    }
  };

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    handleAddComment( {
      ...formData,
      parent_id: null,
      ...( post ? { post_id: post?.id } : { blog_id: blog?.id } ),
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        GetInitalCommentData();
        updateCommentCount( "add" );
      }
      Alert?.[ response.data?.success ? "success" : "warning" ](
        response.data ? response.data.message : response.message
      );

      setSaveLoading( false );
      formiks.setTouched( {}, false );
    } );
  };

  //GET PAGINATED DATA (IF WE CLICK VIEW MORE TO COMMENT )
  const GetPaginatedData = ( new_page ) => {
    setLoadMore( true );
    let old = [ ...data ];
    handleGetComment( {
      ...( post ? { post_id: post?.id } : { blog_id: blog?.id } ),
      per_page: commentPerPage,
      page: new_page,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        formiks.setValues( { description: "" } );
        setPage( response.data.data?.current_page );
        if ( response.data.data ) {
          response.data.data.data?.map( ( item ) => old.push( item ) );
        }

        setData( old );
        setTotalPage( response.data.data?.last_page );
      } else {
        Alert?.[ response.data?.success ? "success" : "warning" ](
          response.data ? response.data.message : response.message
        );
      }
      setLoadMore( false );
    } );
  };

  const GetInitalCommentData = ( perPage ) => {
    setLoading( true );

    handleGetComment( {
      ...( post ? { post_id: post?.id } : { blog_id: blog?.id } ),
      per_page: perPage ? perPage : commentPerPage,
      page: 1,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        formiks.setValues( { description: "" } );
        setPage( response.data.data?.current_page );
        setData( response.data.data ? response.data.data.data : [] );
        setTotalPage( response.data.data?.last_page );
        setTotalComments( response.data.data?.total );
      } else {
        Alert?.[ response.data?.success ? "success" : "warning" ](
          response.data ? response.data.message : response.message
        );
      }

      setLoading( false );
      setSaveLoading( false );
    } );
  };

  const handleSuccessResponse = ( type, commentChildrenCount ) => {
    if ( type !== "add" ) {
      GetInitalCommentData(); //delete
    } else {
      GetInitalCommentData( data?.length );
    }

    updateCommentCount( type, commentChildrenCount );
  };

  useEffect( () => {
    GetInitalCommentData();
  }, [] );

  return (

    <MUI.CardContent>
      { handleAddComment && ( <form onSubmit={ formiks.handleSubmit }>
        <div className="post-add-a-comment">
          <div className="avatar">
            <MUI.Avatar
              src={
                user?.profile_image
                  ? REACT_APP_IMAGE_URL + user.profile_image
                  : ""
              }
              alt={ user ? user.first_name + " " + user?.last_name : "" }
            />
          </div>
          <div className="input">
            <FormInput
              wrapperClassName={ "remove-margin-top" }
              name="description"
              placeholder="ADD_A_COMMENT"
              value={ formiks?.values?.description }
              onChange={ ( e ) => {
                formiks.setFieldValue( "description", e.target.value );
              } }
              helperText={
                formiks.touched?.description && formiks.errors?.description
              }
            />
          </div>
          <div>
            <Button
              isSelected
              name="POST"
              type="submit"
              loading={ saveLoading }
              disabled={ saveLoading ? true : false }
            />
          </div>
        </div>
      </form> ) }
      <div className="margin-up-10">
        { loading && <LinearLoaderComponent /> }
        { data.length !== 0 &&
          data?.map( ( commentElement ) => (
            <CommentItem
              { ...props }
              data={ commentElement }
              key={ commentElement?.id }
              handleSuccessResponse={ handleSuccessResponse }
              commentReplyId={ commentReplyId }
              setCommentReplyId={ setCommentReplyId }
            />
          ) ) }
      </div>
      <ViewMorePagination
        page={ page }
        loading={ loadMore }
        pageTotal={ totalPage }
        data={ data }
        dataLeft={ data ? totalComments - data.length : 0 }
        handleLoadChange={ handleLoadChange }
      />
    </MUI.CardContent>

  );
}
export default CommentComponent;
