import React from "react";
import { IconButton } from "@mui/material";
import "./BackRep.scss";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const BackRep = ( props ) => {
  const { title, to } = props;
  return (
    to ? ( <Link to={ to }>  <BackRepButton { ...props } /> </Link > ) : <BackRepButton { ...props } />
  );
};
export const BackRepButton = ( props ) => {
  const { onClick, title } = props;
  const [ t ] = useTranslation( 'common' )
  return (
    <div className="back-rep-container">
      <IconButton onClick={ onClick ? onClick : null }>
        <svg
          width="29"
          height="20"
          viewBox="0 0 10 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M7.49922 15.8086L9.26172 14.0461L3.53672 8.30859L9.26172 2.57109L7.49922 0.808594L-0.000781715 8.30859L7.49922 15.8086Z"
            fill="#02C0CC"
          />
        </svg>
      </IconButton>
      <h4>{ t( title ) }</h4></div>
  )
}


BackRep.propTypes = {
  to: PropTypes.any.isRequired,
  title: PropTypes.string,
};
export default BackRep;
