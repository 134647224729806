import React, { useContext } from "react";

import { CardMedia, Tab, Tabs } from "@mui/material";

import { useLocation, useNavigate, NavLink } from "react-router-dom";

import Button from "../button/Button";

import "./GroupInfoCard.scss";

import { useTranslation } from "react-i18next";
import { PermissionContext } from "../../context/PermissionContext";
function GroupInfoCard( props ) {
  const [ t ] = useTranslation( "common" );
  const { permissions } = useContext( PermissionContext );
  const {
    image,
    profile,
    loadingAction,
    groupName,
    companyName,
    information,
    is_joined,
    onJoin,
    onLeave,
    navigateTo,
    data,
    status,
    onAccept,
    onReject,
  } = props;

  let location = useLocation().pathname.split( "/" );
  let navigate = useNavigate();
  const handleChange = ( e, newValue ) => {
    navigate( newValue );
  };

  //checking user permission before the visibility of the section
  const checkUserGroupPermissions = ( userStatus ) => {
    if ( ( userStatus === "joined" && permissions?.Group?.[ 'leave-company-group' ] ) ||

      ( userStatus === "invited" && permissions?.Group?.[ 'accept-or-reject-group-invitation' ] ) ||
      ( userStatus === "has_left" && permissions?.Group?.[ 'join-company-group' ] )

    ) { return true }
    else {
      return false
    }
  };


  return (
    <div className="groupInfoCard">
      <div className="HeaderInfo">
        <div style={ { position: "relative" } }>
          <CardMedia
            component="img"
            height="120"
            image={ image }
            alt={ groupName }
          />
          <img className="profileImage" src={ profile } />
        </div>
        <p className="groupName">{ groupName }</p>
        <NavLink className="linksCompany" to={ navigateTo }>
          { companyName }
        </NavLink>
        <p className="information">{ information }</p>
        { checkUserGroupPermissions( data?.user_status ) && (
          <>
            { data?.user_status !== "invited" ? ( <div className="buttonArea">
              <Button
                onClick={ !is_joined ? onJoin : onLeave }
                className="JoinButton"
                isSelected={ !is_joined && true }
                width="20%"
                name={ !is_joined ? t( "JOIN" ) : t( "LEAVE_GROUP" ) }
              />
            </div> ) : <div className="buttonArea">
              <Button
                onClick={ onAccept }
                loading={ loadingAction?.accept }
                disabled={ loadingAction?.accept }
                className="JoinButton"
                isSelected
                width="20%"
                style={ { padding: "0px" } }
                name={ t( "ACCEPT" ) }
              />
              <Button
                onClick={ onReject }
                loading={ loadingAction?.reject }
                disabled={ loadingAction?.reject }
                className="JoinButton"
                width="20%"
                style={ { padding: "0px" } }
                name={ t( "REJECT" ) }
              />
            </div> }

          </> ) }
      </div>
      <div style={ { width: "100%" } }>
        <Tabs
          value={ location[ 5 ] }
          onChange={ handleChange }
          textColor="primary"
          aria-label="secondary tabs example"
          className="tabsCompo"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab to="feed" value="feed" name="feed" label={ t( "FEED" ) } />
          <Tab
            disabled={ !is_joined }
            to="media"
            value="media"
            name="media"
            label={ t( "MEDIA" ) }
          />
          <Tab
            disabled={ !is_joined ? true : false }
            to="members"
            value="members"
            name="members"
            label={ `${ t( "MEMBERS" ) } ${ data?.users_count !== 0 ? `(${ data?.users_count })` : ""
              }` }
          />
        </Tabs>
      </div>
    </div>
  );
}

export default GroupInfoCard;
