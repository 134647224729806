//UTILITIES
import React, { useState, useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useInnerWidth } from "../../hooks/useInnerWidth";
//COMPONENT
import {
  Avatar,
  Card,
  CardContent,
  ListItem,
  ListItemAvatar,
  ListItemText,
} from "@mui/material";
import { AttachIcon } from "../../assets/icons/SVG";
import palette from "../../theme/color.scss";
import ChatContact from "./ChatContacts";
import "./Chat.scss";

import { MessageList } from "./MessageElement";

import { ref, uploadBytesResumable, getDownloadURL } from "@firebase/storage";
import ChatUserDetail from "./ChatUserDetail";

import { sendMessage, storage } from "../../core/services/firebase";
import { SendChat } from "../../core/services/chat";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { FormSendMessageInput } from "../../components/form/form-componenrt/FormComponent";
import { UserContext } from "../../context/UserContext";
import { AlertContext } from "../../context/AlertContext";
import { ChatContext } from "../../context/ChatContext";

export function ChatMessenger( props ) {
  //CONTEXT
  const { handleOpenContacts } = props;
  const [ t ] = useTranslation( "common" );
  const { user } = useContext( UserContext );
  const { chatUser, setChatUser } = useContext( ChatContext );
  const { setAlert } = useContext( AlertContext );
  const { chatId } = useParams();
  const screenWidth = useInnerWidth();
  //VARIABLES
  //to always have a unique id
  const roomId = useMemo( () => {
    return `${ Math.min( user?.id, chatId ) }-${ Math.max( user?.id, chatId ) }`;

  }, [ user?.id, chatId ] )

  const [ openUserDetail, setOpenUserDetail ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  const [ value, setValue ] = useState( {
    value: null,
    isAttachement: false,
    type: "", //text , image , file
  } );

  //FUNCTIONS
  const handleChange = ( event ) => {
    setValue( { value: event.target.value, isAttachement: false, type: "text" } );
  };

  //Firebase Storage

  const handleSubmit = ( event ) => {
    event.preventDefault();

    if ( value?.value ) {
      setLoading( true );
      if ( value?.isAttachement ) {
        const storageRef = ref( storage, `/${ process.env.REACT_APP_FIREBASE_STORAGE_FILE }/${ value?.value?.name }` );
        const uploadTask = uploadBytesResumable( storageRef, value?.value );
        uploadTask.on(
          "state_changed",
          ( snapshot ) => {
            // var progress = Math.round(
            //   (snapshot.bytesTransferred / snapshot.totalBytes) * 100
            // );
          },
          ( err ) => {
            setAlert( { text: "FAILED", error: true } );
            setValue( {
              isAttachement: false,
              value: null,
              type: "",
            } );
            setLoading( false );
          },
          () => {
            getDownloadURL( uploadTask.snapshot.ref ).then( ( url ) => {
              sendMessage( roomId, user, url, chatId, value?.type );
              setValue( {
                isAttachement: false,
                value: null,
                type: "",
              } );

              setLoading( false );
              if (
                !chatUser?.user?.exist_user_chat &&
                !chatUser?.user?.user_chats
              ) {
                SendChat( { chat_id: roomId, user_id: chatId } );
                //meanwhile because the api is not recalled to set that the connection is done
                setChatUser( {
                  user: { ...chatUser?.user, exist_user_chat: true }, new: true
                } );
              }
            } );
          }
        );
      } else {
        sendMessage( roomId, user, value?.value, chatId, "text" );

        if ( !chatUser?.user?.exist_user_chat && !chatUser?.user?.user_chats ) {
          SendChat( { chat_id: roomId, user_id: chatId } );
          //meanwhile because the api is not recalled to set that the connection is done
          setChatUser( { user: { ...chatUser?.user, exist_user_chat: true }, new: true } );
        }

        setValue( {
          isAttachement: false,
          value: null,
          type: "",
        } );
        setTimeout( function () {
          setLoading( false );
        }, 1000 );
      }
    }
  };

  const onFileChange = ( e ) => {
    setValue( {
      isAttachement: true,
      value: e.target.files[ 0 ],
      type: e.target.files[ 0 ].type,
    } );
  };

  const handleDeleteImage = ( e ) => {
    setValue( {
      isAttachement: false,
      value: null,
      type: "",
    } );
  };

  return (
    <>
      <div className="messenger-header">
        { screenWidth < 900 && (
          <div
            className="cursor-pointer all-contacts-link"
            onClick={ () => handleOpenContacts() }
          >
            { t( "ALL_CONTACTS" ) }
          </div>
        ) }

        <Card className="messenger-header-card">
          <CardContent className="content">
            <ListItem
              //for now if the user isn't from doctor portal we will show extra detail like phone number and email
              className={ `${ chatUser?.user?.portal_id !== 3 ? "cursor-pointer" : "" }` }
              onClick={ chatUser?.user?.portal_id !== 3 ? () => setOpenUserDetail( true ) : null }
            >
              <ListItemAvatar>
                <Avatar
                  alt={ chatUser?.user?.first_name }
                  src={
                    chatUser?.user?.profile_image
                      ? REACT_APP_IMAGE_URL + chatUser?.user?.profile_image
                      : ""
                  }
                />
              </ListItemAvatar>
              <ListItemText
                primary={
                  chatUser?.user?.first_name + " " + chatUser?.user?.last_name
                }
              />
            </ListItem>
          </CardContent>
        </Card>
      </div>
      <div className="messenger-body">
        <Card className="messenger-body-card">
          <CardContent>
            <div>
              <MessageList roomId={ roomId } />
            </div>
          </CardContent>
        </Card>
      </div>
      { !chatUser?.user?.deleted_at && ( <div className="messenger-footer">
        <Card className="messenger-footer-card">
          <CardContent className="content">
            <div class="image-upload">
              <label for="file-input" className="cursor-pointer">
                <AttachIcon rotate fill={ palette.primaryColor } />
              </label>
              <input
                id="file-input"
                type="file"
                onClick={ ( event ) => ( event.target.value = null ) }
                onChange={ onFileChange }
                accept="application/msword, application/pdf, image/png"
              />
            </div>
            <FormSendMessageInput
              handleDelete={ () => handleDeleteImage() }
              value={ value }
              disabled={ value?.isAttachement }
              placeholder={ "WRITE_A_MESSAGE" }
              onChange={ handleChange }
              loading={ loading }
              onSend={ handleSubmit }
            />
          </CardContent>
        </Card>
      </div> ) }

      { openUserDetail && (
        <ChatUserDetail
          dialog
          data={ chatUser?.user }
          onClose={ () => setOpenUserDetail( false ) }
        />
      ) }

    </>
  );
}

export default ChatMessenger;
