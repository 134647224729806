import React from "react";

export const VideoSVG = () => (
  <svg
    width="34"
    height="26"
    viewBox="0 0 24 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M18.25 6.125V1.75C18.25 1.0625 17.6875 0.5 17 0.5H2C1.3125 0.5 0.75 1.0625 0.75 1.75V14.25C0.75 14.9375 1.3125 15.5 2 15.5H17C17.6875 15.5 18.25 14.9375 18.25 14.25V9.875L23.25 14.875V1.125L18.25 6.125Z"
      fill="#02C0CC"
    />
  </svg>
);
