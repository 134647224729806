import * as React from "react";

const GroupsSVG = (props) => (
  <svg
    width="31"
    height="16"
    viewBox="0 0 31 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ pointerEvents: "none" }}
  >
    <path
      d="M15.833 8.9375C17.8705 8.9375 19.6705 9.425 21.133 10.0625C22.483 10.6625 23.333 12.0125 23.333 13.475V15.5H8.33301V13.4875C8.33301 12.0125 9.18301 10.6625 10.533 10.075C11.9955 9.425 13.7955 8.9375 15.833 8.9375ZM5.83301 9.25C7.20801 9.25 8.33301 8.125 8.33301 6.75C8.33301 5.375 7.20801 4.25 5.83301 4.25C4.45801 4.25 3.33301 5.375 3.33301 6.75C3.33301 8.125 4.45801 9.25 5.83301 9.25ZM7.24551 10.625C6.78301 10.55 6.32051 10.5 5.83301 10.5C4.59551 10.5 3.42051 10.7625 2.35801 11.225C1.43301 11.625 0.833008 12.525 0.833008 13.5375V15.5H6.45801V13.4875C6.45801 12.45 6.74551 11.475 7.24551 10.625ZM25.833 9.25C27.208 9.25 28.333 8.125 28.333 6.75C28.333 5.375 27.208 4.25 25.833 4.25C24.458 4.25 23.333 5.375 23.333 6.75C23.333 8.125 24.458 9.25 25.833 9.25ZM30.833 13.5375C30.833 12.525 30.233 11.625 29.308 11.225C28.2455 10.7625 27.0705 10.5 25.833 10.5C25.3455 10.5 24.883 10.55 24.4205 10.625C24.9205 11.475 25.208 12.45 25.208 13.4875V15.5H30.833V13.5375ZM15.833 0.5C17.908 0.5 19.583 2.175 19.583 4.25C19.583 6.325 17.908 8 15.833 8C13.758 8 12.083 6.325 12.083 4.25C12.083 2.175 13.758 0.5 15.833 0.5Z"
      fill="#71A2A7"
    />
  </svg>
);

export default GroupsSVG;
