//UTILITIES
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
//MUI
import { Dialog, DialogContent, Divider, LinearProgress } from "@mui/material";
//COMPONENTS
import { FilterIcon } from "../../assets/icons/SVG";
import {
  FormCheckBox,
  FormRadioButton,
  FormSearchBar,
} from "../form/form-componenrt/FormComponent";
import InfiniteScroll from "react-infinite-scroller";
import Button, { IconsButton } from "../button/Button";
import { FilterSkeleton } from "../skeletons/SkeletonsComponent";
import { dataPerPage } from "../../core/variables/Variables";
//API
import { getSpecialities } from "../../core/services/register";
//CSS
import "./FilterPopUp.scss";
import { getRolesByMP } from "../../core/services/roles";
import { PaperComponent } from "../dialog/DialogPaper";
import DialogCustomTitle from "../dialog/DialogCustomTitle";
import {
  objectCleaner,
  groupParamsByKey,
} from "../../core/functions/Functions";
import NoData from "../Fallback/NoData";
import {
  getAllCompanies,
  getAllTherapeutics,
} from "../../core/services/companies";
import { getAllCategories, getAllProducts } from "../../core/services/products";
export default function FilterPopUp( props ) {
  const {
    filterByAllSpecialities,
    filterByRoles,
    filterByTherapeuticalAreas,
    filterByCategories,
    filterByCompanies,
    filterByProducts,
  } = props;
  const [ t ] = useTranslation( "common" );
  const { pathname } = useLocation();
  const [ filterSearchParams, setFilterSearchParams ] = useSearchParams();
  const [ openFilter, setOpenFilter ] = useState( false );
  //for inside search
  const [ filterSearch, setFilterSearch ] = useState( {
    role_ids: "",
    speciality_ids: "",
    category_ids: "",
    therapeutic_ids: "",
    company_ids: "",
    product_ids: "",
  } );

  //for setting state before changing the searchParams
  const [ searchable, setSearchable ] = useState( {
    speciality_ids: filterSearchParams.getAll( "speciality_ids" ) || [],
    role_ids: filterSearchParams.getAll( "role_ids" ) || [],
    status: filterSearchParams.get( "status" ) || null,
    category_ids: filterSearchParams.getAll( "category_ids" ) || [],
    therapeutic_ids: filterSearchParams.getAll( "therapeutic_ids" ) || [],
    company_ids: filterSearchParams.getAll( "company_ids" ) || [],
    product_ids: filterSearchParams.getAll( "product_ids" ) || [],
  } );

  //unified function for creating pagination state
  const createInitialDataState = () => ( {
    page: 1,
    per_page: dataPerPage,
    data: [],
    loading: false,
    totalPage: 1,
  } );

  const [ specialitiesData, setSpecialitiesData ] = useState( [
    createInitialDataState( [] ),
  ] );
  const [ rolesData, setRolesData ] = useState( [ createInitialDataState( [] ) ] );
  const [ categoriesData, setCategoriesData ] = useState( [
    createInitialDataState(),
  ] );
  const [ therapeuticalsData, setTherapeuticalsData ] = useState( [
    createInitialDataState(),
  ] );
  const [ companiesData, setCompaniesData ] = useState( [
    createInitialDataState(),
  ] );
  const [ productsData, setProductsData ] = useState( [
    createInitialDataState(),
  ] );

  const FilterArray = [
    {
      type: "checkbox",
      pagination: true,
      field: "speciality_ids",
      title: "SPECIALITIES",
      setState: setSpecialitiesData,
      api: getSpecialities,
      textSearch: true,
      data: specialitiesData,
      visible: filterByAllSpecialities,
    },
    {
      type: "checkbox",
      field: "role_ids",
      title: "ROLES",
      data: rolesData,
      textSearch: true,
      pagination: true,
      apiFilter: { portal_ids: [ 2, 3 ] },
      setState: setRolesData,
      api: getRolesByMP,
      visible: filterByRoles,
    },
    {
      type: "checkbox",
      field: "company_ids",
      pagination: true,
      title: "COMPANIES",
      api: getAllCompanies,
      setState: setCompaniesData,
      textSearch: true,
      data: companiesData,
      visible: filterByCompanies,
    },
    {
      type: "checkbox",
      pagination: true,
      field: "therapeutic_ids",
      api: getAllTherapeutics,
      title: "THERAPEUTICAL_AREAS",
      setState: setTherapeuticalsData,
      textSearch: true,
      data: therapeuticalsData,
      visible: filterByTherapeuticalAreas,
    },
    {
      type: "checkbox",
      pagination: true,
      field: "category_ids",
      api: getAllCategories,
      title: "CATEGORIES",
      setState: setCategoriesData,
      textSearch: false,
      data: categoriesData,
      visible: filterByCategories,
    },
    {
      type: "checkbox",
      pagination: true,
      field: "product_ids",
      api: getAllProducts,
      title: "PRODUCTS",
      setState: setProductsData,
      textSearch: true,
      data: productsData,
      visible: filterByProducts,
    },
  ];

  const FilterBox = FilterArray?.filter( ( el ) => el.visible );

  const handleCheckboxChange = ( field, value ) => {
    let array = searchable?.[ field ] || [];
    if ( value.value === false ) {
      let find_item = array?.indexOf( value.name?.toString() );
      array.splice( find_item, 1 );
    } else {
      array.push( value.name?.toString() );
    }

    setSearchable( { ...searchable, [ field ]: array } );
  };

  const handleRadioChange = ( field, value ) => {
    setSearchable( { ...searchable, [ field ]: value } );
  };

  //GET Data unified function for pagination of all apis
  const GetData = ( item, pagination, searchValue ) => {
    !pagination && item?.setState( { ...item?.data, loading: true } );
    let new_data = [];
    let current_page =
      pagination && item?.data?.page + 1 <= item?.data?.totalPage
        ? item?.data?.page < item?.data?.totalPage
          ? item?.data?.page + 1
          : item?.data?.page
        : 1;
    item
      ?.api( {
        ...( item?.apiFilter && { ...item?.apiFilter } ), //an already existing filtet
        ...( item?.pagination && {
          //pagination
          page: current_page,
          per_page: dataPerPage,
        } ),
        ...( filterSearch.hasOwnProperty( item?.field ) && {
          //filterable
          name: searchValue ? searchValue : filterSearch[ item?.field ],
        } ),
        sort: 2, //always alphabetically
      } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          if ( item?.pagination ) {
            //if the data include pagination
            if ( pagination ) {
              //if pagination parameter is true
              new_data = [ ...item?.data?.data, ...res?.data?.data?.data ];
            } else {
              new_data = res.data?.data?.data || [];
            }

            item?.setState( {
              per_page: dataPerPage,
              page: current_page,
              data: new_data,
              loading: false,
              totalPage: res?.data?.data?.last_page,
            } );
          } else {
            //data doesn't include pagination
            item?.setState( {
              per_page: 1,
              page: 1,
              data: res?.data?.data,
              loading: false,
              totalPage: 1,
            } );
          }
        }
      } );
  };
  const handleSearch = () => {
    // setFilterSearchParams( {
    //   ...groupParamsByKey( filterSearchParams ),
    //   ...objectCleaner( searchable ),
    // } );
    // setOpenFilter( false );
    //taking all previous search and override the new ones
    //if already user search by name in a table and then choose a filter
    setFilterSearchParams( { ...objectCleaner( searchable ) } );
    setOpenFilter( false )
  };
  const handleResetFilter = () => {
    let newFilter = {
      speciality_ids: [],
      role_ids: [],
      status: null,
      category_ids: [],
      therapeutic_ids: [],
      company_ids: [],
      product_ids: [],
    };
    setSearchable( {
      ...newFilter,
    } );
    let newFilters = {
      ...groupParamsByKey( filterSearchParams ),
      page: 1,
      per_page: dataPerPage,
      ...newFilter,
    };

    setFilterSearchParams( objectCleaner( newFilters ) );
    setOpenFilter( false );
  };
  //seaching the filter data when user removes its hand from the keyboard
  const debounced = useDebouncedCallback(
    // function
    ( item, value ) => {
      GetData( item, false, value );
    },
    // delay in ms
    500
  );
  useEffect( () => {
    FilterBox.map( ( el ) => {
      el?.api && GetData( el, false, "" ); //check if item has api if static don't enter the function
    } );
  }, [] );

  //#resetting filters on pathname change
  useEffect( () => {

    setSearchable( {
      speciality_ids: filterSearchParams.getAll( "speciality_ids" ) || [],
      role_ids: filterSearchParams.getAll( "role_ids" ) || [],
      status: filterSearchParams.get( "status" ) || null,
      category_ids: filterSearchParams.getAll( "category_ids" ) || [],
      therapeutic_ids: filterSearchParams.getAll( "therapeutic_ids" ) || [],
      company_ids: filterSearchParams.getAll( "company_ids" ) || [],
      product_ids: filterSearchParams.getAll( "product_ids" ) || [],
    } );
  }, [ pathname ] );
  return (
    <>
      <IconsButton icon={ <FilterIcon /> } onClick={ () => setOpenFilter( true ) } />
      { openFilter && (
        <Dialog
          open={ true }
          fullWidth
          keepMounted
          className="FilterPopUp"
          aria-labelledby="draggable-dialog-title"
          PaperComponent={ PaperComponent }
        >
          <DialogCustomTitle
            title={ "FILTER" }
            onClose={ () => {
              setOpenFilter( false );
            } }
            classNames={ `dialog-title-bb-color draggable-dialog-title` }
          />
          <DialogContent className="filter-dialog-content">
            <div className="clear-button">
              <Button
                className="clear"
                name="CLEAR_FILTER"
                onClick={ handleResetFilter }
              />
            </div>

            { FilterBox?.map( ( item, index ) => (
              <React.Fragment key={ index }>
                <div className="filter-content">
                  <div className="filter-name-searchBar">
                    <p>{ t( item.title ) }</p>
                    { !item?.data?.loading && item?.textSearch && (
                      <div className="searchBar">
                        <FormSearchBar
                          placeholder="SEARCH"
                          variant="standard"
                          value={ filterSearch[ item.field ] }
                          onChange={ ( value ) => {
                            setFilterSearch( {
                              ...filterSearch,
                              [ item.field ]: value,
                            } );
                            debounced( item, value );
                          } }
                        />
                      </div>
                    ) }
                  </div>

                  { item.type === "checkbox" || item?.type === "radio" ? (
                    item?.data?.loading ? (
                      <FilterSkeleton />
                    ) : item?.data?.data?.length === 0 ? (
                      <NoData variant={ "text" } />
                    ) : (
                      <div className="data-display">
                        { item.type === "radio" ? (
                          <div className="data">
                            <FormRadioButton
                              required
                              row={ true }
                              notObject
                              data={ item.data?.data } //if data is based on pagination
                              value={ searchable?.[ item?.field ] || null }
                              onChange={ ( value ) =>
                                handleRadioChange( item?.field, value )
                              }
                            />
                          </div>
                        ) : (
                          <InfiniteScroll
                            pageStart={ 0 }
                            initialLoad={ false }
                            loadMore={ () => GetData( item, true, "" ) }
                            hasMore={
                              !item?.data?.loading &&
                                item?.data?.page < item?.data?.totalPage
                                ? true
                                : false
                            }
                            loader={ <LinearProgress /> }
                            useWindow={ false }
                            threshold={ 300 }
                          >
                            <div className="data">
                              { " " }
                              { item.data?.data?.map( ( sub_item, sub_index ) => (
                                <FormCheckBox
                                  booleanValue={ false }
                                  value={ sub_item?.id?.toString() }
                                  key={ sub_index }
                                  helperText=""
                                  checked={
                                    searchable?.[ item.field ]?.includes(
                                      sub_item?.id?.toString()
                                    )
                                      ? true
                                      : false
                                  }
                                  label={ sub_item.name }
                                  onChange={ ( value ) => {
                                    handleCheckboxChange( item?.field, {
                                      name: sub_item.id,
                                      value: value.target.checked,
                                    } );
                                  } }
                                />
                              ) ) }
                            </div>
                          </InfiniteScroll>
                        ) }
                      </div>
                    )
                  ) : (
                    ""
                  ) }

                  { index !== FilterBox?.length - 1 && (
                    <Divider className="filter-divider" />
                  ) }
                </div>
              </React.Fragment>
            ) ) }

            <div className="search-button">
              <Button
                name="SEARCH"
                isSelected
                disabled={ FilterBox?.some( ( item ) => item?.data?.loading ) }
                onClick={ () => handleSearch() }
              />
            </div>
          </DialogContent>
        </Dialog>
      ) }
    </>
  );
}
