import { useEffect } from "react";
import Proptypes from "prop-types";
export const FormPrompt = (props) => {
  const { hasUnsavedChanges } = props;
  useEffect(() => {
    const onBeforeUnload = (e) => {
      if (hasUnsavedChanges) {
        e.preventDefault();
        e.returnValue = "";
      }
    };
    window.addEventListener("beforeunload", onBeforeUnload);
    return () => {
      window.removeEventListener("beforeunload", onBeforeUnload);
    };
  }, [hasUnsavedChanges]);
};
FormPrompt.propTypes = {
  hasUnsavedChanges: Proptypes.bool,
};
