import React, { useEffect } from "react";
import Form from "../../../components/form/forms/Form";
import PasswordChanged from "../../../assets/icons/password-changed.svg";
import "./PasswordChanged.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { CardContent, Card } from "@mui/material";
function EmailSent() {
  const [t] = useTranslation("common");
  let navigate = useNavigate();
  useEffect(() => {
    setTimeout(function () {
      navigate(`/login`);
    }, 2000);
  }, []);
  return (
    <div className="page-container loginProcess-container">
      <Card>
        <CardContent className="passwordchanged-container">
          <div>
            <img alt="" src={PasswordChanged} />
          </div>
          <h5>{t("PASSWORD CHANGED!")}</h5>
          <div className="success-message">
            {t("YOUR_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY")}
          </div>
        </CardContent>
      </Card>

      {/* <Form title="">
        <div className="email-icon-container">
          <div style={{ padding: "10px" }}>
            <img alt="" src={PasswordChanged} />
          </div>
          <h5>{t("PASSWORD CHANGED!")}</h5>
          <h6 style={{ fontWeight: "100" }}>
            {t("YOUR_PASSWORD_HAS_BEEN_RESET_SUCCESSFULLY")}
          </h6>
        </div>
      </Form> */}
    </div>
  );
}

export default EmailSent;
