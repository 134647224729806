//UTILITIES
import React, { useMemo, useState } from 'react';
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment-timezone";
import PropTypes from "prop-types";

//CSS
import "./DisplayComponent.scss"
//COMPONENT
import { Avatar, Tooltip } from "@mui/material";
import { dateFormat } from "../../core/variables/Variables";
import { MultipleTags } from "../../assets/icons/SVG";



export const DisplayDateTime = ( props ) => {
  const { format, data, calendar } = props;
  const [ t ] = useTranslation( 'common' )
  if ( data ) {
    const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone; //to get the timezone of the user<
    const utcMoment = moment.utc( data, "YYYY-MM-DD HH:mm:ss" );
    const beirutMoment = utcMoment.tz( userTimezone );

    return calendar ? beirutMoment.calendar( null, {
      sameDay: `[${ t( "TODAY" ) }]`,
      lastDay: `[${ t( "YESTERDAY" ) }]`,
      lastWeek: "DD/MM/YYYY",
      sameElse: "DD/MM/YYYY",
    } ) : beirutMoment.format( format )

  } else {
    return "-";
  }
};
DisplayDateTime.defaultProps = {
  format: dateFormat,
};
DisplayDateTime.propTypes = {
  format: PropTypes.string,
  data: PropTypes.string,
};

export const DisplayTranslatedValue = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const { data } = props;
  const keys = Object.keys( data );

  return data
    ? data.hasOwnProperty( localStorage.getItem( "lang" ) )
      ? data[ localStorage.getItem( "lang" ) ]
      : data[ keys[ 0 ] ]
    : t( "NOT_AVAILABLE" );
};

export const DisplayAvatar = ( props ) => {
  const { path, data } = props;
  return (
    <div className="cell-with-image">
      <Avatar variant="rounded" src={ path } />

      { data && <div>&nbsp; { data }</div> }
    </div>
  );
};

export const DisplayTags = ( props ) => {
  const { data, max } = props;


  const tagsandSpecialties = useMemo( () => {
    if ( data ) {
      if ( data?.tags?.length !== 0 || data?.field_categories?.length !== 0 ) {
        let tags = data?.tags && data?.tags?.length !== 0 ? data?.tags : [];
        let specialties = data?.field_categories && data?.field_categories?.length !== 0 ? data?.field_categories : [];
        return [ ...specialties, ...tags ]?.map( ( el ) => { return el?.name } );
      }
    }

  }, [ data ] )

  //we put them into different functions to be able to track the length of the initial array before the slice
  const finalData = useMemo( () => {
    return max ? tagsandSpecialties?.slice( 0, max ) : tagsandSpecialties
  }, [ tagsandSpecialties ] )

  return (
    <div className="tags-container">
      { finalData?.map( ( tag, tagIndex ) => {
        return (
          <div className="tag-element" key={ tagIndex }>
            { max !== null && tagIndex === ( max - 1 ) && tagsandSpecialties?.length > max && (
              <MultipleTags className="tag-badge" />
            ) }
            { tag }
          </div>
        );
      } ) }

    </div>
  )
}
DisplayTags.defaultProps = {
  data: [],
  max: 1,
};
DisplayTags.propTypes = {
  max: PropTypes.number,
  data: PropTypes.array,
};


export const DisplayTooltip = ( props ) => {
  const { followCursor, title, arrow, componentsProps, placement, children } = props
  const [ openTooltip, setOpenTooltip ] = useState( false );

  const handleTooltipOpen = () => {
    setOpenTooltip( !openTooltip );
  };

  const handleTooltipClose = () => {
    setOpenTooltip( false );
  };

  return (

    <Tooltip
      title={ title }
      followCursor={ followCursor }
      placement={ placement }
      arrow={ arrow }
      open={ openTooltip }
      componentsProps={ componentsProps }
      onClose={ handleTooltipClose }
    >
      <span className={ title ? "cursor-pointer" : "" } onClick={ handleTooltipOpen }>{ children }</span>
    </Tooltip>
  )
}

DisplayTooltip.defaultProps = {
  title: "",
  arrow: true,
  followCursor: false,
  placement: "bottom-end",
  componentsProps: {
    tooltip: {
      sx: {
        marginTop: "15px",
        backgroundColor: "#02c0cc",
      },
    },
    arrow: {
      backgroundColor: "#02c0cc",
    },
  }
};
DisplayTooltip.propTypes = {
  title: PropTypes.string,
  placement: PropTypes.string,
  arrow: PropTypes.bool,
  followCursor: PropTypes.bool,
  componentsProps: PropTypes.any,
};
