import * as React from "react";

const MeetingsSVG = (props) => (
  <svg
    width="26"
    height="18"
    viewBox="0 0 26 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ pointerEvents: "none" }}
  >
    <path
      d="M19.9163 6.875V1.91667C19.9163 1.1375 19.2788 0.5 18.4997 0.5H1.49967C0.720508 0.5 0.0830078 1.1375 0.0830078 1.91667V16.0833C0.0830078 16.8625 0.720508 17.5 1.49967 17.5H18.4997C19.2788 17.5 19.9163 16.8625 19.9163 16.0833V11.125L25.583 16.7917V1.20833L19.9163 6.875Z"
      fill={props.fill ? props.fill : "#71A2A7"}
    />
  </svg>
);

export default MeetingsSVG;
