
//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import PropTypes from "prop-types";
import { objToFormData } from "../../../core/functions/Functions";
//COMPONENT
import DialogCustomTitle from "../../../components/dialog/DialogCustomTitle";
import Button from "../../../components/button/Button";
import * as MUI from "@mui/material";
import {
  FormDropdownList,
  FormInput,
  FormMultiSelect,
  FormTagInput,
} from "../../../components/form/form-componenrt/FormComponent";
//API
import { addBlogs, getTags } from "../../../core/services/blogs";
import { getSpecialities } from "../../../core/services/register";
import { visibilityStatuses } from "../../../core/variables/Variables";

const validationSchema = yup.object().shape( {
  title: yup.string( "INVALID_FORMAT" ).nullable().required( "FIELD_IS_REQUIRED" ),
  tags_id: yup
    .array()
    .nullable()
    .required( "FIELD_IS_REQUIRED" )
    .min( 1, "FIELD_IS_REQUIRED" ),
  speciality_ids: yup.array().nullable(),
  description: yup.string().nullable().required( "FIELD_IS_REQUIRED" ),
  tags: yup
    .array()
    .nullable()
    .when( "tags_id", {
      is: ( value ) => value && value?.includes( 0 ),
      then: yup
        .array()
        .nullable()
        .required( "FIELD_IS_REQUIRED" )
        .min( 1, "FIELD_IS_REQUIRED" ),
      otherwise: yup.array().nullable(),
    } ),
  is_gazette: yup.bool(),
  status_id: yup.number().nullable(),
  is_active: yup.bool(),
} );

export function QuestionHandle( props ) {
  //TRANSLATION :
  const [ t ] = useTranslation( "common" );
  //CONTEXT
  const alert = useContext( AlertContext );
  //VARIABLES
  const { onClose, onSuccess, type, topicData, } = props;
  const [ specialtiesArray, setSpecialtiesArray ] = useState( [] );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ tags, setTags ] = useState( [] );
  const visibilityStatusData = visibilityStatuses?.map( ( el ) => { return { ...el, name: t( el?.name ) } } )
  //INITIATE FORM:
  const formiks = useFormik( {
    initialValues: {
      title: type ? `${ type === "product" ? t( 'QUESTION_ABOUT' ) : t( 'QUESTION_FOR' ) } ${ topicData?.name }` : "",
      description: "",
      is_gazette: false,
      is_active: true,
      tags: [],
      status_id: 1, //always publish
      tags_id: [],
      speciality_ids: [],
      visibility_status: visibilityStatusData?.find( ( el ) => el?.id === ( type === "product" ? "public" : "private" ) )

    },
    validationSchema: validationSchema,
    onSubmit: ( payload ) => handleSubmitForm( payload ),
  } );

  const handleSubmitForm = ( formValues ) => {
    setSaveLoading( true );
    let field = type ? type === "product" ? "product_id" : "pharmaceutical_company_id" : ""
    let values = {
      ...formValues,
      visibility_status: formValues?.visibility_status?.id,
      speciality_ids: formValues?.speciality_ids?.filter( ( el ) => el !== 0 ),
      tags_id: formValues?.tags_id?.filter( ( el ) => el !== 0 ),
      /*EXPLANATION:
      In case the question is not general :it's from product or company
      */
      ...( type && {
        [ field ]: topicData?.id
      } ),
    };
    addBlogs( objToFormData( values ) ).then( ( res ) => {

      alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
      if ( res?.data?.success ) {
        onClose();
        onSuccess && onSuccess();
      }

      setSaveLoading( false );
    } );
  };

  //GETTING SPECIALTIES DATA FOR DROPDOWN
  const getAllSpecialties = () => {
    getSpecialities().then( ( res ) => {
      if ( res?.data?.success ) {
        setSpecialtiesArray( res?.data?.data );
      }
    } );
  }
  //GETTING TAGS DATA FOR DROPDOWN
  const getAllTags = () => {
    getTags().then( ( res ) => {
      if ( res?.data?.success ) {
        setTags( [
          ...res?.data?.data,
          {
            id: 0,
            name: t( "OTHER" ),
          },
        ] );
      }
    } );
  };

  useEffect( () => {
    getAllTags();
    getAllSpecialties();
  }, [] );

  return (
    <MUI.Dialog disablebackdropclick="true" fullWidth open={ true }>
      <DialogCustomTitle
        classNames={ `dialog-title-bb-color` }
        title={ "POST_A_QUESTION" }
        onClose={ () => {
          onClose();
        } }
      />

      <MUI.DialogContent className="post-a-question-component">
        <form onSubmit={ formiks.handleSubmit }>
          <FormInput
            label="TITLE"
            placeholder="ENTER_TITLE"
            name="title"
            value={ formiks.values?.title }
            onChange={ ( event ) =>
              formiks.setFieldValue( "title", event.target.value )
            }
            helperText={ formiks.touched?.title && formiks.errors?.title }
          />
          <FormDropdownList
            label="VISIBILITY_STATUS"
            placeholder="SELECT_STATUS"
            name="visibility_status"
            data={ visibilityStatusData }
            value={ formiks.values?.visibility_status }
            onChange={ ( value ) =>
              formiks.setFieldValue( "visibility_status", value )
            }
            helperText={ formiks.touched?.visibility_status && formiks.errors?.visibility_status }
          />

          { !topicData && (
            <FormMultiSelect
              data={ specialtiesArray }
              label={ "SPECIALITIES" }
              placeholder="SELECT_SPECIALITY"
              value={ formiks.values?.speciality_ids }
              onChange={ ( val ) => {
                formiks.setFieldValue( "speciality_ids", val );
                formiks.setFieldError( "speciality_ids", "" );
              } }
              helperText={ formiks.touched?.speciality_ids && formiks.errors?.speciality_ids }
            /> ) }

          < FormMultiSelect
            data={ tags }
            label={ "TAGS" }
            placeholder="SELECT_TAGS"
            value={ formiks.values?.tags_id }
            onChange={ ( val ) => {
              formiks.setFieldValue( "tags_id", val );
              formiks.setFieldError( "tags_id", "" );
            } }
            helperText={ formiks.touched?.tags_id && formiks.errors?.tags_id }
          />

          { formiks?.values?.tags_id?.includes( 0 ) && (
            <FormTagInput
              required
              label={ "TAGS" }
              placeholder={ "ADD_TAGS" }
              name="tags"
              value={ formiks.values?.tags }
              onChange={ ( val ) => {
                formiks.setFieldValue( "tags", val );
                formiks.setFieldError( "tags", "" );
              } }
              helperText={ formiks.touched?.tags && formiks.errors?.tags }
            />
          ) }

          <FormInput
            label="QUESTION"
            rows={ 4 }
            placeholder="ENTER_QUESTION"
            name={ `description` }
            value={ formiks.values?.description }
            onChange={ ( e ) =>
              formiks.setFieldValue( "description", e.target.value )
            }
            helperText={
              formiks.touched?.description && formiks.errors?.description
            }
          />
          <div className="button-container">
            <Button
              onClick={ () => onClose() }
              name="CANCEL"
              width="15% !important"
            />
            <Button
              type="submit"
              isSelected
              disabled={ saveLoading }
              loading={ saveLoading }
              name={ "SUBMIT" }
              width="15% !important"
            />
          </div>
        </form>
      </MUI.DialogContent>
    </MUI.Dialog>
  );
}
export default QuestionHandle;
QuestionHandle.defaultProps = {
  type: null,
  topicData: null,
};

QuestionHandle.propTypes = {
  type: PropTypes.string,
  topicData: PropTypes.object,
};