//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../core/functions/Functions";
import { useSearchParams } from "react-router-dom/dist";
import { AlertContext } from "../../context/AlertContext";
import { dataPerPage } from "../../core/variables/Variables";
//COMPONENT
import CompanyDefaultImage from "../../assets/images/company-default-image.png";
import { Grid } from "@mui/material";
import { NoData } from "../../components/Fallback/NoData";
import { CatalogSkeleton } from "../../components/skeletons/SkeletonsComponent";
import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";
import CardReward from "../../components/cards/CardReward";
import { SearchBar } from "../../components/form/Search/SearchBar";
//CSS
import "./Companies.scss";
import { getSuggestedCompanies } from "../../core/services/companies";

const Companies = () => {
  const [ t ] = useTranslation( "common" );
  let navigate = useNavigate();
  const Alert = useContext( AlertContext );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ name, setname ] = useState( searchParams.get( "search" ) );
  const { search } = groupParamsByKey( searchParams ) || {};
  const [ data, setData ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );

  const getAllData = ( pagination ) => {
    let handleLoad = pagination ? setIsLoadMore : setIsLoading;
    handleLoad( true );

    let new_data = [];
    let current_page =
      pagination && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;
    getSuggestedCompanies( {
      name: search,
      page: current_page,
      per_page: dataPerPage,
      sort: 2,
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setPage( res?.data?.data?.current_page );
        setTotalPage( res.data.data?.last_page );
        if ( pagination ) {
          new_data = [ ...data, ...res?.data?.data?.data ];
        } else {
          new_data = res.data?.data?.data || [];
        }
        setData( new_data );
      } else {
        Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      }
      handleLoad( false );
    } );
  };

  const debounced = useDebouncedCallback(
    // function
    ( value ) => {
      let url = {
        ...groupParamsByKey( searchParams ),
        search: value,
        page: 1,
      };
      setSearchParams( objectCleaner( url ) );
    },
    // delay in ms
    500
  );


  useEffect( () => {
    getAllData();
  }, [ search ] );

  const handleNavigate = ( id ) => {
    navigate( `../companies-details/about/${ id }` );
  };
  return (
    <div className="middleNoLeftRightComponent companies-card">
      <h1 className="primary-title">{ t( "COMPANIES" ) }</h1>
      <div>
        <SearchBar
          className="search-company"
          placeholder={ t( "SEARCH_BY_NAME" ) }
          value={ name }
          onChange={ ( e ) => {
            setname( e.target?.value );
            debounced( e.target?.value );
          } }
        />
      </div>
      <div className="companies-grid">
        <Grid
          container
          rowSpacing={ 3 }
          columns={ { xs: 4, sm: 6, md: 10, lg: 13 } }
          columnSpacing={ { xs: 1, sm: 2, md: 1 } }
        >
          { isLoading ? (
            <CatalogSkeleton image count={ 10 } />
          ) : data.length === 0 ? (
            <NoData title={ "NO_COMPANIES_FOUND" } />
          ) : (
            <>
              { data?.map( ( e, index ) => (
                <Grid
                  key={ index }
                  item
                  xs={ 3 }
                  onClick={ () => handleNavigate( e.id ) }
                  className="card-reward-hover"
                >
                  <CardReward
                    rewardId={ e.id }
                    title={ e.name }
                    description={ e?.activity_domains?.name || e?.activity_domain_description }
                    points={ e.points }
                    defaultImage={ CompanyDefaultImage }
                    image={ e.profile_picture }
                  />
                </Grid>
              ) ) }
              { page < totalPage && !isLoading && (
                <LoadMoreButton
                  isLoadMore={ isLoadMore }
                  title="LOAD_MORE"
                  onClick={ () => getAllData( true ) }
                />
              ) }
            </>
          ) }
        </Grid>
      </div>
    </div>
  );
};
export default Companies;
