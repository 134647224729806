//REACT
import React from "react";
import PropTypes from "prop-types";
import TableCustomHeader from "./TableCustomHeader";
import * as MUI from "@mui/material";

//CSS
import "./TableComponent.scss";
import { TableSkeleton } from "../skeletons/SkeletonsComponent";
import NoData from "../Fallback/NoData";
import { dataPerPage } from "../../core/variables/Variables";

const TableComponent = (props) => {
  const {
    requestSort,
    orderBy,
    tableHeaders,
    actions,
    tableData,
    sortedBy,
    collapse,
    loading,
    onAdd,
    children,
    onFilter,
  } = props;

  const handleSortRequest = (value) => {
    requestSort(value);
  };

  return (
    <div className="table-section">
      <MUI.TableContainer className="table-container">
        <MUI.Table>
          <TableCustomHeader
            collapse={collapse}
            requestSort={handleSortRequest}
            orderBy={orderBy}
            sortedBy={sortedBy}
            headers={tableHeaders}
            actions={actions}
            onAdd={onAdd}
            onFilter={onFilter}
          />
          {!loading && tableData?.length !== 0 && (
            <MUI.TableBody>{children}</MUI.TableBody>
          )}
        </MUI.Table>
        {!loading && tableData?.length === 0 && (
          <div className="table-no-data-found">
            <NoData height={200} width={200} />
          </div>
        )}
        {loading && <TableSkeleton count={dataPerPage} />}
      </MUI.TableContainer>
    </div>
  );
};

TableComponent.defaultProps = {
  actions: true,
  collapse: false,
  orderBy: "",
  sortedBy: "",
  collapse: false,
};
TableComponent.propTypes = {
  modelType: PropTypes.array,
  onAdd: PropTypes.func,
  onOpen: PropTypes.func,
  requestSort: PropTypes.func,
  actions: PropTypes.bool,
  collapse: PropTypes.bool,
  headers: PropTypes.array,
  orderBy: PropTypes.string,
  sortedBy: PropTypes.string,
  requestSort: PropTypes.func,
};
export default TableComponent;
