
import { useTranslation } from "react-i18next";
import React, { useState } from "react";
import { pdfjs } from "react-pdf";
import { Document, Page } from "react-pdf";
import { SizeMe } from "react-sizeme";
import {
  IconButton,
  LinearProgress,
  Skeleton,
} from "@mui/material";
import {
  SingleNextIcon,
  SinglePreviousIcon,
} from "../../assets/icons/SVG";
import { Download } from "@mui/icons-material";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";


import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "./PDFReader.scss"

pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${ pdfjs.version }/legacy/build/pdf.worker.min.js`;

const PDFReader = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const { data } = props;
  const [ numPages, setNumPages ] = useState( null );
  const [ pageNumber, setPageNumber ] = useState( 1 );


  const onDocumentLoadSuccess = ( { numPages } ) => {
    setNumPages( numPages );
  };

  return (
    <SizeMe
      monitorHeight
      refreshRate={ 128 }
      refreshMode={ "debounce" }
      render={ ( { size } ) => (
        <div className="pdfViewerWrapper">
          <div className="pdfViewerContainer">
            <Document
              file={ REACT_APP_IMAGE_URL + data?.post_media?.[ 0 ]?.path }
              onLoadSuccess={ onDocumentLoadSuccess }
              loading={ <Skeleton width={ "100%" } height={ "400px" } /> }
            >
              <Page
                width={ size?.width }
                pageNumber={ pageNumber }
                loading={
                  <Page
                    pageNumber={ pageNumber - 1 }
                    width={ size?.width }
                  /> }
              />
            </Document>
            <div className="pdfViewerNav">
              <IconButton
                className="previous-button"
                disabled={ pageNumber <= 1 }
                onClick={ () => setPageNumber( pageNumber - 1 ) }
              >
                <SinglePreviousIcon />
              </IconButton>
              <IconButton
                className="next-button"
                disabled={ pageNumber >= numPages }
                onClick={ () => setPageNumber( pageNumber + 1 ) }
              >
                <SingleNextIcon />
              </IconButton>
            </div>
          </div>
          <div className="actionBar">
            <LinearProgress
              variant="determinate"
              value={ ( pageNumber * 100 ) / numPages }
            />
            <div className="slide-actions">
              <div className="slide">
                { `${ t( "SLIDE" ) } ${ pageNumber } / ${ numPages }` }
                <LinearProgress
                  variant="determinate"
                  value={ ( pageNumber * 100 ) / numPages }
                />
              </div>
              <IconButton component="a" href={ REACT_APP_IMAGE_URL + data?.post_media?.[ 0 ]?.path }>
                <Download color="primary" />
              </IconButton>
            </div>
          </div>
        </div> ) }
    />
  );
};

PDFReader.propTypes = {};

export default PDFReader;
