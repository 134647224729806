//UTILITIES
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
//COMPONENT
import DialogCustomTitle from "../../../components/dialog/DialogCustomTitle";
import * as MUI from "@mui/material";
import TablePaginationSection from "../../../components/table-pagination/TablePaginationSection";
import { filterDataPerPage } from "../../../core/variables/Variables";

//API
import { getUserAchievementsByID } from "../../../core/services/user";
import { ListComponentSkeleton } from "../../../components/skeletons/SkeletonsComponent";
import { DisplayDateTime } from "../../../components/display-component/DisplayComponent";


export function MyPointsDetail( props ) {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );

  //VARIABLES
  const { achievementItem, onClose } = props;
  const [ loading, setLoading ] = useState( false );
  const [ page, setPage ] = useState( 1 );
  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ data, setData ] = useState( [] );

  //INITIATE USE FORM

  //FUNCTIONS
  const GetData = () => {
    setLoading( true );
    getUserAchievementsByID( {
      achievement_id: achievementItem?.id,
      per_page: filterDataPerPage,
      page: page,
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setData( res?.data?.data ? res?.data?.data?.data : [] );
        setPageTotal( res?.data.data?.last_page );
      }
      setLoading( false );
    } );
  };
  const handlePageChange = ( value, page ) => {
    setPage( page );
  };
  useEffect( () => {
    GetData();
  }, [ page ] );

  return (
    <MUI.Dialog disablebackdropclick="true" fullWidth open={ true }>
      <DialogCustomTitle
        classNames={ "dialog-title-remove-mb" }
        title={
          achievementItem
            ? achievementItem?.title + " - " + achievementItem?.description
            : ""
        }
        onClose={ () => onClose() }
      />



      <MUI.DialogContent>
        <MUI.List>
          { !loading ? (
            data?.map( ( element, index ) => (
              <MUI.ListItem
                key={ index }
                className="user-points-detail-item"
                divider={ index !== data?.length - 1 }
              >
                <div>{ t( 'DATE' ) }</div>
                <div>
                  <DisplayDateTime
                    data={ element?.pivot?.created_at }
                    format="DD-MM-YYYY" />
                </div>
                { element?.achievement_detail?.name && (
                  <>
                    <div>{ t( 'COLLECTED_FROM' ) }</div>
                    <div>{ element?.achievement_detail?.name }</div></> ) }
                { element?.achievement_detail?.company_name && (
                  <>
                    <div>{ t( 'COMPANY' ) }</div>
                    <div>{ element?.achievement_detail?.company_name }</div>
                  </> ) }
              </MUI.ListItem>
            ) )
          ) : (
            <ListComponentSkeleton count={ 3 } />
          ) }
        </MUI.List>
        <TablePaginationSection
          page={ page }
          totalPage={ pageTotal }
          handlePageChange={ handlePageChange }
          data={ data }
        />
      </MUI.DialogContent>
    </MUI.Dialog>
  );
}

export default MyPointsDetail;
