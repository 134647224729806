//UTILITY
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import * as yup from "yup";
//CSS
import "./ContactUs.scss";
//ASSETS
import ContactUsPicture from "../../assets/images/ContactUsPicture.png";
//COMPONENTS
import Button from "../../components/button/Button";
import { FormInput } from "../../components/form/form-componenrt/FormComponent";
//CONTEXT
import { AlertContext } from "../../context/AlertContext";
//API
import { sendContactUs } from "../../core/services/contact";
//VALIDATIONSCHEMA
const validationSchema = yup.object( {
  full_name: yup.string().required( "FIELD_IS_REQUIRED" ),
  email: yup.string().email( "INVALID_FORMAT" ).required( "FIELD_IS_REQUIRED" ),

  message: yup.string().required( "FIELD_IS_REQUIRED" ),
} );

const ContactUs = () => {
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ t ] = useTranslation( "common" );
  //CONTEXT
  const { setAlert } = useContext( AlertContext );

  const formik = useFormik( {
    initialValues: {
      full_name: "",
      email: "",
      message: "",
    },
    validationSchema: validationSchema,

    onSubmit: ( payload ) => {
      setSaveLoading( true );
      sendContactUs( payload ).then( ( res ) => {

        setAlert( {
          visible: true,
          text: res?.data ? res?.data?.message : res?.message,
          type: res?.data?.success ? "success" : "error",
        } );
        res?.data?.success && formik.resetForm();
        setSaveLoading( false );
      } );
    },
  } );

  return (
    <div className="contact-us">
      <img
        src={ ContactUsPicture }
        alt="CONTACT_US"
        className="contact-us-picture"
      />

      <form onSubmit={ formik.handleSubmit } className="form">
        <h1>{ t( "CONTACT_US" ) }</h1>
        <FormInput
          label="NAME"
          placeholder={ "ENTER_NAME" }
          name="full_name"
          type="full_name"
          value={ formik.values?.full_name }
          onChange={ formik.handleChange }
          helperText={ formik.touched?.full_name && formik.errors?.full_name }
        />
        <FormInput
          label="EMAIL"
          placeholder={ "ENTER_EMAIL" }
          name="email"
          type="email"
          value={ formik.values?.email }
          onChange={ formik.handleChange }
          helperText={ formik.touched?.email && formik.errors?.email }
        />
        <FormInput
          label="MESSAGE"
          placeholder={ "ENTER_MESSAGE" }
          name="message"
          type="message"
          value={ formik.values?.message }
          onChange={ formik.handleChange }
          // onBlur={formik.handleBlur}
          helperText={ formik.touched?.message && formik.errors?.message }
          multiline
          rows={ 4 }
        />
        <Button
          isSelected
          name="SUBMIT"
          type="submit"
          disabled={ saveLoading }
          loading={ formik.isSubmitting }
          loadingPosition="end"
        ></Button>
      </form>

      <div className="blue-image-design" />
    </div>
  );
};

export default ContactUs;
