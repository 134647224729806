//UTILITIES
import React from "react";
import { Outlet } from "react-router-dom";
//COMPONENT
import RewardsDetailsSide from "../../layout/sideBar/RewardsDetailsSide";

const RewardsDetails = () => {

  return (
    <div className="globalStructure">
      <RewardsDetailsSide />
      <div className="middleNoRightComponent">
        <Outlet />
      </div>
    </div>
  );
};

export default RewardsDetails;
