import { Avatar, Container } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../button/Button";
import "./SuggestedGroups.scss";
import GroupDefaultImage from "../../assets/images/group-default-image.png";
import { useTranslation } from "react-i18next";

function SuggestedGroups(props) {
  const [t] = useTranslation("common");
  const {
    img,
    groupName,
    members,
    notification,
    onJoin,
    alt,
    link,
    userStatus,
  } = props;

  return (
    <div className="SuggestedGroups">
      <Link style={{ color: "black" }} className={"link"} to={link}>
        <Container className="SuggestedHeader">
          <Avatar
            style={{ width: "60px", height: "60px" }}
            alt={alt}
            src={img ? img : GroupDefaultImage}
          />
          <div className="groupName">
            <p className="Name"> {groupName}</p>
            <p className="members">{`${members} ${t("MEMBERS")}`}</p>
          </div>
        </Container>
      </Link>

      <Container className="SuggestBody">
        <p className="Notification">{notification}</p>
        {onJoin && (
          <Button
            className="button"
            isSelected
            width="100%"
            name="JOIN"
            onClick={onJoin}
          />
        )}
      </Container>
    </div>
  );
}

export default SuggestedGroups;
