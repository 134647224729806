import { useQuery } from "@tanstack/react-query";
import { api } from "./main";

export const getSuggestedGroups = ( payload ) => {
  return api
    .get( `v1/suggested-groups`, {
      params: payload,
    } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const useSuggestedGroups = ( payload ) =>
  useQuery( {
    queryKey: [ "suggested-groups", payload ],
    queryFn: () => getSuggestedGroups( payload ).then( ( res ) => res?.data ),
  } );

export const getjoinedGroups = ( data ) => {
  return api
    .get( `v1/self-groups`, { params: data } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const joinGroup = ( group_id ) => {
  return api
    .patch( `v1/join-group/${ group_id }` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getGroupsByCompanyId = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `/v1/company-groups/${ payload?.company_id }${ page }`, {
      params: { ...payload },
    } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getgroupdetails = ( group_id, payload ) => {
  return api
    .get( `v1/groups/${ group_id }`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getGroupMembers = ( id, payload ) => {
  return api
    .get( `v1/group-members/${ id }`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const leaveGroup = async ( group_id ) => {
  return api
    .patch( `/v1/leave-group/${ group_id }` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getInvitation = ( payload ) => {
  return api
    .get( `v1/self-group-invitations`, {
      params: { ...payload },
    } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const useInvitedGroups = ( payload ) =>
  useQuery( {
    queryKey: [ "invited-groups", payload ],
    queryFn: () => getInvitation( payload ).then( ( res ) => res?.data ),
  } );

export const AcceptOrReject = ( data ) => {
  return api
    .patch( `v1/accept-group-invitation`, data )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getOtherGroups = ( payload ) => {
  return api
    .get( `/v1/other-groups`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getUserGroupsById = ( user_id ) => {
  return api
    .get( `/v1/user-groups/${ user_id }` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
