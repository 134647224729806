import React from "react";

const EditSVG = (props) => {
  return (
    <div style={{ width: "25px", height: "25px" }} onClick={props.onClick}>
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        style={{ marginTop: "-11px" }}
      >
        <path
          d="M0.375 11.6563V14.625H3.34375L12.0996 5.86919L9.13083 2.90044L0.375 11.6563ZM14.3954 3.57336C14.7042 3.26461 14.7042 2.76586 14.3954 2.45711L12.5429 0.604609C12.2342 0.295859 11.7354 0.295859 11.4267 0.604609L9.97792 2.05336L12.9467 5.02211L14.3954 3.57336Z"
          fill="#02C0CC"
        />
      </svg>
    </div>
  );
};

export default EditSVG;
