import { api } from "./main";
export const getUserById = async ( id ) => {
  return await api
    .get( `v1/users/${ id }` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const getUserProfessionalInfo = async ( id ) => {
  return await api
    .get( `v1/user-professional-info/${ id }` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const getUserAchievementsByID = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";

  return api
    .get( `v1/user-achievements/${ payload?.achievement_id }${ page }`, {
      params: { ...payload },
    } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getUserAchievements = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `v1/user-achievements${ page }`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getAllRewards = ( payload ) => {
  return api
    .get( `v1/rewards`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const getAllRewardsAchievement = () => {
  return api
    .get( `v1/achievements` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getSelf = () => {
  return api
    .get( `v1/self-user` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const updateUserBio = ( data ) => {
  return api
    .put( "v1/user-bio", data )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const updateUserImage = ( data ) => {
  return api
    .post( "v1/user-profile-image", data )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const updateUserInfo = ( data ) => {
  return api
    .put( "v1/user", data )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const updateUserProfession = ( data ) => {
  return api
    .post( "v1/user-professional-info", data )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const changeUserPassword = ( data ) => {
  return api
    .patch( "v1/change-password", data )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getDocuments = ( payload ) => {
  return api
    .get( `v1/documents`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getDocumentById = ( data ) => {
  return api
    .get( `v1/documents`, { params: data } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const getsignature = ( id, role ) => {
  return api
    .get( `v1/signature?meeting_id=${ id }&role=${ role }` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const GetSelfPermissions = ( payload ) => {
  return api
    .get( `/v1/self-permissions`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const deleteSelfUser = () => {
  return api
    .delete( `/v1/users` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};