//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";
import { serialize } from "object-to-formdata";
import * as yup from "yup";
import { useFormik } from "formik";

//COMPONENT
import { DisplayDateTime } from "../../../components/display-component/DisplayComponent";
import EditSVG from "../../../assets/icons/EditSVG";
import "./AccountInfo.scss";
import { IconButton } from "@mui/material";
import Button, { IconsButton } from "../../../components/button/Button";
import FileSVG from "../../../assets/icons/FileSVG";
import {
  FormMultiSelect,
  FormDropdownList,
  FormNumberInput,
  FormInput,
  FormSingleUpload,
  FormRadioButton,
  FormCalendarInput,
  FormTextEditor,
} from "../../../components/form/form-componenrt/FormComponent";
import LoaderComponent from "../../../components/loader/LoaderComponent";
import { DocumentPopUp } from "../../../components/popup/Popup";
//API
import * as userInfo from "../../../core/services/user";
import * as editFields from "../../../core/services/register";
import { dateFormat, fileTypes } from "../../../core/variables/Variables";
import { CheckVisibilityIcon } from "../../../core/functions/Functions";

const removeZeros = ( obj ) => {
  Object.entries( obj ).forEach( ( [ key, value ] ) => {
    if ( Array.isArray( value ) ) {
      obj[ key ] = value.filter( ( val ) => val !== 0 );
    } else if ( typeof value === "object" && value !== null ) {
      removeZeros( value );
    }
  } );

  return obj;
};
const removeBlob = ( obj ) => {
  let newobj = { ...obj };
  typeof newobj?.professional_license_file_ === "string" &&
    delete newobj?.professional_license_file_;
  return newobj;
};
const ProfessionalDetails = () => {
  const [ t ] = useTranslation( "common" );

  const Alert = useContext( AlertContext );
  const { user } = useContext( UserContext );

  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ professionalData, setProfessionalData ] = useState( false );
  const [ fields, setFields ] = useState( [] );
  const [ fieldsSchema, setFieldsSchema ] = useState( null );
  const [ isEdit, setEdit ] = useState( false );
  const [ onHideState, setOnHideState ] = useState( false );
  const [ path, setPath ] = useState( "" );
  const [ isLoading, setIsLoading ] = useState( true );

  //INITIATE FORM
  const formiks = useFormik( {
    validationSchema: fieldsSchema,
    onSubmit: ( payload ) => handleSubmit( payload ),
  } );

  //BASED  ON THE FIELD TYPE WE ARE MAKING THE SCHEMA
  const switchCaseType = ( fieldType, isRequired ) => {
    fieldType = fieldType.toLowerCase();

    if ( fieldType === "multiple select" ) {
      if ( isRequired ) {
        return yup
          .array()
          .nullable()
          .min( 1, "FIELD_IS_REQUIRED" )
          .required( "FIELD_IS_REQUIRED" );
      } else {
        return yup.array().nullable();
      }
    } else if (
      fieldType === "string" ||
      fieldType === "textbox" ||
      fieldType === "date"
    ) {
      if ( isRequired ) {
        return yup
          .string( "INVALID_FORMAT" )
          .nullable()
          .required( "FIELD_IS_REQUIRED" );
      } else {
        return yup.string( "INVALID_FORMAT" ).nullable();
      }
    } else if ( fieldType === "email" ) {
      if ( isRequired ) {
        return yup
          .string()
          .email( "INVALID_FORMAT" )
          .nullable()
          .required( "FIELD_IS_REQUIRED" );
      } else {
        return yup.string().email( "INVALID_FORMAT" ).nullable();
      }
    } else if ( fieldType === "file" || fieldType === "image" ) {
      if ( isRequired ) {
        return yup.mixed().nullable().required( "FIELD_IS_REQUIRED" );
      } else {
        return yup.mixed().nullable();
      }
    } else if (
      fieldType.toLowerCase() === "float" ||
      fieldType.toLowerCase() === "integer"
    ) {
      if ( isRequired ) {
        return yup
          .number( "INVALID_FORMAT" )
          .nullable()
          .required( "FIELD_IS_REQUIRED" );
      } else {
        return yup.number( "INVALID_FORMAT" ).nullable();
      }
    } else {
      if ( isRequired ) {
        return yup.object().nullable().required( "FIELD_IS_REQUIRED" );
      } else {
        return yup.object().nullable();
      }
    }
  };

  const handleSubmit = ( payload ) => {
    setSaveLoading( true );
    let values = { language_id: 1, user_id: user.id };

    fields?.map( ( el ) => {
      //always check array before object because array is considered an object and not vice versa
      if ( el?.field_type?.type === "file" || el?.field_type?.type === "image" ) {
        if ( payload[ el.slug ] instanceof File ) {
          values[ el.slug ] = payload[ el.slug ];
        } else {
          //nothing
        }
      } else if ( el?.is_categorical === true ) {
        let check_has_other =
          el?.has_other && formiks?.values?.[ el.slug ]?.some( ( ele ) => ele == 0 );
        if ( formiks?.values?.[ el.slug ] ) {
          values[ el?.slug ] = {
            ids: Array.isArray( formiks?.values?.[ el.slug ] )
              ? formiks?.values?.[ el.slug ]
                ?.filter( ( val ) => val !== 0 )
                .map( ( ele ) => ele )
              : [ formiks?.values?.[ el.slug ]?.id ],
            custom:
              el?.has_other && check_has_other
                ? formiks?.values?.[ el.slug + "custom_" ]
                : "",
          };
        }

      } else {
        values[ el.slug ] = payload[ el.slug ];
      }
    } );

    const formData = serialize( removeBlob( removeZeros( values ) ), {
      allowEmptyArrays: true,
    } );
    userInfo.updateUserProfession( formData ).then( ( res ) => {
      Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      if ( res?.data?.success ) {
        setEdit( !isEdit );
        getUserProfessionalInfo();
      }
      setSaveLoading( false );
    } );
  };
  const handleEdit = () => {
    setEdit( !isEdit );
  };

  const getUserProfessionalInfo = () => {
    setIsLoading( true );
    let all_new_fields = {};
    let new_fields_schema = {};
    let added_fields = {};
    userInfo.getUserProfessionalInfo( user.id ).then( ( userRes ) => {

      if ( userRes?.data?.success ) {
        const userData = userRes?.data?.data;
        userData?.map( ( userProf ) => {
          added_fields[ userProf.name ] = userProf;
          if ( userProf.has_other && userProf?.dynamic_field_value?.[ 0 ]?.value ) {
            //WE ARE OVERRIDING THE IS_CATEGORICAL AND FIELD TYPE PROPERTIES TO MATCH THE CONDITIONS
            added_fields[ t( "CUSTOM" ) + " " + userProf.name ] = {
              ...userProf,
              is_categorical: false,
              field_type: { name: "string" },
            };
          }
        } );
        setProfessionalData( added_fields );
        editFields.getAllFields( user?.role_id ).then( ( fieldRes ) => {
          if ( fieldRes?.data?.success ) {
            fieldRes?.data?.data?.map( ( item ) => {
              let selectedField = userData
                ? userData.find( ( d ) => d?.slug === item.slug )
                : null;

              new_fields_schema[ item.slug ] = switchCaseType(
                item.field_type.name,
                item.is_required
              );
              if ( item.has_other ) {
                new_fields_schema[ item?.slug + "custom_" ] = yup
                  .string()
                  .when( item.slug, {
                    is: ( value ) => value.some( ( el ) => el?.id === 0 ),
                    then: yup.string().required( "FIELD_IS_REQUIRED" ),
                    otherwise: yup.string(),
                  } );
              } //NOW SETTING THE DEFAULT VALUES OF INPUTS (IF THERE IS A VALUE )
              if ( selectedField ) {
                if ( item?.is_categorical ) {
                  if ( item.field_type.name === "Multiple Select" ) {
                    //IF MULTIPLE THE FIELD MIGHT HAVE OTHER
                    all_new_fields[ item.slug ] =
                      item?.has_other &&
                        selectedField?.dynamic_field_value?.[ 0 ]?.value
                        ? [
                          0,
                          ...( selectedField?.dynamic_field_value?.[ 0 ]?.field_category?.map(
                            ( el ) => el?.id
                          ) || [] ),
                        ]
                        : selectedField?.dynamic_field_value?.[ 0 ]?.field_category?.map(
                          ( el ) => el?.id
                        );
                  } else {
                    //DROPDOWN AND RADIO BUTTON
                    all_new_fields[ item.slug ] =
                      selectedField?.dynamic_field_value?.[ 0 ]?.field_category[ 0 ];
                  }
                  //IN CASE FIELD IS CATEGORICAL , CHECK IF HAS OTHER
                  if ( item?.has_other ) {
                    all_new_fields[ item.slug + "custom_" ] = selectedField
                      ?.dynamic_field_value?.[ 0 ]?.value
                      ? selectedField?.dynamic_field_value?.[ 0 ]?.value
                      : "";
                  }
                } else {
                  //STRING , DATES,NUMBER,TEXT
                  all_new_fields[ item.slug ] =
                    selectedField?.dynamic_field_value?.[ 0 ]?.value;
                }
              }
              //IN CASE THE FIELD IS NOT REQUIRED AND NOT FILLED
              else {
                if ( item.field_type.name === "Multiple Select" ) {
                  all_new_fields[ item.slug ] = [];
                } else {
                  all_new_fields[ item.slug ] = null;
                }

                //IN ALL CASES IF THE FIELD HAS_OTHER
                if ( item?.has_other ) {
                  all_new_fields[ item.slug + "custom_" ] = "";
                }
              }
            } );

            const dynamicFieldsSchema = yup.object().shape( new_fields_schema );
            setFieldsSchema( dynamicFieldsSchema );
            setFields( fieldRes?.data?.data );
            formiks.setValues( all_new_fields );
          }
        } );
      }
      setIsLoading( false );
    } );
  };

  const openModal = ( path ) => {
    setOnHideState( !onHideState );
    setPath( path );
  };

  useEffect( () => {
    getUserProfessionalInfo();
  }, [] );

  return (
    <div className="user-account-info">
      <div className="header">
        { t( "PROFESSIONAL_DETAILS" ) }

        { !isEdit ? (
          <IconButton
            aria-label="delete"
            color="secondary"
            className="svgBackground"
            onClick={ handleEdit }
          >
            <EditSVG />
          </IconButton>
        ) : null }
      </div>
      { isLoading ? (
        <LoaderComponent />
      ) : (
        <form onSubmit={ formiks.handleSubmit } autoComplete="off">
          <div className={ `user-account-content` }>
            { !isEdit
              ? Object.keys( professionalData ).map( ( field ) => (
                <div className={ `body-item` }>
                  <div className="field-label">{ field } { CheckVisibilityIcon( professionalData?.[ field ]?.visibility_status ) }</div>
                  <div className="field-value">
                    { !professionalData?.[ field ]?.is_categorical ? (
                      professionalData?.[ field ]?.field_type?.name ===
                        "Date" ? (
                        <DisplayDateTime
                          data={
                            professionalData?.[ field ]?.dynamic_field_value[ 0 ]
                              .value
                          }
                          format={ dateFormat }
                        />
                      ) : professionalData?.[ field ]?.field_type?.name ===
                        "File" ||
                        professionalData?.[ field ]?.field_type?.name ===
                        "Image" ? (
                        professionalData?.[ field ]?.dynamic_field_value?.[ 0 ]
                          .value ? (
                          <IconsButton
                            icon={ <FileSVG /> }
                            onClick={ () =>
                              openModal(
                                professionalData?.[ field ]
                                  ?.dynamic_field_value?.[ 0 ].value
                              )
                            }
                          />
                        ) : (
                          t( "NOT_AVAILABLE" )
                        )
                      )
                        : professionalData?.[ field ]?.field_type?.name === "Textbox" ? (
                          <div
                            dangerouslySetInnerHTML={ {
                              __html: professionalData?.[ field ]?.dynamic_field_value?.[ 0 ]?.value
                            } }
                          />
                        )
                          : professionalData?.[ field ]?.dynamic_field_value[ 0 ]
                            .value ? (
                            professionalData?.[ field ]?.dynamic_field_value[ 0 ]
                              .value
                          ) : (
                            t( "NOT_AVAILABLE" )
                          )
                    ) : professionalData?.[ field ]?.dynamic_field_value?.[ 0 ]
                      ?.field_category.length !== 0 ? (
                      professionalData?.[
                        field
                      ]?.dynamic_field_value?.[ 0 ]?.field_category
                        ?.map( ( obj ) => obj.name )
                        .join( ", " )
                    ) : (
                      t( "NOT_AVAILABLE" )
                    ) }
                  </div>
                </div>
              ) )
              : fields?.map( ( item, index ) => (
                <div key={ index }>
                  { item.field_type.name === "Multiple Select" ? (
                    <>
                      <FormMultiSelect
                        labelIcon={ CheckVisibilityIcon( item?.visibility_status ) }
                        data={
                          item?.has_other
                            ? [
                              ...item.field_category,
                              {
                                name: t( "OTHER" ),
                                id: 0,
                              },
                            ]
                            : item.field_category
                        }
                        name={ `${ item.slug }` }
                        required={ item.is_required ? true : false }
                        label={ `${ item.name }` }
                        placeholder={ `${ t( "ENTER" ) } ${ item.name }` }
                        value={ formiks.values?.[ item?.slug ] }
                        onChange={ ( value ) => {
                          formiks.setFieldValue( item?.slug, value );
                          formiks.setFieldValue( `${ item?.slug }custom_`, "" );
                          formiks.setFieldError( `${ item.slug }custom_`, "" );
                        } }
                        helperText={
                          formiks.touched?.[ item?.slug ] &&
                          formiks.errors?.[ item?.slug ]
                        }
                      />

                      { item?.has_other &&
                        formiks?.values?.[ item?.slug ]?.some(
                          ( el ) => el === 0
                        ) && (
                          <FormInput
                            labelIcon={ CheckVisibilityIcon( item?.visibility_status ) }
                            name={ `${ item.slug }custom_` }
                            required={ item.is_required ? true : false }
                            label={ `${ item.name }` }
                            placeholder={ `${ t( "ENTER" ) } ${ item.name }` }
                            value={ formiks.values?.[ `${ item.slug }custom_` ] }
                            onChange={ ( e ) => {
                              formiks.setFieldValue(
                                `${ item.slug }custom_`,
                                e.target.value
                              );
                            } }
                            helperText={
                              formiks.touched?.[ `${ item.slug }custom_` ] &&
                              formiks.errors?.[ `${ item.slug }custom_` ]
                            }
                          />
                        ) }
                    </>
                  ) : item.field_type.name === "String" ||
                    item.field_type.name === "Email" ? (
                    <FormInput
                      labelIcon={ CheckVisibilityIcon( item?.visibility_status ) }
                      name={ `${ item.slug }` }
                      required={ item.is_required ? true : false }
                      label={ `${ item.name }` }
                      placeholder={ `${ t( "ENTER" ) } ${ item.name }` }
                      value={ formiks.values?.[ item.slug ] }
                      onChange={ ( e ) => {
                        formiks.setFieldValue( item?.slug, e.target.value );
                      } }
                      helperText={
                        formiks.touched?.[ item?.slug ] &&
                        formiks.errors?.[ item?.slug ]
                      }
                    />
                  ) : item?.field_type?.name === "Textbox" ? (
                    <FormTextEditor
                      labelIcon={ CheckVisibilityIcon(
                        item?.visibility_status
                      ) }
                      name={ `${ item.slug }` }
                      required={ item.is_required }
                      label={ `${ item.name }` }
                      placeholder={ `${ t( "ENTER" ) } ${ item.name }` }
                      value={ formiks.values?.[ item.slug ] }
                      onChange={ ( value ) => {
                        formiks.setFieldValue( item?.slug, value );
                      } }
                      helperText={
                        formiks.touched?.[ item?.slug ] &&
                        formiks.errors?.[ item?.slug ]
                      }
                    />
                  ) : item.field_type.name === "Float" ||
                    item.field_type.name === "Integer" ? (
                    <FormNumberInput
                      labelIcon={ CheckVisibilityIcon( item?.visibility_status ) }
                      name={ `${ item.slug }` }
                      required={ item.is_required ? true : false }
                      label={ `${ item.name }` }
                      value={ formiks.values?.[ item.slug ] }
                      placeholder={ `${ t( "ENTER" ) } ${ item.name }` }
                      onChange={ ( value ) => {
                        formiks.setFieldValue(
                          item?.slug,
                          value === "" ? null : value
                        );
                      } }
                      helperText={
                        formiks.touched?.[ item?.slug ] &&
                        formiks.errors?.[ item?.slug ]
                      }
                    />
                  ) : item.field_type.name === "Dropdown" ? (
                    <FormDropdownList
                      labelIcon={ CheckVisibilityIcon( item?.visibility_status ) }
                      name={ `${ item.slug }` }
                      required={ item.is_required ? true : false }
                      data={ item.field_category }
                      label={ item.name }
                      placeholder={ `${ t( "ENTER" ) } ${ item.name }` }
                      value={ item.field_category.find(
                        ( i ) => i?.id === formiks.values?.[ item?.slug ]?.id
                      ) }
                      onChange={ ( value ) => {
                        formiks.setFieldValue( item?.slug, value );
                      } }
                      helperText={
                        formiks.touched?.[ item?.slug ] &&
                        formiks.errors?.[ item?.slug ]
                      }
                    />
                  ) : item.field_type.name === "Date" ? (
                    <FormCalendarInput
                      labelIcon={ CheckVisibilityIcon( item?.visibility_status ) }
                      name={ `${ item.slug }` }
                      required={ item.is_required ? true : false }
                      label={ item.name }
                      placeholder={ `${ t( "ENTER" ) } ${ item.name }` }
                      value={ formiks.values?.[ item.slug ] }
                      onChange={ ( value ) => {
                        formiks.setFieldValue( item?.slug, value );
                      } }
                      helperText={
                        formiks.touched?.[ item?.slug ] &&
                        formiks.errors?.[ item?.slug ]
                      }
                    />
                  ) : item.field_type.name === "File" ||
                    item.field_type.name === "Image" ? (
                    <FormSingleUpload
                      labelIcon={ CheckVisibilityIcon( item?.visibility_status ) }
                      accept={
                        item.field_type.name === "File"
                          ? [ "PDF" ]
                          : fileTypes?.image
                      }
                      name={ `${ item.slug }` }
                      required={ item.is_required ? true : false }
                      label={ `${ item.name }` }
                      downloadTitle={ "UPLOAD" }
                      type={ item.field_type.name }
                      placeholder={ `${ t( "ENTER" ) }${ item.name }` }
                      value={ formiks.values?.[ item.slug ] }
                      onChange={ ( value ) =>
                        formiks.setFieldValue( item?.slug, value )
                      }
                      helperText={
                        formiks.touched?.[ item?.slug ] &&
                        formiks.errors?.[ item?.slug ]
                      }
                    />
                  ) : item.field_type.name === "Radio Button" ? (
                    <FormRadioButton
                      labelIcon={ CheckVisibilityIcon( item?.visibility_status ) }
                      row
                      name={ `${ item.slug }` }
                      data={ item.field_category }
                      required={ item.is_required ? true : false }
                      label={ item.name }
                      placeholder={ `Enter ${ item.name }` }
                      value={ formiks.values?.[ item.slug ] }
                      onChange={ ( value ) => {
                        formiks.setFieldValue( item?.slug, value );
                      } }
                      helperText={
                        formiks.touched?.[ item?.slug ] &&
                        formiks.errors?.[ item?.slug ]
                      }
                    />
                  ) : (
                    ""
                  ) }
                </div>
              ) ) }
          </div>
          { isEdit ? (
            <div className="buttonGrouping">
              <Button name="CANCEL" width="15%" onClick={ handleEdit } />
              <Button
                loading={ saveLoading }
                disabled={ saveLoading }
                type="submit"
                name="SAVE"
                width="15%"
                isSelected
              />
            </div>
          ) : null }
        </form>
      ) }
      <DocumentPopUp
        onHideState={ onHideState }
        onHide={ () => setOnHideState( false ) }
        pdf={ path }
        type="pdf"
      />
    </div>
  );
};

export default ProfessionalDetails;
