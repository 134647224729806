import palette from "../theme/color.scss";
import { createTheme, Select, Tabs, Tab } from "@mui/material";
import styled from "@emotion/styled";

export const theme = createTheme( {
  palette: {
    primary: {
      main: "#02C0CC",
    },
    secondary: {
      main: "#71a2a7",
    },
  },
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          backgroundColor: palette.whiteColor,
          borderRadius: "5px",
          "& .MuiOutlinedInput-root": {
            "&:hover fieldset": {
              border: `1px solid  ${ palette.primaryColor }`,
            },
          },

          fieldset: {
            border: `1px solid  ${ palette.inputColor }`,
          },

          input: {
            "&::placeholder": {
              color: palette.inputColor,
              opacity: "1",
            },
          },
          textarea: {
            "&::placeholder": {
              color: palette.inputColor,
              opacity: "1",
            },
          },
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          color: "red",
          margin: "5px 0px 0px 0px",
          wordBreak: "break-work",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          textTransform: "capitalize",
        },
        label: {
          fontSize: "12px",
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: "0px 0px 5px 2px rgba(0, 0, 0, 0.06)",
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          "&:last-child": {
            paddingBottom: 16,
          },
        },
      },
    },

    MuiTableSortLabel: {
      styleOverrides: {
        icon: {
          color: palette.primaryColor,
        },
        root: {
          color: palette.primaryColor,
          fontWeight: 400,
          fontSize: "18px",

          "&.Mui-active": {
            color: palette.primaryColor,
          },
          "&:hover": {
            color: palette.primaryColor,
          },
        },
      },
    },
    MuiSelect: {
      border: `1px solid  ${ palette.inputColor }`,

      styleOverrides: {
        root: {
          backgroundColor: palette.whiteColor,
          border: "none",
          textOverflow: "ellipsis",
          overflowX: "hidden",
        },
        select: {
          border: `1px solid  ${ palette.inputColor }`,
        },
      },
    },
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: palette.whiteColor,
          borderRadius: "5px",
          border: `0 solid ${ palette.whiteColor }`,
          paddingTop: "0px",
          paddingBottom: "0px",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          borderBottom: `1px solid ${ palette.primaryColor }`,
          color: palette.primaryColor,
          fontWeight: 400,
          fontSize: "18px",
        },
        body: {
          lineHeight: "16px",
          fontWeight: "400",
          fontSize: "14px",
          textTransform: "capitalize",
        },
      },
    },
    MuiTable: {
      styleOverrides: {
        root: {
          backgroundColor: palette.whiteColor,
          borderRadius: "5px",
        },
      },
    },
    MuiPagination: {
      styleOverrides: {
        root: {
          float: "right",
          backgroundColor: palette.primaryColor,
          borderRadius: "5px",
          marginTop: "10px",
        },
      },
    },
    MuiPaginationItem: {
      styleOverrides: {
        root: {
          color: palette.whiteColor,
          fontSize: "14px",
          fontWeight: 700,
        },
      },
    },
    MuiToggleButton: {
      styleOverrides: {
        root: {
          color: "black",
          padding: "3px 10px",
          textTransform: "none",
          fontWeight: 400,
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          color: palette.primaryColor,
          fontWeight: 400,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          "&:focus": {
            outline: "none",
          },

          whiteSpace: "nowrap",
          textTransform: "none",
          boxShadow: "unset",
        },
      },
    },
    MuiSkeleton: {
      styleOverrides: {
        root: ( { ownerState } ) => ( {
          backgroundColor: "#E4EEEF",
          color: "#fff",
        } ),
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          textTransform: "none",
          "&:hover": { color: palette.primaryColor },
        },
      },
    },
  },
} );

export const LanguageSelect = styled( Select )( {
  "& .MuiSelect-root": {
    border: "none",
  },
  "& .MuiOutlinedInput-input": {
    padding: "0px 10px",
    border: "none",
  },
  "& .MuiOutlinedInput-root": {
    border: "none",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    border: "none",
  },
  "& .MuiOutlinedInput-input.MuiSelect-select": {
    border: "none",
  },
} );
// StyledTabs and StyledTab are used in account details in addresses for now
export const StyledTabs = styled( Tabs )( () => ( {
  "&.MuiTabs-root": {
    minHeight: "35px",
    backgroundColor: palette.primaryColor80,
    width: "fit-content",
    borderRadius: "10px",
  },
  ".MuiTabs-indicator": {
    height: "0px",
  },
} ) );
export const StyledTab = styled( Tab )( () => ( {
  "&.MuiTab-root": {
    minHeight: "35px",
    padding: "10px",
    borderRadius: "10px",
    transition: "color 0.5s ease",
  },
  "&.MuiTab-root.Mui-selected": {
    color: palette.primaryColor,
    backgroundColor: palette.whiteColor,
    border: `1px solid ${ palette.primaryColor80 }`,
  },
} ) );
