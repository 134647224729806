import React from "react";
import "./GoalsCard.scss";

const OurGoalsCard = (props) => {
  const { image, title, description } = props;

  return (
    <div className="goals-card">
      {image}
      <p className="title">{title}</p>
      <p className="description">{description}</p>
    </div>
  );
};

export default OurGoalsCard;
