//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { objectCleaner } from "../../../core/functions/Functions";
import { Link, useSearchParams } from "react-router-dom/dist";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";
//styling
import "./MyRedemptions.scss";
//COMPONENT
import {
  DisplayAvatar,
  DisplayDateTime,
} from "../../../components/display-component/DisplayComponent";
import RedemptionDetail from "./RedemptionDetail";
import RowComponent from "../../../components/table-component/RowComponent";
import { dataPerPage, dateFormat } from "../../../core/variables/Variables";
import TablePaginationSection from "../../../components/table-pagination/TablePaginationSection";
import TableComponent from "../../../components/table-component/TableComponent";
import * as MUI from "@mui/material";

//apis
import * as userAPI from "../../../core/services/RewardsRedemption";
import { REACT_APP_IMAGE_URL } from "../../../core/services/main";


const MyRedemptions = () => {
  //translate hook
  const [ t ] = useTranslation( "common" );
  //context
  const { user } = useContext( UserContext );
  const [ openDetail, setOpenDetail ] = useState( { open: false, detail: null } )
  const alert = useContext( AlertContext );
  //states hook
  const tableHeaders = [
    {
      name: "MY_REDEMPTION",
    },
    {
      name: "POINTS",
    },
    {
      name: "STATUS",
    },
    {
      name: "DATE",
    },
    {
      name: "ADDRESS",
    },
  ];
  const [ data, setData ] = useState( [] );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ loading, setLoading ] = useState( false );
  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ filter, setFilter ] = useState( {
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: searchParams.get( "page" ) || 1,
  } );

  //get user redemptions
  const getData = async () => {
    setLoading( true );

    userAPI.getUserRedemption( { ...filter, user_id: user?.id } ).then( ( res ) => {
      !res?.data?.success && alert?.warning( res?.data ? res?.data?.message : res?.message );
      setData( res?.data?.data ? res?.data?.data?.data : [] );
      setPageTotal( res?.data?.data?.last_page );
      setLoading( false );
    } );
  };

  const handlePageChange = ( e, page ) => {
    let newFilters = {
      ...filter,
      page: page,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };


  useEffect( () => {
    setFilter( {
      per_page: searchParams.get( "per_page" ) || dataPerPage,
      page: +searchParams.get( "page" ) || 1,
    } );
  }, [ searchParams ] );


  useEffect( () => {
    getData();
  }, [ filter ] );

  return (
    <>
      <TableComponent
        tableHeaders={ tableHeaders }
        loading={ loading }
        tableData={ data }
        actions={ false }
      >
        { data?.map( ( element ) => (
          <RowComponent
            actions={ false }
            row={ element }
            key={ element?.id }
            handleHover={ () => setOpenDetail( {
              open: true,
              detail: element,
            } ) }

          >
            <MUI.TableCell>
              <DisplayAvatar
                path={
                  element?.reward.path
                    ? REACT_APP_IMAGE_URL + element?.reward.path
                    : ""
                }
                data={ element?.reward?.name }
              />
            </MUI.TableCell>
            <MUI.TableCell>{ element?.amount }</MUI.TableCell>
            <MUI.TableCell>{ element.redemption_status?.name }</MUI.TableCell>
            <MUI.TableCell>
              <DisplayDateTime data={ element?.updated_at } format={ dateFormat } />
            </MUI.TableCell>
            <MUI.TableCell>
              { element?.address?.address_name || `${ element?.address?.country?.name }, ${ element?.address?.city_name } ` }

            </MUI.TableCell>
          </RowComponent>
        ) ) }
      </TableComponent>

      <TablePaginationSection
        page={ filter?.page }
        totalPage={ pageTotal }
        handlePageChange={ handlePageChange }
        data={ data }
      />
      { openDetail.open &&
        <RedemptionDetail
          data={ openDetail?.detail }
          onClose={ () => setOpenDetail( { open: false, detail: null } ) } /> }
    </>
  );
};
export default MyRedemptions;
