//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import { AlertContext } from "../../context/AlertContext";
import { useTranslation } from "react-i18next";
import palette from "../../theme/color.scss"
//COMPONENT

import * as MUI from "@mui/material";
import { ThreeDots, } from "../../assets/icons/SVG";
import CommentComponent from "../comment-component/CommentComponent";
import GazCard from "../../components/cards/GazetteCard";
import { Event } from "../../core/tracking/tracking";
import BackRep from "../../components/back-repository/BackRep";
import { PostSkeleton } from "../../components/skeletons/SkeletonsComponent";
//API
import { deleteBlogComment } from "../../core/services/comments";
import {
  commentBlog,
  deleteBlog,
  getBlogById,
  getBlogsComment,
  likeBlog,
  useSimilarBlogs,
} from "../../core/services/blogs";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
//CSS
import blogDefault from "../../assets/images/blogDefault.png";
import "./Gazette.scss";
import { useParams } from "react-router-dom/dist";
import { DisplayDateTime, DisplayTags } from "../../components/display-component/DisplayComponent";
import { RowMenu } from "../../components/row-menu/RowMenu";
import DialogComponent from "../../components/dialog/Dialog";
import { PermissionContext } from "../../context/PermissionContext";
import PostReactions from "../../components/post-reactions/PostReaction";
import { useMemo } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";

function GazetteInformation( props ) {
  const { id } = useParams();
  const [ t ] = useTranslation( "common" );
  const { permissions } = useContext( PermissionContext )
  const location = useLocation();
  const navigate = useNavigate();
  const Alert = useContext( AlertContext );
  const [ data, _ ] = useState( location?.state?.data );
  const { user } = useContext( UserContext );
  const [ blog, setBlog ] = useState( null );
  const [ loading, setLoading ] = useState( true );
  const [ showComments, setShowComments ] = useState( false );
  const [ showDialogue, setShowDialogue ] = useState( false );
  const { data: similarBlogs } = useSimilarBlogs( {
    per_page: 5,
    page: 1,
    blog_id: id,
  } );

  const handleLikePost = (
    is_liked,
    id,
    reactionTypeId,
    reaction,
    prevReaction ) => {
    // if is_liked in the payload is false => I want to like the post
    // enfisam mnl backend
    likeBlog( {
      blog_id: id,
      is_liked: is_liked,
      is_gazette: true,
      like_type_id: reactionTypeId,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        updateBlogLikeCount(
          data,
          !is_liked,
          reactionTypeId,
          reaction,
          prevReaction );
        Event( "Like", `Like_Post_${ blog?.id }`, "HOME_PAGE_GAZZETE" );
      }
      Alert?.[ response.data?.success ? "success" : "error" ](
        response?.data ? response?.data?.message : response?.data
      );
    } );
  };




  const updateBlogLikeCount = (
    postData,
    is_reacted,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {
    if ( is_reacted ) {
      setBlog( {
        ...blog,
        ...( !blog?.is_reacted && {
          likes_count: ( blog?.likes_count || 0 ) + 1,
        } ),
        ...( blog?.is_reacted && {
          [ prevReaction.key ]: ( blog[ prevReaction.key ] || 0 ) - 1,
        } ),
        is_reacted: 1,
        [ reaction ]: ( blog[ reaction ] || 0 ) + 1,

        user_like: [
          {
            like_type_id: reactionTypeId,
          },
        ],
      } );
    } else {
      setBlog( {
        ...blog,
        ...( blog?.is_reacted && { likes_count: blog?.likes_count - 1 } ),
        is_reacted: 0,
        [ prevReaction.key ]: blog[ prevReaction.key ] - 1,
        user_like: [
          {
            like_type_id: reactionTypeId,
          },
        ],
      } );
    }
  };

  const updatePostCommentCount = ( type, commentChildrenCount ) => {
    if ( type === "add" ) {
      setBlog( { ...blog, comments_count: blog?.comments_count + 1 } );
    } else {
      //delete comment and its descendant
      setBlog( { ...blog, comments_count: blog?.comments_count - ( 1 + commentChildrenCount ) } );
    }
  };

  const GetData = () => {
    getBlogById( id ).then( ( response ) => {
      if ( response.data?.success ) {
        setBlog( response.data?.data );
      }
      setLoading( false );
    } );
  };

  const handleExpandClick = () => {
    setShowComments( !showComments );
  };
  //delete the blog api, it takes the blog id as data
  const handleDeleteBlog = ( data ) => {
    deleteBlog( data ).then( ( res ) => {
      if ( res?.data?.success ) {
        setShowDialogue( false ); // close the gialogue
        navigate( -1 ); //go to the previous link
      }
      Alert?.[ res?.data?.success ? "success" : "error" ]( res?.data?.message ); //get the message that the blog is successfully deleted or not
    } );
  };

  const blogTagsSpecialties = useMemo( () => {
    if ( blog ) {
      let specialties = blog?.field_categories;
      let tags = blog?.tags
      return [ ...specialties, ...tags ]?.map( ( el ) => { return el?.name } )
    }

  }, [ blog ] )


  useEffect( () => {
    GetData();
  }, [ id ] );


  return (
    <>
      <div className="GazetteInformation">
        <BackRep to={ `../blog/blogs` } title={ "BLOG" } />
        { !loading ? (
          <div className="GazetteInformationSecion">
            <div className="GazetteHeader">
              <div style={ { display: "flex", width: "100%" } }>
                <div
                  style={ {
                    display: "flex",
                    width: "100%",
                    flexDirection: "column",
                  } }
                >
                  <div style={ { display: "flex", flexWrap: "wrap" } }>
                    <MUI.Typography className="Title">
                      { " " }
                      { blog?.title }
                    </MUI.Typography>

                  </div>
                  <MUI.Typography className="date">
                    <DisplayDateTime data={ blog?.created_at } calendar />
                  </MUI.Typography>
                </div>

                { blog?.user?.id === user?.id && (
                  <MUI.IconButton
                    aria-label="edit"
                    color="secondary"
                    className="svgBackground"
                    style={ { alignSelf: "flex-start" } }
                  >
                    <RowMenu
                      icon={ <ThreeDots rotate="90" fill={ palette.primaryColor } /> }
                      handleEdit={ () =>
                        navigate( `../blog/edit-blog/${ blog?.id }` )
                      }
                      handleDelete={ () => setShowDialogue( true ) }
                    />
                  </MUI.IconButton>
                ) }
              </div>
            </div>

            <MUI.Container className="InformationBody">
              <div
                className="paragraph"
                dangerouslySetInnerHTML={ { __html: blog?.description } }
              />
              <DisplayTags data={ blog } max={ null } />
            </MUI.Container>

            <LazyLoadImage
              alt="image"
              className="Image"
              src={ REACT_APP_IMAGE_URL + blog?.featured_image }
              onError={ ( { target } ) => {
                // Handle image loading error
                target.src = blogDefault;
              } }
            />

            <div className="gazette-actions-content">
              <div className="reactions-section">
                <PostReactions
                  { ...props }
                  data={ { ...blog, is_commentable: true } }
                  blog
                  handleLike={ handleLikePost }
                  expandComments={ blog?.comments_count === 0 && !permissions?.Comment?.[ 'create-comment' ] ? null : () => handleExpandClick() }


                /></div>
              <div
                className="publisher-section cursor-pointer"
                onClick={ () => navigate( `../connections/discover/user-info/${ blog?.user?.id }` ) }>
                <div>
                  {
                    blog?.user?.first_name + " " + blog?.user?.last_name
                  }
                </div>
                <MUI.Avatar
                  alt={ blog?.user ? blog.user.first_name : "" }
                  src={
                    blog?.user
                      ? REACT_APP_IMAGE_URL + blog.user.profile_image
                      : ""
                  }
                />
              </div>



            </div>








            <MUI.Collapse in={ showComments } timeout="auto" unmountOnExit>
              <CommentComponent
                post={ false }
                user={ user }
                blog={ blog }
                handleGetComment={ getBlogsComment }
                handleAddComment={ permissions?.Comment?.[ 'create-comment' ] && commentBlog }
                updateCommentCount={ ( type, commentChildrenCount ) => updatePostCommentCount( type, commentChildrenCount ) }
                handleDeleteComment={ permissions?.Comment?.[ 'delete-comment' ] && deleteBlogComment }
              />
            </MUI.Collapse>
          </div>
        ) : (
          <PostSkeleton count={ 1 } />
        ) }

        { similarBlogs && similarBlogs?.data?.data?.length !== 0 && (
          <>
            <MUI.Typography className="Similar">{ t( "SIMILAR" ) }</MUI.Typography>
            <MUI.Grid
              className="SimilarSection"
              container
              rowSpacing={ 1 }
              columns={ { xs: 4, sm: 8, md: 12 } }
              columnSpacing={ { xs: 1, sm: 2, md: 3 } }
            >
              { similarBlogs?.data?.data?.map( ( e, index ) => (
                <MUI.Grid key={ index } item xs={ 4 }>
                  <Link
                    style={ { color: "black", textDecoration: "none" } }
                    to={ {
                      pathname: `../blog/blogs/${ e.id }`,
                      state: { data: data },
                    } }
                  >
                    <GazCard
                      img={ e?.featured_image }
                      title={ e?.title }
                      tags={ e?.tags }
                      parag={ e?.description }
                      profile={ e?.user?.profile_image }
                      name={ e?.user?.first_name }
                      date={ e.created_at }
                    />
                  </Link>
                </MUI.Grid>
              ) ) }
            </MUI.Grid>
          </>
        ) }
      </div>
      {/* open the dialogue component when showDialogue is true */ }
      { showDialogue && (
        <DialogComponent
          actions
          message="ARE_YOU_SURE_YOU_WANT_TO_DELETE"
          onClose={ () => setShowDialogue( false ) }
          data={ blog?.id }
          handleResponse={ handleDeleteBlog }
        />
      ) }
    </>
  );
}

export default GazetteInformation;
