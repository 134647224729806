//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { objectCleaner } from "../../../core/functions/Functions";
import { useSearchParams } from "react-router-dom/dist";

//COMPONENT
import MyPointsDetail from "./MyPointsDetail";
import RowComponent from "../../../components/table-component/RowComponent";
import { dataPerPage } from "../../../core/variables/Variables";
import TablePaginationSection from "../../../components/table-pagination/TablePaginationSection";
import TableComponent from "../../../components/table-component/TableComponent";
import * as MUI from "@mui/material";
//APIs

import * as userAPI from "../../../core/services/user";

import "./MyPoints.scss";

// Context

const MyPoints = () => {
  const alert = useContext( AlertContext );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ loading, setLoading ] = useState( false );
  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ openDetail, setOpenDetail ] = useState( {
    state: null,
    open: false,
  } );
  const [ filter, setFilter ] = useState( {
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: searchParams.get( "page" ) || 1,
  } );
  const tableHeaders = [
    {
      name: "ACTIVITY",
    },
    {
      name: "POINTS_PER_ACTIVITY",
    },
    {
      name: "COUNT",
    },
    {
      name: "TOTAL",
    },
  ];

  const [ data, setData ] = useState( [] );

  const getUserdata = () => {
    setLoading( true );
    userAPI
      .getUserAchievements( {
        page: filter?.page,
        per_page: filter?.per_page,
      } )
      .then( ( res ) => {
        !res?.data?.success && alert?.warning( res?.data ? res?.data?.message : res?.message );
        setData( res?.data?.data ? res?.data?.data?.data : [] );
        setPageTotal( res?.data?.data?.last_page );
        setLoading( false );
      } );
  };

  const handlePageChange = ( e, page ) => {
    let newFilters = {
      ...filter,
      page: page,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };
  useEffect( () => {
    setFilter( {
      per_page: searchParams.get( "per_page" ) || dataPerPage,
      page: +searchParams.get( "page" ) || 1,
    } );
  }, [ searchParams ] );
  useEffect( () => {
    getUserdata();
  }, [ filter ] );

  return (
    <>
      <TableComponent
        tableHeaders={ tableHeaders }
        loading={ loading }
        tableData={ data }
        actions={ false }
      >
        { data?.map( ( element ) => (
          <RowComponent
            actions={ false }
            row={ element }
            key={ element?.id }
            handleHover={ () =>
              setOpenDetail( {
                bool: true,
                state: element,
              } )
            }
          >
            <MUI.TableCell>{ element.title }</MUI.TableCell>
            <MUI.TableCell>{ element.points_to_be_earned }</MUI.TableCell>
            <MUI.TableCell>{ element.user_count }</MUI.TableCell>
            <MUI.TableCell>
              { element.user_count * element.points_to_be_earned }
            </MUI.TableCell>
          </RowComponent>
        ) ) }
      </TableComponent>

      <TablePaginationSection
        page={ filter?.page }
        totalPage={ pageTotal }
        handlePageChange={ handlePageChange }
        data={ data }
      />
      { openDetail.bool && (
        <MyPointsDetail
          achievementItem={ openDetail?.state }
          onClose={ () => setOpenDetail( false ) }
        />
      ) }
    </>
  );
};
export default MyPoints;
