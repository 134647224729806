//UTILITIES
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

//COMPONENT
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import {
  Avatar,
  Card,
  CardContent,
  Divider,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Skeleton,
} from "@mui/material";
import { DialogContent, Dialog } from "@mui/material";

//CSS
import "./Chat.scss";
import { RectangularSkeleton } from "../../components/skeletons/SkeletonsComponent";
import DialogCustomTitle from "../../components/dialog/DialogCustomTitle";

export function UserChatDetail( props ) {
  const [ t ] = useTranslation( "common" );
  const { data, loading } = props;

  return (
    <>
      <ListItem className="chat-user-item">
        <ListItemAvatar>
          <Avatar
            src={
              data?.profile_image
                ? REACT_APP_IMAGE_URL + data?.profile_image
                : ""
            }
          />
        </ListItemAvatar>
        <ListItemText
          primary={
            !loading ? data?.first_name + " " + data?.last_name : <Skeleton />
          }
          secondary={ !loading ? data?.role?.name : <Skeleton width={ 100 } /> }
        />
      </ListItem>


      <CardContent>
        { !loading ? (
          <>

            <div className="user-personal-information">
              <div className="title">{ t( "EMAIL" ) }</div>
              <div className="body">{ data?.email }</div>
            </div>
            <div className="user-personal-information">
              <div className="title">{ t( "PHONE_NUMBER" ) }</div>
              <div className="body">{ data?.phone_number }</div>
            </div>
          </>
        ) : (
          <RectangularSkeleton height={ 100 } />
        ) }
      </CardContent>
    </>
  );
}

export function ChatUserDetail( props ) {
  const { dialog, onClose, loading } = props;

  return dialog ? (
    <Dialog disablebackdropclick="true" fullWidth open={ true }>
      <DialogCustomTitle
        title={ "USER_DETAIL" }
        onClose={ () => onClose() }
        classNames={ `dialog-title-bb-color` }
      />
      <DialogContent>
        <UserChatDetail { ...props } />
      </DialogContent>
    </Dialog>
  ) : (
    <Card className="chat-user-content">
      <UserChatDetail { ...props } />
    </Card>
  );
}
export default ChatUserDetail;
ChatUserDetail.defaultProps = {
  dialog: false,
};

ChatUserDetail.propTypes = {
  dialog: PropTypes.bool,
};
