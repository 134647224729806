import { api } from "./main";

import { Cookies } from "react-cookie";

export const isUserLoggedIn = () => {
  const cookies = new Cookies();
  return cookies?.get( "user" ) === "true";
};

export const UserLogin = ( data ) => {
  return api
    .post( "v1/login", data )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const ForgetPassword = ( payload ) => {
  return api
    .patch( "v1/reset-password-form", payload )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const ResetPassword = ( data ) => {
  return api
    .patch( "v1/reset-password", data )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const LogoutHandler = () => {
  return api
    .get( `v1/logout` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};

export const isLoggedIn = () => {
  return api
    .get( `v1/is-logged-in` )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
