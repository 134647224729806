import React, { createContext, useState } from "react";

const ChatContext = createContext({ user: null, new: false });

const ChatContextProvider = (props) => {
  const { children } = props;
  const [chatUser, setChatUser] = useState({ user: null, new: false });

  return (
    <ChatContext.Provider value={{ chatUser, setChatUser }}>
      {children}
    </ChatContext.Provider>
  );
};
export { ChatContextProvider, ChatContext };
