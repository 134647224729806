import { api } from "./main";

export const redeemAward = ( data ) => {
  return api
    .post( `v1/redemptions`, data )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getUserRedemption = ( data ) => {
  let page = data?.page ? `?page=${ data.page }` : "";
  return api
    .get( `v1/redemptions${ page }`, {
      params: data,
    } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
