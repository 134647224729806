//UTILTIIES
import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { useMessages } from "../../hooks/useMessages";
import { Link, useParams } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
import { ChatContext } from "../../context/ChatContext";
import { UserContext } from "../../context/UserContext";
//COMPONENT
import { markMessageAsUnread, setupUnreadMessagesListener } from "../../core/services/firebase";
import {
  ListItemAvatar,
  ListItemText,
  ListItem,
  Divider,
  IconButton,
  Avatar,
  Badge,
} from "@mui/material";
import { PinChat } from "../../core/services/chat";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { PinnedIcon, UnpinnedIcon } from "../../assets/icons/SVG";

//API

//COMPONENT OF THE ONE ELEMENT CHAT IN A  LIST

export function ChatElement( props ) {
  //CONTEXT
  const { data, index, length, pinned, chatlist, reloadData, onClose } = props;
  const { chatId } = useParams();
  const { setAlert } = useContext( AlertContext );
  const { user } = useContext( UserContext )
  const { setChatUser } = useContext( ChatContext );
  const [ chatRooms, setChatRooms ] = useState( {} );
  const roomId = useMemo( () => {
    return `${ Math.min( user?.id, data?.id ) }-${ Math.max( user?.id, data?.id ) }`
  }, [ user?.id, data?.id ] )
  const { messages } = useMessages( roomId )


  const handlePin = ( id, pinnedStatus ) => {
    PinChat( {
      user_chat_id: id,
      pin: pinnedStatus,
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        reloadData();
      } else {
        setAlert( {
          text: res?.data ? res?.data?.message : res?.message,
          error: !res?.data?.success,
        } );
      }
    } );
  };




  useEffect( () => {
    /*Timing Issue: On refresh, the component might be remounted, causing the setupUnreadMessagesListener function to be called again. 
    If this function doesn't return the unsubscribe function immediately on the initial render, 
    the unsubscribeUnreadMessages variable could be undefined until the function is called again.
    */
    let unsubscribeUnreadMessages;

    //listener of the unreadMessages for the badge
    unsubscribeUnreadMessages = setupUnreadMessagesListener(
      user?.id,
      async ( _, chatRooms ) => {
        //if the user is already opening that chat page mark it as read
        if ( chatId == data?.id && chatRooms.hasOwnProperty( roomId ) ) {
          await markMessageAsUnread( user?.id, false, roomId );

        } else {
          setChatRooms( chatRooms )
        }

        // Update your navigation bar badge based on the hasUnread value
        // For example, you can dispatch an action to update your state or use a global event bus
      }
    );

    return () => {
      if ( unsubscribeUnreadMessages ) {
        unsubscribeUnreadMessages();
      }

    };
  }, [ messages, user?.id, roomId ] )


  return (
    <Link to={ `../chat/${ data.id }` }>
      <ListItem
        disableRipple
        key={ data?.id }
        className="chat-element-item"
        button={ true }
        onClick={ ( e ) => {
          setChatUser( { user: data, new: false } );
          if ( chatRooms?.hasOwnProperty( roomId ) ) {
            markMessageAsUnread( user?.id, false, roomId );
          }

          onClose && onClose();
        } }
      >
        <ListItemAvatar>
          <Badge color="error"
            variant="dot"
            invisible={ !chatRooms?.hasOwnProperty( roomId ) }
            anchorOrigin={ {
              vertical: 'bottom',
              horizontal: 'right',
            } }>
            <Avatar
              src={
                data?.profile_image
                  ? REACT_APP_IMAGE_URL + data?.profile_image
                  : null
              }
              alt={ data?.first_name }
            /></Badge>
        </ListItemAvatar>
        <ListItemText
          id={ data.user_id }
          primary={
            <div className="primary-line">
              <div>{ data?.first_name + " " + data?.last_name }</div>
              { chatlist ? (
                <div>
                  { pinned ? (
                    <IconButton
                      onClick={ ( e ) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        handlePin( data?.pivot?.user_chat_id, false );
                      } }
                    >
                      <PinnedIcon />
                    </IconButton>
                  ) : (
                    <IconButton
                      onClick={ ( e ) => {
                        e?.preventDefault();
                        e?.stopPropagation();
                        handlePin( data?.pivot?.user_chat_id, true );
                      } }
                    >
                      <UnpinnedIcon />
                    </IconButton>
                  ) }
                </div>
              ) : (
                " "
              ) }
            </div>
          }
        />
      </ListItem>

      { index !== length - 1 && ( <Divider /> ) }
    </Link>
  );
}

export default ChatElement;
ChatElement.defaultProps = {
  pinned: false,
  chatlist: false,
};

ChatElement.propTypes = {
  pinned: PropTypes.bool,
  chatlist: PropTypes.bool,
};
