import React, { useState, useEffect, useContext } from "react";
import { useNavigate } from "react-router-dom/dist";
import toast, { Toaster } from "react-hot-toast";
import { NotificationContext } from "../../../context/NotificationContext";
import { LogoSVG } from "../../../assets/icons/SVG";
//CSS
import "./PushNotifications.scss"
import { onMessageListener, requestPermission } from "../../../core/services/firebase";




const PushNotification = () => {
    let navigate = useNavigate();
    const [ notification, setNotification ] = useState( { title: "", body: "", data: { url: "", id: null }, } );
    const { updateNotificationCount } = useContext( NotificationContext );
    const handleClick = ( tId ) => {
        if ( notification?.data?.url !== "" ) {
            navigate( notification?.data?.url.slice( 1 ) )
        } else {
            navigate( `notifications` );
        }
        toast.dismiss( tId ); //to close the push notification
    };



    const notify = () => toast( ( t ) => ( <ToastDisplay toast={ t } /> ), { duration: 4000 } );
    const ToastDisplay = ( toast ) => (
        < div className={ `push-notification-layout ${ notification?.data?.url !== "" ? 'cursor-pointer' : "" } `
        } onClick={ notification?.data?.url !== "" ? () => handleClick( toast?.id ) : null } >
            <LogoSVG width={ 70 } height={ 10 } />
            <div className='description'> <div>{ notification?.title } </div>
                <div>{ notification?.body }</div></div>

        </div >

    )



    useEffect( () => {

        requestPermission();
    }, [] )
    useEffect( () => {
        notification?.title && notify();
    }, [ notification ] );


    onMessageListener()
        .then( ( payload ) => {

            setNotification( {
                title: payload?.notification?.title,
                body: payload?.notification?.body,
                data: {
                    url: `..${ payload?.fcmOptions?.link }`
                }
            } );
            updateNotificationCount();
        } )
        .catch( ( err ) => console.log( "failed: ", err ) );

    return <Toaster />;
};

export default PushNotification;
