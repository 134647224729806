import dayjs from "dayjs";




export const convertUTCToLocal = ( utcStr ) => {
    const utcDate = new Date( utcStr );
    const timezoneOffset = new Date().getTimezoneOffset();
    const localDate = new Date( utcDate.getTime() - timezoneOffset * 60000 );
    return localDate;
};


//check if the meeting have 10mins to start
export const meetingIsHappeningNow = ( meeting_time ) => {
    let tenMinutesPrior = new Date();
    tenMinutesPrior.setMinutes( tenMinutesPrior.getMinutes() + 10 );
    let tenMinutesAfter = new Date();
    tenMinutesAfter.setMinutes( tenMinutesAfter.getMinutes() - 10 );
    if (
        convertUTCToLocal( meeting_time ) < tenMinutesPrior
    )
        return true;
    else return false;

}
export const getMeetingStartDateTenMinutesPrior = ( meeting_start_time ) => {
    //this function to get the start date session before 10 min
    const start = convertUTCToLocal( meeting_start_time ); //convert the start_date_time to utc
    let tenMinutesPrior = new Date( start );
    tenMinutesPrior.setMinutes( tenMinutesPrior.getMinutes() - 10 );
    //use dayjs to format the date ten minutes prior
    return dayjs( tenMinutesPrior ).format( "DD-MM-YYYY HH:mm:ss" );
};