import {
  Outlet,
  useNavigate,
  useParams,
} from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import RootLayout from "./rootLayout/RootLayout";
import { useContext, useEffect } from "react";
import { LanguageContext } from "../context/LanguageContext";
import { UserContext } from "../context/UserContext";

export const ProtectedLayout = ( props ) => {
  const { requireLogin } = props
  const { user } = useAuth();
  let navigate = useNavigate();
  const { language, setLanguage } = useContext( LanguageContext );
  const { lang } = useParams();
  const { userLoading } = useContext( UserContext )

  useEffect( () => {
    /*if you are already loggedin and you open an invitation of a user to the platform
    from the email , it will show the choose profession with the navigation bar
    which is not the right behavior. that's why we added a loader to ensure the call of isLoggedIn 
    before redirecting the user

    */
    setLanguage( lang === "en" ? "en" : "ro" );
    if ( !userLoading ) {
      if ( requireLogin && !user ) {
        navigate( `/${ language }/login` )
      }
      else if ( user && !requireLogin ) {
        navigate( `/${ language }/home` )
      }
    }



  }, [ language, user, userLoading ] );

  return (
    <RootLayout { ...props }>
      <Outlet />
    </RootLayout>
  );
};
