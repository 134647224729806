import React, { createContext, useEffect, useState } from "react";
import { Alert, Snackbar } from "@mui/material";
//creating context
const AlertContext = createContext();

//alert context triggering alerts allover the app.
const AlertContextProvider = ( props ) => {
  //props distructuring
  const { children } = props;

  //hooks
  const [ alert, setAlert ] = useState( { visible: false } );

  //clearing alert component after 3 secs
  useEffect( () => {
    alert.visible &&
      setTimeout( () => {
        setAlert( { visible: false } );
      }, 3000 );
  }, [ alert ] );

  return (
    <AlertContext.Provider
      value={ {
        alert,
        setAlert,
        success: ( text, timeout ) => {
          setAlert( { visible: true, text: text, type: "success" } );
          setTimeout( () => {
            setAlert( {} );
          }, timeout * 1000 || 10000 );
        },
        warning: ( text, timeout ) => {
          setAlert( { visible: true, text: text, type: "warning" } );
          setTimeout( () => {
            setAlert( {} );
          }, timeout * 1000 || 10000 );
        },

        error: ( text, timeout ) => {
          setAlert( { visible: true, text: text, type: "error" } );
          setTimeout( () => {
            setAlert( {} );
          }, timeout * 1000 || 10000 );
        },
        clear: () => setAlert( {} ),
      } }
    >
      { alert?.visible ? (
        <Snackbar
          open={ true }
          autoHideDuration={ 6000 }
          onClose={ () => setAlert( { visible: false } ) }
          anchorOrigin={ {
            vertical: "top",
            horizontal: "center",
          } }
        >
          <Alert
            onClose={ () => setAlert( { ...alert, visible: false } ) }
            severity={ alert?.type }
            sx={ { width: "100%" } }
          >
            { alert?.text }
          </Alert>
        </Snackbar>
      ) : null }
      { children }
    </AlertContext.Provider>
  );
};

//exporting context
export { AlertContextProvider, AlertContext };
