//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { useNavigate, useOutletContext } from "react-router-dom";
//COMPONENT
import LoadMoreButton from "../../../components/load-more-button/LoadMoreButton";
import DialogComponent from "../../../components/dialog/Dialog";
import { NoData } from "../../../components/Fallback/NoData";
import { QASkeleton } from "../../../components/skeletons/SkeletonsComponent";
import { dataPerPage } from "../../../core/variables/Variables";
//API
import {
  deleteBlog,
  getBlogs,
  getUserBlogs,
} from "../../../core/services/blogs";
//CSS
import "./MyQuestions.scss";
import QuestionComponent from "../browse/QuestionComponent";
import { useLocation, useSearchParams } from "react-router-dom/dist";
import { groupParamsByKey } from "../../../core/functions/Functions";

const MyQuestions = ( props ) => {
  const Alert = useContext( AlertContext );
  const [ searchParams, _ ] = useSearchParams();
  const { search } = groupParamsByKey( searchParams ) || {};
  const { type } = props;
  //VARIABLES
  const [ isLoading, setIsLoading ] = useState( true );
  const [ data, setData ] = useState( [] );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );
  const [ currentId, setCurrentId ] = useState();
  const [ show, setShow ] = useState( false );
  let navigate = useNavigate();



  const getQuestionsData = ( new_page ) => {
    let handleLoad = new_page ? setIsLoadMore : setIsLoading;
    handleLoad( true );
    let handleApi = type === "MyQuestions" ? getUserBlogs : getBlogs;
    let current_page =
      new_page && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;
    handleApi( {
      is_gazette: false,
      page: current_page,
      per_page: dataPerPage,
      name: search,
      speciality_ids: searchParams.getAll( "speciality_ids" ) || [],
      company_ids: searchParams.getAll( "company_ids" ) || [],
      product_ids: searchParams.getAll( "product_ids" ) || [],
      status_id: 1, //published always
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setPage( res?.data.data?.current_page );
        if ( new_page ) {
          setData( [ ...data, ...res?.data.data.data ] );
        } else {
          setData( res?.data?.data?.data || [] );
        }
        setTotalPage( res?.data.data?.last_page );
      } else {
        Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      }
      handleLoad( false );
    } );
  };

  const handleDeleteQuestion = ( id ) => {
    setShow( true );
    setCurrentId( id );
  };

  const handleDeleteResponse = ( questionId ) => {
    deleteBlog( questionId ).then( ( res ) => {
      if ( res?.data?.success ) {
        // Find the index of the question to delete in the array if it is equal to questionId
        const newData = [ ...data ];
        const indexToDelete = newData.findIndex(
          ( myQuestions ) => myQuestions.id === questionId
        );

        // If the question is found, remove it from the array using splice
        if ( indexToDelete !== -1 ) {
          newData.splice( indexToDelete, 1 );
        }
        setData( newData );
        setShow( false );
        setCurrentId( null );
      }
      Alert?.[ res?.data?.success ? "success" : "error" ]( res?.data?.message );
    } );
  };
  const updatePostCommentCount = ( postData, type, commentChildrenCount ) => {
    let array = [ ...data ];
    let index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( type === "add" ) {
      array[ index ][ "comments_count" ] += 1;
    } else {
      //delete comment and its descendant
      array[ index ][ "comments_count" ] -= ( 1 + commentChildrenCount );
    }
    setData( array );
  };

  const updatePostLikeCount = (
    postData,
    is_reacted,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {
    let array = [ ...data ];
    let post_index = array.findIndex( ( item ) => item.id === postData?.id );
    //if is_reacted => unlike enfisam backend
    if ( is_reacted ) {
      //like
      if ( !array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] += 1; //if user already reacted and now he is changing reaction
      } else {
        array[ post_index ][ prevReaction?.key ] -= 1; //if user already reacted before then we remove the prev
      }
      array[ post_index ][ "is_reacted" ] = 1;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    } else {
      if ( array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] -= 1;
      }
      array[ post_index ][ "is_reacted" ] = 0;
      array[ post_index ][ prevReaction?.key ] -= 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    }

    setData( array );
  };
  const handleDetail = ( questionId ) => {
    navigate( `../answer-section/${ questionId }` );
  };


  useEffect( () => {

    getQuestionsData( false );
  }, [ search, searchParams ] );

  return (
    <div className="myQuestions-container">
      <div className="myQuestions-body">
        { isLoading ? (
          <QASkeleton count={ 3 } />
        ) : data?.length === 0 ? (
          <NoData title={ "NO_QUESTIONS_FOUND" } />
        ) : (
          <>
            { data?.map( ( val ) => (
              <QuestionComponent
                data={ val }
                handleDetail={ () => handleDetail( val?.id ) }
                key={ val?.id }
                updatePostLikeCount={ updatePostLikeCount }
                updatePostCommentCount={ updatePostCommentCount }
                handleDeleteQuestion={ () => handleDeleteQuestion( val.id ) }
                tags={ val?.tags }
              />
            ) ) }
            { page < totalPage && !isLoading && (
              <LoadMoreButton
                isLoadMore={ isLoadMore }
                title="LOAD_MORE"
                onClick={ () => getQuestionsData( true ) }
              />
            ) }
          </>
        ) }
      </div>
      { show && (
        <DialogComponent
          actions
          message="ARE_YOU_SURE_YOU_WANT_TO_DELETE"
          onClose={ () => setShow( false ) }
          data={ currentId }
          handleResponse={ handleDeleteResponse }
        />
      ) }
    </div>
  );
};

export default MyQuestions;
