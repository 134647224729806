import React from "react";
import { CardMedia, Container } from "@mui/material";
import "./PresentationCard.scss";
import PDFIMAGE from "../../assets/images/pdf-image.png";
import VIDEOIMAGE from "../../assets/images/video-image.png";

const PresentationCard = ( props ) => {
  const { header, type } = props;
  return (
    <div className="PresentationCard">
      <div className="video">
        <CardMedia
          component="img"
          height="120"
          className="PowerPointPresentation"
          id="iframe"
          src={ type === 4 ? PDFIMAGE : VIDEOIMAGE } //if not presentation then video
          display
          styles={ { width: "100%", toolbar: { display: "none" } } }
        />
      </div>

      <Container>
        <div className="PresentationType">{ header }</div>
      </Container>
    </div>
  );
};

export default PresentationCard;
