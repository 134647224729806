import React, { forwardRef, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import Button from "../button/Button";
import { DeleteArrow } from "../../assets/images/svg";
import {
  CardMedia,
  Typography,
  DialogActions,
} from "@mui/material";
import { Dialog, DialogContent } from "@mui/material";
import { DialogContentText, DialogTitle, Slide } from "@mui/material";
import "./Popup.scss";
import defaultImage from "../../assets/images/DEFAULT.jpg";
import PDF from "../../assets/images/pdf-image.png";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { UpdateSVG } from "../../assets/icons/SVG";


const Transition = forwardRef( function Transition( props, ref ) {
  return <Slide direction="up" ref={ ref } { ...props } />;
} );
export function AlertDialogSlide( props ) {
  const { popupAction } = props;
  const [ open, setOpen ] = useState( false );
  const [ t ] = useTranslation( "common" );

  const handleModal = () => {
    setOpen( !open );
    if ( props.redirect ) {
      window.location.reload( "/rewards-details/e-shop" );
    }
    props.onHide( props.onHideState );
  };

  return (
    <Dialog
      open={ props.onHideState }
      TransitionComponent={ Transition }
      keepMounted
      onClose={ handleModal }
      sx={ {
        fontSize: 18,
        textTransform: "none",
      } }
      aria-describedby="alert-dialog-slide-description"
      className="AlertDialog"
    >
      { props.children }
      { props.title && (
        <DialogTitle>
          <div className="titlepoints">{ t( props.title ) }</div>
        </DialogTitle>
      ) }

      { props.body && (
        <DialogContent>
          <DialogContentText id="alert-dialog-slide-description">
            <div className="body">{ t( props.body ) } </div>
          </DialogContentText>
        </DialogContent>
      ) }

      <DialogActions>
        <div
          style={ {
            display: "flex",
            alignItems: "center",
            gap: "15px",
            width: "100%",
          } }
        >
          <div
            style={ {
              display: "flex",
              gap: "15px",
              width: "100%",
              justifyContent: "center",
            } }
            className="button-cancel"
          >
            <Button
              //color="secondary"
              name={ props.buttonTitle ? props.buttonTitle : "CANCEL" }
              onClick={ handleModal }
              width={ "30%" }
            />
            { props.withAction && (
              <Button
                isSelected
                name={ props.buttonTitl ? props.buttonTitl : "CONTINUE" }
                onClick={ () => popupAction() }
                width={ "30%" }
              />
            ) }
          </div>
        </div>
      </DialogActions>
    </Dialog>
  );
}

export function ImageCropper( props ) {
  const [ t ] = useTranslation( 'common' )
  const [ open, setOpen ] = useState( false );

  const handleModal = ( e ) => {
    setOpen( !open );
    props.onHide( props.onHideState );
  };

  return (
    <Dialog
      open={ props.onHideState }
      TransitionComponent={ Transition }
      keepMounted
      onClose={ handleModal }
      sx={ {
        fontSize: 18,
        textTransform: "none",
      } }
      aria-describedby="alert-dialog-slide-description"
      onBackdropClick={ props.onBackdropClick }
    >
      <div className="imageCropperPopup">
        <div className="headerPopup">
          <div className="dialogInformation">
            <p className="headerText">{ t( 'EDIT_PROFILE_PICTURE' ) }</p>
            <div
              onClick={ props.onCloseClick }
              style={ { marginRight: "20px", cursor: "pointer" } }
            >
              <DeleteArrow />
            </div>
          </div>

          <div className="bodyImageCapture">{ props.children }</div>
        </div>
      </div>
    </Dialog>
  );
}

export function ShowDescDialog( props ) {
  return (
    <Dialog
      open={ props.onHideState }
      TransitionComponent={ Transition }
      keepMounted
      onClose={ props.onHide }
      sx={ {
        fontSize: 18,
        textTransform: "none",
        height: "50vh",
      } }
      aria-describedby="alert-dialog-slide-description"
    >
      <div style={ { width: "95%", float: "right", marginTop: "10px" } }>
        <div
          onClick={ props.onCancel }
          style={ {
            float: "right",
            paddingRight: "10px",
            cursor: "pointer",
            margin: props.CloseMargin,
            padding: props.ClosePadding,
          } }
        >
          <DeleteArrow />
        </div>
      </div>
      <div style={ { padding: "10px" } }>
        <div
          style={ {
            margin: "auto",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#f7f9f9",
          } }
        >
          { props.image ? (
            <img
              component="img"
              height="200"
              src={ process.env.REACT_APP_IMAGE_URL + props.image }
              alt="green iguana"
            />
          ) : (
            <img
              component="img"
              height="200"
              src={ defaultImage }
              alt="green iguana"
            />
          ) }
        </div>
        <Typography gutterBottom variant="h7" component="div">
          { props.title }
        </Typography>
        <Typography style={ { cursor: "pointer" } } color="text.secondary">
          <div style={ { minWidth: "250px", maxWidth: "660px" } }>
            { props.body }
          </div>
        </Typography>
      </div>
    </Dialog>
  );
}

export function DocumentPopUp( props ) {
  const { onHideState, onHide, type, pdf } = props;

  return (
    <Dialog
      open={ onHideState }
      TransitionComponent={ Transition }
      keepMounted
      onClose={ onHide }
      sx={ {
        fontSize: 18,
        textTransform: "none",
      } }
      aria-describedby="alert-dialog-slide-description"
    >
      { type === "pdf" ? (
        <iframe
          src={ `https://docs.google.com/viewer?url=${ REACT_APP_IMAGE_URL + pdf }
                  &embedded=true`}
          style={ { width: "500px", height: "700px" } }
        />
      ) : (
        <CardMedia
          component="img"
          height="400"
          required
          image={ type === "pdf" ? PDF : process.env.REACT_APP_IMAGE_URL + pdf }
          alt={ "" }
        />
      ) }
    </Dialog>
  );
}

export function SchedulePopUp( props ) {
  const { onClose, onCancel, icon, message, children, title } = props;
  const [ t ] = useTranslation( 'common' )
  return (
    <Dialog disablebackdropclick="true" fullWidth open={ true } onClose={ onClose }>
      <DialogTitle>
        { t( title ) }
      </DialogTitle>
      <DialogContent className="schedule-popup-content">
        { children ? (
          children
        ) : (
          <div
            style={ {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            } }
          >
            { icon }

            <p className="titleForceUpdate">{ t( message ) }</p>

            <div className="ButtonsGrouping">
              <Button width="100%" name="CANCEL" onClick={ onCancel } />
              <Button
                width="100%"
                isSelected
                name="CONFIRM"
                onClick={ props.onConfirm }
                loading={ props.loading }
              />
            </div>
          </div>
        ) }
      </DialogContent>
    </Dialog>
  );
}
SchedulePopUp.defaultValues = {
  message:
    "Meetings 1, 2, 3 conflicts with your new schedule. Are you sure you want to Update Schedule ?",
  className: "",
  icon: <UpdateSVG />,
};
SchedulePopUp.propTypes = {
  message: PropTypes.string,
  icon: PropTypes.node,
};

