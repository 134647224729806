//ALL 3RD PARTIES APIS FOR STATE AND CITIES
import axios from "axios";
import { X_RAPIDAPI_KEY } from "./main";

const requestHeaders = {
  "X-RapidAPI-Key": X_RAPIDAPI_KEY,
  "X-RapidAPI-Host": "wft-geo-db.p.rapidapi.com",
};

//geolimit is acting like per page
//offset is acting like page
export const getRegions = async (
  countryCode,
  offset = 0,
  limit = "",
  namePrefix = ""
) => {
  const options = {
    method: "GET",
    url: `https://wft-geo-db.p.rapidapi.com/v1/geo/countries/${ countryCode }/regions`,
    params: {
      limit: limit,
      offset: offset,
      namePrefix: namePrefix,
    },
    headers: requestHeaders,
  };
  try {
    const response = await axios.request( options );
    return response?.data;
  } catch ( error ) {
    return error
  }
};

//geolimit is acting like per page
//offset is acting like page
export const getCities = async (
  countryCode,
  regionCode,
  offset = 0,
  limit = "",
  namePrefix = ""
) => {
  const options = {
    method: "GET",
    url: `https://wft-geo-db.p.rapidapi.com/v1/geo/countries/${ countryCode }/regions/${ regionCode }/cities`,
    params: {
      limit: limit,
      offset: offset,
      namePrefix: namePrefix,
    },
    headers: requestHeaders,
  };
  try {
    const response = await axios.request( options );
    return response?.data;
  } catch ( error ) {
    throw new Error(
      `Failed to get regions for country ${ countryCode }. ${ error.message }`
    );
  }
};
