//UTILITIES
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

//CSS
import "./Document.scss";

//COMPONENT
import { NoData } from "../../../components/Fallback/NoData";
import { dataPerPage, filterDataPerPage } from "../../../core/variables/Variables";
import LoadMoreButton from "../../../components/load-more-button/LoadMoreButton";
import { FormControl, Button, Skeleton } from "@mui/material";
import FileSVG from "../../../assets/icons/FileSVG";
import { DocumentPopUp } from "../../../components/popup/Popup";
//API
import * as document from "../../../core/services/user";
import { REACT_APP_IMAGE_URL } from "../../../core/services/main";

const Document = () => {
  const [ t ] = useTranslation( "common" );
  const [ data, setData ] = useState( [] );
  const [ onHideState, setOnHideState ] = useState( false );
  const [ path, setPath ] = useState( "" );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );

  const getDoc = ( new_page ) => {
    let handleLoad = new_page ? setIsLoadMore : setIsLoading;
    handleLoad( true );
    let current_page =
      new_page && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;
    document
      .getDocuments( { page: current_page, per_page: dataPerPage } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          setPage( res?.data?.data?.current_page );
          if ( new_page ) {
            setData( [ ...data, ...res?.data?.data?.data ] );
          } else {
            setData( res.data?.data?.data || [] );
          }

          setTotalPage( res.data.data?.last_page );
        }
        handleLoad( false );
      } );
  };

  useEffect( () => {
    getDoc( false );
  }, [] );

  const openModal = ( path ) => {
    setOnHideState( !onHideState );
    setPath( path );
  };

  return (
    <div className="educationalQualification">
      <div className="header">{ t( "DOCUMENTS" ) }</div>
      { isLoading ? (
        <Skeleton />
      ) : data?.length === 0 ? (
        <NoData title={ "NO_DOCUMENTS_FOUND" } />
      ) : (
        <>
          <div className="documents">
            { data?.map( ( docElement ) => (
              <div className="input" key={ docElement?.id }>


                <Button
                  className="document-button"
                  variant="outlined"
                  component="span"
                  startIcon={ <FileSVG /> }
                  onClick={ () => window.open( REACT_APP_IMAGE_URL + docElement?.path, '_blank' ) }
                >
                  { docElement?.name }
                </Button>

                <DocumentPopUp
                  onHideState={ onHideState }
                  onBackdropClick={ () => setPath( "" ) }
                  onHide={ () => setOnHideState( false ) }
                  type="pdf"
                  pdf={ path }
                />
              </div>
            ) ) }

          </div>
          { page < totalPage && !isLoading && (
            <LoadMoreButton
              isLoadMore={ isLoadMore }
              title="LOAD_MORE"
              onClick={ () => getDoc( true ) }
            />
          ) }
        </>
      )
      }
    </div >
  );
};

export default Document;
