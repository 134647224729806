import { api } from "./main";
//#region GET METHODS
export const getAllCountries = ( data ) => {
  return api
    .get( `v1/countries` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getSelfAddress = ( payload ) => {

  return api
    .get( `v1/self-addresses`, { params: payload } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getCityByCoutryId = ( id ) => {
  return api
    .get( `v1/get-cities-by-country-id/${ id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};


export const getCity = async ( input ) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/place/autocomplete/json?input=${ input }&components=country:RO&types=%28cities%29&key=AIzaSyCCP5CF3Rvo2L_z6jppuDjIhuIaxyvel-4`,
      { mode: "cors" }
    );
    const data = await response.json();
  } catch ( error ) {
    console.error( "Error fetching places:", error );
  }
};

//#endregion
export const updateAddress = ( payload ) => {

  return api
    .put( `v1/address/${ payload?.address_id }`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const deleteAddress = ( payload ) => {

  return api
    .delete( `v1/address/${ payload }`, { data: payload } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const addAddress = ( payload ) => {

  return api
    .post( `v1/address`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};


