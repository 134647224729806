import * as React from "react";

const RingSVG = (props) => (
  <svg
    width="31"
    height="30"
    viewBox="0 0 31 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={{ pointerEvents: "none" }}
    {...props}
  >
    <path
      d="M10.5 25.01c1.375 0 2.5-1.125 2.5-2.5H8a2.5 2.5 0 0 0 2.5 2.5Zm7.5-7.5v-6.25c0-3.838-2.05-7.05-5.625-7.9v-.85A1.872 1.872 0 0 0 10.5.635 1.872 1.872 0 0 0 8.625 2.51v.85C5.037 4.21 3 7.41 3 11.26v6.25l-2.5 2.5v1.25h20v-1.25l-2.5-2.5Z"
      fill="#71A2A7"
    />
  </svg>
);

export default RingSVG;
