import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as MUI from "@mui/material";
import DialogCustomTitle from "./DialogCustomTitle";

import Button from "../button/Button";

//CSS
import "./Dialog.scss";
import { NoticeDialog } from "../../theme/ComponentTheme";
const Transition = forwardRef(function Transition(props, ref) {
  return <MUI.Slide direction="up" ref={ref} {...props} />;
});

export function DialogComponent(props) {
  //TRANSLATION
  const [t] = useTranslation("common");

  //VARIABLES
  const { data, onClose, handleResponse, message, actions } = props;

  return (
    <NoticeDialog open={true} TransitionComponent={Transition}>
      <DialogCustomTitle title="" onClose={() => onClose()} />
      <MUI.DialogContent>
        <div className="notice-confirmation-component">
          <div>{t("NOTICE")}</div>
          <div>{t(message)}</div>
        </div>
      </MUI.DialogContent>
      {actions && (
        <MUI.DialogActions>
          <MUI.Button
            fullWidth
            variant="contained"
            type={"button"}
            onClick={() => {
              onClose();
            }}
            color="secondary"
            className="notice-confirmationn-buttons"
          >
            {t("NO")}
          </MUI.Button>
          <MUI.Button
            fullWidth
            variant="contained"
            type={"button"}
            onClick={() => {
              handleResponse(data);
            }}
            className="notice-confirmationn-buttons"
            color="primary"
          >
            {t("YES")}
          </MUI.Button>
        </MUI.DialogActions>
      )}
    </NoticeDialog>
  );
}
export default DialogComponent;
DialogComponent.defaultProps = {
  message: "ARE_YOU_SURE_YOU_WANT_TO_DELETE",
};

DialogComponent.propTypes = {
  message: PropTypes.string,
};
