import { api } from "./main";

export const getNewestPosts = ( data ) => {
  return api
    .get( `v1/posts-by-groups`, {
      params: data,
    } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
export const getPostById = ( post_id ) => {
  return api
    .get( `v1/posts/${ post_id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getPostlikes = ( payload ) => {
  return api
    .get( `v1/post-likes/${ payload?.post_id }`, {
      params: {
        ...payload,
      }
    } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getPostsType = ( data ) => {
  return api
    .get( `v1/post-types`, {
      params: data,
    } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const likePost = ( payload ) => {
  return api
    .patch( `v1/like-post/${ payload?.post_id }`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const createCommentPost = ( data ) => {
  return api
    .post( "v1/create-comment", data )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getImagesPosts = ( group_id, payload ) => {
  return api
    .get( `v1/group-posts/${ group_id }`, { params: payload } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getUserPostDetails = ( payload ) => {
  return api
    .get( `v1/posts`, {
      params: payload,
    } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};
