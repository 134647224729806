import { api } from "./main";
export const getAllProducts = (data) => {
  return api
    .get(`v1/products`, {
      params: data,
    })
    .then((res) => res)
    .catch((err) => err);
};
export const getAllCategories = (data) => {
  return api
    .get(`v1/product-categories`, {
      params: data,
    })
    .then((res) => res)
    .catch((err) => err);
};
export const getProductById = (product_id) => {
  return api
    .get(`/v1/products/${product_id}`)
    .then((res) => res)
    .catch((err) => err);
};
