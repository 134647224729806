import { useQuery } from "@tanstack/react-query";
import { api } from "./main";

export const getSchedule = () => {
  return api
    .get( "v1/self-schedule" )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getDays = () => {
  return api
    .get( "v1/weekdays" )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const createSchedule = ( data ) => {
  return api
    .post( "v1/schedule", data )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const updateSchedule = ( data ) => {
  return api
    .put( "v1/schedule", data )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getMeetingHistory = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `v1/user-event-history/${ payload?.user_id }${ page }`, {
      params: payload,
    } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const acceptMeeting = ( event_id ) => {
  return api
    .patch( `v1/accept-event/${ event_id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const cancelMeeting = ( event_id, payload ) => {
  return api
    .delete( `v1/cancel-event/${ event_id }`, { data: payload } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getDoctorMeetingsEvents = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `v1/self-activity-type-events/${ payload?.activity_type_id }${ page }`, {
      params: payload,
    } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const useMeetingRequest = ( payload ) =>
  useQuery( [ "self-activity-type-events", payload ], () =>
    getDoctorMeetingsEvents( payload ).then( ( res ) => res?.data?.data )
  );
