//UTILITIES
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import * as yup from "yup";
import React, {
  Fragment,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

//COMPONENT
import {
  nameRequiredLimited,
  dateOfBirthSchema,
  phoneNumberSchema,
  passwordSchema,
  over18,
} from "../../core/validation/form-validation";
import {
  FormMultiSelect,
  FormDropdownList,
  FormInput,
  FormPassword,
  FormCalendarInput,
  FormPhoneInput,
  FormNumberInput,
  FormRadioButton,
  FormSingleUpload,
  FormCheckBox,
  FormTextEditor,
  FormPaginationDropdownList,
} from "../../components/form/form-componenrt/FormComponent";
import { useTranslation } from "react-i18next";
import "react-phone-number-input/style.css";
import "./Register.scss";
import * as getData from "../../core/services/register";
import { Link, Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
import Button from "../../components/button/Button";
import { serialize } from "object-to-formdata";
import { CheckVisibilityIcon } from "../../core/functions/Functions";
import secureLocalStorage from "react-secure-storage";
import { useQuery } from "@tanstack/react-query";
import GeneralTermsOfUse from "../../assets/footerPDF/GeneralTermsOfUse.pdf";
import dayjs from "dayjs";
import { getAllCountries } from "../../core/services/address";
import { getCities, getRegions } from "../../core/services/gio-db";
import { InputLabel } from "@mui/material";
import { fileTypes } from "../../core/variables/Variables";

const HandleStep1Schema = yup.object({
  first_name: nameRequiredLimited(true, 30),
  last_name: nameRequiredLimited(true, 30),
  user_title_id: yup.object().nullable().required("FIELD_IS_REQUIRED"),
  email: yup
    .string()
    .email("INVALID_FORMAT")
    .nullable()
    .required("FIELD_IS_REQUIRED"),

  phone_number: phoneNumberSchema,
  password: passwordSchema,
  password_confirmation: yup
    .string("INVALID_FORMAT")
    .oneOf([yup.ref("password"), null], "PASSWORD_MUST_MATCH")
    .required("FIELD_IS_REQUIRED"),
  date_of_birth: dateOfBirthSchema,
});

function Register() {
  let navigate = useNavigate();
  const upperEl = useRef(null); //used to scroll up when rendering the component
  let roleId = sessionStorage.getItem("profession_id");
  const [t] = useTranslation("common");
  const [schemaStep2, setSchemaStep2] = useState(null);
  const [searchParams] = useSearchParams();
  const alert = useContext(AlertContext);
  const [saveLoading, setSaveLoading] = useState(false);
  const [page, setPage] = useState(
    Boolean(secureLocalStorage.getItem("submit")) || 0
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  //gio-db loadings
  const [stateLoading, setStateLoading] = useState(false);
  const [cityLoading, setCityLoading] = useState(false);
  const [stateData, setStateData] = useState({
    per_page: 10,
    page: 1,
    data: [],
    total: 0,
  });
  const [cityData, setCityData] = useState({
    per_page: 10,
    page: 1,
    data: [],
    total: 0,
  });

  //INITIATE FORM:
  const {
    control: controlStep1,
    getValues: getValues1,
    handleSubmit: handleSubmitStep1,
    formState: { errors: errorsStep1 },
  } = useForm({
    defaultValues: {
      invited_token: searchParams.get("token") || null,
      first_name: "",
      last_name: "",
      role_id: roleId,
      portal_id: 3,
      user_title_id: null,
      date_of_birth: dayjs(over18).format("YYYY-MM-DD"),
      email: searchParams.get("email") || null,
      phone_number: "",
      password: "",
      confirmPassword: "",
      language_id: 1,
      ...secureLocalStorage.getItem("submit"),
      is_notification: true,
    },
    resolver: yupResolver(HandleStep1Schema),
  });

  const {
    control: controlStep2,
    handleSubmit: handleSubmitStep2,
    setValue: setValueStep2,
    clearErrors: clearErrorsStep2,
    watch: watchStep2,
    getValues: getValues2,
    reset: resetStep2,

    formState: { errors: errorsStep2 },
  } = useForm({
    defaultValues: {},
    resolver: yupResolver(schemaStep2),
  });

  //BASED  ON THE FIELD TYPE WE ARE MAKING THE SCHEMA
  const switchCaseType = (fieldType, isRequired) => {
    fieldType = fieldType.toLowerCase();

    if (fieldType === "multiple select") {
      if (isRequired) {
        return yup
          .array()
          .nullable()
          .min(1, "FIELD_IS_REQUIRED")
          .required("FIELD_IS_REQUIRED");
      } else {
        return yup.array().nullable();
      }
    } else if (
      fieldType === "string" ||
      fieldType === "textbox" ||
      fieldType === "date"
    ) {
      if (isRequired) {
        return yup
          .string("INVALID_FORMAT")
          .nullable()
          .required("FIELD_IS_REQUIRED");
      } else {
        return yup.string("INVALID_FORMAT").nullable();
      }
    } else if (fieldType === "email") {
      if (isRequired) {
        return yup
          .string()
          .email("INVALID_FORMAT")
          .nullable()
          .required("FIELD_IS_REQUIRED");
      } else {
        return yup.string().email("INVALID_FORMAT").nullable();
      }
    } else if (fieldType === "file" || fieldType === "image") {
      if (isRequired) {
        return yup.mixed().nullable().required("FIELD_IS_REQUIRED");
      } else {
        return yup.mixed().nullable();
      }
    } else if (
      fieldType.toLowerCase() === "float" ||
      fieldType.toLowerCase() === "integer"
    ) {
      if (isRequired) {
        return yup
          .number("INVALID_FORMAT")
          .nullable()
          .required("FIELD_IS_REQUIRED");
      } else {
        return yup.number("INVALID_FORMAT").nullable();
      }
    } else {
      if (isRequired) {
        return yup.object().nullable().required("FIELD_IS_REQUIRED");
      } else {
        return yup.object().nullable();
      }
    }
  };
  const { data: titlesData } = getData.useGetTitle();
  const { data: dynamicFieldData, isLoading } = useQuery(
    ["dynamicFieldData", roleId],
    () => getData.getAllFields(roleId).then((res) => res?.data?.data)
  );
  //get all user countries
  const { data: countries, countryLoading } = useQuery({
    queryKey: ["all-countries"],
    queryFn: () => {
      return getAllCountries().then((res) => res?.data?.data);
    },
  });

  const GetCityData = async (isPaginated, filter) => {
    let new_page = cityData?.page + 1;
    let offset = isPaginated
      ? cityData?.page == 1
        ? 0
        : (cityData?.page - 1) * cityData?.per_page
      : 0;

    if (watchStep2("addresses.state")) {
      setCityLoading(true);
      getCities(
        watchStep2("addresses.country_id")?.country_code,
        watchStep2("addresses.state")?.isoCode,
        offset,
        10,
        filter
      )
        .then((res) => {
          if (isPaginated) {
            setCityData({
              ...cityData,
              page: new_page,
              data: res
                ? [...cityData?.data, ...res?.data]
                : [...cityData?.data],
              total: res
                ? Math.ceil(res?.metadata?.totalCount / 10)
                : cityData?.total / 10,
            });
          } else {
            setCityData({
              ...cityData,
              page: 1,
              data: res ? res?.data : [],
              total: res
                ? Math.ceil(res?.metadata?.totalCount / 10)
                : cityData?.total / 10,
            });
          }

          setCityLoading(false);
        })
        .catch(() => {
          setCityData({ per_page: 10, page: 1, data: [], total: 0 });
          setCityLoading(false);
        });
    } else {
      setCityData({ per_page: 10, page: 1, data: [], total: 0 });
      setCityLoading(false);
    }
  };
  const GetStateData = async (isPaginated, filter) => {
    let new_page = stateData?.page + 1;
    let offset = isPaginated
      ? stateData?.page == 1
        ? 0
        : (stateData?.page - 1) * stateData?.per_page
      : 0;

    if (watchStep2("addresses.country_id")) {
      setStateLoading(true);
      getRegions(
        watchStep2("addresses.country_id")?.country_code,
        offset,
        10,
        filter
      )
        .then((res) => {
          if (isPaginated) {
            setStateData({
              ...stateData,

              page: new_page,
              data: res
                ? [...stateData?.data, ...res?.data]
                : [...stateData?.data],
              total: res
                ? Math.ceil(res?.metadata?.totalCount / 10)
                : stateData?.total / 10,
            });
          } else {
            setStateData({
              ...stateData,
              page: 1,
              data: res ? res?.data : [],
              total: res
                ? Math.ceil(res?.metadata?.totalCount / 10)
                : stateData?.total / 10,
            });
          }
        })
        .catch(() => {
          setStateData({ per_page: 10, page: 1, data: [], total: 0 });
          setStateLoading(false);
        })
        .finally(() => {
          setStateLoading(false);
        });
    } else {
      setStateData({ per_page: 10, page: 1, data: [], total: 0 });
      setStateLoading(false);
    }
  };

  useEffect(() => {
    let all_new_fields = {};
    let new_fields_schema = {};
    dynamicFieldData?.map((item) => {
      new_fields_schema[item.slug] = switchCaseType(
        item.field_type?.name,
        item.is_required
      );

      //DON'T FORGET TO CHECK IF THE FIELD HAS OTHER WE SHOULD ADD AN EXTRA FIELD TO SCHEMA
      if (item.has_other) {
        new_fields_schema[item?.slug + "custom_"] = yup
          .string()
          .when(item.slug, {
            is: (value) => value && value?.some((el) => el === 0),
            then: yup.string().required("FIELD_IS_REQUIRED"),
            otherwise: yup.string(),
          });
      }

      all_new_fields[item.slug] =
        item.field_type.name === "Multiple Select" ? [] : null;

      //IN ALL CASES IF THE FIELD HAS_OTHER
      if (item?.has_other) {
        all_new_fields[item.slug + "custom_"] = "";
      }
    });
    !Object.values(getValues2()).length &&
      resetStep2({
        ...all_new_fields,
        is_terms: false,
        country_id: null,
        city_name: null,
        state: null,
      });

    const dynamicFieldsSchema = yup.object().shape({
      ...new_fields_schema,
      is_terms: yup.bool().oneOf([true], t("FIELD_IS_REQUIRED")),
      addresses: yup.object().shape({
        country_id: yup.object().nullable().required("FIELD_IS_REQUIRED"),
        city_name: yup.object().nullable().required("FIELD_IS_REQUIRED"),
        state: yup.object().nullable().required("FIELD_IS_REQUIRED"),
      }),
    });

    setSchemaStep2(dynamicFieldsSchema);
  }, [dynamicFieldData]);
  //FUNCTIONS
  const handleSubmitStep1Form = (payload) => {
    secureLocalStorage.setItem("submit", payload);
    setPage(1);
  };
  const addressName = t("MY_WORK_ADDRESS");

  const handleSubmitStep2Form = (formValues) => {
    setSaveLoading(true);
    let values = {}; //to fill the right vales form formValues of step2
    let formValues1 = getValues1();
    dynamicFieldData.map((el) => {
      if (el?.is_categorical === true) {
        let check_has_other =
          el?.has_other && formValues?.[el.slug]?.some((ele) => ele == 0);
        //in case the field is not required and not filled
        if (formValues[el.slug]) {
          values[el?.slug] = {
            ids: Array.isArray(formValues?.[el.slug])
              ? formValues?.[el.slug]
                  ?.filter((val) => val !== 0)
                  .map((ele) => ele)
              : [formValues?.[el.slug]?.id],
            custom:
              el?.has_other && check_has_other
                ? formValues?.[el.slug + "custom_"]
                : "",
          };
        }
      } else if (
        el?.field_type?.name?.toLowerCase() === "file" ||
        el?.field_type?.name?.toLowerCase() === "image"
      ) {
        if (formValues?.[el.slug] instanceof File) {
          values[el.slug] = formValues?.[el.slug];
        } else {
          //nothing
        }
      } else {
        if (formValues?.[el.slug]) {
          values[el.slug] = formValues?.[el.slug];
        }
      }
    });

    const formData = serialize({
      ...formValues1,
      user_title_id: formValues1?.user_title_id?.id,
      ...values,
    });
    formData.append("addresses[0][address_name]", addressName);
    formData.append(
      "addresses[0][country_id]",
      formValues?.addresses?.country_id?.id
    );
    formData.append(
      "addresses[0][country_code]",
      formValues?.addresses?.country_id?.country_code
    );
    formData.append(
      "addresses[0][state_code]",
      formValues?.addresses?.state?.isoCode
    );
    formData.append("addresses[0][state]", formValues?.addresses?.state?.name);
    formData.append(
      "addresses[0][city_name]",
      formValues?.addresses?.city_name?.name
    );
    formData.append(
      "addresses[0][address]",
      `${formValues?.addresses?.country_id?.name}, ${formValues?.addresses?.city_name?.name}`
    );

    getData.userRegistration(formData).then((res) => {
      alert?.[res?.data?.success ? "success" : "warning"](
        res?.data?.success
          ? t("SUCCESSFULLY_REGISTERED")
          : res?.data
          ? res?.data?.message
          : res?.message
      );
      res?.data?.success && navigate(`../login`);
      res?.data?.success && secureLocalStorage.clear();

      setSaveLoading(false);
    });
  };

  const BackHandler = () => {
    setPage(0);
  };

  useEffect(() => {
    upperEl?.current?.scrollIntoView({ behavior: "smooth" });
  });

  return (
    <>
      <div className="register-component-container">
        {!roleId ? (
          <Navigate to="../choose-your-profession" />
        ) : !page ? (
          <>
            <h2>{t("PERSONAL_DETAILS")}</h2>
            <form
              onSubmit={handleSubmitStep1(handleSubmitStep1Form)}
              autoComplete="new-password">
              <div className="register-form-input mb-3">
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      label="FIRST_NAME"
                      required
                      placeholder={"FIRST_NAME"}
                      name="first_name"
                      value={value}
                      onChange={onChange}
                      helperText={error?.message}
                    />
                  )}
                  name={`first_name`}
                  control={controlStep1}
                />
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      label="LAST_NAME"
                      placeholder={"LAST_NAME"}
                      name="last_name"
                      value={value}
                      onChange={onChange}
                      helperText={error?.message}
                    />
                  )}
                  name={`last_name`}
                  control={controlStep1}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormDropdownList
                      data={titlesData}
                      value={value}
                      label={"TITLE"}
                      placeholder={"SELECT_TITLE"}
                      helperText={error?.message}
                      onChange={onChange}
                    />
                  )}
                  name={`user_title_id`}
                  control={controlStep1}
                />
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormCalendarInput
                      labelIcon={CheckVisibilityIcon("friends")}
                      required
                      name="date_of_birth"
                      label="DATE_OF_BIRTH"
                      placeholder="ENTER_DATE"
                      helperText={error?.message}
                      value={value}
                      maxDate={over18}
                      onChange={onChange}
                    />
                  )}
                  name={`date_of_birth`}
                  control={controlStep1}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormInput
                      labelIcon={CheckVisibilityIcon("friends")}
                      label="EMAIL"
                      placeholder={"ENTER_EMAIL"}
                      name="email"
                      value={value}
                      onChange={onChange}
                      disabled={searchParams.get("email")}
                      helperText={error?.message}
                    />
                  )}
                  name={`email`}
                  control={controlStep1}
                />
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormPhoneInput
                      labelIcon={CheckVisibilityIcon("friends")}
                      label="PHONE_NUMBER"
                      placeholder={"ENTER_PHONE_NUMBER"}
                      name="phone_number"
                      value={value}
                      onChange={onChange}
                      helperText={error?.message}
                    />
                  )}
                  name={`phone_number`}
                  control={controlStep1}
                />

                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormPassword
                      required
                      label="PASSWORD"
                      placeholder={"ENTER_PASSWORD"}
                      name="password"
                      value={value}
                      onChange={onChange}
                      helperText={error?.message}
                    />
                  )}
                  name={`password`}
                  control={controlStep1}
                />
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormPassword
                      required
                      label="CONFIRM_PASSWORD"
                      placeholder={"ENTER_PASSWORD"}
                      name="password_confirmation"
                      value={value}
                      onChange={onChange}
                      helperText={error?.message}
                    />
                  )}
                  name={`password_confirmation`}
                  control={controlStep1}
                />
              </div>
              <Button width="100%" isSelected name="SUBMIT" type="submit" />
              <p className="already-account">
                {t("ALREADY_HAVE_AN_ACCOUNT")}?{" "}
                <Link to="../login">{t("LOG_IN")}</Link>
              </p>
            </form>
          </>
        ) : (
          <div ref={upperEl}>
            {/*we added it to scroll up the component*/}
            <h2>{t("PROFESSIONAL_DETAILS")}</h2>
            <form
              onSubmit={handleSubmitStep2(handleSubmitStep2Form)}
              autoComplete="new-password">
              <div className="register-form-input">
                {" "}
                <div className="work-address">
                  <InputLabel className="work-address-title">
                    {t("WORK_ADDRESS")}*
                  </InputLabel>
                  {/* Country Input */}
                  <div className="work-address-fields">
                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormDropdownList
                          placeholder="SELECT_COUNTRY"
                          name="addresses.country_id"
                          data={countries || []}
                          value={value}
                          onChange={(value) => {
                            onChange(value);
                            setValueStep2("state", null, {
                              shouldValidate: true,
                            });
                            setValueStep2("city_name", null, {
                              shouldValidate: true,
                            });
                            clearErrorsStep2(["city_name", "state"]);
                            GetStateData(
                              false,
                              "",
                              watchStep2("country_id")?.isoCode
                            );
                          }}
                          loading={countryLoading}
                          helperText={error?.message}
                          accessName="name"
                          accessValue="country_code"
                        />
                      )}
                      name={`addresses.country_id`}
                      control={controlStep2}
                    />
                    {/* District Input */}

                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormPaginationDropdownList
                          name="addresses.state"
                          data={stateData}
                          loading={stateLoading}
                          noOptionsText={
                            !watchStep2("country_id")
                              ? "SELECT_COUNTRY_FIRST"
                              : "NO_OPTIONS"
                          }
                          placeholder="SELECT_COUNTY"
                          value={value}
                          accessValue={"isoCode"}
                          handleResetData={GetStateData}
                          onChange={(value) => {
                            onChange(value);
                            GetCityData(false, "");
                          }}
                          helperText={error?.message}
                        />
                      )}
                      name={`addresses.state`}
                      control={controlStep2}
                    />

                    {/* City Input */}

                    <Controller
                      render={({
                        field: { onChange, value },
                        fieldState: { error },
                      }) => (
                        <FormPaginationDropdownList
                          required
                          data={cityData}
                          placeholder="SELECT_CITY"
                          name="addresses.city_name"
                          value={value}
                          onChange={(value) => {
                            onChange(value);
                            clearErrorsStep2(["city_name"]);
                          }}
                          handleResetData={GetCityData}
                          helperText={error?.message}
                          loading={cityLoading}
                          noOptionsText={
                            !watchStep2("state")
                              ? "SELECT_DISTRICT_FIRST"
                              : "NO_OPTIONS"
                          }
                        />
                      )}
                      name={`addresses.city_name`}
                      control={controlStep2}
                    />
                  </div>
                </div>
                {/* //CATEGORY FIELD DATA : DYNAMIC FIELDS */}{" "}
                {!isLoading &&
                  dynamicFieldData?.map((item, index) => (
                    <Fragment key={index}>
                      <Controller
                        render={({
                          field: { onChange, value },
                          fieldState: { error },
                        }) =>
                          item.field_type.name === "Multiple Select" ? (
                            <>
                              <FormMultiSelect
                                labelIcon={CheckVisibilityIcon(
                                  item?.visibility_status
                                )}
                                data={
                                  item?.has_other
                                    ? [
                                        ...item.field_category,
                                        {
                                          id: 0,
                                          name: t("OTHER"),
                                        },
                                      ]
                                    : item.field_category
                                }
                                name={`${item.slug}`}
                                required={item.is_required}
                                label={`${item.name}`}
                                placeholder={`${t("ENTER")} ${item.name}`}
                                value={value}
                                onChange={(value) => {
                                  onChange(value);
                                  clearErrorsStep2([
                                    `${item.slug}custom_`,
                                    `${item.slug}`,
                                  ]);
                                }}
                                helperText={error?.message}
                              />

                              {item?.has_other &&
                                getValues2(`${item.slug}`)?.some(
                                  (el) => el === 0
                                ) && (
                                  <Controller
                                    render={({
                                      field: { onChange, value },
                                      fieldState: { error },
                                    }) => (
                                      <FormInput
                                        labelIcon={CheckVisibilityIcon(
                                          item?.visibility_status
                                        )}
                                        name={`${item.slug}custom_`}
                                        required={
                                          item.is_required ? true : false
                                        }
                                        label={`${t("CUSTOM")} ${item.name}`}
                                        placeholder={`${t("ENTER")} ${
                                          item.name
                                        }`}
                                        value={value}
                                        onChange={(e) =>
                                          onChange(e.target.value)
                                        }
                                        helperText={error?.message}
                                      />
                                    )}
                                    name={`${item.slug}custom_`}
                                    control={controlStep2}
                                  />
                                )}
                            </>
                          ) : ["String", "Email"].includes(
                              item.field_type.name
                            ) ? (
                            <FormInput
                              labelIcon={CheckVisibilityIcon(
                                item?.visibility_status
                              )}
                              name={`${item.slug}`}
                              required={item.is_required}
                              label={`${item.name}`}
                              placeholder={`${t("ENTER")} ${item.name}`}
                              value={value}
                              onChange={(e) => onChange(e.target.value)}
                              helperText={error?.message}
                            />
                          ) : item?.field_type?.name === "Textbox" ? (
                            <FormTextEditor
                              labelIcon={CheckVisibilityIcon(
                                item?.visibility_status
                              )}
                              name={`${item.slug}`}
                              required={item.is_required}
                              label={`${item.name}`}
                              placeholder={`${t("ENTER")} ${item.name}`}
                              value={value}
                              onChange={(value) => onChange(value)}
                              helperText={error?.message}
                            />
                          ) : item.field_type.name === "Float" ||
                            item.field_type.name === "Integer" ? (
                            <FormNumberInput
                              labelIcon={CheckVisibilityIcon(
                                item?.visibility_status
                              )}
                              name={`${item.slug}`}
                              required={item.is_required ? true : false}
                              label={`${item.name}`}
                              placeholder={`${t("ENTER")} ${item.name}`}
                              value={value}
                              onChange={(value) =>
                                onChange(value === "" ? null : value)
                              }
                              helperText={error?.message}
                            />
                          ) : item.field_type.name === "Dropdown" ? (
                            <FormDropdownList
                              labelIcon={CheckVisibilityIcon(
                                item?.visibility_status
                              )}
                              name={`${item.slug}`}
                              required={item.is_required ? true : false}
                              data={item.field_category}
                              label={item.name}
                              placeholder={`${t("ENTER")} ${item.name}`}
                              value={value}
                              onChange={(value) => onChange(value)}
                              helperText={error?.message}
                            />
                          ) : item.field_type.name === "Date" ? (
                            <FormCalendarInput
                              labelIcon={CheckVisibilityIcon(
                                item?.visibility_status
                              )}
                              name={`${item.slug}`}
                              required={item.is_required}
                              label={item.name}
                              placeholder={`${t("ENTER")} ${item.name}`}
                              value={value}
                              onChange={(value) => onChange(value)}
                              helperText={error?.message}
                            />
                          ) : item.field_type.name === "File" ||
                            item.field_type.name === "Image" ? (
                            <FormSingleUpload
                              labelIcon={CheckVisibilityIcon(
                                item?.visibility_status
                              )}
                              accept={
                                item.field_type.name === "File"
                                  ? ["PDF"]
                                  : fileTypes?.image
                              }
                              name={`${item.slug}`}
                              required={item.is_required ? true : false}
                              label={`${item.name}`}
                              downloadTitle={"UPLOAD"}
                              type={item.field_type.name}
                              placeholder={`${t("ENTER")}${item.name}`}
                              value={value}
                              onChange={(value) => onChange(value)}
                              helperText={error?.message}
                            />
                          ) : item.field_type.name === "Radio Button" ? (
                            <FormRadioButton
                              labelIcon={CheckVisibilityIcon(
                                item?.visibility_status
                              )}
                              row
                              name={`${item.slug}`}
                              data={item.field_category}
                              required={item.is_required ? true : false}
                              label={item.name}
                              placeholder={`Enter ${item.name}`}
                              value={value}
                              onChange={(value) => onChange(value)}
                              helperText={error?.message}
                            />
                          ) : (
                            ""
                          )
                        }
                        name={`${item.slug}`}
                        control={controlStep2}
                      />
                    </Fragment>
                  ))}
                <Controller
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <FormCheckBox
                      className="checkbox"
                      name={"is_terms"}
                      onChange={(e) => {
                        onChange(e.target.checked);
                      }}
                      checked={value}
                      label={
                        <>
                          {t("READ AND AGREE")}{" "}
                          <a href={GeneralTermsOfUse} target="_blank">
                            {t("TERMS_AND_CONDITIONS")}
                          </a>
                        </>
                      }
                      helperText={error?.message}
                    />
                  )}
                  name="is_terms"
                  control={controlStep2}
                />
              </div>
              <div className="register-back">
                <Button
                  width="100%"
                  disabled={saveLoading}
                  name={t("BACK")}
                  onClick={BackHandler}
                />
                <Button
                  width="100%"
                  loading={saveLoading || !Object.keys(getValues2())?.length}
                  isSelected
                  disabled={saveLoading}
                  name="REGISTER"
                  type="submit"
                />
              </div>
              <p className="already-account">
                {t("ALREADY_HAVE_AN_ACCOUNT")}?{" "}
                <Link to="../login">{t("LOG_IN")}</Link>
              </p>
            </form>
          </div>
        )}
      </div>
    </>
  );
}

export default Register;
