import { useQuery } from "@tanstack/react-query";
import { api } from "./main";

export const getTitle = () => {
  return api
    .get( "v1/user-titles" )
    .then( ( res ) => res?.data?.data )
    .catch( ( error ) => {
      return error;
    } );
};
export const useGetTitle = () => useQuery( [ "titles" ], getTitle );

export const getAllFields = ( id ) => {
  return api
    .get( `v1/role-registration-fields/${ id }` )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};

export const userRegistration = ( data ) => {
  return api
    .post( "v1/users", data )
    .then( ( res ) => res )
    .catch( ( error ) => {
      return error;
    } );
};
export const getSpecialities = ( payload ) => {
  return api
    .get( `/v1/specialities`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
