import React, { createContext, useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import { getSelf } from "../core/services/user";
import { isLoggedIn } from "../core/services/auth";
//creating context
const UserContext = createContext();

//user context .
const UserContextProvider = ( props ) => {
  //props distructuring
  const { children } = props;

  const [ cookies, setCookies ] = useCookies( [ "user_info", "user", ] );
  const [ loading, setLoading ] = useState( false )
  //fetching user information
  const getSelfUser = () =>
    getSelf().then( ( res ) => {
      setCookies( "user_info", JSON.stringify( res?.data?.data ), { path: "/" } );
    } );


  const checkIfLoggedIn = () => {
    setLoading( true )
    isLoggedIn().then( ( res ) => {
      setCookies( "user", Boolean( res?.data?.data ), { path: "/" } );
      setLoading( false )
      if ( Boolean( res?.data?.data ) == true ) {
        getSelfUser();
      }
    } );
  }

  useEffect( () => {
    checkIfLoggedIn();
  }, [] );

  return (
    <UserContext.Provider value={ { user: cookies?.user_info, getSelfUser, userLoading: loading } }>
      { children }
    </UserContext.Provider>
  );
};

//exporting context
export { UserContextProvider, UserContext };
