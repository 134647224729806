import React from "react";
import { IconButton, Switch } from "@mui/material";
import { FormCheckBox, TimePicker } from "../form-componenrt/FormComponent";
import { useTranslation } from "react-i18next";
import { BinIcon, CreateIcon } from "../../../assets/icons/SVG";
import "./TimeInterval.scss";
import dayjs from "dayjs";
const TimeInterval = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const { handleCheckBox, checkBoxValue, onRemove } = props;
  const { time, checked, disabled, exceptionTime, handleAdd } = props;
  const { timeChange, handleDelete, day } = props;

  /*we have to types of returned data ,  either the name of the day (for the first section)
  eirther the date "1 2024-05-16" so that's why we added exact date to have the date object to 
  be able to formatted
*/
  const { name, exact_date } = day || {};


  const generateExcludedTimes = () => {
    //this function will disable all times that
    const excludedTimes = [];

    time?.forEach( ( interval ) => {
      const startTime = new Date();
      const endTime = new Date();

      const [ startHours, startMinutes ] = interval?.start_time?.split( ":" ) || [];
      startTime.setHours( parseInt( startHours, 10 ) );
      startTime.setMinutes( parseInt( startMinutes, 10 ) );

      const [ endHours, endMinutes ] = interval?.end_time?.split( ":" ) || [];
      endTime.setHours( parseInt( endHours, 10 ) );
      endTime.setMinutes( parseInt( endMinutes, 10 ) );

      const currentTime = new Date( startTime );

      while ( currentTime <= endTime ) {
        excludedTimes.push( new Date( currentTime ) );
        currentTime.setMinutes( currentTime.getMinutes() + 15 );
      }
    } );

    return excludedTimes;
  };


  return (
    <div className="TimeInterval">
      <FormCheckBox
        className="BodyTimeInterval"
        control={ <Switch defaultChecked /> }
        label={ name || dayjs( new Date( exact_date ) ).format( 'ddd, MMM DD ' ) }
        labelPlacement="start"
        checked={ checked }
        onChange={ handleCheckBox }
        value={ checkBoxValue }
      />

      <div className={ `IntervalPicker ` }>
        { !checked ? (
          <p className="unavailable">{ t( "UNAVAILABLE" ) }</p>

        ) : (
          time?.map( ( e, index ) => (
            <div className={ `interval ${ disabled ? "disabled" : "" }` } key={ index }>
              <TimePicker
                disabled={ disabled }
                onChange={ ( event ) => timeChange( index, event, `start`, e.id ) }
                value={ e.start_time }
                className={ `formInput${ checked ? "" : "disabled" }` }
                excludeTimes={ generateExcludedTimes() }
              />
              <p className="divider">-</p>
              <TimePicker
                disabled={ disabled }
                onChange={ ( event ) => timeChange( index, event, `end`, e.id ) }
                value={ e.end_time }
                className={ `formInput${ checked ? "" : "disabled" }` }
                excludeTimes={ generateExcludedTimes() }
              />
              { !disabled && ( <IconButton onClick={ () => handleDelete( name || day, index ) }>
                { !exceptionTime || time.length ? <BinIcon /> : null }
              </IconButton> ) }
            </div>
          ) )
        ) }
        {/* if the input is disabled no need to show the add , and remove with disabled format */ }
        { !disabled && checked && ( <div className="add-time" onClick={ handleAdd }>
          <CreateIcon />
          <p>
            { t(
              !time || !Object?.keys( time )?.length
                ? "ADD_TIME_INTERVAL"
                : "ADD_ANOTHER_TIME_INTERVAL"
            ) }
          </p>
        </div> ) }
      </div>

      {/* only exception time within the dialog can be removed  */ }
      { exceptionTime && (
        <div onClick={ onRemove } className="RemoveButton">
          { t( "REMOVE" ) }
        </div>
      ) }
    </div>
  );
};

export default TimeInterval;
