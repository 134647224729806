import React, { createContext, useEffect, useState } from "react";
// import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllActiveLanguages } from "../core/services/language.js";

import { useTranslation } from "react-i18next";
import { useQuery } from "@tanstack/react-query";

//creating context
const LanguageContext = createContext();

//Language context. this context will provides you with two values: All available languages and the selected one.
const LanguageContextProvider = ( props ) => {
  const { i18n } = useTranslation( "common" );

  //props distructuring
  const { children } = props;

  //hooks

  const { isLoading, data: languages } = useQuery( {
    queryKey: [ "languages" ],
    queryFn: getAllActiveLanguages,
  } );
  return (
    <LanguageContext.Provider
      value={ {
        language: i18n.language,
        setLanguage: i18n.changeLanguage,
        languages: languages ?? [], // Set empty array if languages is null or undefined
        loadingLanguages: isLoading,
      } }
    >
      { children }
    </LanguageContext.Provider>
  );
};

//exporting context
export { LanguageContextProvider, LanguageContext };
