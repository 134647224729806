import { useContext, useState } from "react";
import { InputPassword } from "../../../components/form/form-componenrt/FormComponent";
import Form from "../../../components/form/forms/Form";
import PrimaryButton from "../../../components/primary-button/PrimaryButton";
import { useNavigate, useParams } from "react-router-dom";
import * as auth from "../../../core/services/auth";
import "./ResetPassword.scss";
import { AlertContext } from "../../../context/AlertContext";

function ResetPassword() {
  const navigate = useNavigate();
  const Alert = useContext(AlertContext);
  const { token } = useParams() || {};
  const [passwordShown, setPasswordShown] = useState(false);
  const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);
  const [input, setInput] = useState({
    newPass: "",
    confirmPass: "",
    token: token,
  });
  const onChange = (e) => {
    setInput((state) => {
      return { ...state, [e.target.name]: e.target.value };
    });
    // setErrorMessage("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      password: input.newPass,
      password_confirmation: input.confirmPass,
      token: token,
    };

    auth.ResetPassword(data).then((res) => {
      Alert?.[res?.data?.success ? "success" : "warning"](
        res?.data ? res?.data?.message : res?.message
      );
      res?.data?.success &&
        setTimeout(() => {
          navigate(`../password-changed`);
        }, 2000);
    });
  };

  return (
    <div className="page-container loginProcess-container">
      <Form onSubmit={handleSubmit} title={"RESET_PASSWORD"}>
        <>
          <div style={{ position: "relative" }}>
            <div>
              <InputPassword
                label={"PASSWORD"}
                type={passwordShown ? "text" : "password"}
                placeHolder={"ENTER_PASSWORD"}
                name="newPass"
                value={input.newPass}
                onChange={onChange}
                showPassword
                onPasswordShownClick={() => setPasswordShown(!passwordShown)}
                passwordShown={passwordShown}
              />
            </div>
          </div>
          <div style={{ position: "relative" }}>
            <div>
              <InputPassword
                label={"CONFIRM_PASSWORD"}
                type={confirmPasswordShown ? "text" : "password"}
                placeHolder={"CONFIRM_PASSWORD"}
                name="confirmPass"
                value={input.confirmPass}
                onChange={onChange}
                showPassword
                onPasswordShownClick={() =>
                  setConfirmPasswordShown(!confirmPasswordShown)
                }
                passwordShown={confirmPasswordShown}
              />
            </div>
          </div>
          <PrimaryButton title={"CONTINUE"} type="submit" width="100%" />
        </>
      </Form>
    </div>
  );
}

export default ResetPassword;
