//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import EditSVG from "../../../assets/icons/EditSVG";
import "./AccountInfo.scss";
import { useCookies } from "react-cookie";
import { IconButton } from "@mui/material";
import { useTranslation } from "react-i18next";
import { AlertContext } from "../../../context/AlertContext";
import { LanguageContext } from "../../../context/LanguageContext";
import { UserContext } from "../../../context/UserContext";
import {
  dateOfBirthSchema,
  nameRequiredLimited,
  over18,
  phoneNumberSchema,
} from "../../../core/validation/form-validation";
import { useNavigate, useOutletContext } from "react-router-dom";
//COMPONENT
import * as yup from "yup";
import { useFormik } from "formik";
import Button from "../../../components/button/Button";
import {
  FormCalendarInput,
  FormDropdownList,
  FormInput,
  FormPhoneInput,
  FormSwitch,
} from "../../../components/form/form-componenrt/FormComponent";
import LoaderComponent from "../../../components/loader/LoaderComponent";
import * as userInfo from "../../../core/services/user";
import * as title from "../../../core/services/register";
import { DisplayDateTime } from "../../../components/display-component/DisplayComponent";
import DialogComponent from "../../../components/dialog/Dialog";
import { dateFormat } from "../../../core/variables/Variables";
import { CheckVisibilityIcon } from "../../../core/functions/Functions";

const schema = yup.object( {
  first_name: nameRequiredLimited( true, 30 ),
  last_name: nameRequiredLimited( true, 30 ),
  user_title_id: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
  email: yup
    .string()
    .email( "INVALID_FORMAT" )
    .nullable()
    .required( "FIELD_IS_REQUIRED" ),
  phone_number: phoneNumberSchema,
  date_of_birth: dateOfBirthSchema,
  is_notification: yup.boolean(),
} );

const PersonalInformation = () => {
  const [ t ] = useTranslation( "common" );
  let navigate = useNavigate();
  const alert = useContext( AlertContext );
  const [ _, setCookies ] = useCookies( [ "user" ] );
  const { language } = useContext( LanguageContext );
  const { user, getSelfUser } = useContext( UserContext );
  const [ isEdit, setEdit ] = useState( false );
  const [ openNotice, setOpenNotice ] = useState( false );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ headerData, setHeaderData ] = useOutletContext();
  const { data: titles, isLoading: loadingTitle } = title.useGetTitle();
  //INITIATE FORM
  const formiks = useFormik( {
    initialValues: {
      first_name: headerData?.first_name,
      last_name: headerData?.last_name,
      user_title_id: headerData?.title,
      date_of_birth: headerData?.date_of_birth,
      email: headerData?.email,
      phone_number: headerData?.phone_number,
      is_notification: headerData?.is_notification,
    },
    validationSchema: schema,
    onSubmit: ( payload ) => handleSubmit( payload ),
  } );

  const handleEdit = () => setEdit( !isEdit );

  const handleSubmit = ( payload ) => {
    setSaveLoading( true );
    userInfo
      .updateUserInfo( {
        ...payload,
        user_title_id: payload?.user_title_id?.id,
      } )
      .then( ( res ) => {
        res?.data?.success && setEdit( !isEdit );
        alert?.[ res?.data?.success ? "success" : "error" ](
          res?.data ? res?.data?.message : res?.data
        );

        if ( res?.data?.success ) {
          setHeaderData( {
            ...headerData,
            ...payload,
            user_title_id: payload?.user_title_id?.id,
          } );
        }
        setSaveLoading( false );
      } )
      .finally( () => {
        getSelfUser();
      } );
  };

  const handleResponse = () => {
    userInfo.deleteSelfUser().then( ( res ) => {
      if ( res?.data?.success ) {
        setCookies( "user", false, { path: "/" } );
        setCookies( "permissions", false, { path: "/" } );
        setCookies( "address", false, { path: "/" } );
        navigate( `/${ language }/login` );
      }
      alert?.[ res?.data?.success ? "success" : "error" ](
        res?.data ? res?.data?.message : res?.message
      );
      setOpenNotice( false );
    } );
  };



  return (
    <div className="user-account-info">
      <div className="header">
        { t( "PERSONAL_INFORMATION" ) }
        <IconButton
          aria-label="delete"
          color="secondary"
          className="svgBackground"
          onClick={ handleEdit }
          style={ isEdit ? { display: "none" } : null }
        >
          <EditSVG />
        </IconButton>
      </div>
      { !Object.values( headerData ).length || loadingTitle ? (
        <LoaderComponent />
      ) : isEdit ? (
        <form onSubmit={ formiks.handleSubmit } autoComplete="off">
          <div className={ `user-account-content` }>
            <FormInput

              required
              label="FIRST_NAME"
              placeholder={ "ENTER_NAME" }
              value={ formiks.values?.first_name }
              onChange={ ( e ) => {
                formiks.setFieldValue( "first_name", e?.target?.value );
              } }
              helperText={
                formiks.touched?.first_name && formiks.errors?.first_name
              }
            />
            <FormInput
              labelIcon={ CheckVisibilityIcon(
                'friends'
              ) }
              name="email"
              label="EMAIL"
              placeholder={ "ENTER_EMAIL" }
              value={ formiks.values?.email }
              onChange={ ( e ) => {
                formiks.setFieldValue( "email", e?.target?.value );
              } }
              helperText={ formiks.touched?.email && formiks.errors?.email }
              disabled={ true }
            />
            <FormInput
              required
              name="last_name"
              label="LAST_NAME"
              placeholder={ "ENTER_NAME" }
              value={ formiks.values?.last_name }
              onChange={ ( e ) => {
                formiks.setFieldValue( "last_name", e?.target?.value );
              } }
              helperText={
                formiks.touched?.last_name && formiks.errors?.last_name
              }
            />
            <FormCalendarInput
              required
              labelIcon={ CheckVisibilityIcon(
                'friends'
              ) }
              label="DATE_OF_BIRTH"
              name="date_of_birth"
              placeholder="ENTER_DATE"
              value={ formiks.values?.date_of_birth }
              maxDate={ over18 }
              onChange={ ( e ) => {
                formiks.setFieldValue( "date_of_birth", e );
              } }
              helperText={
                formiks.touched?.date_of_birth && formiks.errors?.date_of_birth
              }
            />

            <FormDropdownList
              required
              data={ titles }
              label="TITLE"
              value={ titles?.find(
                ( i ) => +i?.id === formiks.values?.user_title_id?.id
              ) }
              loading={ loadingTitle }
              id="bootstrap-input"
              onChange={ ( e ) => {
                formiks.setFieldValue( "user_title_id", e );
              } }
              helperText={
                formiks.touched?.user_title_id && formiks.errors?.user_title_id
              }
              accessName="name"
              accessValue="id"
            />

            <FormPhoneInput
              labelIcon={ CheckVisibilityIcon(
                'friends'
              ) }
              label="PHONE_NUMBER"
              value={ formiks.values?.phone_number }
              onChange={ ( e ) => {
                formiks.setFieldValue( "phone_number", e );
              } }
              helperText={
                formiks.touched?.phone_number && formiks.errors?.phone_number
              }
            />
          </div>
          <FormSwitch
            label="RECEIVE_NOTIFICATION"
            value={ formiks.values?.is_notification }
            onChange={ ( e ) => {
              formiks.setFieldValue( "is_notification", e );
            } }
          />
          <div className="buttonGrouping">
            <Button name="CANCEL" width="15%" onClick={ handleEdit } />
            <Button
              name="SAVE"
              width="15%"
              type="submit"
              isSelected
              loading={ saveLoading }
              disabled={ saveLoading }
            />
          </div>
        </form>
      ) : (
        <>
          <div className={ `user-account-content` }>
            <div className={ `body-item` }>
              <div className="field-label">{ t( "FIRST_NAME" ) }</div>
              <div className="field-value">{ user?.first_name }</div>
            </div>
            <div className={ `body-item` }>
              <div className="field-label">{ t( "EMAIL" ) } { CheckVisibilityIcon( 'friends' ) }</div>
              <div className="field-value">{ user?.email }</div>
            </div>
            <div className={ `body-item` }>
              <div className="field-label">{ t( "LAST_NAME" ) }</div>
              <div className="field-value">{ user?.last_name }</div>
            </div>
            <div className={ `body-item` }>
              <div className="field-label">{ t( "DATE_OF_BIRTH" ) } { CheckVisibilityIcon( 'friends' ) }</div>
              <div className="field-value" >
                <DisplayDateTime
                  data={ user?.date_of_birth }
                  format={ dateFormat }
                />
              </div>
            </div>
            <div className={ `body-item` }>
              <div className="field-label">{ t( "TITLE" ) }</div>
              <div className="field-value">{ user?.title?.name }</div>
            </div>

            <div className={ `body-item` }>
              <div className="field-label">{ t( "PHONE_NUMBER" ) } { CheckVisibilityIcon( 'friends' ) }</div>
              <div className="field-value">{ user?.phone_number }</div>
            </div>
            <div className={ `body-item` }>
              <div className="field-label">{ t( "RECEIVE_NOTIFICATION" ) }</div>
              <div className="field-value">{ user?.is_notification ? t( 'ENABLED' ) : t( 'DISABLED' ) }</div>
            </div>
          </div>
          { !user?.deleted_at && (
            <div className="deactivate-action-button">
              <Button
                isSelected
                name={ t( "DEACTIVATE" ) }
                onClick={ () => setOpenNotice( true ) }
              />
            </div>
          ) }
        </>
      ) }

      { openNotice && (
        <DialogComponent
          actions
          message="ARE_YOU_SURE_YOU_WANT_TO_DEACTIVATE_YOUR_ACCOUNT"
          onClose={ () => setOpenNotice( false ) }
          handleResponse={ handleResponse }
        />
      ) }
    </div>
  );
};

export default PersonalInformation;
