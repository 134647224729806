import { api } from "./main";

export const getAllActiveLanguages = () => {
  return api
    .get( "v1/languages" )
    .then( ( res ) => res?.data?.data )
    .catch( ( err ) => err );
};
export const switchLanguage = ( language_id ) =>
  api
    .put( `v1/switch-language/${ language_id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
