import React, { useContext, useEffect, useState } from "react";
import { PermissionContext } from "../../context/PermissionContext";
import { Link, Outlet } from "react-router-dom";
import SideComponent from "../../layout/sideBar/SideComponent";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom/dist";
import { groupParamsByKey } from "../../core/functions/Functions";
import { Tab, Tabs } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const Meetings = () => {
  const { permissions } = useContext(PermissionContext);
  const [t] = useTranslation("common");
  const [searchParams] = useSearchParams();
  const { search } = groupParamsByKey(searchParams) || {};
  const [activeTab, setActiveTab] = useState(0);
  const isSmallScreen = useMediaQuery({ query: "(max-width: 900px)" });
  const handleTabChange = (event, newValue) => {
    setActiveTab(newValue);
  };

  const links = [
    {
      to: `schedule`,
      name: "SCHEDULE",
      visible: permissions?.Schedule?.["view-specific-user-schedule"],
    },
    {
      to: `requests`,
      name: "REQUESTS",
      visible: permissions?.Meeting?.["view-specific-doctor-meetings"],
    },
    {
      to: `rejected`,
      name: "REJECTED",
      visible: permissions?.Meeting?.["view-specific-doctor-meetings"],
    },
    {
      to: `history`,
      name: "HISTORY",
      visible: permissions?.Meeting?.["view-doctor-specific-meetings-history"],
    },
  ];

  useEffect(() => {
    let active_link = links
      ?.filter((el) => el?.visible)
      .findIndex((el) => window.location.pathname.includes(el?.to));
    setActiveTab(active_link || 0);
  }, [window.location.pathname]);

  return (
    <div className="globalStructure">
      <SideComponent title="MEETING">
        <div className="navs">
          <Tabs
            onChange={handleTabChange}
            value={activeTab}
            orientation={isSmallScreen ? "horizontal" : "vertical"}
            variant={isSmallScreen && "scrollable"}
            allowScrollButtonsMobile
            indicatorColor="none"
          >
            {links
              ?.filter((el) => el?.visible)
              ?.map((e, index) => (
                <Tab
                  label={t(e.name)}
                  key={index}
                  component={Link}
                  to={e.to}
                  className="links"
                  value={index}
                  sx={{
                    "&.Mui-selected": {
                      // Styles for the active tab
                      backgroundColor: "#d6f3f4",
                      color: "#02c0cc",
                      borderBottom: "2px solid #02c0cc",
                    },
                  }}
                ></Tab>
              ))}
          </Tabs>
        </div>
      </SideComponent>
      <div className="middleNoRightComponent">
        <Outlet context={{ search }} />
      </div>
    </div>
  );
};

export default Meetings;
