import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";

export default function LinkWrapper( { link, title, onClick } ) {
  return (
    <>
      <Modal show={ true }>
        <Modal.Title>{ title }</Modal.Title>
        <Modal.Body>
          <iframe src={ link } style={ { width: "100%", height: "500px" } } />
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ onClick }>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
