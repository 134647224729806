//UTILITY
import React, { useEffect } from "react";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
//CSS
import "./LandingPage.scss";
import "swiper/scss/navigation";
//ASSETS
import {
  CommercialSVG,
  EducationSVG,
  SocialSVG,
} from "../../assets/icons/LandingPageSVG";
import Doctors from "../../assets/images/landingPageImages/Doctors.png";
import OperationRoom from "../../assets/images/landingPageImages/OperationRoom.png";
//API
import { getRoles } from "../../core/services/roles";
//COMPONENTS
import CategoriesCard from "../../components/cards/landingPageCard/CategoriesCard";
import GoalsCard from "../../components/cards/landingPageCard/GoalsCard";
import { LandingPageProfessionsSkeleton } from "../../components/skeletons/SkeletonsComponent";
import Button from "../../components/button/Button";
import { NoDataFoundlandingPage } from "../../components/Fallback/NoData";
import { useTranslation } from "react-i18next";
import secureLocalStorage from "react-secure-storage";

const LandingPage = () => {
  const [ t ] = useTranslation( "common" );
  const goals = [
    {
      id: "g1",
      image: <EducationSVG />,
      title: t( "EDUCATION" ),
      description: t( "EDUCATION_PARAGRAPH" ),
    },
    {
      id: "g2",
      image: <SocialSVG />,
      title: t( "SOCIAL" ),
      description: t( "SOCIAL_PARAGRAPH" ),
    },
    {
      id: "g3",
      image: <CommercialSVG />,
      title: t( "COMMERCIAL" ),
      description: t( "COMMERCIAL_PARAGRAPH" ),
    },
  ];

  const { data: professions, isLoading } = useQuery( [ "roles" ], getRoles );

  useEffect( () => {
    //to remove registration field if user quit signup page
    secureLocalStorage.removeItem( 'submit' );
  }, [] )

  return (
    <>
      <div className="landing-page">
        <CommunicationPlatform />
        <div className="goal-display">
          { goals.map( ( goal ) => (
            <GoalsCard
              key={ goal.id }
              image={ goal.image }
              title={ goal.title }
              description={ goal.description }
            />
          ) ) }
        </div>
        <PlatformForKnowledge />
        <h1>
          { t(
            "THE_PLATFORM_IS_DEDICATED_EXCLUSIVELY_TO_THE_FOLLOWING_CATEGORIES"
          ) }
        </h1>
        { isLoading ? (
          <Swiper
            modules={ [ Navigation ] }
            slidesPerView={ "auto" }
            grabCursor
            spaceBetween={ 30 }
            navigation
          >
            { [ ...Array( 5 ) ].map( ( _, index ) => (
              <SwiperSlide
                key={ index }
                className="LandingPageProfessionSkeleton"
              >
                <LandingPageProfessionsSkeleton num={ 1 } />
              </SwiperSlide>
            ) ) }
          </Swiper>
        ) : professions?.length ? (
          <Swiper
            modules={ [ Navigation ] }
            slidesPerView={ "auto" }
            grabCursor
            pagination={ { clickable: true } }
            spaceBetween={ 30 }
            navigation
          >
            { professions
              ?.sort( ( a, b ) =>
                a.name === "doctor" ? -1 : b.name === "doctor" ? 1 : 0
              )
              ?.map( ( category ) => (
                <SwiperSlide
                  key={ category?.id }
                  className={ `categories-card ${ category?.id != 5 && "disabled"
                    } ` }
                >
                  <CategoriesCard
                    key={ category?.id }
                    title={ category.name }
                    link={ `../register` }
                    id={ category?.id }
                  />
                </SwiperSlide>
              ) ) }
          </Swiper>
        ) : (
          <NoDataFoundlandingPage />
        ) }
      </div>
      <br />
    </>
  );
};

export default LandingPage;

const CommunicationPlatform = () => {
  const [ t ] = useTranslation( "common" );
  return (
    <div className="display-upper-info">
      <div className="blue-image" />
      <div className="description">
        <h1>
          { t( "COMMUNICATION PLATFORM DEDICATED TO HEALTHCARE PROFESSIONALS" ) }
        </h1>
        <p>{ t( "FREE_ENTRY_PARAGRAPH" ) }</p>
        <div className="button-placement">
          <Button isSelected name="REGISTER" href="../choose-your-profession" />
          <Button isSelected name="LOGIN" href="../login" />
        </div>
      </div>
      <img src={ Doctors } alt="Doctors" className="doctors-image" />
    </div>
  );
};
const PlatformForKnowledge = () => {
  const [ t ] = useTranslation( "common" );
  return (
    <div className="Knowledge-platform">
      <img
        src={ OperationRoom }
        alt="Operation Room"
        className="operating-room-picture"
      />
      <div className="blue-container">
        <h1>
          { t( "A PLATFORM FOR KNOWLEDGE BUILT UPON A COMMUNITY FOUNDATION" ) }{ " " }
        </h1>
        <p>{ t( "PLATFORM FOR KNOWLEDGE" ) }</p>
        <Button isSelected name="REGISTER" width={ "305px" } href="../register" />
      </div>
    </div>
  );
};
