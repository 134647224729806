import React, { useContext, useEffect, useState } from "react";
import { Outlet, useParams } from "react-router-dom";
import { AppContext } from "../../../AppContext";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";
//COMPONENT
import GroupInfoCard from "../../../components/cards/GroupInfoCard";
import { REACT_APP_IMAGE_URL } from "../../../core/services/main";
import { NoData } from "../../../components/Fallback/NoData";
import defaultCover from "../../../../src/assets/images/defaultCover.png";
import defaultProfile from "../../../../src/assets/images/defaultProfile.png";
import DialogComponent from "../../../components/dialog/Dialog"
import {
  GroupProfileSkeleton,
  PostSkeleton,
} from "../../../components/skeletons/SkeletonsComponent";
//API
import * as groupDetails from "../../../core/services/groups";

//CSS
import "./GroupDetails.scss";


function GroupDetails() {
  let groupId = useParams();

  const { joinedGroups, setJoinedGroups } = useContext( AppContext );
  const perPage = 2;
  const Alert = useContext( AlertContext );
  const [ groupDetail, setGroupDetail ] = useState( [] );
  const { getSelfUser } = useContext( UserContext );
  const [ isLoading, setIsloading ] = useState( true );
  const [ noDataFound, setNodataFound ] = useState( false );
  const [ onHideState, setOnHideState ] = useState( false );
  const [ page, setPage ] = useState( 1 );
  const [ loadingAction, setLoadingAction ] = useState( {
    accept: false,
    reject: false
  } )
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ loadMoreLoader, setLoadMoreLoader ] = useState( false );

  const openModal = () => setOnHideState( !onHideState );
  // handle accept or reject group invitation
  const handleInviteResponse = ( id, response ) => {
    setLoadingAction( { accept: response, reject: response === false ? true : false } )
    let data = {
      group_id: id,
      is_accepted: response,
    };
    groupDetails.AcceptOrReject( data ).then( ( res ) => {
      if ( res?.data?.success ) {
        getSelfUser();
        setJoinedGroups( response ? joinedGroups + 1 : joinedGroups - 1 );
      }

      Alert?.[ res?.data?.success ? "success" : "warning" ](
        res.data ? res.data?.message : res?.message
      );
      setLoadingAction( { accept: false, reject: false } )
    } );
  };
  const onJoin = ( id ) => {
    groupDetails.joinGroup( id ).then( ( res ) => {
      if ( res?.data?.success ) {
        setJoinedGroups( joinedGroups + 1 );
        getGroup();
        Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      }
    } );
  };

  const onHandleLeave = () => {
    groupDetails.leaveGroup( groupId.id ).then( ( res ) => {
      Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );

      if ( res?.data?.success ) {
        setJoinedGroups( joinedGroups - 1 );
        openModal();
        getGroup();
      }
    } );
  };
  const LoadMore = () => {
    setLoadMoreLoader( true );
    let oldData = [ ...groupDetail?.posts?.data ];
    let payload = {
      page: page + 1,
      per_page: perPage,
    };

    groupDetails.getgroupdetails( groupId?.id, payload ).then( ( res ) => {
      if ( res?.data?.success ) {
        let array = res.data.data.posts;
        array.data.map( ( val ) => {
          oldData.push( val );
        } );
        array[ "data" ] = oldData;

        setGroupDetail( { ...groupDetail, posts: array } );
        setTotalPage( res.data.data.posts?.last_page );
        setPage( res.data.data.posts?.current_page );
      }
      setLoadMoreLoader( false );
    } );
  };
  const getGroup = () => {
    setIsloading( true );
    groupDetails
      ?.getgroupdetails( groupId.id, { page: 1, per_page: perPage } )
      .then( ( res ) => {
        setNodataFound( !res?.data?.data );
        setTotalPage( res.data.data?.posts?.last_page );
        setPage( res.data.data?.posts?.current_page );
        setGroupDetail( res.data.data );
        setIsloading( false );
      } );
  };

  useEffect( () => {
    getGroup();
  }, [ groupId.id, joinedGroups ] );

  return (
    <div className="middleNoRightComponent group-details">
      { isLoading ? (
        <>
          <GroupProfileSkeleton count={ 1 } />
          <PostSkeleton count={ 2 } />
        </>
      ) : noDataFound ? (
        <NoData
          title={ "No Feeds Available" }
          NoData={ noDataFound }
          style={ { width: "100%" } }
        />
      ) : (
        <>
          <GroupInfoCard
            data={ groupDetail }
            image={
              groupDetail?.cover_image
                ? REACT_APP_IMAGE_URL + groupDetail?.cover_image
                : defaultCover
            }
            profile={
              groupDetail?.profile_image
                ? REACT_APP_IMAGE_URL + groupDetail?.profile_image
                : defaultProfile
            }
            groupName={ groupDetail.title }
            companyName={ groupDetail.pharmaceutical_company.name }
            information={ groupDetail.description }
            is_joined={ groupDetail?.is_joined }
            status={ groupDetail?.user_status }
            onJoin={ () => onJoin( groupDetail?.id ) }
            onLeave={ openModal }
            loadingAction={ loadingAction }
            navigateTo={ `../../companies-details/about/${ groupDetail?.pharmaceutical_company.id }` }
            onReject={ () => handleInviteResponse( groupDetail?.id, false ) }
            onAccept={ () => handleInviteResponse( groupDetail?.id, true ) }
          />
          <div className="outlet-section">
            <Outlet
              context={ {
                groupDetail,
                LoadMore,
                totalPage,
                page,
                loadMoreLoader,
              } }
            />
          </div>
          { onHideState && (
            <DialogComponent
              data={ groupDetail }
              onClose={ () => setOnHideState( false ) }
              message={ "LEAVE_GROUP_CONFIRM" }
              handleResponse={ onHandleLeave }
              actions
            />
          ) }
        </>
      ) }
    </div>
  );
}

export default GroupDetails;
