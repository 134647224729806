import ReactGA4 from "react-ga4";

const InitializeGoogleAnalytics = () => {
  ReactGA4.initialize("G-CBZ4ZD8KEB");
};

export const Event = (category, action, label) => {
  ReactGA4.event({
    category: category,
    action: action,
    label: label,
  });
};
