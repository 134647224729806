//UTILITIES
import React, { useEffect, useState } from "react";
import { AppContext } from "./AppContext";
import * as Sentry from '@sentry/react';
import { AlertContextProvider } from "./context/AlertContext";
import { NotificationContextProvider } from "./context/NotificationContext";
import { PermissionContextProvider } from "./context/PermissionContext";
import { UserContextProvider } from "./context/UserContext";
import MainRoute from "./core/routes/routes"
import { LanguageContextProvider } from "./context/LanguageContext";
import { CookiesProvider } from "react-cookie";
import { BrowserRouter } from "react-router-dom";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { createSyncStoragePersister } from "@tanstack/query-sync-storage-persister";
import { persistQueryClient } from "@tanstack/react-query-persist-client";
import ReactGA4 from "react-ga4";
//SCSS
import { ThemeProvider } from "@mui/material";
import { theme } from "./theme/Theme";
import "./App.scss";
import "./pages/rightBar/RightBar.scss";

const queryClient = new QueryClient( {
  defaultOptions: {
    queries: {
      cacheTime: 1000 * 60 * 60, // 1 hour
      staleTime: 1000 * 10,
    },
  },
} );

const localStoragePersister = createSyncStoragePersister( {
  storage: window.localStorage,
} );
// const sessionStoragePersister = createSyncStoragePersister({ storage: window.sessionStorage })

persistQueryClient( {
  queryClient,
  persister: localStoragePersister,
  fetchPolicy: 'network-only' //to bypass cache when calling refetch
} );
function App() {
  const [ path, setPath ] = useState( "" );
  const [ leftPath, setLeftPath ] = useState( "" );
  const [ joinedGroups, setJoinedGroups ] = useState( 0 );
  const [ search, setSearch ] = useState( {
    id: "",
    inputSearch: "",
    connectionSearch: "",
    discoverSearch: "",
    groupSearch: "",
    sortBy: "",
    filter: [],
    filterByCategory: "",
    filterByDate: {
      startDate: "",
      endDate: "",
    },
    sortBy: "",
  } );

  const TRACKING_ID = "G-CBZ4ZD8KEB"; // OUR_TRACKING_ID
  ReactGA4.initialize( TRACKING_ID );

  useEffect( () => {
    //NOTES :remove any accidental use of console logs
    if ( process.env.REACT_APP_ENV === "PROD" ) {
      console.log = () => { }
    }

  }, [] );



  return (
    <QueryClientProvider client={ queryClient }>
      <ThemeProvider theme={ theme }>
        <BrowserRouter>
          <CookiesProvider>
            <UserContextProvider>
              <NotificationContextProvider>
                <AppContext.Provider
                  value={ {
                    path,
                    setPath,
                    leftPath,
                    setLeftPath,
                    search,
                    setSearch,
                    joinedGroups,
                    setJoinedGroups,
                  } }
                >
                  <LanguageContextProvider>
                    <AlertContextProvider>
                      <PermissionContextProvider>
                        <MainRoute />
                      </PermissionContextProvider>
                    </AlertContextProvider>
                  </LanguageContextProvider>
                </AppContext.Provider>
              </NotificationContextProvider>
            </UserContextProvider>
          </CookiesProvider>
        </BrowserRouter>
      </ThemeProvider>
    </QueryClientProvider>
  );
}

export default App;
