import React, { useEffect, useContext, useState } from "react";
import { Avatar } from "@mui/material";
import * as MUI from "@mui/material";
import { useOutletContext, useParams, useSearchParams } from "react-router-dom";
import * as details from "../../../../core/services/groups";
import "./Members.scss";
import TableComponent from "../../../../components/table-component/TableComponent";
import RowComponent from "../../../../components/table-component/RowComponent";
import { REACT_APP_IMAGE_URL } from "../../../../core/services/main";
import { objectCleaner, groupParamsByKey } from "../../../../core/functions/Functions";
import TablePaginationSection from "../../../../components/table-pagination/TablePaginationSection";
import FilterPopUp from "../../../../components/filter-popup/FilterPopUp";
import { dataPerPage } from "../../../../core/variables/Variables";
import { StarSVG } from "../../../../assets/icons/SVG";
import { AlertContext } from "../../../../context/AlertContext";
function Members() {
  const alert = useContext( AlertContext );
  const { id } = useParams() || {};
  const { groupDetail } = useOutletContext( {} ) || {};
  const [ data, setData ] = useState( {} );
  const [ isLoading, setIsloading ] = useState( true );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const tableHeaders = [
    {
      name: "USER",
    },
    {
      name: "ROLE",
    },
    {
      name: "SPECIALTY",
    },
  ];


  const GetData = () => {
    setIsloading( true );

    details?.getGroupMembers( id, {
      is_joined: true,
      has_left: false,
      speciality_ids: searchParams.getAll( "speciality_ids" ) || [],
      per_page: +searchParams.get( "per_page" ) || dataPerPage,
      page: +searchParams.get( "page" ) || 1,
    } ).then( ( res ) => {
      if ( res?.data?.success ) {

        setData(
          res.data.data?.group_members
            ? res.data.data?.group_members
            : []
        );

      } else {
        alert?.[ "warning" ](
          res?.data ? res?.data?.message : res?.message
        );

      }
      setIsloading( false );
    } );
  };
  const handlePageChange = ( _, page ) => {
    let newFilters = {
      ...groupParamsByKey( searchParams ),
      page: page,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };

  useEffect( () => {
    GetData();
  }, [ searchParams ] );



  return <>
    <div className="MembersCard">
      <TableComponent
        tableHeaders={ tableHeaders }
        tableData={ data?.data }
        loading={ isLoading }
        actions
        onFilter={ <FilterPopUp filterByAllSpecialities /> }
      >
        { data?.data?.map( ( e ) => (
          <RowComponent row={ e } key={ e?.id } loading={ isLoading }>
            <MUI.TableCell className="picture-fullName">
              <MUI.Badge
                anchorOrigin={ {
                  vertical: 'bottom',
                  horizontal: 'right',
                } }
                invisible={ groupDetail?.admin_id !== e?.id }
                badgeContent={ <StarSVG /> }>
                <Avatar
                  variant="rounded"
                  src={ REACT_APP_IMAGE_URL + e?.profile_image }
                  alt={ e?.first_name }
                  className="imageAv"
                />
              </MUI.Badge>


              { e?.first_name + " " + e?.last_name }
            </MUI.TableCell>
            <MUI.TableCell>
              { e?.role?.name }
            </MUI.TableCell>
            <MUI.TableCell>
              { e?.specialities }
            </MUI.TableCell>
          </RowComponent>
        ) ) }
      </TableComponent>
    </div>
    <TablePaginationSection
      className="members-pagination"
      page={ +searchParams.get( 'page' ) }
      totalPage={ data.last_page }
      data={ data?.data }
      handlePageChange={ handlePageChange }
    /></>


}

export default Members;
