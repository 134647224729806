//UTILITIES
import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, CardMedia, CardContent } from "@mui/material";

//CSS
import "./DiscoverCard.scss";

//COMPONENT
import Button from "../../components/button/Button";
import Default from "../../assets/images/DEFAULT.jpg";
import { PermissionContext } from "../../context/PermissionContext";

const DiscoverCard = ( props ) => {
  const { profile, forInvitation, user } = props;
  const { image, isSelected, onClick } = props;

  const { onConnect, disabled } = props;
  const [ t ] = useTranslation( "common" );
  return (
    <div className="discover-connections" style={ props.style }>
      <div
        className="DiscoverImage"
        onClick={ onClick }
        style={ onClick && { cursor: "pointer" } }
      >
        { user ? (
          <Avatar
            src={ `${ process.env.REACT_APP_IMAGE_URL + user?.profile_image }` }
            alt={ user?.first_name }
            className="DiscoImage"
          />
        ) : image ? (
          <CardMedia
            component="img"
            height="160"
            image={ process.env.REACT_APP_IMAGE_URL + image }
            alt=""
          />
        ) : forInvitation ? (
          forInvitation
        ) : (
          <CardMedia component="img" height="160" image={ Default } alt="" />
        ) }
      </div>

      <CardContent>
        <div
          onClick={ props.onClick }
          style={ props.onClick && { cursor: "pointer" } }
        >
          { profile && (
            <div className="profile" style={ props.profileStyle }>
              <div className="name-Doctor">
                { user ? (
                  user?.first_name + " " + user?.last_name
                ) : (
                  <div className="invite-users"> { t( "INVITE_OTHER_USERS" ) }</div>
                ) }
              </div>
              { user && (
                <>
                  <div className="major-Name">
                    { `${ user?.role?.name } ${ user?.portal_id === 2
                        ? `${ t( "FROM" ) } ${ user?.pharmaceutical_company?.name }`
                        : ""
                      }` }
                  </div>
                  <div className="mutual-Connections">
                    { `${ user?.mutual_count } ${ t( "MUTUAL_CONNECTIONS" ) }` }
                  </div>
                </>
              ) }
            </div>
          ) }
        </div>
        { user ? (
          <div className="button">
            <Button
              isSelected={ isSelected }
              disabled={ disabled }
              name={
                user?.is_friend === 0
                  ? `${ t( "CONNECT" ) }`
                  : user?.is_friend === 1
                    ? `${ t( "UNFRIEND" ) }`
                    : `${ t( "PENDING" ) }...`
              }
              width="100%"
              onClick={ onConnect }
            />
          </div>
        ) : (
          <div className="button">
            <Button
              isSelected
              name={ "invite" }
              width="100%"
              onClick={ props.onInvite }
            />
          </div>
        ) }
      </CardContent>
    </div>
  );
};

export default DiscoverCard;
