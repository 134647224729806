//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { AlertContext } from "../../context/AlertContext";
import { Link } from "react-router-dom";
import { useSearchParams } from "react-router-dom/dist";
import { groupParamsByKey } from "../../core/functions/Functions";
//COMPONENT
import PinnedGroupCard from "../../components/cards/PinnedGroupCard";
import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";
import { ListComponentSkeleton } from "../../components/skeletons/SkeletonsComponent";
import { NoData } from "../../components/Fallback/NoData";
//API
import { getjoinedGroups } from "../../core/services/groups";

import { dataPerPage } from "../../core/variables/Variables";

//CSS
import "./Groups.scss";
import { PermissionContext } from "../../context/PermissionContext";

const JoinedGroups = () => {
  const Alert = useContext( AlertContext );
  const { permissions } = useContext( PermissionContext )
  const [ searchParams ] = useSearchParams();
  const { search } = groupParamsByKey( searchParams ) || {};
  //VARIABLES
  const [ data, setData ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );

  const getJGroups = ( pagination ) => {
    let handleLoad = pagination ? setIsLoadMore : setIsLoading;
    handleLoad( true );

    let new_data = [];
    let current_page =
      pagination && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;
    getjoinedGroups( {
      name: search,
      page: current_page,
      per_page: dataPerPage,
      sort: 2,
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setPage( res?.data?.data?.current_page );
        setTotalPage( res.data.data?.last_page );
        if ( pagination ) {
          new_data = [ ...data, ...res?.data?.data?.data ];
        } else {
          new_data = res.data?.data?.data || [];
        }
        setData( new_data );
      } else {
        Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      }
      handleLoad( false );
    } );
  };

  useEffect( () => {
    getJGroups();
  }, [ search ] );

  return (
    <div className="middleNoRightComponent">
      { isLoading ? (
        <ListComponentSkeleton count={ dataPerPage } />
      ) : data?.length === 0 ? (
        <NoData title={ "NO_GROUPS_FOUND" } width="100%" />
      ) : (
        <div className="joinedHeader">
          { data?.map( ( e ) => (
            <Link
              style={ { color: "black" } }
              className="link"
              to={ permissions?.Group?.[ 'view-group' ] ? `../group-details/${ e.id }/feed` : "#" }
            >
              <PinnedGroupCard
                key={ e?.id }
                pinId={ e?.id }
                img={ REACT_APP_IMAGE_URL + e.profile_image }
                name={ e?.title }
                members={ e?.users_count }
                pinned={ e?.pinned }
                style={ { padding: "10px" } }
              />
            </Link>
          ) ) }
          { page < totalPage && !isLoading && (
            <LoadMoreButton
              isLoadMore={ isLoadMore }
              title="LOAD_MORE"
              onClick={ () => getJGroups( true ) }
            />
          ) }
        </div>
      ) }
    </div>
  );
};

export default JoinedGroups;
