import { api } from "./main";

export const getRoles = () => {
  return api
    .get(`v1/portal-roles/${3}`)
    .then((res) => res?.data?.data)
    .catch((error) => error);
};

export const getRolesByMP = (payload) => {
  return api
    .get(`v1/roles-by-portals`, {
      params: {
        ...payload,
      },
    })
    .then((res) => res)
    .catch((error) => error);
};
