import React, { useState } from "react";
import { Link, Outlet, useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Tab, Tabs } from "@mui/material";
import { useMediaQuery } from "react-responsive";
//COMPONENT

import SideComponent from "../../layout/sideBar/SideComponent";

import { groupParamsByKey } from "../../core/functions/Functions";
import { useContext } from "react";
import { PermissionContext } from "../../context/PermissionContext";
import { useEffect } from "react";

function GroupsMain() {
  const [ t ] = useTranslation( "common" );
  const { permissions } = useContext( PermissionContext )
  const [ searchParams ] = useSearchParams();
  const { search } = groupParamsByKey( searchParams ) || {};
  const [ activeTab, setActiveTab ] = useState( 0 );
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );
  const handleTabChange = ( event, newValue ) => {
    setActiveTab( newValue );
  };

  //VARIABLES

  const links = [
    { to: `your-feed`, name: "YOUR_FEED", visible: permissions?.Posts?.[ "view-posts-by-group-id" ] },
    { to: `discover`, name: "DISCOVER", visible: permissions?.Group?.[ "view-suggested-groups" ] },
    { to: `invitations`, name: "INVITATIONS", visible: permissions?.Group?.[ "view-group-invitations" ] },
    { to: `joined-groups`, name: "JOINED", visible: true },
  ];


  useEffect( () => {
    let active_link = links?.filter( ( el ) => el?.visible ).findIndex( ( el ) => window.location.pathname.includes( el?.to ) )
    setActiveTab( active_link || 0 )
  }, [ window.location.pathname ] )

  return (
    <div className="globalStructure">
      <SideComponent
        title="GROUPS"
        placeholder={ `${ t( "SEARCH_BY_GROUP_NAME" ) }...` }
      >
        <div className="navs">
          <Tabs
            onChange={ handleTabChange }
            value={ activeTab }
            orientation={ isSmallScreen ? "horizontal" : "vertical" }
            variant={ isSmallScreen && "scrollable" }
            allowScrollButtonsMobile
            indicatorColor="none"
          >
            { links?.filter( ( el ) => el.visible )?.map( ( e, index ) => (
              <Tab
                label={ t( e.name ) }
                key={ index }
                component={ Link }
                to={ e.to }
                className="links"
                value={ index }
                sx={ {
                  "&.Mui-selected": {
                    // Styles for the active tab
                    backgroundColor: "#d6f3f4",
                    color: "#02c0cc",
                    borderBottom: "2px solid #02c0cc",
                  },
                } }
              >
                {/* <NavLink to={e.to} className="links" key={index}>
                  {t(e.name)}
                </NavLink> */}
              </Tab>
            ) ) }
          </Tabs>
        </div>
      </SideComponent>
      <>
        <Outlet context={ { search } } />
      </>
    </div>
  );
}

export default GroupsMain;
