import React, { useContext } from "react";
import { Avatar, Container } from "@mui/material";
import Button from "../button/Button";
import "./GroupsInvitation.scss";
import { Link } from "react-router-dom";
import { PermissionContext } from "../../context/PermissionContext";
import { useTranslation } from "react-i18next";
function GroupsInvitation(props) {
  const [t] = useTranslation("common");
  const { permissions } = useContext(PermissionContext);
  const {
    img,
    groupName,
    members,
    notification,
    loadingActions,
    onReject,
    onAccept,
  } = props;

  return (
    <div className="GroupsInvitation">
      <Link
        to={
          permissions?.Group?.["view-group"]
            ? `../../groups/group-details/${props.id}/feed`
            : "#"
        }
      >
        <Container className="invitationHeader">
          <Avatar
            style={{ width: "60px", height: "60px" }}
            alt="avatarImage"
            src={img}
          />
          <div className="groupName">
            <p className="Name"> {groupName}</p>
            <p className="members">{`${members} ${t("MEMBERS")}`}</p>
          </div>
        </Container>
      </Link>

      <Container className="InvitationBody">
        <p className="Notification">{notification}</p>
        {permissions?.Group?.["accept-or-reject-group-invitation"] && (
          <div className="buttonsGroup">
            <Button
              name="Reject"
              onClick={onReject}
              loading={loadingActions?.reject}
              disabled={loadingActions?.reject}
            />
            <Button
              isSelected
              name="CONFIRM"
              loading={loadingActions?.accept}
              disabled={loadingActions?.accept}
              onClick={onAccept}
            />
          </div>
        )}
      </Container>
    </div>
  );
}

export default GroupsInvitation;
