import React from "react";
import { Avatar } from "@mui/material";
import "./ReationProfileRow.scss";
import { REACT_APP_IMAGE_URL } from "../../../core/services/main";
const ReationProfileRow = ( { data, reaction } ) => {
  return (

    <div className="reations-profile">
      <div className="avatar-doted-image-container">
        <Avatar alt={ data?.user.first_name } src={ REACT_APP_IMAGE_URL + data?.user.profile_image } />
        <div className="avatar-doted-image" alt="">
          { reaction?.svgComponent }
        </div>
      </div>
      <div className="reations-profile-info">
        <h6>
          { data?.user.first_name } { data?.user.last_name }
        </h6>
      </div>
    </div>

  );
};

export default ReationProfileRow;
