import React, { createContext, useEffect, useState } from "react";
import { GetSelfPermissions } from "../core/services/user";
import { isUserLoggedIn } from "../core/services/auth";
import { AllPermissions } from "../core/variables/PermissionsScopes";
import { useCookies } from "react-cookie";

const PermissionContext = createContext( {
  permissions: null,
  updatePermissions: () => { },
  loadingPermissions: false,
} );

const PermissionContextProvider = ( props ) => {
  const { children } = props;
  const [ permissions, setPermissions ] = useState( null );
  const [ loadingPermissions, setLoadingPermissions ] = useState( false );
  const [ cookies, setCookies ] = useCookies( [ "permissions" ] );

  const GetUserPermissions = () => {
    setLoadingPermissions( true );
    GetSelfPermissions().then( ( response ) => {
      let permissionObj = {};
      if ( response.data?.success ) {
        Object.keys( AllPermissions )?.map( ( val ) => {
          permissionObj[ val ] = Object.keys( AllPermissions[ val ] ).reduce(
            ( a, v ) => ( {
              ...a,
              [ v ]: response?.data?.data[ val ]?.find( ( res ) => res.name === v )
                ? true
                : false,
            } ),
            {}
          );
        } );

        setCookies( "permissions", JSON.stringify( permissionObj ), {
          path: "/",
        } );

        setPermissions( permissionObj );
      }
      setLoadingPermissions( false );
    } );
  };

  const updatePermissions = () => {

    if ( isUserLoggedIn() ) {
      GetUserPermissions();
    }

  };
  useEffect( () => {
    if ( isUserLoggedIn() ) {

      GetUserPermissions();
    }
  }, [ isUserLoggedIn() ] );
  return (
    <PermissionContext.Provider
      value={ {
        permissions: cookies.permissions,
        updatePermissions,
        loadingPermissions,
      } }
    >
      { children }
    </PermissionContext.Provider>
  );
};
export { PermissionContextProvider, PermissionContext };
