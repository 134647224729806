//UTILITIES
import React, { useContext, useState } from "react";
import { AlertContext } from "../../context/AlertContext";
import { Event } from "../../core/tracking/tracking";
import { NotificationContext } from "../../context/NotificationContext";
import { PermissionContext } from "../../context/PermissionContext";
import { useTranslation } from "react-i18next";
//COMPONENT
import * as MUI from "@mui/material";
import { DisplayDateTime } from "../../components/display-component/DisplayComponent";
import { ClockIcon, ReasonSVG } from "../../assets/icons/SVG";
import CancelMeetingHandle from "./CancelMeetingHandle";
import { acceptMeeting } from "../../core/services/schedule";
import Button from "../../components/button/Button";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { getMeetingStartDateTenMinutesPrior, meetingIsHappeningNow } from "../../core/helpers/meetingHelpers";

export const MeetingElement = ( props ) => {
    const { meeting, updateData, history, type } = props;
    const { updateNotificationCount } = useContext( NotificationContext );
    const alert = useContext( AlertContext );
    const [ t ] = useTranslation( "common" );
    const { permissions } = useContext( PermissionContext )
    const [ loadingAccept, setLoadingAccept ] = useState( false )
    const [ openReject, setOpenReject ] = useState( false );

    const onConfirm = () => {
        setLoadingAccept( true )
        acceptMeeting( meeting?.id ).then( ( res ) => {
            alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
            if ( res?.data?.success ) {
                Event( "Meeting", "Confirm_Meeting", "MEETING_PAGE_REQUESTS", meeting?.id );
                updateData();
                updateNotificationCount();
            }
            setLoadingAccept( false )
        } );
    };


    const handleJoin = () => {
        if ( !meeting?.has_started ) {
            alert?.[ "error" ]( t( "PLEASE_WAIT_THE_MEETING_TO_START" ) );
        } else {
            window.open( meeting?.link )
        }
    }


    return (
        <>
            <MUI.Card className="event-request-card" key={ meeting?.id }>
                <MUI.CardHeader
                    className="event-request-header"
                    title={ meeting?.user?.first_name + " " + meeting?.user?.last_name }
                    subheader={ meeting?.user?.pharmaceutical_company?.name }
                    action={
                        meeting?.is_online ? t( "ONLINE" ) : t( "VISIT_TO_DOCTOR_OFFICE" )
                    } //now because no zoom
                    avatar={
                        <MUI.Avatar
                            src={ REACT_APP_IMAGE_URL + meeting?.user?.profile_image }
                            alt={ meeting?.user?.first_name }
                        />
                    }
                />

                <div className="event-request-body">
                    <div>{ meeting?.title }</div>
                    <div>{ meeting?.description }</div>

                    <div>
                        <ClockIcon />{ " " }
                        <DisplayDateTime data={ meeting?.start_date_time.split( " " )[ 0 ] } format="MMM Do YY" />,<DisplayDateTime data={ meeting?.start_date_time } format="LT" />
                        - <DisplayDateTime data={ meeting?.end_date_time } format="LT" />
                    </div>
                    {/* when user reject the reason is with reject reason 
            when company member reject the reason is within the reject reasons array */}
                    { type === 3 && ( meeting?.reject_reason || meeting?.reject_reasons?.[ 0 ]?.description ) && (
                        <div>
                            <ReasonSVG /> { meeting?.reject_reason || meeting?.reject_reasons?.[ 0 ]?.description }
                        </div>
                    ) }
                </div>
                {/* not history and not rejected */ }
                { !history && type !== 3 && (
                    <div className="button-container">
                        {/* type is pending */ }
                        { type === 1 && (
                            <>
                                { permissions?.Meeting?.[ 'cancel-meeting' ] && (
                                    <Button
                                        onClick={ () => {
                                            setOpenReject( true );
                                        } }
                                        name="REJECT"
                                    /> ) }
                                { permissions?.Meeting?.[ 'accept-meeting' ] && (
                                    <Button
                                        loading={ loadingAccept }
                                        disabled={ loadingAccept }
                                        onClick={ () => onConfirm() }
                                        isSelected
                                        name={ "CONFIRM" }
                                    /> ) } </>
                        ) }
                        { ( type === 2 && !meeting?.is_completed && meetingIsHappeningNow( meeting?.start_date_time ) ) && (
                            <Button
                                onClick={ () => handleJoin() }
                                isSelected
                                name={ "JOIN_MEETING" }
                            /> ) }

                    </div>

                ) }

            </MUI.Card>
            { openReject && (
                <CancelMeetingHandle
                    id={ meeting?.id }
                    onSuccess={ () => updateData() }
                    onClose={ () => setOpenReject( false ) }
                />
            ) }
        </>
    )
}
