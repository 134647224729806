import React, { useContext, useState } from "react";
import * as yup from "yup";
import { useForm, Controller } from "react-hook-form";
import { AlertContext } from "../../../context/AlertContext";
import { useTranslation } from "react-i18next";
import { yupResolver } from "@hookform/resolvers/yup";
//CSS
import "./Settings.scss";
//API
import * as userInfo from "../../../core/services/user";
//COMPONENT
import Button from "../../../components/button/Button";
import { FormPassword } from "../../../components/form/form-componenrt/FormComponent";
import { passwordSchema } from "../../../core/validation/form-validation";


const HandleSchema = yup.object( {
  current_password: yup.string().required( "FIELD_IS_REQUIRED" ),
  password: passwordSchema,
  password_confirmation: yup
    .string( "INVALID_FORMAT" )
    .oneOf( [ yup.ref( "password" ), null ], "PASSWORD_MUST_MATCH" )
    .required( "FIELD_IS_REQUIRED" ),
} );
const Settings = () => {
  const alert = useContext( AlertContext );
  const [ t ] = useTranslation( "common" );
  const [ saveLoading, setSaveLoading ] = useState( false );

  const inputs = [
    { label: "CURRENT_PASSWORD", name: "current_password" },
    { label: "NEW_PASSWORD", name: "password" },
    { label: "CONFIRM_NEW_PASSWORD", name: "password_confirmation" },
  ];

  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm( {
    defaultValues: {
      current_password: "",
      password: "",
      password_confirmation: "",
    },
    resolver: yupResolver( HandleSchema ),
  } );

  const handleSubmitForm = ( formData ) => {
    setSaveLoading( true );
    userInfo.changeUserPassword( { ...formData } ).then( ( res ) => {
      if ( res?.data?.success ) {
        reset();
      }
      alert?.[ res?.data?.success ? "success" : "error" ](
        res?.data ? res?.data?.message : res?.data
      );
      setSaveLoading( false );
    } );
  };



  return (
    <div className="settings">
      <div className="header">{ t( "CHANGE_PASSWORD" ) }</div>
      <form
        className="justify-content-center change_password"
        onSubmit={ handleSubmit( handleSubmitForm ) }
      >
        { inputs.map( ( item, index ) => (
          <Controller
            render={ ( {
              field: { onChange, value },
              fieldState: { error },
            } ) => (
              <FormPassword
                required
                label={ item.label }
                placeholder={ "ENTER_PASSWORD" }
                name={ item?.name }
                value={ value }
                onChange={ onChange }
                helperText={ error?.message }
              /> ) }
            name={ item?.name }
            control={ control }
          />

        ) ) }
        <Button
          className="submit"
          name="SUBMIT"
          type="submit"
          width="30%"
          isSelected
          disabled={ saveLoading }
          loading={ saveLoading }

        />

      </form>
    </div>
  );
};

export default Settings;
