import React from "react";
import { Avatar, Container, Typography } from "@mui/material";
import "./GazetteCard.scss";

import blogDefault from "../../assets/images/blogDefault.png";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { DisplayDateTime } from "../display-component/DisplayComponent";
import { MultipleTags } from "../../assets/icons/SVG";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useMemo } from "react";

function GazetteCard( props ) {
  const { img, tags, title, data, parag, profile, name, date } = props;

  const questionTagsSpecialties = useMemo( () => {
    if ( data ) {
      let specialties = data?.field_categories;
      let tags = data?.tags
      return [ ...specialties, ...tags ]?.map( ( el ) => { return el?.name } )
    }

  }, [ data ] )

  return (
    <div className="GazetteCard">
      <div className="GazetteImage">
        <LazyLoadImage
          alt="image"
          src={ REACT_APP_IMAGE_URL + img }
          onError={ ( { target } ) => {
            // Handle image loading error
            target.src = blogDefault;
          } }
        />
      </div>
      <Container className="GazetteBody">
        <div className="tag-container">
          { questionTagsSpecialties?.slice( 0, 2 ).map( ( tag, index ) => (
            <div key={ index } className="tag">
              { index === 1 && questionTagsSpecialties.length > 2 && (
                <MultipleTags className="plus-badge" />
              ) }
              { tag }
            </div>
          ) ) }
        </div>
        <div className="Gazette-Title">{ title }</div>

        <div
          className="Parag"
          dangerouslySetInnerHTML={ {
            __html: parag,
          } }
        ></div>
      </Container>
      <Container className="GazetteFooter">
        <div className="leftFooter">
          <Avatar className="ProfileImg" src={ REACT_APP_IMAGE_URL + profile } />
        </div>
        <div className="RightFooter">
          <Typography className="name">{ name }</Typography>
          <Typography className="date">
            { " " }
            <DisplayDateTime data={ date } format={ "L" } />
          </Typography>
        </div>
      </Container>
    </div>
  );
}

export default GazetteCard;
