//UTILITIES
import React, { useState, useRef, useEffect, useContext } from "react";
import ReactPlayer from "react-player/lazy";
import { Event } from "../../../core/tracking/tracking";
import moment from "moment";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  Radio,
  RadioGroup,
  Skeleton,
} from "@mui/material";
import { answerQuiz, getQuizByQuizId } from "../../../core/services/quiz";
import Button from "../../button/Button";
import "./react-player.scss";
import { CloseIcon, CorrectSVG, IncorrectSVG } from "../../../assets/icons/SVG";
import { UserContext } from "../../../context/UserContext";



const SecondsToTime = ( seconds ) => {
  const hours = Math.floor( seconds / 3600 );
  const minutes = Math.floor( ( seconds % 3600 ) / 60 );
  const remainingSeconds = seconds % 60;
  const timeString = moment()
    .startOf( "day" )
    .add( hours, "hours" )
    .add( minutes, "minutes" )
    .add( remainingSeconds, "seconds" )
    .format( "HH:mm:ss.S" );
  return timeString;
};

const VideoPlayer = ( props ) => {
  const { interuptArray, id, link } = props;
  const reactPlayerRef = useRef();
  const [ play, setPLay ] = useState( false );
  const [ step, setStep ] = useState();
  const [ playedtime, setplayedtime ] = useState( 0 );
  const duration = SecondsToTime( reactPlayerRef?.current?.getDuration() );
  const [ progress, setprogress ] = useState( 0 );

  const [ open, setopen ] = useState( false );
  const handlePlay = () => {
    Event( "WATCH", `PLAY_${ id }`, "VIDEO" );
    setPLay( true );
  };
  const handleStart = () => {
    !step &&
      interuptArray?.length &&
      // clickCountCampaign( interuptArray?.[ 0 ]?.pivot?.campaign_id );
      setPLay( true );
  };
  const handleOnProgress = async ( state ) => {
    let data = {
      stopped_at: SecondsToTime( state?.playedSeconds ),
      total: duration,
    };
    setplayedtime( SecondsToTime( state?.playedSeconds ) );
    setprogress( state?.playedSeconds );
    if ( interuptArray?.length > 0 ) {
      interuptArray?.map( ( interruption ) => {
        if (
          step?.video_interruption_at !==
          interruption?.pivot?.video_interruption_at &&
          interruption?.pivot?.video_interruption_at + ".0" ===
          SecondsToTime( state?.playedSeconds )
        ) {
          setStep( interruption?.pivot );
          setopen( true );
          if ( document?.fullscreenElement ) {
            document?.exitFullscreen().catch( ( err ) => console.error( err ) );
          }
          setPLay( false );

        }
      } );
    }
  };

  const handlePause = ( id ) => () => {
    let data = {
      stopped_at: playedtime,
      total: duration,
    };
    setPLay( false );
  };

  const handleEnded = ( e ) => {
    let data = {
      stopped_at: playedtime,
      total: duration,
    };
    Event( "WATCH", `Watch_All_${ data.post_id }`, "VIDEO" );

    setplayedtime( progress );
  };



  return (
    <>
      <ReactPlayer
        progressInterval={ 100 }
        ref={ reactPlayerRef }
        playing={ play }
        controls
        id={ id }
        width={ props.halfWidth ? "70%" : "100%" }
        onStart={ handleStart }
        onEnded={ handleEnded }
        onPlay={ handlePlay }
        onPause={ handlePause( id ) }
        onProgress={ handleOnProgress }
        url={ process.env.REACT_APP_IMAGE_URL + `${ link }` }
      />
      { open ? (
        <QuizDialog
          open={ open }
          setOpen={ setopen }
          step={ step }
          setStep={ setStep }
          setPLay={ setPLay }
          reactPlayerRef={ reactPlayerRef }
          video
        />
      ) : null }
    </>
  );
};
export default VideoPlayer;
export const QuizDialog = ( props ) => {
  const { getSelfUser } = useContext( UserContext );
  const [ isBreakable, setBreakable ] = useState( false );
  const [ isBreakableAction, setBreakableAction ] = useState( false );
  const [ answers, setAnswers ] = useState( [] );
  const [ questionStep, setQuestionStep ] = useState( 0 );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ loadingResult, setLoadingResult ] = useState( true );
  const [ questions, setQuestions ] = useState();
  const [ result, setResult ] = useState( "" );

  const { open, setOpen, step, setPLay, setStep, video } = props;
  const { setPageNumber, reactPlayerRef } = props;

  useEffect( () => {
    getSelfUser();
    step?.quiz_id && setIsLoading( true );
    step?.quiz_id &&
      getQuizByQuizId( step?.quiz_id )
        .then( ( res ) => {
          setQuestions( res?.data?.data?.questions );
          setAnswers( [
            {
              question_id: res?.data?.data?.questions?.[ 0 ]?.id,
              question_answer_id:
                res?.data?.data?.questions?.[ 0 ]?.answers?.[ 0 ]?.id,
            },
          ] );
          res?.data?.data?.questions?.[ 0 ]?.is_breakable &&
            setBreakable(
              !res?.data?.data?.questions?.[ 0 ]?.answers?.[ 0 ]?.is_correct
            );
        } )
        .finally( () => setIsLoading( false ) );
  }, [ step?.quiz_id ] );

  const continueHandler = () => {
    if ( isBreakableAction ) {
      handleCancellation();
    } else if ( isBreakable ) {
      setBreakableAction( true );
    } else {
      setLoadingResult( true );
      let payload = {
        ...step,
        question_answers: answers,
      };
      questions?.length === questionStep + 1 &&
        answerQuiz( payload ).then( ( res ) => {
          setResult( res?.data );
          setLoadingResult( false );
        } );
      questions?.length !== questionStep && setQuestionStep( questionStep + 1 );
      questions?.length === questionStep && setOpen( false );
      video &&
        questions?.length === questionStep &&
        result?.success &&
        setPLay( true );
      questions?.length === questionStep &&
        !result?.success &&
        handleCancellation();
    }
  };
  const handleChange = ( question, answer ) => () => {
    question?.[ questionStep ]?.is_breakable && setBreakable( !answer?.is_correct );
    setAnswers(
      answers.some( ( obj ) => obj?.question_id === question?.[ questionStep ]?.id )
        ? answers.map( ( obj ) =>
          obj?.question_id === question?.[ questionStep ]?.id
            ? {
              question_id: question?.[ questionStep ]?.id,
              question_answer_id: +answer?.id,
            }
            : obj
        )
        : [
          ...answers,
          {
            question_id: question?.[ questionStep ]?.id,
            question_answer_id: +answer?.id,
          },
        ]
    );
  };
  const handleCancellation = () => {
    video && reactPlayerRef?.current?.seekTo( 0 ); //reset video
    video && setPLay( true ); //play video after resetting it
    !video && setPageNumber( 1 ); //reset presentation
    setStep();
    setAnswers();
    setQuestionStep( 0 );
    setBreakableAction();
    setOpen( false );
  };
  return (
    <Dialog open={ open } maxWidth="md" fullWidth className="quizDialog">
      <DialogTitle>
        Quiz{ " " }
        <IconButton
          aria-label="close"
          onClick={ handleCancellation }
          sx={ {
            position: "absolute",
            right: 8,
            top: 8,
          } }
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>

      <DialogContent>
        { isLoading ? (
          <LoadingQuiz />
        ) : (
          <FormControl fullWidth>
            <FormLabel className="label">
              { questions?.length === questionStep ? (
                loadingResult ? (
                  <Skeleton width={ "40%" } />
                ) : (
                  `Total Questions ${ result?.data } / ${ questions?.length }`
                )
              ) : (
                questions?.[ questionStep ]?.title
              ) }
            </FormLabel>
            { questions?.length !== questionStep ? (
              <RadioGroup
                name="controlled-radio-buttons-group"
                value={ answers?.[ questionStep ]?.question_answer_id }
              >
                { questions?.[ questionStep ]?.answers.map( ( el ) => (
                  <FormControlLabel
                    value={ el }
                    onClick={ handleChange( questions, el ) }
                    control={ <Radio /> }
                    label={ el.title }
                    key={ el.id }
                    checked={
                      answers?.[ questionStep ]?.question_answer_id === el.id
                    }
                    disabled={ isBreakableAction }
                  />
                ) ) }
              </RadioGroup>
            ) : loadingResult ? (
              <LoadingResult />
            ) : (
              <div className="result">
                <div>
                  <CorrectSVG />
                  Correct: { result?.data }
                </div>
                <div>
                  <IncorrectSVG />
                  Incorrect: { questions?.length - result?.data }
                </div>
              </div>
            ) }
          </FormControl>
        ) }
      </DialogContent>
      <DialogActions>
        { isBreakableAction ? (
          <p className="isBreakableAction">
            Oops! Incorrect answer. Please re-watch the section and retry the
            quiz to continue.
          </p>
        ) : null }
        { !( questions?.length === questionStep ) || !questionStep ? (
          <Button
            name="Back"
            onClick={ () => setQuestionStep( questionStep - 1 ) }
            disabled={ !questionStep }
          />
        ) : null }
        <Button
          name={
            isBreakableAction
              ? "Retry"
              : questions?.length - 1 === questionStep
                ? "Next"
                : `Continue ${ questions?.length === questionStep
                  ? video
                    ? "Watching"
                    : "Reading"
                  : ""
                }`
          }
          onClick={ continueHandler }
          isSelected
          disabled={ isLoading }
        />
      </DialogActions>
    </Dialog>
  );
};
const LoadingQuiz = () => {
  return (
    <FormControl fullWidth>
      <FormLabel className="label">
        <Skeleton width={ "30%" } />
      </FormLabel>

      <RadioGroup name="controlled-radio-buttons-group">
        { [ ...Array( 2 ) ].map( ( _, index ) => (
          <FormControlLabel
            control={ <Radio /> }
            label={ <Skeleton width={ 25 } /> }
            disabled
            key={ index }
          />
        ) ) }
      </RadioGroup>
    </FormControl>
  );
};
const LoadingResult = () => (
  <div className="result">
    <Skeleton width={ "30%" } />
    <Skeleton width={ "30%" } />
  </div>
);
