//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import { UserContext } from "../../context/UserContext";
//COMPONENT
import { PermissionContext } from "../../context/PermissionContext";
import { Avatar, Tab, Tabs } from "@mui/material";
//SCSS
import "./SideBar.scss";


const RewardsDetailsSide = () => {
  const [ t ] = useTranslation( "common" );
  const location = useLocation()
  const { user, getSelfUser } = useContext( UserContext );
  const [ activeTab, setActiveTab ] = useState( 0 );
  const { permissions } = useContext( PermissionContext )
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );

  const links1 = [
    { id: 0, to: `my-points`, name: "MY_POINTS", visible: permissions?.Achievements?.[ "view-user-achievements" ] },
    { visible: permissions?.Reward?.[ 'view-all-rewards' ], id: 1, to: `e-shop`, name: "E_SHOP" },
    { visible: permissions?.Redemption?.[ 'view-user-redemptions' ], id: 2, to: `my-redemptions`, name: "MY_REDEMPTION" },
    { visible: permissions?.Achievements?.[ 'view-all-achievements' ], id: 3, to: `earn-points`, name: "EARN_POINT" },
  ];


  const handleTabChange = ( event, newValue ) => {
    setActiveTab( newValue );
  };


  useEffect( () => {
    getSelfUser();
  }, [] );


  useEffect( () => {
    let active_link = links1?.filter( ( el ) => el?.visible )?.findIndex( ( el ) => location?.pathname?.split( '/' )?.pop() === el?.to )
    setActiveTab( active_link || 0 )
  }, [ location.pathname ] )


  return (
    <div className=" sideBar sideComponent">
      <h1 className="reward-title primary-title">
        { t( "REWARDS_AND_REDEMPTION" ) }
      </h1>
      <div className="headerProfile-reward">
        <div className="rewardProfile-side">
          <Avatar
            alt={ user?.first_name }
            src={ process.env.REACT_APP_IMAGE_URL + user?.profile_image }
            sx={ { width: 40, height: 40 } }
          />
          <p>{ user?.first_name }</p>
        </div>
        <div className="rewardProfile-side rewardProfile-text ">
          <p className="available-number">{ t( "AVAILABLE_NUMBER_OF_POINTs" ) }</p>
          <p className="number-of-points">
            { user?.total_loyalty_points } Pts
          </p>
        </div>
      </div>
      <div className="navs">
        <Tabs
          onChange={ handleTabChange }
          value={ activeTab }
          orientation={ isSmallScreen ? "horizontal" : "vertical" }
          variant={ isSmallScreen && "scrollable" }
          allowScrollButtonsMobile
          indicatorColor="none"
        >
          { links1?.filter( ( el ) => el.visible )?.map( ( e, index ) => (
            <Tab
              label={ t( e.name ) }
              key={ index }
              component={ Link }
              to={ e.to }
              className="links"
              value={ index }
              sx={ {
                "&.Mui-selected": {
                  // Styles for the active tab
                  backgroundColor: "#d6f3f4",
                  color: "#02c0cc",
                  borderBottom: "2px solid #02c0cc",
                },
              } }
            ></Tab>
          ) ) }
        </Tabs>
      </div>
    </div>
  );
};
export default RewardsDetailsSide;
