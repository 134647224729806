import * as React from "react";
import { NavLink } from "react-router-dom";
import "./LinkedText.scss";
import { useTranslation } from "react-i18next";

const LinkedText = (props) => {
  const [t] = useTranslation("common");
  const { link, title, navigate, className, width } = props;
  return (
    <div
      style={{ display: "flex", width: width }}
      className={`linked-text ${className}`}
    >
      <p>{t(title)} </p>
      {link ? (
        <NavLink
          to={`${link ? link : ""}`}
          style={{
            textDecoration: "underline",
            marginLeft: "3%",
          }}
        >
          {t(navigate)}
        </NavLink>
      ) : (
        <p className="terms">{" " + t(navigate)}</p>
      )}
    </div>
  );
};
export default LinkedText;
