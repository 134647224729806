//UTILITY
import React, { useState } from "react";
import "swiper/css/pagination";
import { useLocation, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//COMPONENT
import Doctors from "../../assets/icons/doctors.svg";
import Pharma from "../../assets/icons/pharma.svg";
import Assistant from "../../assets/icons/assistant.svg";
import Student from "../../assets/icons/student.svg";
import { ChooseYourProfessionSkeleton } from "../../components/skeletons/SkeletonsComponent";
import ProfessionCard from "../../components/profession-card/ProfessionCard";
import { NoData } from "../../components/Fallback/NoData";
import Button from "../../components/button/Button";
//CSS
import "./Profession.scss";

import { getRoles } from "../../core/services/roles";
import { useQuery } from "@tanstack/react-query";
import { Swiper, SwiperSlide } from "swiper/react";

let svgs = [Doctors, Pharma, Assistant, Student];

const Profession = () => {
  const [t] = useTranslation("common");
  const { pathname, search } = useLocation();
  const [profession, setProfession] = useState("");
  sessionStorage.setItem("profession_id", profession);

  const { data: professions, isLoading } = useQuery(["roles"], getRoles);

  return (
    <div className="profession-container">
      <h1>{t("CHOOSE_YOUR_PROFESSION")}</h1>
      <h2></h2>
      {isLoading ? (
        <ChooseYourProfessionSkeleton />
      ) : !professions?.length ? (
        <NoData />
      ) : (
        <Swiper
          slidesPerView={"auto"}
          grabCursor
          touchEventsTarget="wrapper"
          pagination={{ clickable: true }}>
          {professions
            ?.sort((a, b) =>
              a.name === "doctor" ? -1 : b.name === "doctor" ? 1 : 0
            )
            ?.map((val, index) => (
              <SwiperSlide key={val.id} className="profession-slide">
                <ProfessionCard
                  className={profession === val.id && "active"}
                  onClick={() => setProfession(val.id)}
                  title={val.name}
                  svg={svgs[index]}
                  clickable={val.name === "doctor"}
                  comingSoon={val.name !== "doctor" ? "Coming Soon" : null}
                  id={val.id}
                />
              </SwiperSlide>
            ))}
        </Swiper>
      )}
      <Button
        href={`${pathname}${search}`.replace(
          "choose-your-profession",
          "register"
        )}
        isSelected
        disabled={profession !== 5}
        name="CONTINUE"
        className="half"
      />
      <p className="already-account">
        {t("ALREADY_HAVE_AN_ACCOUNT")}? <Link to="../login">{t("LOG_IN")}</Link>
      </p>
    </div>
  );
};

export default Profession;
