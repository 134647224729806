//UTILITIES
import React, { forwardRef, useState, useContext, useMemo } from "react";
import { UserContext } from "../../../context/UserContext";
import { Link, useNavigate } from "react-router-dom/dist";
//COMPONENT
import * as MUI from "@mui/material";
import { deleteBlogComment } from "../../../core/services/comments";
import CommentComponent from "../../comment-component/CommentComponent";
import PostReactions from "../../../components/post-reactions/PostReaction";
import { Event } from "../../../core/tracking/tracking";
//API
import {
  commentBlog,
  getBlogsComment,
  likeBlog,
} from "../../../core/services/blogs";
import { REACT_APP_IMAGE_URL } from "../../../core/services/main";
import "./Browse.scss";
import { DeleteSVG } from "../../../assets/icons/SVG";
import { DisplayDateTime, DisplayTags } from "../../../components/display-component/DisplayComponent";
import { PermissionContext } from "../../../context/PermissionContext";
import { PrivateFieldSVG, PublicFieldSVG } from "../../../assets/icons/VisibilityStatus";
import { CheckVisibilityIcon } from "../../../core/functions/Functions";


const Transition = forwardRef( function Transition( props, ref ) {
  return <MUI.Slide direction="up" ref={ ref } { ...props } />;
} );

export function QuestionComponent( props ) {
  //VARIABLES
  const { user } = useContext( UserContext );
  const navigate = useNavigate()
  const { permissions } = useContext( PermissionContext );
  const {
    data,
    updatePostCommentCount,
    updatePostLikeCount,
    list,
    handleDetail,
    handleDeleteQuestion, // delete the question onClick
    tags,
    showAllTags, //to show all the tags in the question detail
  } = props;
  const [ showComments, setShowComments ] = useState( false );




  //FUNCTIONS
  const handleExpandClick = () => {
    setShowComments( !showComments );
  };

  //is liked false => like
  const handleLikePost = async (
    is_liked,
    id,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {
    await likeBlog( {
      blog_id: id,
      is_gazette: false,
      is_liked: is_liked,
      like_type_id: reactionTypeId,
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        if ( !is_liked ) {
          //if like
          Event( "Like", `Like_Post_${ id }`, "HOME_PAGE_QA" );
        }
        updatePostLikeCount(
          data,
          !is_liked,
          reactionTypeId,
          reaction,
          prevReaction
        );
      }
    } );
  };

  const questionTagsSpecialties = useMemo( () => {
    if ( data ) {
      let specialties = data?.field_categories;
      let tags = data?.tags
      return [ ...specialties, ...tags ]?.map( ( el ) => { return el?.name } )
    }

  }, [ data ] )

  return (
    <>
      <MUI.Card>
        <MUI.CardContent className="question-element">
          <div className="header">

            <div className="profile cursor-pointer" onClick={ () => navigate( `../../connections/discover/user-info/${ data?.user?.id }` ) }>
              <MUI.Avatar
                src={ REACT_APP_IMAGE_URL + data?.user?.profile_image }
                style={ { width: "40px", height: "40px" } }
                alt={ data?.user?.first_name.toUpperCase() }

              />
              <div className="basic-detail">
                <div className="username">{ data?.user?.first_name + " " + data?.user?.last_name }</div>
                <div className="datetime-reference">
                  <DisplayDateTime
                    data={ data?.created_at }
                    format="DD/MM/YYYY"
                  /> { data?.model ? ` | ${ data?.model?.name }` : "" }</div>


              </div>
            </div>

            { handleDeleteQuestion && (
              <div
                className="cta cursor-pointer"
                onClick={ ( e ) => {
                  e.stopPropagation();
                  handleDeleteQuestion();
                } }
              >
                <DeleteSVG />
              </div>
            ) }

          </div>
          <div className={ `${ handleDetail ? 'cursor-pointer' : "" } description` } onClick={ handleDetail ? () => handleDetail() : null }>
            <div className="title"> { CheckVisibilityIcon( data?.visiblity_status === "public" ? "public" : "private" ) } { data?.title } </div>
            <div>{ data?.description }</div>

          </div>

          { data && (
            <DisplayTags data={ data } max={ 2 } />
          ) }

          <PostReactions
            { ...props }
            data={ { ...data, is_commentable: true } }
            blog
            handleLike={ handleLikePost }
            expandComments={ data?.comments_count === 0 && !permissions?.Comment?.[ 'create-comment' ] ? null : () => handleExpandClick() }


          />

        </MUI.CardContent>
        { showComments && (
          <MUI.Collapse in={ showComments } timeout="auto" unmountOnExit>
            <CommentComponent
              post={ false }
              user={ user }
              blog={ data }
              handleGetComment={ getBlogsComment }
              handleAddComment={
                permissions?.Comment?.[ "create-comment" ] && commentBlog
              }
              updateCommentCount={ ( type, commentChildrenCount ) => updatePostCommentCount( data, type, commentChildrenCount ) }
              handleDeleteComment={
                permissions?.Comment?.[ "delete-comment" ] && deleteBlogComment
              }
            />
          </MUI.Collapse> ) }
      </MUI.Card >

    </>


    // <>
    //   <MUI.Card className={ handleDetail ? "cursor-pointer" : "" }>
    //     <MUI.CardContent>
    //       <div className="answerquestion-container">
    //         <div onClick={ handleDetail && handleDetail }>
    //           <div className={ `profile-answer` }>
    //             <div className="image-container">
    //               <MUI.Avatar
    //                 src={ REACT_APP_IMAGE_URL + data?.user?.profile_image }
    //                 style={ { width: "40px", height: "40px" } }
    //                 alt={ data?.user?.first_name.toUpperCase() }
    //               />
    //             </div>
    //             <div style={ { display: "flex", width: "100%" } }>
    //               <div
    //                 style={ {
    //                   marginLeft: "10px", //test
    //                   width: "100%",
    //                   paddingBottom: "10px",
    //                 } }
    //               >
    //                 <MUI.Typography style={ { fontSize: "20px" } }>
    //                   { data?.user?.first_name + " " + data?.user?.last_name }
    //                 </MUI.Typography>
    //                 <MUI.Typography
    //                   style={ { fontSize: "14px", color: "#71A2A7" } }
    //                 >
    //                   { " " }
    //                   <DisplayDateTime
    //                     data={ data?.created_at }
    //                     format="DD/MM/YYYY"
    //                   />
    //                 </MUI.Typography>
    //               </div>
    //             </div>{ " " }
    //             { handleDeleteQuestion && (
    //               <div
    //                 className="cursor-pointer"
    //                 onClick={ ( e ) => {
    //                   e.stopPropagation();
    //                   handleDeleteQuestion();
    //                 } }
    //               >
    //                 <DeleteSVG />
    //               </div>
    //             ) }
    //           </div>
    //           <div className="question-description-content">
    //             <p>{ data?.title }</p>
    //             <p>{ data?.description }</p>
    //           </div>
    //         </div>

    //         <div className="tag-container">
    //           { data && (
    //             <DisplayTags data={ data } max={ 1 } />
    //           ) }

    //         </div>
    //         <PostReactions
    //           { ...props }
    //           data={ { ...data, is_commentable: true } }
    //           blog
    //           handleLike={ handleLikePost }
    //           expandComments={ data?.comments_count === 0 && !permissions?.Comment?.[ 'create-comment' ] ? null : () => handleExpandClick() }


    //         />
    //       </div>
    //     </MUI.CardContent>
    //     { showComments && (
    //       <MUI.Collapse in={ showComments } timeout="auto" unmountOnExit>
    //         <CommentComponent
    //           post={ false }
    //           user={ user }
    //           blog={ data }
    //           handleGetComment={ getBlogsComment }
    //           handleAddComment={
    //             permissions?.Comment?.[ "create-comment" ] && commentBlog
    //           }
    //           updateCommentCount={ ( type, commentChildrenCount ) => updatePostCommentCount( data, type, commentChildrenCount ) }
    //           handleDeleteComment={
    //             permissions?.Comment?.[ "delete-comment" ] && deleteBlogComment
    //           }
    //         />
    //       </MUI.Collapse> ) }

    //   </MUI.Card >

    // </>
  );
}
export default QuestionComponent;
