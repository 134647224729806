import React, { useContext, useState, useEffect } from "react";

//Styling
import "./Discover.scss";
import { InviteSVG } from "../../../assets/icons/SVG";
import { DeleteArrow } from "../../../assets/images/svg";
import LoadMoreButton from "../../../components/load-more-button/LoadMoreButton";
import { useTranslation } from "react-i18next";

//APIs
import * as discoverAPI from "../../../core/services/connections";
import { invite } from "../../../core/services/invite";
import { Grid, Dialog, Slide } from "@mui/material";
//Reusable Components
import DiscoverCard from "../../../components/cards/DiscoverCard";
import { NoData } from "../../../components/Fallback/NoData";
import { FormInput } from "../../../components/form/form-componenrt/FormComponent";
import Button from "../../../components/button/Button";
import { ProfileCardSkeleton } from "../../../components/skeletons/SkeletonsComponent";
import { Link, Outlet, useSearchParams } from "react-router-dom";

//context
import { AlertContext } from "../../../context/AlertContext";
import * as Yup from "yup";
import { useFormik } from "formik";
import { groupParamsByKey } from "../../../core/functions/Functions";
import { PermissionContext } from "../../../context/PermissionContext";

const Discover = () => {
  const [ t ] = useTranslation( "common" );
  const { permissions } = useContext( PermissionContext )
  const [ searchParams, _ ] = useSearchParams();
  const [ data, setData ] = useState( [] )
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ page, setPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false )
  const [ isLoading, setIsLoading ] = useState( false )
  const { search } = groupParamsByKey( searchParams ) || {};

  const alert = useContext( AlertContext );



  const [ openInvite, setOpenInvite ] = useState( false );


  const getData = ( new_page ) => {
    let handleLoad = new_page ? setIsLoadMore : setIsLoading;
    handleLoad( true );
    let current_page =
      new_page && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;
    discoverAPI.getDiscover( {
      name: search,
      role_ids: searchParams.getAll( "role_ids" ) || [],
      speciality_ids: searchParams.getAll( "speciality_ids" ) || [],
      page: current_page,
      per_page: 10
    } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          setPage( res?.data?.data?.current_page );
          if ( new_page ) {
            setData( [ ...data, ...res?.data?.data?.data ] );
          } else {
            setData( res.data?.data?.data || [] );
          }
          setTotalPage( res.data.data?.last_page );
        }
        handleLoad( false );
      } );
  };


  const onConnect = ( user ) => ( e ) => {
    e.stopPropagation();
    e.preventDefault();

    discoverAPI
      .createConnection( {
        requested_user_id: user?.id,
      } )
      .then( ( res ) => {
        alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
        let array = [ ...data ];
        let element = array.find( ( element ) => element.id === user?.id );
        element.is_friend = 2;
      } );
  };

  const onRemove = ( user ) => ( e ) => {
    e.stopPropagation();
    e.preventDefault();

    discoverAPI
      .AcceptReject( {
        user_id: user?.id,
        is_accepted: false,
      } )
      .then( ( res ) => {
        alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
        let array = [ ...data ];
        let element = array.find( ( element ) => element.id === user?.id );
        element.is_friend = 0;
      } );
  };

  useEffect( () => {
    getData( false )
  }, [ search, searchParams ] )


  return isLoading ? (
    <div className="discover">
      <Grid
        container
        rowSpacing={ 1 }
        columns={ { xs: 8, sm: 12, md: 16 } }
        columnSpacing={ { xs: 1, sm: 2, md: 3 } }
      >
        <ProfileCardSkeleton count={ 12 } />
      </Grid>
    </div>
  ) : !data?.length ? (
    <NoData
      title={ "No Users Available" }
      width="100%"
      NoData
      loading={ isLoading }
    />
  ) : (
    <div className="discover">
      <Grid
        container
        rowSpacing={ 3 }
        columns={ { xs: 8, sm: 12, md: 16 } }
        columnSpacing={ { xs: 1, sm: 2, md: 3 } }
      >
        { permissions?.User?.[ 'invite-to-platform' ] && ( <Grid item xs={ 4 }>
          <DiscoverCard
            profile
            onInvite={ () => setOpenInvite( true ) }
            style={ { paddingTop: "25px" } }
            forInvitation={ <InviteSVG /> }
          />
        </Grid> ) }
        { data?.map( ( user ) => (
          <Grid
            item
            xs={ 4 }
            component={ Link }
            to={ `user-info/${ user.id }` }
            state={ { mutual: user.mutual_count } }
          >
            <DiscoverCard
              profile
              user={ user }
              onConnect={ ( !user?.is_friend ? onConnect : onRemove )( user ) }
              isSelected={ !user?.is_friend }
            />
          </Grid>
        ) ) }
      </Grid>
      { page < totalPage && !isLoading && (
        <LoadMoreButton
          isLoadMore={ isLoadMore }
          title="LOAD_MORE"
          onClick={ () => getData( true ) }
        />
      ) }
      { openInvite && (
        <InviteUser openInvite={ openInvite } setOpenInvite={ setOpenInvite } />
      ) }

      <Outlet />
    </div>
  );
};
export default Discover;
const Transition = React.forwardRef( function Transition( props, ref ) {
  return <Slide direction="up" ref={ ref } { ...props } />;
} );
export const InviteUser = ( props ) => {
  const alert = useContext( AlertContext );
  const [ Loading, setLoading ] = useState( false );
  const inviteSchema = Yup.object( {
    email: Yup.string().email().required( "FIELD_IS_REQUIRED" ),
    message: Yup.string().required( "FIELD_IS_REQUIRED" ),
    user_name: Yup.string().required( "FIELD_IS_REQUIRED" ),
  } );
  const { openInvite, setOpenInvite } = props;
  const [ t ] = useTranslation( "common" );

  const formiks = useFormik( {
    initialValues: {
      email: "",
      message: t(
        "I_INVITE_YOU_TO_BECOME_A_MEMBER_OF_THIS_PROFESSIONAL_PLATFORM"
      ),
      user_name: "",
    },
    validationSchema: inviteSchema,
    onSubmit: ( payload ) => {
      setLoading( true );
      invite( payload ).then( ( res ) => {
        res?.data?.success && setOpenInvite( false );
        alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
        setLoading( false );
      } );
    },
  } );

  return (
    <Dialog
      open={ true }
      TransitionComponent={ Transition }
      keepMounted
      aria-describedby="alert-dialog-slide-description"
      maxWidth="lg"
    >
      <form className="invite-dialog-container" onSubmit={ formiks.handleSubmit }>
        <div className="invite-dialog-header">
          <h6> { t( "INVITE_USER" ) }</h6>
          <div
            style={ { float: "right", margin: "10px 10px 0px 0px" } }
            onClick={ () => setOpenInvite( false ) }
          >
            <DeleteArrow style={ { marginTop: "-5px", cursor: "pointer" } } />
          </div>
        </div>
        <div className="invite-dialog-body">
          <FormInput
            label="FULL_NAME"
            name="user_name"
            type={ "text" }
            placeholder={ "ENTER_NAME" }
            value={ formiks.values.user_name }
            onChange={ formiks.handleChange }
            error={
              formiks.touched.user_name && Boolean( formiks.errors.user_name )
            }
            helperText={ formiks.touched.user_name && formiks.errors.user_name }
          />

          <FormInput
            label="EMAIL"
            type="email"
            name="email"
            placeholder={ "ENTER_EMAIL" }
            value={ formiks.values.email }
            onChange={ formiks.handleChange }
            error={ formiks.touched.email && Boolean( formiks.errors.email ) }
            helperText={ formiks.touched.email && formiks.errors.email }
          />
          <FormInput
            label="MESSAGE"
            type="text"
            name="message"
            placeholder={ "Enter your Message" }
            value={ formiks.values.message }
            onChange={ formiks.handleChange }
            multiline
            rows={ 4 }
            error={ formiks.touched.message && Boolean( formiks.errors.message ) }
            helperText={ formiks.touched.message && formiks.errors.message }
          />
        </div>
        <div className="invite-dialog-footer">
          <Button
            name="CANCEL"
            onClick={ () => setOpenInvite( false ) }
            width="20%"
          />
          <Button
            isSelected
            name="Invite"
            type="submit"
            width="20%"
            disabled={ Loading }
            loading={ Loading }
          />
        </div>
      </form>
    </Dialog>
  );
};
