//UTILITIES
import React from "react";

//COMPONENT
import { LinearProgress, Grid } from "@mui/material";

//CSS
import "./LoaderComponent.scss";

export function LinearLoaderComponent(props) {
  return (
    <>
      <Grid spacing={1} container>
        <Grid xs item>
          <LinearProgress title="linear" />
        </Grid>
      </Grid>
    </>
  );
}

export default LinearLoaderComponent;
