import { api } from "./main";
export const getCommentByPostId = ( payload ) => {
  return api
    .get( `v1/post-comments/${ payload?.post_id }`, {
      params: payload,
    } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const createComment = ( data ) => {
  return api
    .post( "v1/comment", data )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const LikeAComment = ( payload ) => {
  return api
    .patch( `v1/like-comment/${ payload?.comment_id }`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const LikeBlogComment = ( data ) => {
  return api
    .post( "v1/like-bog", data )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const GetRepliesByCommentId = ( payload ) => {
  return api
    .get( `v1/comment-replies/${ payload?.comment_id }`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const deleteComment = ( comment_id ) => {
  return api
    .delete( `v1/comment/${ comment_id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const deleteBlogComment = ( comment_id ) => {
  return api
    .delete( `v1/blogs/comment-blog/${ comment_id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
