import React, { useContext } from "react";

import 'moment/locale/ro';
import "moment/locale/en-gb";
import moment from "moment";
import "./RootLayout.scss";
import { Outlet } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import NavigationBar from "../navigationBar/NavigationBar";
import { UserContext } from "../../context/UserContext";
import { Footer } from "../footer/Footer";
import PushNotification from "../../pages/user-notification/push-notifications/PushNotifications";
import { useAuth } from "../../hooks/useAuth";
import { LanguageContext } from "../../context/LanguageContext";
import ErrorPage from "../../pages/error/ErrorPage";

function ErrorFallback( props ) {
  return (
    <ErrorPage { ...props } />

  );
}

const RootLayout = ( props ) => {
  const { language } = useContext( LanguageContext )
  const { user: userInfo } = useContext( UserContext );
  const { user } = useAuth();
  const { footer } = props;


  //update moment localization to translate calendar element to romanian
  moment.locale( language, {
    week: {
      dow: 1,
      doy: 1,
    }
  } );
  moment.updateLocale( language );

  return (
    <>
      <NavigationBar user={ userInfo } />

      { user && <PushNotification /> }
      <main className={ `main-body ${ props.className || "" }` }>
        <ErrorBoundary
          FallbackComponent={ ErrorFallback }
          onReset={ () => {
            // reset the state of your app so the error doesn't happen again
          } }
        >

          <Outlet />
        </ErrorBoundary>
      </main>
      { footer && <Footer /> }
    </>
  );
};

export default RootLayout;
