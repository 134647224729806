//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";
//COMPONENT
import { QASkeleton } from "../../../components/skeletons/SkeletonsComponent";
import QuestionComponent from "../browse/QuestionComponent";
//API
import { deleteBlog, getBlogById } from "../../../core/services/blogs";
import { useNavigate, useParams } from "react-router-dom";
import DialogComponent from "../../../components/dialog/Dialog";

const QuestionDetail = () => {
  const navigate = useNavigate();
  const Alert = useContext( AlertContext );
  const { user } = useContext( UserContext );
  const [ question, setQuestion ] = useState( null );
  const [ loading, setLoading ] = useState( false );
  const [ currentId, setCurrentId ] = useState();
  const [ show, setShow ] = useState( false );
  const { id } = useParams() || {};

  const getData = () => {
    setLoading( true );
    getBlogById( id ).then( ( res ) => {
      if ( res?.data?.success ) {
        setQuestion( res?.data?.data );
      } else {
        Alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
      }

      setLoading( false );
    } );
  };
  const handleDeleteQuestion = ( id ) => {
    setShow( true );
    setCurrentId( id );
  };

  const handleDeleteResponse = ( questionId ) => {
    deleteBlog( questionId ).then( ( res ) => {
      if ( res?.data?.success ) {
        setShow( false );
        setCurrentId( null );
        navigate( -1 );
      }
      Alert?.[ res?.data?.success ? "success" : "error" ]( res?.data?.message );
    } );
  };

  const updatePostLikeCount = (
    postData,
    is_reacted,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {
    if ( is_reacted ) {
      setQuestion( {
        ...question,
        ...( !question?.is_reacted && {
          likes_count: ( question?.likes_count || 0 ) + 1,
        } ),
        ...( question?.is_reacted && {
          [ prevReaction.key ]: ( question[ prevReaction.key ] || 0 ) - 1,
        } ),
        is_reacted: 1,
        [ reaction ]: ( question[ reaction ] || 0 ) + 1,

        user_like: [
          {
            like_type_id: reactionTypeId,
          },
        ],
      } );
    } else {
      setQuestion( {
        ...question,
        ...( question?.is_reacted && { likes_count: question?.likes_count - 1 } ),
        is_reacted: 0,
        [ reaction ]: question[ reaction ] + 1,
        [ prevReaction.key ]: question[ prevReaction.key ] - 1,
        user_like: [
          {
            like_type_id: reactionTypeId,
          },
        ],
      } );
    }
  };
  const updatePostCommentCount = ( postData, type, commentChildrenCount ) => {
    if ( type === "add" ) {
      setQuestion( {
        ...question,
        comments_count: question?.comments_count + 1,
      } );
    } else {
      //delete comment and its descendant
      setQuestion( {
        ...question,
        comments_count: question?.comments_count - ( 1 + commentChildrenCount ),
      } );
    }
  };

  useEffect( () => {
    getData();
  }, [] );

  return (
    <>
      { !loading ? (
        <QuestionComponent
          data={ question }
          mainHeader
          updatePostLikeCount={ updatePostLikeCount }
          updatePostCommentCount={ updatePostCommentCount }
          handleDeleteQuestion={
            question?.user_id === user?.id
              ? () => handleDeleteQuestion( question?.id )
              : false
          }
          tags={ question?.tags }
          showAllTags
        />
      ) : (
        <QASkeleton count={ 1 } />
      ) }
      { show && (
        <DialogComponent
          actions
          message="ARE_YOU_SURE_YOU_WANT_TO_DELETE"
          onClose={ () => setShow( false ) }
          data={ currentId }
          handleResponse={ handleDeleteResponse }
        />
      ) }
    </>
  );
};
export default QuestionDetail;
