import React, { useState } from "react";
import { Avatar, MenuItem, Divider, Popover, Menu } from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { CloseIcon } from "../../assets/icons/SVG";
import { IconButton } from "@mui/material";
import "./ScheduleComponent.scss";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";

import { useNavigate } from "react-router-dom";
import CancelMeetingHandle from "../../pages/meetings/CancelMeetingHandle";
import { useTranslation } from "react-i18next";
import { DisplayDateTime } from "../display-component/DisplayComponent";
import Button, { LinkButton } from "../button/Button";
const CustomEvent = ( props ) => {
  const { event } = props;
  const [ t ] = useTranslation( "common" );
  const [ anchorEl, setAnchorEl ] = useState( null );
  const navigate = useNavigate();
  const [ anchorElDots, setAnchorElDots ] = useState( null );
  const [ anchorElDialog, setAnchorElDialog ] = useState( null );
  const [ messageDesc, setMessageDesc ] = useState( "" );
  const [ loading, setLoading ] = useState( false );
  const open = Boolean( anchorEl );
  const openDots = Boolean( anchorElDots );
  const openDialog = Boolean( anchorElDialog );

  const handleClick = ( event ) => {
    setAnchorEl( event.currentTarget );
  };
  const handleClose = () => {
    setAnchorEl( null );
  };
  const handleClickDots = ( event ) => {
    setAnchorElDots( event.currentTarget );
  };

  const handleCloseDots = () => {
    setAnchorElDots( null );
  };
  const handleClickDialog = ( event ) => {
    setAnchorElDialog( event.currentTarget );
  };
  const handleCloseDialog = () => {
    setAnchorElDialog( null );
  };




  return (
    <div>
      <span
        id="basic-button"
        aria-controls={ open ? "basic-menu" : undefined }
        aria-haspopup="true"
        aria-expanded={ open ? "true" : undefined }
        onClick={ handleClick }
        style={ { fontSize: "13px" } }
      >
        { props.title }
      </span>

      <Popover
        id="basic-menu"
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        MenuListProps={ {
          "aria-labelledby": "basic-button",
        } }
      >
        <div className="detail-event-container">
          <div className="detail-event-header">
            <div style={ { fontSize: "16px" } }>{ props.title }</div>
            { !event?.is_completed && (
              <IconButton onClick={ handleClickDots } icon={ <CloseIcon /> }>
                <MoreVertIcon />
              </IconButton>
            ) }
          </div>
          <div className="detail-event-body">
            <div className="date-time">
              <div >
                <DisplayDateTime data={ event.start } format={ "dddd MMMM d YYYY" } />

              </div>
              <div >
                { <DisplayDateTime data={ event?.start } format="HH:mm A" /> }
                { event.end && (
                  <span> - <DisplayDateTime data={ event.end } format="HH:mm A" /></span>
                ) }

              </div>
              { event?.has_started && !event?.is_completed &&
                (
                  <div className="event-action">
                    <LinkButton
                      onClick={ () => window.open( event?.link ) }
                      text={ "JOIN_MEETING" }

                    />
                  </div>
                ) }

            </div>

            <Divider />
            <div className="detail-event-info-user">
              <Avatar
                alt={ event.user ? event.user.first_name : "" }
                src={
                  event.user && event.user.profile_image
                    ? REACT_APP_IMAGE_URL + event.user.profile_image
                    : ""
                }
              />

              <div className="description">
                <div>
                  { event.user
                    ? event.user.first_name + " " + event.user.last_name
                    : "" }
                </div>
                <div>
                  { event.user ? event.user.pharmaceutical_company?.name : "" }
                </div>
              </div>
            </div>
          </div>
        </div>
        <Menu
          anchorEl={ anchorElDots }
          id="account-menu"
          open={ openDots }
          onClose={ handleCloseDots }
          onClick={ handleCloseDots }
          PaperProps={ {
            elevation: 0,
            sx: {
              overflow: "visible",
              filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
              mt: 1.5,
              "& .MuiAvatar-root": {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
              "&:before": {
                content: '""',
                display: "block",
                position: "absolute",
                top: 0,
                right: 14,
                width: 10,
                height: 10,
                bgcolor: "background.paper",
                transform: "translateY(-50%) rotate(45deg)",
                zIndex: 0,
              },
            },
          } }
          transformOrigin={ { horizontal: "right", vertical: "top" } }
          anchorOrigin={ { horizontal: "right", vertical: "bottom" } }
        >
          <MenuItem onClick={ handleClickDialog }>{ t( "CANCEL_MEETING" ) }</MenuItem>
        </Menu>
      </Popover>
      { openDialog && (
        <CancelMeetingHandle
          onClose={ () => setAnchorElDialog( false ) }
          onSuccess={ ( id ) => props.handleCancelEvent( id ) }
          id={ event?.id }
        />
      ) }
    </div>
  );
};

export default CustomEvent;
