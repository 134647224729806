import React, { useEffect } from "react";
import Form from "../../../components/form/forms/Form";
import EmailIcon from "../../../assets/icons/email-sent.svg";
import "./EmailSent.scss";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function EmailSent() {
  const [t] = useTranslation("common");
  let navigate = useNavigate();
  useEffect(() => {
    setTimeout(function () {
      navigate(`/login`);
    }, 2000);
  }, []);
  return (
    <div className="page-container loginProcess-container">
      <Form title="">
        <div className="email-icon-container">
          <img alt="" src={EmailIcon} />
          <h6>{t("AN_EMAIL_HAS_BEEN_SENT!")}</h6>
        </div>
      </Form>
    </div>
  );
}

export default EmailSent;
