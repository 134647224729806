//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useTranslation } from "react-i18next";
//CSS
import "./CompaniesAbout.scss";
//COMPONENT
//API
import {
  getCompanyById,
  getCompanyLocations,
} from "../../core/services/companies";
import { AskQuestionSVG } from "../../assets/icons/CtaSVG";
import LoaderComponent from "../../components/loader/LoaderComponent";
import * as MUI from "@mui/material";
import { filterDataPerPage } from "../../core/variables/Variables";
import TablePaginationSection from "../../components/table-pagination/TablePaginationSection";
import RowComponent from "../../components/table-component/RowComponent";
import TableComponent from "../../components/table-component/TableComponent";

import { LinkButton } from "../../components/button/Button";
import { Card } from "react-bootstrap";
import QuestionHandle from "../questionandanswer/browse/QuestionHandle";
import { AlertContext } from "../../context/AlertContext";

const CompaniesAbout = () => {
  const [ t ] = useTranslation( "common" );
  const alert = useContext( AlertContext );
  const { id } = useParams() || {};
  const [ openMessage, setOpenMessage ] = useState( false )
  const [ data, setData ] = useState( [] );
  const [ loadingLocations, setLoadingLocations ] = useState( false );
  const [ locations, setLocations ] = useState( [] );
  const [ isloading, setIsLoading ] = useState( true );
  const [ page, setPage ] = useState( 1 );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const tableHeaders = [
    {
      name: "LOCATIONS",
    },
    {
      name: "PHONE_NUMBERS",
    },
    {
      name: "EMAIL_ADDRESSES",
    },
  ];

  const getLocations = () => {
    setLoadingLocations( true );
    getCompanyLocations( {
      per_page: filterDataPerPage,
      page: page,
      company_id: id,
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setLocations( res?.data?.data?.data );
        setTotalPage( res?.data?.data?.last_page );
      }
      setLoadingLocations( false );
    } );
  };
  const handlePageChange = ( value, page ) => {
    setPage( page );
  };
  const getCompany = () => {
    setIsLoading( true );

    getCompanyById( id )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          setData( res?.data?.data || [] );
          setIsLoading( false );
        } else {
          alert?.[ "warning" ](
            res?.data ? res?.data?.message : res?.message
          );
        }

      } )
      .catch( ( err ) => {
        alert?.[ "warning" ](
          err
        );
      } );
  };
  useEffect( () => {
    getCompany();
    getLocations();
  }, [] );

  if ( isloading ) {
    return <LoaderComponent />;
  } else
    return (
      <Card>
        <MUI.CardContent className="company-element-info">
          <div className="header">


            <LinkButton
              text="ASK_A_QUESTION"
              endIcon={ <AskQuestionSVG /> }
              onClick={ () => setOpenMessage( true ) } />


          </div>
          <div className="company-basic-info">
            <div className="row-style" >

              <div className="title">{ t( 'DESCRIPTION' ) }</div>
              <div className="body">
                { data?.company?.description || t( "NO_DESCRIPTION" ) }
              </div>
            </div>
            <div className="row-style" >

              <div className="title">{ t( 'PHONE' ) }</div>
              <div className="body">
                { data?.company?.phone_number || t( "NOT_AVAILABLE" ) }
              </div>
            </div>
            <div className="row-style" >

              <div className="title">{ t( 'EMAIL' ) }</div>
              <div className="body">
                { data?.company?.email || t( "NOT_AVAILABLE" ) }
              </div>
            </div>
            <div className="row-style" >

              <div className="title">{ t( 'ACTIVITY_DOMAIN' ) }</div>
              <div className="body">
                { data?.company?.activity_domains?.name || data?.company?.activity_domain_description }
              </div>
            </div>
          </div>


          <TableComponent
            tableHeaders={ tableHeaders }
            tableData={ data }
            loading={ loadingLocations }
          >
            { locations?.map( ( element ) => (
              <RowComponent
                row={ element }
                key={ element?.id }
                loading={ loadingLocations }
              >
                <MUI.TableCell>
                  { element.country?.name + ", " + element.city_name }
                </MUI.TableCell>
                <MUI.TableCell>
                  <div className="ordered-list-element">
                    { element[ "pharmaceutical_company_location" ]
                      .filter( ( location ) => location.is_phone === true )
                      .map( ( location, index ) => {
                        return <li key={ index }>{ location.contacts }</li>;
                      } ) }
                  </div>
                </MUI.TableCell>
                <MUI.TableCell>
                  <div className="ordered-list-element">
                    { element[ "pharmaceutical_company_location" ]
                      .filter( ( location ) => location.is_phone === false )
                      .map( ( location, index ) => {
                        return <li key={ index }>{ location.contacts }</li>;
                      } ) }
                  </div>
                </MUI.TableCell>
              </RowComponent>
            ) ) }
          </TableComponent>

          <TablePaginationSection
            page={ page }
            totalPage={ totalPage }
            data={ data }
            handlePageChange={ handlePageChange }
          />



          { openMessage && (
            <QuestionHandle
              onClose={ () => setOpenMessage( false ) }
              type="pharmaceutical_company_id"
              topicData={ data?.company }
            />

          ) }
        </MUI.CardContent>
      </Card>
    );
};

export default CompaniesAbout;
