//UTILITIES
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
//COMPONENT
import { ListItemIcon, ListItemText } from "@mui/material";
import {
  ThreeDots,
  RejectIcon,
  AcceptIcon,
} from "../../assets/icons/SVG";
import { IconButton, Menu, Fade, MenuItem } from "@mui/material";

//CSS
import "./RowMenu.scss";

//THIS COMPONENT IS DESIGNATED FOR ALL MENU WITH 3 DOTS
export function RowMenu( props ) {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );

  const {
    item,
    handleReject,
    handleEdit,
    icon,
    handleView,
    handleDelete,
    handleAccept,
    handleDeleteUser,
    absolute,
  } = props;
  const [ anchorEl, setAnchorEl ] = React.useState( null );
  const open = Boolean( anchorEl );

  const actionsProps = useMemo( () => {
    return [
      {
        title: "VIEW",
        action: handleView || null,
      },
      {
        title: "EDIT",
        action: handleEdit || null,
      },
      {
        title: "DELETE",
        action: handleDelete || null,

      },
      {
        title: "DEACTIVATE",
        action: handleDeleteUser || null,
      },
      {
        title: "ACCEPT",
        action: handleAccept || null,
        icon: <AcceptIcon fill="black" />,
      }
      , {
        title: "REJECT",
        action: handleReject || null,
        icon: <RejectIcon fill="black" />
      },

    ]
  }, [ props ] )

  const itemArray = useMemo( () => {
    return actionsProps?.filter( propName =>
      propName?.action !== null )
  }, [ actionsProps ] );


  const handleMenuClick = ( event ) => {
    setAnchorEl( event.currentTarget );
  };

  const handleClose = () => {
    setAnchorEl( null );
  };

  return (
    <>
      <div
        style={
          absolute && {
            position: "absolute",
            top: 8,
            right: 0,
            transform: "translateX(-50%)",
          }
        }
      >
        <IconButton
          sx={ { padding: '0px' } }
          aria-controls={ open ? "fade-menu" : undefined }
          aria-haspopup="true"
          aria-expanded={ open ? "true" : undefined }
          onClick={ handleMenuClick }
          component="span"
        >
          { icon }
        </IconButton>
      </div>

      <Menu
        id="fade-menu"
        MenuListProps={ {
          "aria-labelledby": "fade-button",
        } }
        anchorEl={ anchorEl }
        open={ open }
        onClose={ handleClose }
        TransitionComponent={ Fade }
      >
        { itemArray?.map( ( actionElement, index ) => (
          <MenuItem
            key={ index }
            divider={ index !== itemArray?.length - 1 }
            onClick={ ( e ) => {
              e.preventDefault();
              e.stopPropagation();
              actionElement?.action( item );
              setAnchorEl( null );
            } }
          >
            { actionElement?.icon && (
              <ListItemIcon>
                { actionElement?.icon }
              </ListItemIcon> ) }
            <ListItemText
              primary={ <div className="row-menu-primary">{ t( actionElement?.title ) }</div> }
            />
          </MenuItem>
        ) ) }
      </Menu>
    </>
  );
}

RowMenu.defaultProps = {
  icon: <ThreeDots fill="black" rotate="90" />,
};

RowMenu.propTypes = {
  icon: PropTypes.any,
};

