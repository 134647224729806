import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useCookies } from "react-cookie";
import { AlertContext } from "../context/AlertContext";
import { api } from "../core/services/main";
import { useNavigate } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";
import { UserContext } from "../context/UserContext";
import { PermissionContext } from "../context/PermissionContext";
import secureLocalStorage from "react-secure-storage";
import { NotificationContext } from "../context/NotificationContext";
// import { Backdrop, CircularProgress, Skeleton } from "@mui/material";

const AuthContext = createContext();

export const AuthProvider = ( { children } ) => {
  const { setAlert } = useContext( AlertContext );
  const { language } = useContext( LanguageContext );
  const { getSelfUser } = useContext( UserContext );
  const { updateNotificationCount } = useContext( NotificationContext );
  const { updatePermissions } = useContext( PermissionContext );
  const [ cookieValidated, setCookieValidated ] = useState( true )
  const [ cookies, setCookies, removeCookie ] = useCookies( [ "user" ] );

  let navigate = useNavigate();

  // call this function when you want to authenticate the user
  const login = async ( data ) => {
    return await api.post( "v1/login", data ).then( ( res ) => {
      !Boolean( res?.data?.data ) &&
        setAlert( {
          visible: true,
          type: "error",
          text: res?.data ? res?.data?.message : res?.message,
        } );

      setCookies( "user", Boolean( res?.data?.data ), { path: "/" } );
      if ( res?.data?.success ) {

        getSelfUser();
        updatePermissions();
        secureLocalStorage.clear();
        updateNotificationCount();
        navigate( `/${ language }/home` );

      }

      return res;
    } );
  };
  const logout = async () => {
    return await api.get( "v1/logout" ).then( ( res ) => {
      removeCookie( 'user', { path: '/' } );
      setCookies( "permissions", false, { path: "/" } );
      setCookies( "address", false, { path: "/" } );
      res?.data?.success && navigate( `${ language }/login` );

      return res;
    } );
  };

  // Simulate cookie validation
  setTimeout( () => {
    // Assume this is where you validate the cookie
    setCookieValidated( false );
  }, 2500 ); // Simulated 2.5 seconds delay

  const value = useMemo(
    () => ( {
      user: cookies?.user === "true",
      login,
      logout,

    } ),
    [ cookies?.user ]
  );
  return <AuthContext.Provider value={ value }>{ children }</AuthContext.Provider>;
};

export const useAuth = () => {
  return useContext( AuthContext );
};
