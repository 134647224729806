//UTILITIES
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import * as MUI from "@mui/material";
//CSS
import "./AboutModal.scss";
//COMPONENTS
import { AboutSVG } from "../../assets/icons/AboutSVG";
import { DisplayDateTime } from "../display-component/DisplayComponent";
import { useQuery } from "@tanstack/react-query";
import { getPlatformRelease } from "../../core/services/PlatformRelease";

const Transition = forwardRef(function Transition(props, ref) {
  return <MUI.Slide direction="up" ref={ref} {...props} />;
});

const AboutModal = (props) => {
  //TRANSLATION
  const [t] = useTranslation("common");
  //PROPS
  const { onClose } = props;

  const { data: latestRelease, isLoading } = useQuery({
    queryKey: ["platform release"],
    queryFn: () => {
      return getPlatformRelease().then((res) => res?.data?.data);
    },
  });

  return (
    <MUI.Dialog
      open={true}
      TransitionComponent={Transition}
      className="dialog-container"
    >
      <div className="top-content">
        <div>
          <div className="about">{t("ABOUT")}</div>
          <div className="version">
            {t("VERSION")}{" "}
            {isLoading ? (
              <MUI.Skeleton height={"10px"} width={"50px"} />
            ) : (
              latestRelease?.semantic_version
            )}
          </div>
          <div className="release-date">
            {t("RELEASE_DATE")}:{" "}
            {isLoading ? (
              <MUI.Skeleton height={"10px"} width={"50px"} />
            ) : (
              <DisplayDateTime
                data={latestRelease?.released_at}
                format={"MMMM D, YYYY"}
              />
            )}
          </div>
        </div>
        <div className="title-right-section">
          <AboutSVG />
        </div>
      </div>
      <div className="middle-content">
        {isLoading ? (
          <>
            <MUI.Skeleton height={"10px"} width={"50px"} />
            <MUI.Skeleton height={"10px"} width={"50px"} />
          </>
        ) : (
          Object.entries(latestRelease?.stack_versions || {}).length > 0 && (
            <div>
              {Object.entries(latestRelease?.stack_versions).map(
                ([key, value]) => (
                  <div key={key}>
                    {key}: {value}
                  </div>
                )
              )}
            </div>
          )
        )}
        {isLoading ? (
          <MUI.Skeleton height={"30px"} width={"200px"} />
        ) : (
          <div
            dangerouslySetInnerHTML={{ __html: latestRelease?.release_notes }}
            className="description"
          />
        )}

        <MUI.Button
          fullWidth
          variant="contained"
          type={"button"}
          onClick={() => {
            onClose();
          }}
          color="primary"
          className="notice-confirmationn-buttons"
        >
          {t("OK")}
        </MUI.Button>
      </div>
      <MUI.Divider className="divider" />
      <div className="bottom-content">
        <div>
          {t("INQUIRIES")}
          <a href="mailto:support@meetamed.com">
            <span className="support">support@meetamed.com</span>
          </a>
        </div>
        <div>© Meet@Med s.a.l</div>
      </div>
    </MUI.Dialog>
  );
};

export default AboutModal;

AboutModal.defaultProps = {
  message: "ARE_YOU_SURE_YOU_WANT_TO_DELETE",
};

AboutModal.propTypes = {
  message: PropTypes.string,
};
