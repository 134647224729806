//UTILITIES
import { useTranslation } from "react-i18next";
import React from "react";
//COMPONENT
import { Close } from "@mui/icons-material";
import * as MUI from "@mui/material";
//CSS
import "./Dialog.scss";

export function DialogCustomTitle(props) {
  //TRANSLATION
  const [t] = useTranslation("common");

  const { title, classNames, onClose } = props;

  return (
    <>
      <MUI.DialogTitle className={classNames}>
        <div className="dialog-title-section">
          <div>{t(title)}</div>
          {/* {typeof onClose === "function" && ( */}
          <div>
            <MUI.IconButton
              onTouchStart={onClose}
              aria-label="close"
              onMouseDown={(e) => e.preventDefault()}
              onClick={() => onClose()}
            >
              <Close />
            </MUI.IconButton>
          </div>
          {/* )} */}
        </div>
      </MUI.DialogTitle>
    </>
  );
}
export default DialogCustomTitle;
