import React from "react";
import * as MUI from "@mui/material";
import { Link } from "react-router-dom";

import { Button as MUIButton, CircularProgress } from "@mui/material";
import "./Button.scss";
import palette from "../../theme/color.scss";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const Button = ( props ) => {
  const [ t ] = useTranslation( "common" );

  const {
    isSelected,
    href,
    style,
    name,
    startIcon,
    onClick,
    id,
    className,
    type,
    ...others
  } = props;

  return (
    <MUIButton
      type={ type }
      name={ id }
      component={ href ? Link : "button" }
      to={ href }
      sx={ {
        backgroundColor: `${ isSelected ? "#02c0cc" : "#71a2a7" }`,
        textTransform: "none",
        borderRadius: "5px",
        color: "white",
        "&:hover": { color: "white" },
        ...style,
      } }
      startIcon={ startIcon }
      disabled={ props.disabled }
      color={ isSelected ? "primary" : "secondary" }
      onClick={ onClick }
      className={ `buttonComponent ${ className }` }
      variant="contained"
      { ...others }
    >
      { props.loading ? `${ t( "LOADING" ) }...` : t( name ) }
    </MUIButton>
  );
};
Button.defaultProps = {
  isSelected: false,
  className: "",
};
Button.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
};
export default Button;

export const LinkButton = ( props ) => {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  const {
    className,
    startIcon,
    onClick,
    text,
    loading,
    color,
    deleteButton,
    endIcon,
  } = props;
  return (
    <MUIButton
      startIcon={ startIcon || null }
      onClick={ onClick }
      endIcon={ endIcon || null }
      className={ className }
    >
      { loading ? (
        <CircularProgress
          size={ "1rem" }
          style={ { color: palette.primaryColor } }
        />
      ) : (
        <>{ t( text ) }</>
      ) }
    </MUIButton>
  );
};

LinkButton.defaultProps = {
  className: "",
};
LinkButton.propTypes = {
  className: PropTypes.string,
};
export const IconsButton = ( props ) => {
  const { className, onClick, icon } = props;
  return (
    <MUI.IconButton
      color="primary"
      className={
        className ? `my-icon-button-style ${ className }` : "my-icon-button-style"
      }
      component="span"
      onClick={ () => onClick && onClick() }
    >
      { icon }
    </MUI.IconButton>
  );
};
