//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
import * as yup from "yup";
import { useTranslation } from "react-i18next";
import { objToFormData } from "../../core/functions/Functions";
import { useFormik } from "formik";
//COMPONENT
import * as MUI from "@mui/material";
import {
  FormDropdownList,
  FormInput,
  FormMultiSelect,
  FormSingleDashedUpload,
  FormTagInput,
  FormTextEditor,
} from "../../components/form/form-componenrt/FormComponent";

import { Skeleton } from "@mui/material";
import BackRep from "../../components/back-repository/BackRep";
import Button from "../../components/button/Button";
//API
import {
  addBlogs,
  getBlogById,
  getTags,
  updateBlogs,
} from "../../core/services/blogs";
import { getSpecialities } from "../../core/services/register";
import { fileTypes } from "../../core/variables/Variables";

const validationSchema = yup.object().shape( {
  title: yup.string( "INVALID_FORMAT" ).nullable().required( "FIELD_IS_REQUIRED" ),
  tags_id: yup
    .array()
    .nullable()
    .required( "FIELD_IS_REQUIRED" )
    .min( 1, "FIELD_IS_REQUIRED" ),
  description: yup.string().nullable().required( "FIELD_IS_REQUIRED" ),
  status_id: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
  tags: yup
    .array()
    .nullable()
    .when( "tags_id", {
      is: ( value ) => value && value?.includes( 0 ),
      then: yup
        .array()
        .nullable()
        .required( "FIELD_IS_REQUIRED" )
        .min( 1, "FIELD_IS_REQUIRED" ),
      otherwise: yup.array().nullable(),
    } ),
  speciality_ids: yup.array().nullable(),
  featured_image: yup.mixed(),
  is_gazette: yup.bool(),
  is_active: yup.bool(),
} );

//THIS COMPONENT IS FOR ADD AND EDIT OF GAZETTE
function GazetteHandle( props ) {
  //TRANSLATION :
  const [ t ] = useTranslation( "common" );
  //CONTEXT
  const alert = useContext( AlertContext );
  //VARIABLES
  const { create } = props;
  const [ specialtiesArray, setSpecialtiesArray ] = useState( [] );
  const { id } = useParams();
  let navigate = useNavigate();
  const status = [
    { id: 1, name: t( "PUBLISH" ) },
    { id: 2, name: t( "ARCHIVE" ) },
  ];
  const [ loading, setLoading ] = useState( false );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ tags, setTags ] = useState( [] );

  //INITIATE FORM:
  const formiks = useFormik( {
    initialValues: {
      title: "",
      description: "",
      is_gazette: true,
      is_active: true,
      tags: [],
      tags_id: [],
      speciality_ids: [],
      featured_image: null,
      status_id: { id: 1, name: t( "PUBLISH" ) },
    },
    validationSchema: validationSchema,
    onSubmit: ( payload ) => handleSubmitForm( payload ),
  } );

  //FUNCTIONS
  const handleSubmitForm = ( formValues ) => {
    let values = {};
    setSaveLoading( true );

    Object.keys( formValues ).map( ( item ) => {
      values[ item ] = formValues?.[ item ];
    } );
    values[ "status_id" ] = formValues?.status_id?.id;
    values[ "tags_id" ] = formValues?.tags_id?.filter( ( el ) => el !== 0 );

    if ( formValues?.featured_image instanceof File === false ) {
      delete values.featured_image;
    }

    if ( create ) {
      addBlogs( objToFormData( values ) ).then( ( res ) => {
        alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
        res?.data?.success && navigate( -1 );
        setSaveLoading( false );
      } );
    } else {
      updateBlogs( id, objToFormData( values ) ).then( ( res ) => {
        alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
        res?.data?.success && navigate( -1 );
        setSaveLoading( false );
      } );
    }
  };

  const getGazetteData = () => {
    setLoading( true );
    getBlogById( id ).then( ( res ) => {
      if ( res?.data?.success ) {
        formiks.setValues( {
          is_gazette: true,
          is_active: true,
          title: res?.data?.data?.title,
          description: res?.data?.data?.description,
          tags: [],
          tags_id: res?.data?.data?.tags?.map( ( item ) => item.id ),
          featured_image: res?.data?.data?.featured_image,
          status_id: res?.data?.data?.status,
          speciality_ids: res?.data?.data?.field_categories?.map(
            ( item ) => item.id
          ),
        } );
      }
      setLoading( false );
    } );
  };
  //GETTING TAGS DATA FOR DROPDOWN
  const getAllTags = () => {
    getTags().then( ( res ) => {
      if ( res?.data?.success ) {
        setTags( [
          ...res?.data?.data,
          {
            id: 0,
            name: t( "OTHER" ),
          },
        ] );
      }
    } );
  };

  //GETTING SPECIALTIES DATA FOR DROPDOWN
  const getAllSpecialties = () => {
    getSpecialities().then( ( res ) => {
      if ( res?.data?.success ) {
        setSpecialtiesArray( res?.data?.data );
      }
    } );
  };

  useEffect( () => {
    if ( !create ) {
      getGazetteData();
    }
    getAllTags();
    getAllSpecialties();
  }, [] );

  return (
    <div
      style={ {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        padding: "0% 15% 0% 15%",
      } }
      className="globalStructure addGazette"
    >
      <div style={ { width: "100%" } } className="middleNoLeftRightComponent">
        <div style={ { display: "flex", paddingBottom: "5px" } }>
          <BackRep to={ -1 } title={ t( "BLOG" ) } />
        </div>
        <MUI.Card>
          <MUI.CardContent>
            { !loading ? (
              <form onSubmit={ formiks.handleSubmit }>
                <FormInput
                  required
                  label="TITLE"
                  placeholder="ENTER_TITLE"
                  name="title"
                  value={ formiks.values?.title }
                  onChange={ ( event ) =>
                    formiks.setFieldValue( "title", event.target.value )
                  }
                  helperText={ formiks.touched?.title && formiks.errors?.title }
                />

                <FormDropdownList
                  data={ status }
                  value={ status?.find(
                    ( el ) => el?.id === formiks.values?.status_id?.id
                  ) }
                  label={ "STATUS" }
                  placeholder="SELECT_STATUS"
                  helperText={
                    formiks.touched?.status_id && formiks.errors?.status_id
                  }
                  onChange={ ( val ) => formiks.setFieldValue( "status_id", val ) }
                />
                <FormMultiSelect
                  data={ specialtiesArray }
                  label={ "SPECIALITIES" }
                  placeholder="SELECT_SPECIALITY"
                  value={ formiks.values?.speciality_ids }
                  onChange={ ( val ) => {
                    formiks.setFieldValue( "speciality_ids", val );
                    formiks.setFieldError( "speciality_ids", "" );
                  } }
                  helperText={
                    formiks.touched?.speciality_ids &&
                    formiks.errors?.speciality_ids
                  }
                />
                <FormMultiSelect
                  required
                  data={ tags }
                  label={ "TAGS" }
                  placeholder="SELECT_TAGS"
                  value={ formiks.values?.tags_id }
                  onChange={ ( val ) => {
                    if ( !val?.includes( 0 ) ) {
                      formiks.setFieldValue( "tags", [] );
                      formiks.setFieldError( "tags", "" );
                    }
                    formiks.setFieldValue( "tags_id", val );
                    formiks.setFieldError( "tags_id", "" );
                  } }
                  helperText={
                    formiks.touched?.tags_id && formiks.errors?.tags_id
                  }
                />

                { formiks?.values?.tags_id?.includes( 0 ) && (
                  <FormTagInput
                    required
                    label={ "CUSTOM_TAGS" }
                    placeholder={ "ADD_TAGS" }
                    name="tags"
                    value={ formiks.values?.tags }
                    onChange={ ( val ) => {
                      formiks.setFieldValue( "tags", val );
                      formiks.setFieldError( "tags", "" );
                    } }
                    helperText={ formiks.touched?.tags && formiks.errors?.tags }
                  />
                ) }

                <FormSingleDashedUpload
                  name={ "featured_image" }
                  label={ "FEATURED_IMAGE" }
                  downloadTitle={ "UPLOAD" }
                  accept={ fileTypes?.image }
                  value={ formiks.values?.featured_image }
                  onChange={ ( val ) =>
                    formiks.setFieldValue( "featured_image", val )
                  }
                  handleDeleteImage={ ( value ) =>
                    formiks.setFieldValue( "featured_image", null )
                  }
                  error={
                    formiks.touched?.featured_image &&
                    formiks.errors?.featured_image
                  }
                />

                <FormTextEditor
                  required
                  label="DESCRIPTION"
                  name={ `description` }
                  placeholder="ENTER_DESCRIPTION"
                  value={ formiks.values?.description }
                  onChange={ ( val ) => formiks.setFieldValue( "description", val ) }
                  helperText={
                    formiks.touched?.description && formiks.errors?.description
                  }
                />

                <div className="Draft">
                  <div className="groupButton">
                    <Button name="CANCEL" width="20%" href="../blog/my-blogs" />
                    <Button
                      disabled={ saveLoading }
                      loading={ saveLoading }
                      type="submit"
                      isSelected
                      name="PUBLISH"
                      width="20%"
                    />
                  </div>
                </div>
              </form>
            ) : (
              <Skeleton />
            ) }
          </MUI.CardContent>
        </MUI.Card>
      </div>
    </div>
  );
}

export default GazetteHandle;
