import axios from "axios";
import { Cookies } from "react-cookie";
export const X_RAPIDAPI_KEY =
  "c68692d028msh6a48be848b729f9p1d3045jsnd4d1a728c9a1";

export const REACT_APP_IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
export const REACT_APP_FIREBASE_KEY = process.env.REACT_APP_FIREBASE_VAPID_KEY
const cookie = new Cookies();
export let api = axios.create( {
  withCredentials: true,
  baseURL: process.env.REACT_APP_API,
  headers: {
    "X-Timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "X-Authorization":
      "RaTzInd7NPCfXm8ZJobwn0IvIyA0z9Ius0OroqW8x0ho4rdysDxJIa1OYQJCXKhc",
    "Accept-Language": `en-US,${ cookie?.get( "language" ) || "en" };q=0.7`,
  },
} );

// Set the AUTH token for any request
api.interceptors.response.use(
  ( response ) => {
    return response;
  },
  async ( error ) => {

    if ( error.response.status === 401 ) {
      await axios
        .get( "v1/refresh-token", {
          withCredentials: true,
          baseURL: process.env.REACT_APP_API,
          headers: {
            "X-Authorization":
              "RaTzInd7NPCfXm8ZJobwn0IvIyA0z9Ius0OroqW8x0ho4rdysDxJIa1OYQJCXKhc",
            "Accept-Language": `en-US,${ cookie?.get( "language" ) || "en" };q=0.7`,
          },
        } )
        .catch( () => {
          const cookies = new Cookies();
          cookies.remove( "user" );
          window.location.replace( `/${ cookies.get( "language" ) }/login` );
        } );
      return axios( error.config );
    } else if ( error.response.status === 503 ) {

      // window.location.replace("/login");
    } else {
      return Promise.reject( error );
    }
  }
);
