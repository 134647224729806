//UTILITIES
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  objectCleaner,
  groupParamsByKey,
} from "../../core/functions/Functions";
import { getNewestPosts, getPostsType } from "../../core/services/posts";
import * as MUI from "@mui/material";

import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";
import { NoData } from "../../components/Fallback/NoData";
import Post from "../../components/post/Post";
import SideComponent from "../../layout/sideBar/SideComponent";
import { PostSkeleton } from "../../components/skeletons/SkeletonsComponent";
import { useTranslation } from "react-i18next";
import { Event } from "../../core/tracking/tracking";
import { useMediaQuery } from "react-responsive";

const Watch = () => {
  const [ searchParams, setSearchParams ] = useSearchParams();
  const { search, sort, type_ids } = groupParamsByKey( searchParams ) || {};

  const [ data, setData ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ categoriesData, setCategoriesData ] = useState( [] );
  const [ page, setPage ] = useState( 1 );
  const [ perPage, setPerPage ] = useState( 10 );
  const [ loadMoreLoader, setLoadMoreLoader ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 0 );
  const [ t ] = useTranslation( "common" );

  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );

  const getPosts = () => {
    setIsLoading( true );
    setPage( 1 );
    let data = {
      per_page: perPage,
      type_ids: searchParams.get( "type_ids" )
        ? [ searchParams.get( "type_ids" ) ]
        : [],
      name: search,
      sort: sort,
    };

    getNewestPosts( data ).then( ( res ) => {
      if ( res?.data?.success ) {
        setIsLoading( false );
        setTotalPage( res?.data?.data?.last_page );
        setPage( res?.data?.data?.current_page );
        setData( res?.data?.data?.data || [] );
      }
    } );
  };
  const LoadMore = () => {
    setLoadMoreLoader( true );
    let oldData = [ ...data ];
    let payload = {
      page: page + 1,
      per_page: perPage,
      type_ids: searchParams.get( "type_ids" )
        ? [ searchParams.get( "type_ids" ) ]
        : [],
      name: search,
      sort: sort,
    };

    getNewestPosts( payload ).then( ( res ) => {
      if ( res?.data?.success ) {
        if ( res?.data?.data === null ) {
          setData( [] );
          setLoadMoreLoader( false );
        } else {
          setLoadMoreLoader( false );
          let array = res?.data?.data?.data;
          array.map( ( val ) => {
            val.newComment = false;
            val.liked = false;
            val.comments = [];
            val.commentsLoader = true;
          } );
          array.map( ( val ) => {
            oldData.push( val );
          } );
          setData( oldData );
          setTotalPage( res?.data?.data?.last_page );
          setPage( res?.data?.data?.current_page );
        }
      }
    } );
  };

  const getAllCategories = () => {
    let array = [];
    getPostsType().then( ( res ) => {
      if ( res?.data?.success ) {
        res?.data?.data.map( ( val ) => {
          array.push( val );

        } );
        setCategoriesData( array );
      }
    } );
  };
  useEffect( () => {
    getPosts();
  }, [ search, sort, type_ids ] );

  const handleCategoryFilter = ( category ) => {
    let url = {
      ...groupParamsByKey( searchParams ),
      type_ids: category,
      page: 1,
    };

    setSearchParams( objectCleaner( url ) );
  };

  useEffect( () => {
    getAllCategories();
    Event( "Post_Views", `Post_ID_${ data?.id }`, "DETAILS_PAGE" );
  }, [] );

  const updatePostCommentCount = ( postData, type, commentChildrenCount ) => {
    let array = [ ...data ];
    let index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( type === "add" ) {
      array[ index ][ "comments_count" ] += 1;
    } else {
      //delete comment and its descendant
      array[ index ][ "comments_count" ] -= ( 1 + commentChildrenCount );
    }
    setData( array );
  };

  const updatePostLikeCount = (
    postData,
    is_reacted,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {
    let array = [ ...data ];
    let post_index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( is_reacted ) {
      //like
      if ( !array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] += 1; //if user already reacted and now he is changing reaction
      } else {
        array[ post_index ][ prevReaction?.key ] -= 1; //if user already reacted before then we remove the prev
      }
      array[ post_index ][ "is_reacted" ] = 1;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    } else {
      if ( array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] -= 1;
      }
      array[ post_index ][ "is_reacted" ] = 0;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ prevReaction?.key ] -= 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    }

    setData( array );
  };

  return (
    <div>
      <div className="globalStructure">
        <SideComponent title="WATCH" sortBy placeholder={ `${ t( "SEARCH" ) }...` }>
          <div className="navs">
            <MUI.Tabs
              value={ searchParams.get( "type_ids" ) }
              orientation={ isSmallScreen ? "horizontal" : "vertical" }
              variant={ isSmallScreen && "scrollable" }
              allowScrollButtonsMobile
              indicatorColor="none"
            >
              <MUI.Tab
                label={ t( "ALL" ) }
                value={ null }
                className="links"
                sx={ {
                  "&.Mui-selected": {
                    // Styles for the active tab
                    backgroundColor: "#d6f3f4",
                    color: "#02c0cc",

                    borderBottom: "2px solid #02c0cc",
                  },
                } }
                onClick={ () => handleCategoryFilter( null ) }
              ></MUI.Tab>

              { categoriesData?.map( ( category, index ) => (
                <MUI.Tab
                  label={ t( category.name ) }
                  key={ category?.id }
                  className="links"
                  value={ category?.id?.toString() }
                  sx={ {
                    "&.Mui-selected": {
                      // Styles for the active tab
                      backgroundColor: "#d6f3f4",
                      color: "#02c0cc",
                      borderBottom: "2px solid #02c0cc",
                    },
                  } }
                  onClick={ () => handleCategoryFilter( category?.id ) }
                ></MUI.Tab>
              ) ) }
            </MUI.Tabs>
          </div>
        </SideComponent>

        <div className="middleComponent">
          { isLoading ? (
            <PostSkeleton count={ 2 } />
          ) : data?.length === 0 ? (
            <NoData title={ "NO_FEED_FOUND" } loading={ false } NoData={ true } />
          ) : (
            <>
              { data?.map( ( post, index ) => (
                <Post
                  list
                  data={ post }
                  key={ index }
                  updatePostLikeCount={ updatePostLikeCount }
                  updatePostCommentCount={ updatePostCommentCount }
                />
              ) ) }
              { page < totalPage && (
                <LoadMoreButton
                  loadMoreLoader={ loadMoreLoader }
                  title="LOAD_MORE"
                  onClick={ LoadMore }
                />
              ) }
            </>
          ) }
        </div>
      </div>
    </div>
  );
};

export default Watch;
