import { FriendsFieldSVG, PrivateFieldSVG, PublicFieldSVG } from "../../assets/icons/VisibilityStatus";

export const capitalizeFirstLetter = ( string ) => {
  return string.charAt( 0 ).toUpperCase() + string.slice( 1 );
};
export const capitalize = ( string ) => {
  return string.replace(
    /(^\w|\s\w)(\S*)/g,
    ( _, m1, m2 ) => m1.toUpperCase() + m2.toLowerCase()
  );
};

export const CheckVisibilityIcon = ( visibilityStatus ) => {
  if ( visibilityStatus === "public" ) {
    return <PublicFieldSVG />
  } else if ( visibilityStatus === "private" ) {
    return <PrivateFieldSVG />
  } else {
    return <FriendsFieldSVG />
  }
}

export const groupParamsByKey = ( params ) =>
  [ ...params.entries() ].reduce( ( acc, tuple ) => {
    // getting the key and value from each tuple
    const [ key, val ] = tuple;
    if ( acc.hasOwnProperty( key ) ) {
      // if the current key is already an array, we'll add the value to it
      if ( Array.isArray( acc[ key ] ) ) {
        acc[ key ] = [ ...acc[ key ], val ];
      } else {
        // if it's not an array, but contains a value, we'll convert it into an array
        // and add the current value to it
        acc[ key ] = [ acc[ key ], val ];
      }
    } else {
      // plain assignment if no special case is present
      acc[ key ] = val;
    }

    return acc;
  }, {} );
export const objectCleaner = ( obj ) => {
  const cleanedObj = Object.fromEntries(
    Object.entries( obj ).filter( ( [ _, v ] ) => {
      if ( Array.isArray( v ) ) {
        return v.some( ( elem ) => elem !== "" );
      } else {
        return v != "null" && v != null && v !== "" && v;
      }
    } )
  );
  return cleanedObj;
};
export const isEmpty = ( obj ) => !Object.keys( obj ).length;
export const getFormData = (
  obj,
  formData = new FormData(),
  parentKey = null
) => {
  for ( let key in obj ) {
    if ( obj.hasOwnProperty( key ) ) {
      const value = obj[ key ];

      const formattedKey = parentKey ? `${ parentKey }[${ key }]` : key;

      if ( Array.isArray( value ) ) {
        value.forEach( ( item, index ) => {
          const itemKey = `${ formattedKey }[${ index }]`;
          if ( typeof item === "object" && item instanceof File ) {
            formData.append( itemKey, item );
          } else {
            getFormData( { [ itemKey ]: item }, formData );
          }
        } );
      } else if (
        typeof value === "object" &&
        value !== null &&
        !( value instanceof File )
      ) {
        getFormData( value, formData, formattedKey );
      } else {
        formData.append( formattedKey, value );
      }
    }
  }

  return formData;
};
export const htmlToText = ( str ) => str?.replace( /<[^>]+>/g, "" );
export const removeZeroFromArray = ( obj ) => {
  for ( const key in obj ) {
    if ( Array.isArray( obj[ key ] ) ) {
      obj[ key ] = obj[ key ].filter( ( item ) => item !== 0 );
    } else if ( typeof obj[ key ] === "object" && obj[ key ] !== null ) {
      removeZeroFromArray( obj[ key ] );
    }
  }
  return obj;
};
export const objToFormData = (
  obj,
  formData = new FormData(),
  parentKey = null
) => {
  for ( let key in obj ) {
    if ( obj.hasOwnProperty( key ) ) {
      const value = obj[ key ];

      const formattedKey = parentKey ? `${ parentKey }[${ key }]` : key;

      if ( Array.isArray( value ) ) {
        value.forEach( ( item, index ) => {
          const itemKey = `${ formattedKey }[${ index }]`;
          if ( typeof item === "object" && item instanceof File ) {
            formData.append( itemKey, item );
          } else {
            objToFormData( { [ itemKey ]: item }, formData );
          }
        } );
      } else if (
        typeof value === "object" &&
        value !== null &&
        !( value instanceof File )
      ) {
        objToFormData( value, formData, formattedKey );
      } else {
        formData.append( formattedKey, value );
      }
    }
  }

  return formData;
};
