import React, { createContext, useEffect, useState } from "react";
import { GetNotificationsCount } from "../core/services/notification";
import { isUserLoggedIn } from "../core/services/auth";


const NotificationContext = createContext( {
    notificationCount: 0,
    updateNotificationCount: () => { },
} );

const NotificationContextProvider = ( props ) => {

    const { children } = props;
    const [ notificationCount, setNotificationCount ] = useState(
        sessionStorage?.getItem( "notifyCount" ) === "undefined"
            ? 0
            : sessionStorage?.getItem( "notifyCount" )
    );

    const updateNotificationCount = () => {
        getUserNotificationData();
    };

    const getUserNotificationData = () => {

        GetNotificationsCount().then( ( res ) => {

            if ( res?.data?.success ) {
                setNotificationCount( res?.data?.data );
                sessionStorage.setItem(
                    "notifyCount",
                    res?.data?.data
                );
            }
        } );
    };

    useEffect( () => {
        if ( isUserLoggedIn() ) {

            getUserNotificationData();
        }
    }, [] );

    return (
        <NotificationContext.Provider
            value={ { notificationCount, updateNotificationCount } }
        >
            { children }
        </NotificationContext.Provider>
    );
};
export { NotificationContextProvider, NotificationContext };
