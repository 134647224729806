import React, { useContext, useState } from "react";
import {
  FormCalendarInput, FormDropdownList,
} from "../form/form-componenrt/FormComponent";
import { useTranslation } from "react-i18next";
import Button, { IconsButton } from "../button/Button";
import { PermissionContext } from "../../context/PermissionContext";
import { CalendarViewSVG, ListViewSVG } from "../../assets/icons/SVG";
import { IconButton } from "@mui/material";


const CustomToolbar = ( props ) => {
  //TRANSLATION
  const [ t ] = useTranslation( 'common' )
  const { selectedDate, changeDate, listView, setListView } = props;

  const ViewData = [
    { id: "month", name: t( "MONTH" ) },
    { id: "week", name: t( "WEEK" ) },
    { id: "day", name: t( "DAY" ) },
    { id: "agenda", name: t( "AGENDA" ) },
  ];

  //FUNCTIONS
  const handleChangeView = ( value ) => {
    props.onView( value );
  };
  return (
    <div className="toolbar-component-container">
      <div className="toolbar-calendar">
        <FormCalendarInput
          formComponent={ false }
          value={ selectedDate }
          onChange={ ( value ) => {
            changeDate( value ? new Date( value ) : null );
          } }
        />

      </div>

      <div className="toolbar-actions">
        { !listView && (
          <div className="view-option">
            <FormDropdownList
              required
              formComponent={ false }
              filterDropdown
              value={ props.view }
              data={ ViewData?.sort( ( a, b ) => a.name.localeCompare( b.name ) ) }
              onChange={ ( value ) => handleChangeView( value ) }
            />
          </div> ) }
        <IconButton
          onClick={ () => setListView( !listView ) } >
          { listView ? <CalendarViewSVG /> : <ListViewSVG /> }
        </IconButton>
      </div>
    </div >
  );
}
export default CustomToolbar;
