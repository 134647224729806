import React, { useState, useContext } from "react";
import "./NavigationBar.scss";
import { MenuItem, Divider, Badge } from "@mui/material";
import { Tabs, Tab, Avatar, Box, Menu } from "@mui/material";
import HomeSVG from "../../assets/icons/HomeSVG";
import MeetingsSVG from "../../assets/icons/MeetingsSVG";
import GroupsSVG from "../../assets/icons/GroupsSVG";
import RingSVG from "../../assets/icons/RingSVG";
import { TrophySVG } from "../../assets/icons/TrophySVG";
import UserSVG from "../../assets/icons/UserSVG";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import {
  BurgerSVG,
  LogoSVG,
  MessagesIcon,
  WebBinarsSVG,
} from "../../assets/icons/SVG";
import Logout from "@mui/icons-material/Logout";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { Drawer, ListItemText } from "@mui/material";
import {
  BriefCaseSVG,
  CompaniesSVG,
  ConnectionsSVG,
  EprescriptionSVG,
  GazetteSVG,
  QnASVG,
  WatchSVG,
  WebinarsSVG,
} from "../../assets/icons/SVG";
import { LanguageSelect } from "../../theme/Theme";
import { LanguageContext } from "../../context/LanguageContext";
import { useAuth } from "../../hooks/useAuth";
import { PermissionContext } from "../../context/PermissionContext";
import DialogComponent from "../../components/dialog/Dialog";
import { NotificationContext } from "../../context/NotificationContext";
import { ThemeBadge } from "../../theme/ComponentTheme";
import { setupUnreadMessagesListener } from "../../core/services/firebase";
import AboutModal from "../../components/aboutModal/AboutModal";
import { DisplayTooltip } from "../../components/display-component/DisplayComponent";

const data = [
  { name: "home", id: 0, link: "/home" },
  { name: "groups", id: 1, link: "/groups/YourFeed" },
  { name: "meetings", id: 2, link: "/meetings/schedule" },
  { name: "message", id: 3, link: "/chat" },
  {
    name: "notifications",
    id: 4,
    link: "notifications",
  },
  { name: "rewards", id: 5, link: "rewards-details" },
  { name: "profile", id: 6, link: "account-details" },
];

const NavigationBar = ( props ) => {
  const { user: userInfo } = props;
  const { user, logout } = useAuth();
  const { permissions } = useContext( PermissionContext );
  const { notificationCount } = useContext( NotificationContext );
  const { language, setLanguage } = useContext( LanguageContext );
  const { languages, loadingLanguages } = useContext( LanguageContext );
  const { t, i18n } = useTranslation( "common" );
  let navigate = useNavigate();
  let { pathname, search } = useLocation();
  let params = window.location.pathname.split( "/" );
  const notificationsAlert = {
    notification: notificationCount?.total,
    reward:
      notificationCount?.earned_points + notificationCount?.redeemed_award,
    group: notificationCount?.group_invitation,
    meeting: notificationCount?.meeting,
    connection:
      notificationCount?.accepted_friend_request +
      notificationCount?.pending_friend_request,
  };

  const homeLinks = [
    {
      image: <BriefCaseSVG />,
      to: `catalog/all`,
      name: "PRODUCT_CATALOG",
      type: 1,
      id: 1,
      visible: true,
    },
    {
      image: <GazetteSVG />,
      to: `blog`,
      name: "BLOG",
      type: 1,
      id: 2,
      visible: true,
    },
    {
      image: <QnASVG />,
      to: `question-and-answer`,
      name: "Q&A",
      type: 1,
      id: 3,
      visible: true,
    },
    {
      image: <WatchSVG />,
      to: `watch/all`,
      name: "Watch",
      type: 1,
      visible: permissions?.Posts?.[ "view-posts-by-group-id" ],
    },
    {
      image: <ConnectionsSVG />,
      to: `connections/requests`,
      name: "Connections",
      notification: notificationsAlert?.connection,
      type: 1,
      id: 6,
      visible: true,
    },
    {
      image: <EprescriptionSVG />,
      to: `EprescriptionSVG`,
      name: "E-Prescription",
      upComing: "Coming Soon",
      type: 2,
      id: 7,
      visible: true,
    },
    {
      image: <CompaniesSVG />,
      to: `companies`,
      name: "COMPANIES",
      type: 1,
      id: 8,
      visible: permissions?.Company?.[ "view-suggested-companies" ],
    },

    {
      image: <WebBinarsSVG />,
      to: `/`,
      name: "Webinars",
      type: 2,
      id: 9,
      visible: true,
    },
  ];

  const mobileNavigate = [
    {
      id: 1,
      svg: <HomeSVG />,
      text: "HOME",
      link: `home`,
      visible: true,
    },

    {
      id: 2,
      svg: <GroupsSVG />,
      text: "GROUPS",
      link: `groups/your-feed`,
      notification: notificationsAlert?.group,
      visible: true,
    },
    {
      id: 3,
      svg: <MeetingsSVG />,
      text: "MEETINGS",
      link: `meetings/schedule`,
      notification: notificationsAlert?.meeting,
      visible:
        permissions?.Schedule?.[ "view-specific-user-schedule" ] ||
        permissions?.Meeting?.[ "view-specific-doctor-meetings" ] ||
        permissions?.Meeting?.[ "view-doctor-specific-meetings-history" ],
    },
    {
      id: 4,
      svg: <MessagesIcon />,
      text: "MESSAGES",
      link: `chat`,
      visible: true,
    },
    {
      id: 5,
      svg: <RingSVG />,
      text: "NOTIFICATIONS",
      link: `notifications`,
      notification: notificationsAlert.notification,
      disabled: !permissions?.Notification?.[ "view-all-user-notification" ],
      visible: permissions?.Notification?.[ "view-all-user-notification" ],
    },
    {
      id: 6,
      svg: <TrophySVG />,
      text: "REWARDS",
      link: `rewards-details`,
      notification: notificationsAlert.reward,
      visible:
        permissions?.Achievements?.[ "view-user-achievements" ] ||
        permissions?.Achievements?.[ "view-all-achievements" ] ||
        permissions?.Redemption?.[ "view-user-redemptions" ] ||
        permissions?.Reward?.[ "view-all-rewards" ],
    },
    {
      id: 7,
      svg: <UserSVG />,
      text: "PROFILE",
      link: `account-details`,
      visible: true,
    },
  ];
  const [ hasUnread, setHasUnread ] = useState( false );
  const [ DrawerOpen, setDrawerOpen ] = useState( false );
  const [ isHomeActive, setIsHomeActive ] = useState( "" );
  const [ isActive, setIsActive ] = useState( "" );
  const [ anchorEl, setAnchorEl ] = useState( null );
  const [ onHideState, setOnHideState ] = useState( false );
  const [ aboutInfoOpen, setAboutInfoOpen ] = useState( false );
  const open = Boolean( anchorEl );
  let nav = data
    ? data?.find( ( e ) =>
      e?.link?.toLowerCase()?.includes( params[ 2 ]?.toLowerCase() )
    )?.name
    : "en";

  const handleClose = () => setAnchorEl( null );
  const changeLanguageOnClick = ( e ) => {
    setLanguage( e.target.value );

    navigate(
      `${ pathname }${ search }`.replace( `/${ language }/`, `/${ e.target.value }/` )
    );
    window.location.reload();
  };

  const handleChange = ( e ) => {
    if ( e.target.name === "home" ) navigate( `home` );
    if ( e.target.name === "groups" ) navigate( `groups/your-feed` );
    if ( e.target.name === "rewards" ) navigate( `rewards-details` );
    if ( e.target.name === "meetings" ) navigate( `meetings` );
    if ( e.target.name === "message" ) navigate( `chat` );
    if ( e.target.name === "profile" ) setAnchorEl( e.currentTarget );
    if ( e.target.name === "notifications" ) navigate( `notifications` );
  };
  const handleProfile = () => {
    navigate( `account-details` );
  };
  const handleLogout = () => {
    logout().then( ( res ) => setOnHideState( false ) );
  };

  //listener of the unreadMessages for the badge
  const unsubscribeUnreadMessages = setupUnreadMessagesListener(
    user?.id,
    ( hasUnread, _ ) => {
      setHasUnread( hasUnread );
      // Update your navigation bar badge based on the hasUnread value
      // For example, you can dispatch an action to update your state or use a global event bus
    }
  );

  const openModal = () => setOnHideState( !onHideState );
  return (
    <>
      <div className="navigationBar">
        <div className="logo">
          <Link to={ `${ user ? "home" : "homepage" }` }>
            <LogoSVG />
          </Link>
          { user && (
            <BurgerSVG onClick={ () => setDrawerOpen( true ) } className="burger" />
          ) }
        </div>
        { user && (
          <>
            <Tabs
              value={ nav }
              onChange={ handleChange }
              aria-label="icon label tabs example"
              textColor="primary"
              TabIndicatorProps={ { style: { background: "#02C0CC" } } }
              className="tabs logo-language-ui"
              variant="scrollable"
            >
              <Tab
                icon={ <HomeSVG /> }
                value="home"
                label={ t( "HOME" ) }
                name="home"
                className="tabIcon"
              />
              <Tab
                icon={
                  <div className="navigation-bar-icon-tab">
                    <Badge
                      variant="dot"
                      color="error"
                      onClick={ () => navigate( `groups/your-feed` ) }
                      invisible={
                        !notificationsAlert?.group ||
                        notificationsAlert?.group === 0
                      }
                    >
                      <GroupsSVG />
                    </Badge>
                  </div>
                }
                value="groups"
                label={ t( "GROUPS" ) }
                name="groups"
                className="tabIcon"
              />
              { ( permissions?.Schedule?.[ "view-specific-user-schedule" ] ||
                permissions?.Meeting?.[ "view-specific-doctor-meetings" ] ||
                permissions?.Meeting?.[
                "view-doctor-specific-meetings-history"
                ] ) && (
                  <Tab
                    icon={
                      <div className="navigation-bar-icon-tab">
                        <Badge
                          variant="dot"
                          color="error"
                          invisible={
                            !notificationsAlert?.meeting ||
                            notificationsAlert?.meeting === 0
                          }
                          onClick={ () => navigate( `meetings` ) }
                        >
                          <MeetingsSVG />
                        </Badge>
                      </div>
                    }
                    value="meetings"
                    label={ t( "MEETINGS" ) }
                    name="meetings"
                    className="tabIcon"
                  />
                ) }
              <Tab
                icon={
                  <Badge
                    variant="dot"
                    color="error"
                    onClick={ () => navigate( `chat` ) }
                    invisible={ hasUnread === false }
                  >
                    <MessagesIcon />
                  </Badge>
                }
                value="message"
                label={ t( "MESSAGES" ) }
                name="message"
                className="tabIcon"
              />
              { permissions?.Notification?.[ "view-all-user-notification" ] && (
                <Tab
                  icon={
                    <ThemeBadge
                      color="secondary"
                      badgeContent={ notificationsAlert?.notification }
                      size="small"
                      onClick={ () => navigate( `notifications` ) }
                      invisible={
                        !notificationsAlert?.notification ||
                        notificationsAlert?.notification === 0
                      }
                    >
                      { " " }
                      <RingSVG />
                    </ThemeBadge>
                  }
                  value="notifications"
                  label={ t( "NOTIFICATIONS" ) }
                  name="notifications"
                  className="tabIcon"
                />
              ) }
              { ( permissions?.Achievements?.[ "view-user-achievements" ] ||
                permissions?.Achievements?.[ "view-all-achievements" ] ||
                permissions?.Redemption?.[ "view-user-redemptions" ] ||
                permissions?.Reward?.[ "view-all-rewards" ] ) && (
                  <Tab
                    icon={
                      <div className="navigation-bar-icon-tab">
                        <Badge
                          variant="dot"
                          color="error"
                          onClick={ () => navigate( `rewards-details` ) }
                          invisible={
                            !notificationsAlert?.reward ||
                            notificationsAlert?.reward === 0
                          }
                        >
                          <TrophySVG />
                        </Badge>
                      </div>
                    }
                    value="rewards"
                    label={ t( "REWARDS" ) }
                    name="rewards"
                  />
                ) }
              <Tab
                onClick={ handleChange }
                icon={
                  <Avatar
                    alt={ userInfo?.first_name }
                    src={ REACT_APP_IMAGE_URL + userInfo?.profile_image }
                    sx={ { width: 20, height: 20 } }
                    onClick={ ( e ) => setAnchorEl( e?.currentTarget ) }
                  />
                }
                value="profile"
                label={ t( "PROFILE" ) }
                name="profile"
                className="tabIcon"
              />
            </Tabs>
            <Menu
              anchorEl={ anchorEl }
              id="account-menu"
              open={ open }
              onClose={ handleClose }
              onClick={ handleClose }
              PaperProps={ {
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 1.5,
                  "& .MuiAvatar-root": {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                  "&:before": {
                    content: '""',
                    display: "block",
                    position: "absolute",
                    top: 0,
                    right: 14,
                    width: 10,
                    height: 10,
                    bgcolor: "background.paper",
                    transform: "translateY(-50%) rotate(45deg)",
                    zIndex: 0,
                  },
                },
              } }
              transformOrigin={ { horizontal: "right", vertical: "top" } }
              anchorOrigin={ { horizontal: "right", vertical: "bottom" } }
            >
              <MenuItem onClick={ handleProfile }>{ t( "MY_PROFILE" ) }</MenuItem>
              <Divider className="navigationBar-split-divider" />
              <MenuItem onClick={ () => setAboutInfoOpen( true ) }>
                { t( "ABOUT" ) }
              </MenuItem>
              <Divider className="navigationBar-split-divider" />
              <MenuItem onClick={ openModal }>{ t( "LOGOUT" ) }</MenuItem>
            </Menu>
          </>
        ) }
        { !loadingLanguages && languages && languages?.length !== 0 ? (
          <LanguageSelect
            value={ i18n?.language }
            onChange={ changeLanguageOnClick }
            className="LanguageDropdown"
          >
            { languages?.map( ( item ) => (
              <MenuItem
                key={ item.id }
                value={ item.code }
                className="language-dropdown-container"
              >
                { item?.icon }
                <ListItemText primary={ item?.code?.toUpperCase() } />
              </MenuItem>
            ) ) }
          </LanguageSelect>
        ) : null }
      </div>

      { onHideState && (
        <DialogComponent
          actions
          onClose={ () => {
            setOnHideState( false );
          } }
          message={ "ARE_YOU_SURE_YOU_WANT_TO_LOGOUT" }
          handleResponse={ handleLogout }
        />
      ) }
      { aboutInfoOpen && (
        <AboutModal
          onClose={ () => {
            setAboutInfoOpen( false );
          } }
        />
      ) }
      <Drawer
        anchor="left"
        open={ DrawerOpen }
        onClose={ () => setDrawerOpen( true ) }
        className="drawer"
      >
        <div className="burgerNav">
          <LogoSVG style={ { marginRight: "20px" } } />
          <BurgerSVG onClick={ () => setDrawerOpen( false ) } className="navIcon" />
        </div>
        <Box className="navDrawer">
          <div className="mobile-nav-container">
            { mobileNavigate
              ?.filter( ( i ) => i.visible )
              ?.map( ( val ) => (
                <div
                  key={ val.id }
                  className={ `mobile-nav ${ isActive === val.id ? "active" : ""
                    }` }
                  onClick={ () => {
                    setIsActive( val.id );
                    navigate( val.link );
                    setDrawerOpen( false );
                  } }
                >
                  <Badge
                    variant="dot"
                    color="error"
                    invisible={ !val?.notification || val?.notification === 0 }
                  >
                    { val.svg }
                  </Badge>

                  { t( val.text ) }
                </div>
              ) ) }
            <div
              onClick={ () => {
                handleLogout();
                setDrawerOpen( false );
              } }
              className="mobile-nav"
            >
              <Logout
                className="svg"
                style={ { color: "#71A2A7" } }
                fontSize="small"
              />
              <p>{ t( "LOGOUT" ) }</p>
            </div>
          </div>
        </Box>
        <div className="navs-mobile">
          { homeLinks
            ?.filter( ( i ) => i?.visible )
            ?.map( ( e, index ) =>
              e.type === 2 ? (
                <DisplayTooltip
                  key={ index }
                  title={ t( "COMING_SOON" ) }
                  placement="right-start"

                ><div className="links" style={ { cursor: "default" } }>
                    <p className="navTypo">{ e.image }</p>
                    { t( e.name ) }
                  </div>
                </DisplayTooltip>

              ) : (
                <div
                  onClick={ () => {
                    setIsHomeActive( e.id );
                    setIsActive( "" );
                    navigate( e.to );
                    setDrawerOpen( false );
                  } }
                  className={ isHomeActive === e.id ? "links active" : "links" }
                  key={ index }
                >
                  <p className="navTypo">
                    <Badge
                      variant="dot"
                      color="error"
                      invisible={ !e?.notification || e?.notification === 0 }
                    >
                      { e.image }
                    </Badge>
                  </p>
                  { t( e.name ) }
                </div>
              )
            ) }
        </div>
      </Drawer>
    </>
  );
};

export default NavigationBar;
