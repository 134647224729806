//UTILITIES
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
//COMPONENT
import { SinglePreviousIcon, SingleNextIcon } from "../../assets/icons/SVG";
import Carousel from "react-material-ui-carousel";
import { Card } from "@mui/material";

//CSS
import "./Carousel.scss";

const CarouselComponent = ( props ) => {
  const { data } = props;
  return (
    <div className="carousel-component">
      <Carousel
        height={ 300 }
        index={ 0 } //default value
        animation="slide"
        navButtonsAlwaysVisible
        indicators={ false }
        autoPlay={ false }
        NextIcon={ <SingleNextIcon /> }
        PrevIcon={ <SinglePreviousIcon /> }
        navButtonsProps={ {
          style: {
            backgroundColor: "unset",
          },
        } }
      >
        { data.map( ( item ) => (
          <CarouselItem data={ REACT_APP_IMAGE_URL + item.path } />
        ) ) }
      </Carousel>
    </div>
  );
};

export default CarouselComponent;

export function CarouselItem( props ) {
  const { data } = props;
  return (
    <Card className="carousel-item-component">
      <img src={ data } />
    </Card>
  );
}
