//UTILITIES
import React, { forwardRef, useContext, useState } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/UserContext";
import { LanguageContext } from "../../context/LanguageContext";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
//COMPONENT
import * as MUI from "@mui/material";
import VideoPlayer from "../../components/new/react-player/react-player";
import CommentComponent from "../../pages/comment-component/CommentComponent";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";
import PostReaction from "../post-reactions/PostReaction";
import ReactionsDetails from "../post-reactions/reaction-details/ReactionsDetails";
import PDFReader from "../pdf-reader/PDFReader";
//API
import { translate, detectlanguge } from "../../core/services/translation";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import {
  createComment,
  deleteComment,
  getCommentByPostId,
} from "../../core/services/comments";
import { likePost } from "../../core/services/posts";
//CSS
import "./Post.scss";
import { PermissionContext } from "../../context/PermissionContext";
import { LazyLoadImage } from "react-lazy-load-image-component";
import DefaultImage from "../../assets/images/DEFAULT.jpg";

const formatDate = ( date ) => {
  if ( Math.abs( moment( date ).diff( new Date(), "days" ) ) < 7 ) {
    return moment( date ).calendar( null, {
      lastDay: "[Yesterday]",
    } );
  } else {
    return moment( date ).format( "MMM D [at] h:mm A" );
  }
};
const Transition = forwardRef( function Transition( props, ref ) {
  return <MUI.Slide direction="up" ref={ ref } { ...props } />;
} );

const Post = ( props ) => {
  //VARIABLES
  const [ t ] = useTranslation( "common" );
  const { permissions } = useContext( PermissionContext );
  const {
    data,
    updatePostCommentCount,
    quizzes,
    campaign,
    isAdmin, //this prop is added to add the logo image if the post is posted by superadmin
    updatePostLikeCount,
  } = props;
  const { image, userName, reactions } = props;
  const { user } = useContext( UserContext );
  const { language } = useContext( LanguageContext );
  const { lang } = useParams() || {};
  const [ showComments, setShowComments ] = useState( false );
  const [ number, setNumber ] = useState( 120 );
  const [ open, setOpen ] = useState( false ); //reactions
  const [ translatedText, setTranslatedText ] = useState( "" );

  //FUNCTIONS
  const handleExpandClick = () => {
    setShowComments( !showComments );
  };

  const handleClickOpen = () => setOpen( true );
  const handleClose = () => setOpen( false );

  const handleTranslate = ( text, language ) => {
    translate( text, language ).then( ( res ) => {
      setTranslatedText( res?.success ? res?.data || "" : text );
    } );
  };

  const handledetect = async ( text, translatedText ) => {
    Boolean( translatedText ) && setTranslatedText( "" );
    !Boolean( translatedText ) &&
      ( await detectlanguge( text ).then( ( res ) => {
        if ( res?.success ) {
          //translate the text based on the language selected
          handleTranslate( text, language );
        } else {
          setTranslatedText( text )
        }

      } ) );
  };
  //is liked false => like
  const handleLikePost = async (
    is_liked,
    id,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {
    await likePost( {
      post_id: id,
      is_liked: is_liked,
      like_type_id: reactionTypeId,
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        updatePostLikeCount(
          data,
          !is_liked,
          reactionTypeId,
          reaction,
          prevReaction
        );
      }
    } );
  };
  return (
    <div className="post-card-container">
      <MUI.CardHeader
        avatar={
          <MUI.Avatar
            className={ isAdmin ? "post-avatar" : " " }
            alt={ userName ? userName : data?.pharmaceutical_group?.title }
            src={
              isAdmin
                ? image
                : `${ REACT_APP_IMAGE_URL }${ image || data?.pharmaceutical_group?.profile_image
                }`
            }
            sx={ { width: "50px", height: "50px" } }
            aria-label="recipe"
          />
        }
        action={
          data?.user_post_track?.[ 0 ]?.pivot?.is_achieved ? (
            <MUI.IconButton aria-label="settings">
              <MilitaryTechIcon color="success" />
            </MUI.IconButton>
          ) : null
        }
        title={ userName ? userName : data?.pharmaceutical_group?.title }
        subheader={ `${ data?.pharmaceutical_group?.pharmaceutical_company?.name || ""
          } ${ data?.pharmaceutical_group ? "-" : "" } ${ formatDate(
            data?.created_at || ""
          ) } ${ campaign ? `- ${ t( "SPONSORED" ) }` : "" }` }
      />
      <MUI.CardContent className="cardContent">
        <div className="title">{ data?.title }</div>
        <MUI.Typography color="text.secondary" className="paragraph">
          <div>
            { data?.description?.substring( 0, number ) }{ " " }
            <span className="show-more-or-less">
              { data?.description?.length < number ? null : data?.description
                ?.length > number ? (
                <>
                  <span onClick={ () => setNumber( number + 144000 ) }>
                    { t( "SHOW_MORE" ) }
                  </span>
                </>
              ) : null }
            </span>
          </div>
        </MUI.Typography>
        { translatedText ? (
          <MUI.Typography color="text.secondary" className="mt-2 translation">
            <div>
              { translatedText?.substring( 0, number ) }{ " " }
              <span className="show-more-or-less">
                { translatedText.length < number ? null : translatedText.length >
                  number ? (
                  <>
                    <span onClick={ () => setNumber( number + 144000 ) }>
                      { t( "SHOW_MORE" ) }
                    </span>
                  </>
                ) : null }
              </span>
            </div>
          </MUI.Typography>
        ) : null }
        { data?.description && (
          <div
            className="translationText"
            onClick={ () => handledetect( data?.description, translatedText ) }
          >
            { t( !translatedText ? "SEE_TRANSLATION" : "REMOVE_TRANSLATION" ) }
          </div>
        ) }
        { data?.type?.slug === "image" ? (
          <Link
            className={ props.halfWidth ? "inline-image-class-width" : "fullWidth" }
            to={ `/${ lang }/watch/description/${ data?.id }/${ data?.type?.slug }` }
          >
            <LazyLoadImage
              key={ data?.id }
              className="image-post"
              width={ "100%" }
              height={ "300px" }
              src={
                REACT_APP_IMAGE_URL +
                data?.post_media?.map( ( val ) => val.path )?.[ 0 ]
              }
              alt="post-image"
              onError={ ( { target } ) => {
                // Handle image loading error
                target.src = DefaultImage;

              } }

            />
          </Link>
        ) :
          data?.type?.slug === "presentation" ? (

            <PDFReader data={ data } />
          ) : data?.type?.slug === "video" ? (
            <VideoPlayer
              data={ data }
              key={ data?.id }
              played={ data?.user_post_track?.[ 0 ]?.pivot?.stopped_at }
              isachieved={ data?.user_post_track?.[ 0 ]?.pivot?.is_achieved }
              id={ data?.id }
              link={ data?.post_media?.map( ( val ) => val.path ) }
              halfWidth={ props.halfWidth }
            />
          ) : null }

        { reactions && (
          <PostReaction
            { ...props }
            data={ data }
            post
            handleClickOpen={ handleClickOpen }
            handleClickClose={ handleClose }
            handleLike={ permissions?.Like?.[ "like-post" ] && handleLikePost }
            expandComments={ data?.comments_count === 0 && !permissions?.Comment?.[ 'create-comment' ] ? null : () => handleExpandClick() }

          />
        ) }
      </MUI.CardContent>

      <MUI.Collapse in={ showComments } timeout="auto" unmountOnExit>
        <CommentComponent
          { ...props }
          user={ user }
          post={ data }
          handleGetComment={ getCommentByPostId }
          handleAddComment={
            permissions?.Comment?.[ "create-comment" ] && createComment
          }
          updateCommentCount={ ( type, commentChildrenCount ) => updatePostCommentCount( data, type, commentChildrenCount ) }
          handleDeleteComment={
            permissions?.Comment?.[ "delete-comment" ] && deleteComment
          }
        />
      </MUI.Collapse>

      <MUI.Dialog
        open={ open }
        TransitionComponent={ Transition }
        fullWidth
        onClose={ handleClose }
      >
        <MUI.DialogContent>
          <ReactionsDetails data={ data } />
        </MUI.DialogContent>
      </MUI.Dialog>
    </div>
  );
};

Post.defaultProps = {
  reactions: true,
};
Post.propTypes = {
  data: PropTypes.object.isRequired,
  reactions: PropTypes.bool,
};
export default Post;
