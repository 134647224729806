
export const AllPermissions = {
  Achievements: {
    "view-all-achievements": false,//view how you can earn points
    "view-user-achievements": false,//view your points
  },

  Address: {
    "create-address": false,
    "update-address": false,
  },

  Comment: {
    "create-comment": false,
    "delete-comment": false,
  },
  Company: {
    "view-suggested-companies": false,
  },

  Document: {
    "view-user-documents": false,
  },

  Group: {
    "view-groups-by-other-user": false,//view a user's group
    "view-group": false, //view group detail
    "view-suggested-groups": false,
    "join-company-group": false,
    "leave-company-group": false,
    "view-group-invitations": false,
    "accept-or-reject-group-invitation": false,
  },

  Like: {
    "like-post": false,
    "like-comment": false,
  },
  Meeting: {
    "view-specific-doctor-meetings": false,
    "view-doctor-specific-meetings-history": false,
    "accept-meeting": false,
    "cancel-meeting": false,
  },
  Notification: {
    "view-all-user-notification": false,
  },

  Posts: {
    "view-posts-by-group-id": false,
  },

  Redemption: {
    "view-user-redemptions": false,
    "redeem-award": false,
  },

  Reward: {
    "view-all-rewards": false,
  },

  Schedule: {
    "create-schedule": false,
    "enable-schedule": false,
    "update-schedule": false,
    "view-specific-user-schedule": false,
  },

  User: {
    "invite-to-platform": false,
  },
};
