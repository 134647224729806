import { useTranslation } from "react-i18next";
import Button from "../../components/button/Button";

import "./ErrorPage.scss"
import { ErrorSVG } from "../../assets/icons/ErrorSVG";
export function ErrorPage( { resetErrorBoundary } ) {
    const [ t ] = useTranslation( 'common' )

    return (
        <div role="alert" className="page-container">
            <div className="error-page">
                <ErrorSVG />
                <div className="title">{ t( 'SOMETHING_WENT_WRONG' ) }</div>
                <div className="sub-title">{ `${ t( 'ERROR_PLEASE_RELOAD' ) } support@meetamed.com` }</div>
                <div>
                    <Button isSelected onClick={ resetErrorBoundary } name={ t( 'RELOAD' ) } />


                </div>
            </div>
        </div>
    )

}

export default ErrorPage;