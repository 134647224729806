import { api } from "./main";
export const getAllCompanies = ( payload ) => {
  let page = payload?.page ? `?page=${ payload.page }` : "";
  return api
    .get( `v1/company-dropdown${ page }`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getSuggestedCompanies = ( data ) => {
  return api
    .get( `/v1/suggested-companies`, { params: data } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const getCompanyById = ( company_id ) => {
  return api
    .get( `/v1/company/${ company_id }` )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getAllTherapeutics = ( data ) => {
  return api
    .get( `/v1/product-therapeutics`, { params: data } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
export const getCompanyLocations = ( payload ) => {
  return api
    .get( `/v1/company-locations/${ payload?.company_id }`, { params: { ...payload } } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
