import "./ProfessionCard.scss";
import { DisplayTooltip } from "../display-component/DisplayComponent";

const Profession = (props) => {
  const { title, className, onClick, id, comingSoon, svg } = props;
  return (
    <DisplayTooltip title={comingSoon} followCursor>
      <div
        className={`profession-card ${id === 5 && "hover"} ${className}`}
        onClick={onClick}>
        <img src={svg} alt="" />
        <h6>{title}</h6>
      </div>
    </DisplayTooltip>
  );
};

export default Profession;
