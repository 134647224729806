//UTILITIES
import React, { useState, useEffect, useContext } from "react";

import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

//COMPONENT
import { List, } from "@mui/material";

//API

import ChatElement from "./ChatElement";
import { GetFriendsList } from "../../core/services/connections";
import ViewMorePagination from "../../components/view-more-pagination/ViewMorePagination";
import { ListComponentSkeleton } from "../../components/skeletons/SkeletonsComponent";
import NoData from "../../components/Fallback/NoData";
import { FormSearchBar } from "../../components/form/form-componenrt/FormComponent";
import { IconsButton } from "../../components/button/Button";
import { ChatContext } from "../../context/ChatContext";
import { chatPerPage } from "../../core/variables/Variables";
import { CloseIcon } from "../../assets/icons/SVG";
import { ChatContactDrawer } from "../../theme/ComponentTheme";

export function ChatContact( props ) {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  //PROP
  const { onClose } = props;
  //VARIABLES
  const [ page, setPage ] = useState( 1 );
  const [ loadingConnections, setLoadingConnections ] = useState( false );
  const [ friends, setFriends ] = useState( [] );
  const [ totalFriends, setTotalFriends ] = useState( 0 );
  const [ loadMore, setLoadMore ] = useState( false );
  const [ filter, setFilter ] = useState( { name: "" } );
  const [ pageTotal, setPageTotal ] = useState( 0 );

  const handleLoadChange = ( type ) => {
    if ( type === "more" ) {
      setPage( page + 1 );
      GetConnection( page + 1 );
    } else {
      setPage( page - 1 );
      GetConnection( page - 1 );
    }
  };

  //GET CONNECTION IF NEW PAGE THEN LOAD MORE
  const GetConnection = ( new_page ) => {
    let handleLoad = new_page ? setLoadMore : setLoadingConnections;
    handleLoad( true );

    GetFriendsList( {
      page: new_page ? new_page : 1,
      per_page: chatPerPage,
      ...filter,
      user_name: filter?.name,
      sort: 2,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setTotalFriends( response.data.data?.total );
        setPage( response.data.data?.current_page );
        if ( new_page ) {
          setFriends( [ ...friends, ...response.data.data.data ] );
        } else {
          setFriends( response.data?.data?.data || [] );
        }

        setPageTotal( response.data.data?.last_page );
      }
      handleLoad( false );
    } );
  };
  const [ name, setName ] = useState( filter?.name );
  const debounced = useDebouncedCallback(
    // function
    ( value ) => {
      setFilter( { name: value } );
    },
    // delay in ms
    500
  );
  useEffect( () => {
    GetConnection();
  }, [ filter ] );

  return (
    <ChatContactDrawer open={ true } onClose={ () => onClose() }>
      <div className="chat-contact-drawer-content">
        <div
          style={ {
            display: "flex",
            flexFlow: "row nowrap",
            justifyContent: "flex-end",
          } }
        >
          <IconsButton
            onClick={ () => onClose() }
            icon={ <CloseIcon /> }
          ></IconsButton>
        </div>
        <div className="number-contacts">
          { totalFriends } { t( "CONTACTS" ) }
        </div>
        <div className="margin-up-20">
          <FormSearchBar
            placeholder="SEARCH_BY_NAME"
            variant="outlined"
            value={ name }
            onChange={ ( value ) => {
              setName( value );
              debounced( value );
            } }
          />
        </div>
        { !loadingConnections ? (
          friends?.length === 0 ? (
            <NoData variant="text" centered={ false } title="NO_CHATS_FOUND" />
          ) : (
            <div className="contact-list">
              <List disablePadding>
                { friends?.map( ( item, index ) => {
                  return (
                    <ChatElement
                      data={ item?.user_info }
                      key={ index }
                      index={ index }
                      length={ friends?.length }
                      onClose={ () => onClose() }
                    />
                  );
                } ) }
              </List>

              <ViewMorePagination
                page={ page }
                loading={ loadMore }
                pageTotal={ pageTotal }
                data={ friends }
                dataLeft={ friends ? totalFriends - friends.length : 0 }
                handleLoadChange={ handleLoadChange }
              />
            </div>
          )
        ) : (
          <ListComponentSkeleton count={ 5 } />
        ) }
      </div>
    </ChatContactDrawer>
  );
}

export default ChatContact;
