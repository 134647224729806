import React, { useState } from "react";
import {
  InputPassword,
  InputTextBootstrap,
} from "../../components/form/form-componenrt/FormComponent";
import Form from "../../components/form/forms/Form";
import PrimaryButton from "../../components/primary-button/PrimaryButton";
import LinkedText from "../../components/form/linked-text/LinkedText";
import "./Login.scss";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

//form validation yup
import ResponseMessage from "../../components/responseMessage/ResponseMessage";
import { useAuth } from "../../hooks/useAuth";

function Login() {
  const { login } = useAuth();

  const [t] = useTranslation("common");
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [passwordShown, setPasswordShown] = useState(false);
  const [input, setInput] = useState({
    email: "",
    password: "",
  });

  const onChange = (e) => {
    setInput((state) => {
      return { ...state, [e.target.name]: e.target.value };
    });
    setErrorMessage("");
  };
  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    let data = {
      portal_id: 3,
      email: input.email,
      password: input.password,
    };
    login(data).then(() => {
      setIsLoading(false);
    });
  }
  return (
    <div className="page-container">
      <div className="login-container">
        <div className="login-form">
          <Form onSubmit={handleSubmit} title={t("LOGIN")}>
            <InputTextBootstrap
              label="EMAIL"
              // type="email"
              placeHolder={"ENTER_EMAIL"}
              name="email"
              value={input.email}
              onChange={onChange}
            />
            <div style={{ position: "relative" }}>
              <InputPassword
                label="PASSWORD"
                type={passwordShown ? "text" : "password"}
                placeHolder={"ENTER_PASSWORD"}
                name="password"
                value={input.password}
                onChange={onChange}
                showPassword
                onPasswordShownClick={() => setPasswordShown(!passwordShown)}
                passwordShown={passwordShown}
              />
            </div>

            <div className="checkbox-group">
              <Link to={`../forgot-password`}>{t("FORGOT_PASSWORD")}</Link>
            </div>
            <div style={{ marginBottom: "15px" }}>
              <PrimaryButton
                loading={isLoading}
                width="100%"
                title="LOGIN"
                type="submit"
              />
              <LinkedText
                title={t("DON'T_HAVE_AN_ACCOUNT")}
                link={`../choose-your-profession`}
                navigate={t(`SIGN_UP`)}
              />
              <ResponseMessage message={errorMessage} />
            </div>
          </Form>
        </div>
        <div className="login-image">
          <img alt="" src="../../assets/images/medical.jpg" />
        </div>
      </div>
    </div>
  );
}

export default Login;
