//UTILITIES
import React, { useContext, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/UserContext";
import { ChatContext } from "../../context/ChatContext";

//COMPONENT
import { DisplayDateTime } from "../../components/display-component/DisplayComponent";
import NoData from "../../components/Fallback/NoData";
import { Avatar, Link } from "@mui/material";
//API
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { useMessages } from "../../hooks/useMessages";
//CSS
import "./Chat.scss";
import moment from "moment";

function MessageList( { roomId } ) {
  const [ t ] = useTranslation( "common" );
  const bottomEl = useRef( null );
  const messages = useMessages( roomId );
  const { user } = useContext( UserContext );
  const { chatUser } = useContext( ChatContext );

  useEffect( () => {
    bottomEl?.current?.scrollIntoView( { behavior: "smooth" } );
  } );



  return (
    <div className="message-list-container">
      <div className="message-list">
        { Object.keys( messages )?.length !== 0 ? (
          Object.keys( messages )?.map( ( messageKey, index ) => (
            <React.Fragment key={ messageKey }>
              <div className="message-date" >
                {/* it's only a date  */ }
                { moment( messageKey ).calendar( null, {
                  sameDay: `[${ t( "TODAY" ) }]`,
                  lastDay: `[${ t( "YESTERDAY" ) }]`,
                  lastWeek: "DD/MM/YYYY",
                  sameElse: "DD/MM/YYYY",
                } ) }

              </div>
              <>
                { messages[ messageKey ]?.map( ( messageItem ) => (
                  <div className="message-element" key={ messageItem?.id }>
                    { messageItem?.uID !== user?.id && (
                      <div className="message-avatar">
                        <Avatar
                          alt={ chatUser?.user?.first_name }
                          src={
                            chatUser?.user?.profile_image
                              ? REACT_APP_IMAGE_URL +
                              chatUser?.user?.profile_image
                              : ""
                          }
                        />
                      </div>
                    ) }
                    <Message
                      message={ messageItem }
                      isOwnMessage={ messageItem?.uID === user?.id }
                    />
                    { messageItem?.uID === user?.id && (
                      <div className="avatar">
                        <Avatar
                          className=""
                          src={ REACT_APP_IMAGE_URL + user?.profile_image }
                        />
                      </div>
                    ) }
                  </div>
                ) ) }
              </>
            </React.Fragment>
          ) )
        ) : (
          <NoData
            variant="text"
            title={ `${ t( "START_A_NEW_CONVERSATION_WITH" ) } ${ chatUser?.user?.first_name
              }` }
          />
        ) }
        <div ref={ bottomEl }></div>
        { chatUser?.user?.deleted_at && (
          <NoData
            variant="text"
            text={ `${ t( "THIS_USER_IS_CURRENTLY_INACTIVE" ) }` }
          />
        ) }
      </div>
    </div>
  );
}

function Message( { message, isOwnMessage } ) {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  const { text, type, timestamp, productId } = message; //destructuring the message props

  return (
    <div className="message-element-content">
      <div className={ `message-media ${ isOwnMessage ? "own-message" : "" }` }>
        <div>
          { type?.startsWith( "image" ) ? (
            <img
              src={ text }
              className="cursor-pointer chat-thumbnail"
              alt="image"
              onClick={ () => window.open( text, "_blank", "noopener,noreferrer" ) }
            />
          ) : type == "application/pdf" ? (
            <Link rel="noopener" target="_blank" href={ text }>
              { " " }
              { t( "VIEW_DOCUMENT" ) }
            </Link>
          ) : (
            <div dangerouslySetInnerHTML={ {
              __html: `<span>${ text } ${ productId ? `<a  href="../catalog/${ productId }">Product Reference</a>` : "" }</span>`,
            } } className={ `hreflink-a ${ isOwnMessage ? "own-message" : "" }` } />
          ) }
        </div>
      </div >
      <div className={ `message-timestamp ${ isOwnMessage ? "own-message" : "" }` }>
        { timestamp
          ? new Date( timestamp?.seconds * 1000 )?.toTimeString()?.substring( 0, 5 )
          : " " }
      </div>
    </div >
  );
}

export { MessageList };
