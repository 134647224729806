//UTILITIES
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
//COMPONENT
import { Skeleton } from "@mui/material";
import PresentationCard from "../../components/cards/PresentationCard";
import LinkIcon from "../../assets/icons/Link.svg";
import { NoPresentation } from "../../components/Fallback/NoData";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

//CSS
import "./RightBar.scss";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
const LinkedPresentation = (props) => {
  const [t] = useTranslation("common");
  const { data, loading } = props;

  return (
    <div className="rightComponent displayNone">
      <div className="rightBar">
        <div className="header">
          <div className="product-linkedpresentation-description">
            <div className="title">{t("LINKED_PRESENTATIONS")}</div>
            <div>
              <img src={LinkIcon} alt="presentation" />
            </div>
          </div>
        </div>
        {loading ? (
          <Skeleton />
        ) : data?.length === 0 ? (
          <NoPresentation
            width="100%"
            title={t("NO_LINKED_PRESENTATION")}
            NoData={true}
          />
        ) : (
          data?.map((item) => (
            <Link
              to={
                item?.hasOwnProperty("type_id")
                  ? `../watch/description/${item?.id}/Presentation`
                  : REACT_APP_IMAGE_URL + item?.path
              }
              target={item?.hasOwnProperty("type_id") ? "" : "_blank"}
            >
              <PresentationCard
                data={item}
                key={item?.id}
                type={item?.hasOwnProperty("type_id") ? item?.type_id : 4}
                //if no type id then it's a file field (which accept only pdf)
                header={item.title ? item.title : item?.path.split("/").pop()}
              />
            </Link>
          ))
        )}
      </div>
    </div>
  );
};

export default LinkedPresentation;
