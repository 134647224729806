export const ErrorSVG = ( props ) => {
    return (
        <svg width="215" height="201" viewBox="0 0 215 201" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.5" clip-path="url(#clip0_17891_51169)">
                <path d="M209.768 179.443H43.4383C40.5482 179.443 38.2056 177.1 38.2056 174.21C38.2056 171.32 40.5482 168.978 43.4383 168.978H209.768C212.658 168.978 215 171.32 215 174.21C215 177.1 212.636 179.443 209.768 179.443Z" fill="#B4CCC9" />
                <path d="M67.3461 68.3975H64.5874V114.682H67.3461V68.3975Z" fill="#B4CCC9" />
                <path d="M79.8257 114.682H64.5874V111.923H79.8257" fill="#B4CCC9" />
                <path d="M66.5584 103.472C64.0625 103.472 62.0264 101.436 62.0264 98.9396V94.8016C62.0264 92.3057 64.0625 90.2695 66.5584 90.2695C69.0544 90.2695 71.0905 92.3057 71.0905 94.8016V98.9396C71.0905 101.436 69.0544 103.472 66.5584 103.472Z" fill="#B4CCC9" />
                <path d="M168.301 159.564H84.884C82.9573 159.564 81.4028 158.01 81.4028 156.083V104.982C81.4028 103.055 82.9573 101.501 84.884 101.501H168.301C170.227 101.501 171.782 103.055 171.782 104.982V156.083C171.782 158.01 170.227 159.564 168.301 159.564Z" fill="#02C0CC" />
                <path opacity="0.2" d="M84.884 102.596C83.5704 102.596 82.4976 103.669 82.4976 104.982V156.083C82.4976 157.397 83.5704 158.469 84.884 158.469H168.301C169.614 158.469 170.687 157.397 170.687 156.083V104.982C170.687 103.669 169.614 102.596 168.301 102.596H84.884Z" fill="#1F82BF" />
                <path d="M173.993 169.787H79.2132C76.6953 169.787 74.6592 167.751 74.6592 165.233V163.591C74.6592 161.073 76.6953 159.037 79.2132 159.037H173.993C176.511 159.037 178.547 161.073 178.547 163.591V165.233C178.525 167.751 176.489 169.787 173.993 169.787ZM79.2132 161.248C77.9214 161.248 76.8486 162.299 76.8486 163.613V165.255C76.8486 166.547 77.8995 167.62 79.2132 167.62H173.993C175.284 167.62 176.357 166.569 176.357 165.255V163.613C176.357 162.321 175.306 161.248 173.993 161.248H79.2132Z" fill="#B4CCC9" />
                <path d="M167.183 107.544H85.9995V153.522H167.183V107.544Z" fill="white" />
                <path opacity="0.5" d="M109.251 161.25C109.251 163.002 111.791 164.425 114.9 164.425H137.232C140.341 164.425 142.881 163.002 142.881 161.25H109.251Z" fill="#1F82BF" />
                <path d="M136.663 143.013H134.474C134.474 139.115 130.686 135.919 126.044 135.919C121.403 135.919 117.615 139.094 117.615 143.013H115.426C115.426 137.911 120.177 133.729 126.044 133.729C131.912 133.751 136.663 137.911 136.663 143.013Z" fill="#0D7C9E" />
                <path d="M113.298 118.061L111.998 119.361L121.937 129.3L123.237 128L113.298 118.061Z" fill="#0D7C9E" />
                <path d="M123.233 119.355L121.932 118.055L111.993 127.994L113.294 129.294L123.233 119.355Z" fill="#0D7C9E" />
                <path d="M131.253 118.065L129.952 119.365L139.891 129.304L141.192 128.004L131.253 118.065Z" fill="#0D7C9E" />
                <path d="M141.187 119.351L139.887 118.051L129.948 127.99L131.248 129.29L141.187 119.351Z" fill="#0D7C9E" />
                <path d="M160.79 117.374C162.06 116.126 163.242 114.791 164.228 113.434C164.228 113.105 164.096 112.711 163.877 112.295C163.264 111.135 161.994 109.843 160.834 109.755C160.396 110.04 159.914 110.39 159.389 110.806C158.491 111.507 157.506 112.361 156.477 113.28C156.192 113.018 155.886 112.755 155.601 112.492C154.288 111.354 153.062 110.368 152.12 109.733C150.544 109.865 148.748 112.164 148.726 113.412C149.23 114.112 149.799 114.813 150.39 115.492C150.96 116.126 151.551 116.761 152.142 117.353C150.763 118.71 149.624 119.892 148.989 120.571C148.88 120.702 148.77 120.812 148.705 120.899C148.726 122.476 150.85 124.468 152.098 124.578C152.448 124.315 153.04 123.899 153.762 123.33C154.528 122.76 155.448 122.038 156.455 121.228C156.718 121.447 156.959 121.644 157.2 121.841C158.82 123.154 160.177 124.118 160.812 124.578C162.06 124.446 164.206 122.476 164.206 120.899C163.899 120.549 163.264 119.87 162.454 119.06C161.972 118.579 161.403 117.987 160.79 117.374Z" fill="#CBE8F1" />
                <path d="M158.885 117.045C158.885 117.024 158.863 117.024 158.841 117.002L158.819 116.98C158.797 116.936 158.754 116.87 158.71 116.826C158.71 116.805 158.688 116.805 158.688 116.783C158.119 116.235 157.506 115.644 156.893 115.097C156.652 114.878 156.28 114.878 156.039 115.097C155.426 115.666 154.813 116.235 154.243 116.783C154.221 116.805 154.221 116.805 154.2 116.826C154.156 116.87 154.134 116.914 154.112 116.958L154.09 116.98C154.068 117.002 154.068 117.024 154.046 117.024C153.827 117.286 153.827 117.702 154.09 117.965C154.747 118.578 155.404 119.169 156.039 119.695C156.301 119.914 156.674 119.914 156.914 119.695C157.549 119.169 158.206 118.578 158.863 117.965C159.104 117.724 159.126 117.308 158.885 117.045Z" fill="#B5DFEE" />
                <path d="M158.82 116.98C158.798 116.936 158.754 116.87 158.711 116.826C158.711 116.805 158.689 116.805 158.689 116.783C158.119 116.235 157.506 115.644 156.893 115.097C156.653 114.878 156.28 114.878 156.04 115.097C155.426 115.666 154.813 116.235 154.244 116.783C154.222 116.805 154.222 116.805 154.2 116.826C154.157 116.87 154.135 116.914 154.113 116.958C154.003 117.199 154.047 117.505 154.244 117.68C154.857 118.25 155.47 118.797 156.061 119.3C156.302 119.498 156.653 119.498 156.872 119.3C157.463 118.797 158.076 118.271 158.689 117.68C158.886 117.505 158.93 117.221 158.82 116.98Z" fill="#71C9EC" />
                <path d="M157.221 121.885C157.375 120.79 160.33 117.988 162.476 119.083C161.972 118.579 161.403 117.988 160.79 117.375C159.367 118.798 157.834 120.134 156.455 121.25C156.718 121.469 156.981 121.688 157.221 121.885Z" fill="#B5DFEE" />
                <path d="M155.58 112.513C155.427 113.848 152.602 116.738 150.369 115.512C150.938 116.147 151.53 116.782 152.121 117.373C153.434 116.081 154.967 114.615 156.456 113.279C156.171 113.016 155.886 112.754 155.58 112.513Z" fill="#B5DFEE" />
                <path d="M154.244 117.703C154.857 118.272 155.47 118.82 156.061 119.323C156.302 119.52 156.652 119.52 156.871 119.323C157.463 118.82 158.076 118.294 158.689 117.703C158.886 117.506 158.929 117.221 158.82 116.98C158.798 116.937 158.754 116.871 158.711 116.827C158.645 117.046 158.535 117.265 158.382 117.44C158.032 117.922 157.484 118.228 156.893 118.36C156.39 118.469 155.842 118.447 155.361 118.228C154.791 117.966 154.332 117.44 154.178 116.827C154.135 116.871 154.113 116.915 154.091 116.959C154.003 117.221 154.047 117.506 154.244 117.703Z" fill="#54C1EB" />
                <path d="M163.614 111.944C163.702 112.054 163.789 112.163 163.877 112.295C163.264 111.134 161.994 109.842 160.834 109.755C160.396 110.04 159.914 110.39 159.389 110.806C159.651 110.74 159.914 110.674 160.177 110.653C161.447 110.521 162.804 110.959 163.614 111.944Z" fill="#B5DFEE" />
                <path d="M152.383 123.417C152.34 123.373 152.274 123.351 152.23 123.351C152.186 123.351 152.164 123.351 152.143 123.373C151.486 123.242 150.873 122.957 150.347 122.563C149.712 122.059 149.253 121.381 149.012 120.614C148.902 120.746 148.793 120.855 148.727 120.943C148.749 122.519 150.873 124.511 152.121 124.621C152.471 124.358 153.062 123.942 153.785 123.373C153.325 123.46 152.865 123.482 152.383 123.417Z" fill="#B5DFEE" />
                <path d="M163.199 121.512C163.33 121.512 163.44 121.402 163.44 121.271C163.44 121.14 163.33 121.03 163.199 121.03C163.067 121.03 162.958 121.14 162.958 121.271C162.958 121.402 163.067 121.512 163.199 121.512Z" fill="#E3F3FA" />
                <path d="M162.148 120.46C162.279 120.46 162.389 120.351 162.389 120.219C162.389 120.088 162.279 119.979 162.148 119.979C162.017 119.979 161.907 120.088 161.907 120.219C161.907 120.351 161.995 120.46 162.148 120.46Z" fill="#E3F3FA" />
                <path d="M161.184 118.993C161.052 118.993 160.943 119.103 160.943 119.234C160.943 119.365 161.052 119.475 161.184 119.475C161.315 119.475 161.425 119.365 161.425 119.234C161.425 119.103 161.315 118.993 161.184 118.993Z" fill="#E3F3FA" />
                <path d="M161.994 122.695C162.126 122.695 162.235 122.585 162.235 122.454C162.235 122.322 162.126 122.213 161.994 122.213C161.863 122.213 161.753 122.322 161.753 122.454C161.753 122.585 161.863 122.695 161.994 122.695Z" fill="#E3F3FA" />
                <path d="M160.922 121.161C160.791 121.161 160.681 121.271 160.681 121.402C160.681 121.533 160.791 121.643 160.922 121.643C161.053 121.643 161.163 121.533 161.163 121.402C161.163 121.271 161.053 121.161 160.922 121.161Z" fill="#E3F3FA" />
                <path d="M159.98 120.176C159.849 120.176 159.739 120.285 159.739 120.417C159.739 120.548 159.849 120.657 159.98 120.657C160.111 120.657 160.221 120.548 160.221 120.417C160.221 120.285 160.111 120.176 159.98 120.176Z" fill="#E3F3FA" />
                <path d="M160.9 123.351C160.768 123.351 160.659 123.46 160.659 123.591C160.659 123.723 160.768 123.832 160.9 123.832C161.031 123.832 161.14 123.723 161.14 123.591C161.14 123.46 161.053 123.351 160.9 123.351Z" fill="#E3F3FA" />
                <path d="M159.849 122.301C159.717 122.301 159.608 122.41 159.608 122.542C159.608 122.673 159.717 122.782 159.849 122.782C159.98 122.782 160.09 122.673 160.09 122.542C160.09 122.41 159.98 122.301 159.849 122.301Z" fill="#E3F3FA" />
                <path d="M158.885 121.315C158.754 121.315 158.645 121.425 158.645 121.556C158.645 121.688 158.754 121.797 158.885 121.797C159.017 121.797 159.126 121.688 159.126 121.556C159.126 121.425 159.017 121.315 158.885 121.315Z" fill="#E3F3FA" />
                <path d="M154.266 113.214C154.398 113.214 154.507 113.105 154.507 112.973C154.507 112.842 154.398 112.732 154.266 112.732C154.135 112.732 154.025 112.842 154.025 112.973C154.025 113.105 154.135 113.214 154.266 113.214Z" fill="#E3F3FA" />
                <path d="M153.324 112.229C153.456 112.229 153.565 112.119 153.565 111.988C153.565 111.857 153.456 111.747 153.324 111.747C153.193 111.747 153.083 111.857 153.083 111.988C153.083 112.119 153.193 112.229 153.324 112.229Z" fill="#E3F3FA" />
                <path d="M153.061 114.397C153.193 114.397 153.302 114.287 153.302 114.156C153.302 114.025 153.193 113.915 153.061 113.915C152.93 113.915 152.82 114.025 152.82 114.156C152.82 114.287 152.93 114.397 153.061 114.397Z" fill="#E3F3FA" />
                <path d="M153.281 118.272C153.281 118.141 153.171 118.031 153.04 118.031C152.908 118.031 152.799 118.141 152.799 118.272C152.799 118.403 152.908 118.513 153.04 118.513C153.171 118.513 153.281 118.403 153.281 118.272Z" fill="#E3F3FA" />
                <path d="M153.412 120.417C153.412 120.285 153.303 120.176 153.172 120.176C153.04 120.176 152.931 120.285 152.931 120.417C152.931 120.548 153.04 120.657 153.172 120.657C153.303 120.657 153.412 120.548 153.412 120.417Z" fill="#E3F3FA" />
                <path d="M154.244 119.234C154.112 119.234 154.003 119.344 154.003 119.475C154.003 119.607 154.112 119.716 154.244 119.716C154.375 119.716 154.485 119.607 154.485 119.475C154.485 119.344 154.375 119.234 154.244 119.234Z" fill="#E3F3FA" />
                <path d="M153.303 122.301C153.171 122.301 153.062 122.41 153.062 122.542C153.062 122.673 153.171 122.782 153.303 122.782C153.434 122.782 153.544 122.673 153.544 122.542C153.544 122.41 153.434 122.301 153.303 122.301Z" fill="#E3F3FA" />
                <path d="M154.244 121.315C154.112 121.315 154.003 121.425 154.003 121.556C154.003 121.688 154.112 121.797 154.244 121.797C154.375 121.797 154.485 121.688 154.485 121.556C154.485 121.425 154.375 121.315 154.244 121.315Z" fill="#E3F3FA" />
                <path d="M155.338 120.374C155.207 120.374 155.098 120.483 155.098 120.615C155.098 120.746 155.207 120.856 155.338 120.856C155.47 120.856 155.579 120.746 155.579 120.615C155.579 120.483 155.47 120.374 155.338 120.374Z" fill="#E3F3FA" />
                <path d="M157.813 114.264C157.944 114.264 158.054 114.154 158.054 114.023C158.054 113.892 157.944 113.782 157.813 113.782C157.682 113.782 157.572 113.892 157.572 114.023C157.572 114.154 157.682 114.264 157.813 114.264Z" fill="#E3F3FA" />
                <path d="M158.864 113.214C158.995 113.214 159.105 113.105 159.105 112.973C159.105 112.842 158.995 112.732 158.864 112.732C158.733 112.732 158.623 112.842 158.623 112.973C158.623 113.105 158.733 113.214 158.864 113.214Z" fill="#E3F3FA" />
                <path d="M160.068 111.988C160.068 111.857 159.959 111.747 159.827 111.747C159.696 111.747 159.586 111.857 159.586 111.988C159.586 112.119 159.696 112.229 159.827 112.229C159.959 112.229 160.068 112.119 160.068 111.988Z" fill="#E3F3FA" />
                <path d="M161.14 111.024C161.14 110.893 161.031 110.783 160.9 110.783C160.768 110.783 160.659 110.893 160.659 111.024C160.659 111.155 160.768 111.265 160.9 111.265C161.031 111.265 161.14 111.177 161.14 111.024Z" fill="#E3F3FA" />
                <path d="M159.017 114.965C158.885 114.965 158.776 115.074 158.776 115.206C158.776 115.337 158.885 115.447 159.017 115.447C159.148 115.447 159.258 115.337 159.258 115.206C159.258 115.074 159.148 114.965 159.017 114.965Z" fill="#E3F3FA" />
                <path d="M160.089 114.397C160.22 114.397 160.33 114.287 160.33 114.156C160.33 114.025 160.22 113.915 160.089 113.915C159.958 113.915 159.848 114.025 159.848 114.156C159.848 114.287 159.936 114.397 160.089 114.397Z" fill="#E3F3FA" />
                <path d="M161.031 113.411C161.162 113.411 161.272 113.302 161.272 113.171C161.272 113.039 161.162 112.93 161.031 112.93C160.9 112.93 160.79 113.039 160.79 113.171C160.79 113.302 160.9 113.411 161.031 113.411Z" fill="#E3F3FA" />
                <path d="M162.126 112.47C162.257 112.47 162.366 112.36 162.366 112.229C162.366 112.098 162.257 111.988 162.126 111.988C161.994 111.988 161.885 112.098 161.885 112.229C161.885 112.36 161.994 112.47 162.126 112.47Z" fill="#E3F3FA" />
                <path d="M160.089 116.104C159.958 116.104 159.848 116.214 159.848 116.345C159.848 116.477 159.958 116.586 160.089 116.586C160.22 116.586 160.33 116.477 160.33 116.345C160.33 116.214 160.242 116.104 160.089 116.104Z" fill="#E3F3FA" />
                <path d="M160.921 115.294C160.921 115.425 161.031 115.534 161.162 115.534C161.294 115.534 161.403 115.425 161.403 115.294C161.403 115.162 161.294 115.053 161.162 115.053C161.031 115.053 160.921 115.162 160.921 115.294Z" fill="#E3F3FA" />
                <path d="M162.126 114.549C162.257 114.549 162.366 114.44 162.366 114.308C162.366 114.177 162.257 114.067 162.126 114.067C161.994 114.067 161.885 114.177 161.885 114.308C161.885 114.44 161.994 114.549 162.126 114.549Z" fill="#E3F3FA" />
                <path d="M163.199 113.608C163.33 113.608 163.44 113.498 163.44 113.367C163.44 113.235 163.33 113.126 163.199 113.126C163.067 113.126 162.958 113.235 162.958 113.367C162.958 113.498 163.067 113.608 163.199 113.608Z" fill="#E3F3FA" />
                <path d="M40.285 5.5387C45.0141 5.49491 49.7432 5.42923 54.4723 5.38544C56.0487 5.36355 57.6032 5.34166 59.1796 5.34166C59.9896 5.34166 60.9749 5.1665 61.7631 5.31976C63.5146 5.64817 64.04 8.05652 65.1347 9.2607C66.317 10.5525 68.0248 10.8809 69.5793 10.0489C70.5207 9.54532 73.8924 5.21029 71.5497 4.72862C70.2142 4.46589 69.7982 7.79379 68.5283 8.29736C66.4265 9.15123 65.5288 5.49491 64.4341 4.33453C62.7921 2.60489 60.2743 3.04278 58.1067 3.02088C56.399 2.99899 54.7132 2.97709 53.0054 2.9552C49.7213 2.91141 46.4372 2.88952 43.1312 2.84573C42.2554 2.84573 41.2921 2.71436 40.4163 2.82383C38.6867 3.02088 38.3583 5.5387 40.285 5.5387Z" fill="#7C7C7C" />
                <path opacity="0.25" d="M39.2559 3.50098C39.2778 4.09212 39.6719 4.61758 40.5695 4.61758C45.2987 4.57379 50.0278 4.5081 54.7569 4.46432C56.3333 4.44242 57.8878 4.42053 59.4641 4.42053C60.2742 4.42053 61.2594 4.24538 62.0476 4.39863C63.7992 4.72705 64.3246 7.1354 65.4193 8.33957C66.6016 9.63132 68.3093 9.95973 69.8638 9.12776C70.4112 8.84314 71.7467 7.28866 72.3598 5.93122C72.1627 7.39813 70.2579 9.67511 69.6011 10.0473C68.0466 10.8793 66.3389 10.5728 65.1566 9.25912C64.0838 8.07684 63.5364 5.6466 61.7849 5.31819C60.9967 5.16493 60.0115 5.34008 59.2014 5.34008C57.625 5.36197 56.0705 5.38387 54.4942 5.38387C49.765 5.42766 45.0359 5.49334 40.3068 5.53713C38.9275 5.53713 38.7085 4.31106 39.2559 3.50098Z" fill="black" />
                <path opacity="0.5" d="M66.4699 7.15764L69.557 7.13574C69.8635 7.13574 70.17 7.22332 70.4547 7.37658C73.4104 9.1281 78.7087 11.4708 82.8467 12.4998C83.613 12.6968 84.1604 13.3756 84.1604 14.1856V60.3165C84.1604 60.9733 83.7882 61.5864 83.1751 61.871L68.2215 69.0522L63.8645 69.0741L51.7352 61.8929C51.2098 61.5864 50.8813 61.0171 50.8813 60.4041V14.2294C50.8813 13.4193 51.4287 12.7187 52.2169 12.5436C58.0626 11.208 64.1711 8.16476 65.7037 7.37658C65.9226 7.24521 66.1853 7.17953 66.4699 7.15764Z" fill="#F9F9F9" />
                <path d="M63.8247 75.2422H68.1816V69.0681H63.8247V75.2422Z" fill="#BABABA" />
                <path opacity="0.15" d="M67.1053 38.7508C72.6883 39.7361 78.5559 39.1011 82.453 37.7437C82.9347 37.5685 83.4383 37.9188 83.4383 38.4443V58.7839C83.4383 60.1632 82.6501 61.4112 81.4021 62.0242L69.842 67.5634C68.7254 68.0889 67.5213 68.3735 66.2733 68.3954C64.7626 68.3954 63.2738 68.0013 61.9821 67.235L53.2901 62.0899C52.1954 61.4331 51.5386 60.2727 51.5386 59.0029V37.1088C51.5386 36.7147 51.8232 36.4081 52.2173 36.3644C55.9174 35.9703 61.3033 37.7218 67.1053 38.7508Z" fill="#02C0CC" />
                <path d="M64.9636 73.4248H67.0654V67.7542H64.9636V73.4248Z" fill="#4CB7F3" />
                <path opacity="0.55" d="M79.4106 16.156C79.8047 26.8622 76.4767 54.3831 73.9808 57.3169C71.4849 60.2507 80.8118 62.5934 81.2716 55.434C81.7313 48.2747 83.8769 17.185 81.9284 14.8424C80.9212 13.6163 79.3449 14.2731 79.4106 16.156Z" fill="white" />
                <path opacity="0.55" d="M78.4902 12.3901C76.3884 11.0545 70.6959 7.79232 69.8639 8.03316C69.0319 8.27399 69.3166 9.36869 72.6883 11.4705C76.06 13.5505 81.3145 14.2073 78.4902 12.3901Z" fill="white" />
                <path opacity="0.15" d="M53.5091 14.4915C53.3777 29.5328 56.7056 61.4325 67.7183 62.2645C77.5269 63.0089 81.3583 60.3159 84.1608 54.7767V60.3378C84.1608 60.9946 83.7886 61.6076 83.1756 61.8923L68.2219 69.0735H68.2V75.2477H63.8431V69.0954L51.7137 61.9142C51.1883 61.6076 50.8599 61.0384 50.8599 60.4254V14.2288C50.8599 13.4187 51.4072 12.7181 52.1954 12.543C51.9984 13.3312 53.5091 14.4915 53.5091 14.4915Z" fill="#B1B2B2" />
                <path opacity="0.25" d="M66.1421 46.0406C66.1421 46.5442 66.5581 46.9602 67.0616 46.9602C67.5652 46.9602 67.9812 46.5442 67.9812 46.0406C67.9812 45.5371 67.5652 45.1211 67.0616 45.1211C66.5581 45.1211 66.1421 45.5371 66.1421 46.0406Z" fill="white" />
                <path opacity="0.25" d="M73.5206 55.0823C74.0285 55.0823 74.4402 54.6706 74.4402 54.1627C74.4402 53.6549 74.0285 53.2432 73.5206 53.2432C73.0128 53.2432 72.6011 53.6549 72.6011 54.1627C72.6011 54.6706 73.0128 55.0823 73.5206 55.0823Z" fill="white" />
                <path opacity="0.25" d="M81.0732 48.1001C81.0732 48.6036 81.4892 49.0196 81.9928 49.0196C82.4964 49.0196 82.9123 48.6036 82.9123 48.1001C82.9123 47.5965 82.4964 47.1805 81.9928 47.1805C81.4892 47.1586 81.0732 47.5746 81.0732 48.1001Z" fill="white" />
                <path opacity="0.25" d="M63.0771 62.0688C63.0771 62.5724 63.4931 62.9884 63.9967 62.9884C64.5003 62.9884 64.9163 62.5724 64.9163 62.0688C64.9163 61.5653 64.5003 61.1493 63.9967 61.1493C63.4931 61.1274 63.0771 61.5434 63.0771 62.0688Z" fill="white" />
                <path opacity="0.25" d="M55.9614 49.4781C55.9614 49.9817 56.3774 50.3977 56.881 50.3977C57.3845 50.3977 57.8005 49.9817 57.8005 49.4781C57.8005 48.9746 57.3845 48.5586 56.881 48.5586C56.3774 48.5586 55.9614 48.9746 55.9614 49.4781Z" fill="white" />
                <path opacity="0.25" d="M64.6968 53.6822C64.6968 54.1858 65.1128 54.6018 65.6163 54.6018C66.1199 54.6018 66.5359 54.1858 66.5359 53.6822C66.5359 53.1787 66.1199 52.7627 65.6163 52.7627C65.1128 52.7627 64.6968 53.1787 64.6968 53.6822Z" fill="white" />
                <path opacity="0.25" d="M57.1001 40.3921C57.1001 40.8956 57.5161 41.3116 58.0196 41.3116C58.5232 41.3116 58.9392 40.8956 58.9392 40.3921C58.9392 39.8885 58.5232 39.4725 58.0196 39.4725C57.5161 39.4506 57.1001 39.8666 57.1001 40.3921Z" fill="white" />
                <path opacity="0.25" d="M53.4214 58.4781C53.4214 58.9817 53.8374 59.3977 54.3409 59.3977C54.8445 59.3977 55.2605 58.9817 55.2605 58.4781C55.2605 57.9746 54.8445 57.5586 54.3409 57.5586C53.8374 57.5586 53.4214 57.9746 53.4214 58.4781Z" fill="white" />
                <path opacity="0.25" d="M74.6154 63.3166C75.1232 63.3166 75.5349 62.9049 75.5349 62.3971C75.5349 61.8892 75.1232 61.4775 74.6154 61.4775C74.1075 61.4775 73.6958 61.8892 73.6958 62.3971C73.6958 62.9049 74.1075 63.3166 74.6154 63.3166Z" fill="white" />
                <path d="M69.5574 10.0274C70.4988 9.52383 73.8705 5.1888 71.5278 4.70713C70.1923 4.4444 69.7763 7.7723 68.5065 8.27587C66.4046 9.15163 68.0029 10.8594 69.5574 10.0274Z" fill="#7C7C7C" />
                <path opacity="0.25" d="M69.8425 9.12916C70.3899 8.84453 71.7254 7.29005 72.3385 5.93262C72.1414 7.39952 70.2585 9.7203 69.5798 10.0487C68.6384 10.4647 68.288 9.93924 69.8425 9.12916Z" fill="black" />
                <path d="M75.0529 188.242C75.25 188.242 75.4689 188.221 75.666 188.133C76.3228 187.892 76.6074 187.345 76.2571 186.863C76.1696 186.754 74.2429 184.148 69.0102 181.412C64.2592 178.916 55.7205 175.807 41.9929 175.15C41.2485 175.106 40.5917 175.5 40.5479 176.026C40.5041 176.551 41.0514 176.989 41.7958 177.033C54.7352 177.668 62.7704 180.536 67.2149 182.835C72.0097 185.331 73.805 187.695 73.8269 187.717C74.0896 188.045 74.5494 188.242 75.0529 188.242Z" fill="#B4CCC9" />
                <path d="M74.8565 195.751C78.3027 195.751 81.0963 193.81 81.0963 191.416C81.0963 189.022 78.3027 187.081 74.8565 187.081C71.4104 187.081 68.6167 189.022 68.6167 191.416C68.6167 193.81 71.4104 195.751 74.8565 195.751Z" fill="#6F7475" />
                <path d="M74.8566 194.416C77.2387 194.416 79.1697 193.073 79.1697 191.416C79.1697 189.76 77.2387 188.417 74.8566 188.417C72.4745 188.417 70.5435 189.76 70.5435 191.416C70.5435 193.073 72.4745 194.416 74.8566 194.416Z" fill="#585D5E" />
                <path d="M79.6511 186.295C79.6511 186.295 69.3828 186.623 69.0762 186.689C68.7478 186.755 72.7326 191.418 74.8782 191.418C77.0019 191.418 80.702 186.295 79.6511 186.295Z" fill="#B4CCC9" />
                <path opacity="0.25" d="M40.5915 175.872C40.7229 176.266 41.2045 176.551 41.8176 176.573C54.757 177.208 62.7921 180.076 67.2366 182.375C72.0314 184.871 73.8267 187.235 73.8486 187.257C74.0895 187.585 74.5711 187.761 75.0528 187.761C75.2499 187.761 75.4688 187.739 75.6659 187.651C76.0381 187.52 76.2789 187.301 76.3665 187.038C76.5197 187.454 76.2351 187.892 75.6659 188.111C75.4688 188.177 75.2499 188.22 75.0528 188.22C74.5711 188.22 74.0895 188.023 73.8486 187.717C73.8267 187.695 72.0314 185.33 67.2366 182.834C62.7921 180.536 54.757 177.645 41.8176 177.032C41.0732 176.989 40.5039 176.551 40.5696 176.025C40.5477 175.982 40.5696 175.916 40.5915 175.872Z" fill="black" />
                <path opacity="0.25" d="M69.0537 186.688C69.0756 186.688 69.1631 186.688 69.2726 186.666C70.017 187.761 73.126 191.067 74.9213 191.067C76.629 191.067 79.3439 187.805 79.8256 186.491C79.9131 187.389 76.7604 191.439 74.8556 191.439C72.7319 191.417 68.7253 186.754 69.0537 186.688Z" fill="black" />
                <path opacity="0.25" d="M70.5868 190.958C70.6524 192.578 72.5572 193.87 74.8999 193.87C77.0674 193.87 78.8408 192.775 79.1692 191.33C79.1692 191.352 79.1692 191.396 79.1692 191.418C79.1692 193.082 77.2426 194.417 74.8561 194.417C72.4696 194.417 70.543 193.082 70.543 191.418C70.543 191.265 70.5649 191.111 70.5868 190.958Z" fill="black" />
                <path opacity="0.25" d="M74.8561 195.248C78.184 195.248 80.8989 193.453 81.0959 191.176C81.0959 191.263 81.1178 191.351 81.1178 191.439C81.1178 193.825 78.3154 195.774 74.878 195.774C71.4406 195.774 68.6382 193.825 68.6382 191.439C68.6382 191.351 68.6382 191.263 68.6601 191.176C68.8133 193.431 71.5282 195.248 74.8561 195.248Z" fill="black" />
                <path d="M6.06416 188.242C5.86712 188.242 5.64818 188.221 5.45113 188.133C4.79431 187.892 4.50968 187.345 4.85999 186.863C4.94756 186.754 6.87425 184.148 12.1069 181.412C16.858 178.937 25.3967 175.829 39.1024 175.15C39.8468 175.106 40.5036 175.5 40.5474 176.026C40.5912 176.551 40.0438 176.989 39.2994 177.033C26.36 177.668 18.3249 180.536 13.8804 182.835C9.08555 185.331 7.29023 187.695 7.26834 187.717C7.0275 188.045 6.54583 188.242 6.06416 188.242Z" fill="#B4CCC9" />
                <path d="M6.23982 195.751C9.68597 195.751 12.4796 193.81 12.4796 191.416C12.4796 189.022 9.68597 187.081 6.23982 187.081C2.79366 187.081 0 189.022 0 191.416C0 193.81 2.79366 195.751 6.23982 195.751Z" fill="#6F7475" />
                <path d="M6.23941 194.416C8.62149 194.416 10.5525 193.073 10.5525 191.416C10.5525 189.76 8.62149 188.417 6.23941 188.417C3.85733 188.417 1.92627 189.76 1.92627 191.416C1.92627 193.073 3.85733 194.416 6.23941 194.416Z" fill="#585D5E" />
                <path d="M1.46653 186.295C1.46653 186.295 11.7349 186.623 12.0414 186.689C12.3698 186.755 8.38506 191.418 6.23944 191.418C4.11571 191.418 0.393717 186.295 1.46653 186.295Z" fill="#B4CCC9" />
                <path opacity="0.25" d="M40.5265 175.872C40.3951 176.266 39.9135 176.551 39.3004 176.573C26.361 177.208 18.3259 180.076 13.8814 182.375C9.08657 184.871 7.29126 187.235 7.26936 187.257C7.02853 187.585 6.54686 187.761 6.06519 187.761C5.86814 187.761 5.6492 187.739 5.45215 187.651C5.07995 187.52 4.83912 187.301 4.75154 187.038C4.59828 187.454 4.88291 187.892 5.45215 188.111C5.6492 188.177 5.86814 188.22 6.06519 188.22C6.54686 188.22 7.02853 188.023 7.26936 187.717C7.29126 187.695 9.08657 185.33 13.8814 182.834C18.3259 180.536 26.361 177.645 39.3004 177.032C40.0448 176.989 40.6141 176.551 40.5484 176.025C40.5484 175.982 40.5265 175.916 40.5265 175.872Z" fill="black" />
                <path opacity="0.25" d="M12.0417 186.688C12.0199 186.688 11.9323 186.688 11.8228 186.666C11.0784 187.761 7.96945 191.067 6.17413 191.067C4.46639 191.067 1.75152 187.805 1.26985 186.491C1.18228 187.389 4.33503 191.439 6.23981 191.439C8.38543 191.417 12.3702 186.754 12.0417 186.688Z" fill="black" />
                <path opacity="0.25" d="M10.5088 190.958C10.4431 192.578 8.53829 193.87 6.19562 193.87C4.0281 193.87 2.25468 192.775 1.92627 191.33C1.92627 191.352 1.92627 191.396 1.92627 191.418C1.92627 193.082 3.85295 194.417 6.23941 194.417C8.62586 194.417 10.5525 193.082 10.5525 191.418C10.5525 191.265 10.5525 191.111 10.5088 190.958Z" fill="black" />
                <path opacity="0.25" d="M6.23982 195.248C2.91191 195.248 0.197047 193.453 0 191.176C0 191.242 0 191.329 0 191.417C0 193.803 2.80244 195.752 6.23982 195.752C9.67719 195.752 12.4796 193.803 12.4796 191.417C12.4796 191.329 12.4796 191.242 12.4577 191.154C12.2826 193.431 9.56772 195.248 6.23982 195.248Z" fill="black" />
                <path d="M41.1389 13.0918H38.3584V173.488H41.1389V13.0918Z" fill="#B4CCC9" />
                <path opacity="0.25" d="M39.1028 13.0918H38.3584V173.488H39.1028V13.0918Z" fill="black" />
                <path d="M40.6569 13.639H39.2338C37.4604 13.639 36.0154 12.194 36.0154 10.4206V3.21746C35.9935 1.44403 37.4385 -0.000976562 39.2119 -0.000976562H40.635C42.4085 -0.000976562 43.8535 1.44403 43.8535 3.21746V10.4206C43.8754 12.2159 42.4303 13.639 40.6569 13.639Z" fill="#91B7B1" />
                <path d="M40.8976 179.488H38.971C36.8253 179.488 35.0957 177.758 35.0957 175.613V173.883C35.0957 171.737 36.8253 170.008 38.971 170.008H40.8976C43.0433 170.008 44.7729 171.737 44.7729 173.883V175.613C44.7729 177.736 43.0433 179.488 40.8976 179.488Z" fill="#91B7B1" />
                <path opacity="0.25" d="M37.7672 179.487H42.0803C43.5691 179.487 44.7514 178.655 44.7514 177.626V174.145C44.2478 176.838 42.3212 178.239 40.3726 178.086C37.7234 177.845 35.3369 173.554 36.5849 170.204C35.6873 170.511 35.0742 171.146 35.0742 171.868V177.626C35.0961 178.633 36.3003 179.487 37.7672 179.487Z" fill="black" />
                <path opacity="0.25" d="M37.7452 13.6397H42.124C43.0873 13.6397 43.8755 12.8515 43.8755 11.8882V8.82303C43.372 10.268 42.3867 11.9101 40.46 11.9101C37.3511 11.9101 36.7162 4.07202 36.7381 0.328125C36.2783 0.634642 35.9937 1.1601 35.9937 1.75124V11.9101C35.9937 12.8734 36.7818 13.6397 37.7452 13.6397Z" fill="black" />
                <path opacity="0.25" d="M38.8618 0.918438C39.3654 0.480556 42.124 0.130251 42.9122 1.31253C43.7223 2.49481 43.5033 9.26009 41.4453 9.76365C39.3873 10.2891 38.3363 8.23107 38.205 5.5162C38.0955 2.82322 38.2488 1.48768 38.8618 0.918438Z" fill="white" />
                <path d="M45.5841 200.811C49.0302 200.811 51.8239 198.87 51.8239 196.476C51.8239 194.081 49.0302 192.141 45.5841 192.141C42.1379 192.141 39.3442 194.081 39.3442 196.476C39.3442 198.87 42.1379 200.811 45.5841 200.811Z" fill="#6F7475" />
                <path d="M45.5836 199.476C47.9657 199.476 49.8968 198.133 49.8968 196.476C49.8968 194.819 47.9657 193.477 45.5836 193.477C43.2016 193.477 41.2705 194.819 41.2705 196.476C41.2705 198.133 43.2016 199.476 45.5836 199.476Z" fill="#585D5E" />
                <path d="M45.4089 191.923C44.6207 191.923 43.9639 191.485 43.9639 190.937C43.8106 183.318 39.388 178.129 39.3224 178.085C38.9064 177.604 39.1253 176.991 39.804 176.706C40.4827 176.422 41.3804 176.575 41.7964 177.035C41.9934 177.253 46.6788 182.727 46.8539 190.894C46.8539 191.463 46.219 191.923 45.4089 191.923Z" fill="#A1B7B4" />
                <path d="M45.1458 191.923C44.4452 191.923 43.8541 191.485 43.8541 190.938C43.7227 183.428 39.7599 178.283 39.7161 178.239C39.3439 177.78 39.5409 177.167 40.154 176.882C40.767 176.597 41.5552 176.751 41.9274 177.21C42.1026 177.429 46.3062 182.815 46.4376 190.916C46.4595 191.463 45.8902 191.923 45.1458 191.923Z" fill="#CCE2DE" />
                <path d="M50.7294 190.74C50.7294 190.74 40.461 190.696 40.1326 190.762C39.8042 190.806 43.4386 195.622 45.5843 195.688C47.708 195.754 51.8022 190.784 50.7294 190.74Z" fill="#B4CCC9" />
                <path opacity="0.25" d="M45.5839 195.294C43.8104 195.229 40.9861 191.879 40.2855 190.74C40.2198 190.74 40.1541 190.74 40.1541 190.74C39.8257 190.784 43.4601 195.601 45.6057 195.666C47.6419 195.732 51.4077 191.244 50.8822 190.762C50.4224 192.01 47.3354 195.36 45.5839 195.294Z" fill="black" />
                <path opacity="0.25" d="M45.5836 198.907C43.3504 198.907 41.4894 197.725 41.2924 196.192C41.2705 196.28 41.2705 196.389 41.2705 196.477C41.2705 198.141 43.1972 199.476 45.5836 199.476C47.9701 199.476 49.8968 198.141 49.8968 196.477C49.8968 196.389 49.8968 196.28 49.8749 196.192C49.6778 197.703 47.8387 198.907 45.5836 198.907Z" fill="black" />
                <path opacity="0.25" d="M45.5841 200.089C42.3218 200.089 39.6289 198.337 39.3661 196.126C39.3442 196.235 39.3442 196.367 39.3442 196.498C39.3442 198.885 42.1467 200.833 45.5841 200.833C49.0214 200.833 51.8239 198.885 51.8239 196.498C51.8239 196.367 51.8239 196.257 51.802 196.126C51.5393 198.337 48.8682 200.089 45.5841 200.089Z" fill="black" />
            </g>
            <defs>
                <clipPath id="clip0_17891_51169">
                    <rect width="215" height="200.813" fill="white" />
                </clipPath>
            </defs>
        </svg>

    )
}