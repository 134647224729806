import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "./i118";
import * as Sentry from "@sentry/react";

//only on beta and prod
if ( process.env.REACT_APP_ENV !== "DEV" ) {
    Sentry.init( {
        dsn: process.env.REACT_APP_SENTRY_DSN,
        beforeSend( event, hint ) {
            // Check if it is an exception, and if so, show the report dialog
            if ( event.exception && event.event_id ) {
                Sentry.showReportDialog( { eventId: event.event_id } );
            }
            return event;
        },

        environment: `DOCTOR-${ process.env.REACT_APP_ENV }`,
        integrations: [
            /*NOTES : this will add a button at the bottom level for the user to report a bug
            // Sentry.feedbackIntegration( {
            //   // Additional SDK configuration goes in here, for example:
            //   colorScheme: "system",
            } ),
          */
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration( {
                maskAllText: false,
                blockAllMedia: false,
            } ),
        ],
        tracePropagationTargets: [ /^https:\/\/beta-doctors\.meetamed\.com\//, /^https:\/\/meetamed\.com\// ],
        // Performance Monitoring
        tracesSampleRate: 0.8, //capture 80% of the transactions
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    } );

}

const root = ReactDOM.createRoot( document.getElementById( "root" ) );
root.render( <App /> );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
