//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  useLocation,
  useOutletContext,
  useNavigate,
} from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
//COMPONENT
import { Grid, IconButton } from "@mui/material";
import GazCard from "../../components/cards/GazetteCard";
import AddIcon from "@mui/icons-material/Add";
import { NoData } from "../../components/Fallback/NoData";
import { BlogsSkeleton } from "../../components/skeletons/SkeletonsComponent";
import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";
//CSS
import "./Gazette.scss";
//API
import { getBlogs, getUserBlogs } from "../../core/services/blogs";
import { dataPerPage } from "../../core/variables/Variables";
import { PlusIcon } from "../../assets/icons/SVG";
import Button from "../../components/button/Button";

const GazetteList = ( props ) => {
  const { type } = props;
  const Alert = useContext( AlertContext );
  let navigate = useNavigate();

  //VARIABLES
  const location = useLocation().pathname.split( "/" );
  const [ gazettes, setGazettes ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );
  const { search, sort } = useOutletContext( {} ) || {};

  //FUNCTIONS
  const handleBack = () => {
    navigate( `../add-blog` );
  };

  const getAllGazettes = ( new_page ) => {
    let handleLoad = new_page ? setIsLoadMore : setIsLoading;
    handleLoad( true );
    let handleApi = type === "gazettes" ? getBlogs : getUserBlogs;

    let current_page =
      new_page && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;

    handleApi( {
      is_gazette: true,
      page: current_page,
      per_page: dataPerPage,
      sort: sort,
      name: search,
      ...( type === "Mygazettes"
        ? { status_id: 1 /*published*/ }
        : type === "drafts"
          ? { status_id: 2 }
          : "" ),
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setPage( res?.data?.data?.current_page );
        if ( new_page ) {
          setGazettes( [ ...gazettes, ...res?.data?.data?.data ] );
        } else {
          setGazettes( res.data?.data?.data || [] );
        }

        setTotalPage( res.data.data?.last_page );
      } else {
        Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      }
      handleLoad( false );
    } );
  };

  useEffect( () => {
    getAllGazettes( false );
  }, [ type, search, sort ] );
  return (
    <div style={ { width: "100%" } }>
      <div className="addIcon">
        <Button
          isSelected
          name="NEW_BLOG"
          variant="contained"
          component={ Link }
          to="../add-blog"
          startIcon={ <PlusIcon size="13" /> }
        />


      </div>

      { isLoading ? (
        <BlogsSkeleton count={ 3 } />
      ) : gazettes?.length === 0 ? (
        <NoData title={ "NO_BLOGS_FOUND" } />
      ) : (
        <>
          <Grid container rowSpacing={ 1 } spacing={ 1 }>
            { gazettes?.map( ( e, index ) => (
              <Grid item xs={ 6 } sm={ 4 } md={ 3 } key={ index }>
                {/* normally each row is about 12 columns  => 12/6 => 2 items per row */ }
                <Link
                  style={ { color: "black" } }
                  className={ "link" }
                  to={ `../blogs/${ e?.id }` }
                  state={ {
                    data: gazettes,
                  } }
                >
                  <GazCard
                    data={ e }
                    img={ e?.featured_image }
                    title={ e?.title }
                    tags={ e?.tags }
                    parag={ e?.description }
                    profile={ e?.user?.profile_image }
                    name={ e?.user?.first_name }
                    date={ e?.created_at }
                  />
                </Link>
              </Grid>
            ) ) }
          </Grid>
          { page < totalPage && !isLoading && (
            <LoadMoreButton
              isLoadMore={ isLoadMore }
              title="LOAD_MORE"
              onClick={ () => getAllGazettes( true ) }
            />
          ) }
        </>
      ) }
    </div>
  );
};

export default GazetteList;
