
import * as MUI from "@mui/material"
import Draggable from 'react-draggable';
export function PaperComponent( props: PaperProps ) {
    return (
        <Draggable
            handle="#draggable-dialog-title"
            cancel={ '[class*="MuiDialogContent-root"]' }
        >
            <MUI.Paper { ...props } style={ { overflow: 'scroll' } } />
        </Draggable>
    );
}