//UTILITIES
import React, { useState } from "react";
import PropTypes from "prop-types";
//COMPONENT
import * as MUI from "@mui/material";
import { useTranslation } from "react-i18next";
import palette from "../../theme/color.scss";

import { IconsButton } from "../button/Button";
import { DeleteSVG, DownArrow, EditIcon, EyeSVG } from "../../assets/icons/SVG";

//API
//CSS
import "./TableComponent.scss";

export function RowComponent(props) {
  const { handleHover, onAdd, onEdit, onDelete, onView } = props;
  const { collapseComponent, colSpan, row, children, actions, collapse } =
    props;

  //TRANSLATION
  const [t] = useTranslation("common");
  //VARIABLES

  const [open, setOpen] = useState(false);

  return (
    <>
      <MUI.TableRow
        className={handleHover ? "cursor-pointer tableRow" : "tableRow"}
        hover={handleHover ? true : false}
        onClick={handleHover ? (e) => handleHover(row?.id) : null}
        key={row?.id}
      >
        {children}

        {collapse && (
          <MUI.TableCell>
            <IconsButton
              aria-label="expand row"
              size="small"
              icon={open ? <DownArrow /> : <DownArrow />}
              onClick={() => {
                setOpen(!open);
              }}
            />
          </MUI.TableCell>
        )}
        {actions && (
          <MUI.TableCell
            align={onAdd ? "left" : "right"}
            onClick={(event) => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            {onEdit && (
              <IconsButton
                icon={<EditIcon className="editIcon" />}
                onClick={(event) => {
                  onEdit(row);
                }}
              />
            )}
            {onDelete && (
              <IconsButton
                icon={<DeleteSVG className="editIcon" />}
                onClick={(event) => {
                  onDelete(row);
                }}
              />
            )}

            {onView && (
              <IconsButton
                icon={
                  <EyeSVG className="editIcon" fill={palette.primaryColor} />
                }
                onClick={(event) => {
                  onView(row);
                }}
              />
            )}
          </MUI.TableCell>
        )}
      </MUI.TableRow>
      {open && (
        <MUI.TableRow className="collapse-row">
          <MUI.TableCell colSpan={colSpan}>
            <MUI.Collapse in={open} timeout="auto" unmountOnExit>
              {collapseComponent}
            </MUI.Collapse>
          </MUI.TableCell>
        </MUI.TableRow>
      )}
    </>
  );
}

RowComponent.defaultProps = {
  collapse: false,
  actions: true,
};
RowComponent.propTypes = {
  row: PropTypes.element,
  collapse: PropTypes.bool,
  actions: PropTypes.bool,
  handleHover: PropTypes.func,
  onAdd: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onComplete: PropTypes.func,
  onCancel: PropTypes.func,
  onView: PropTypes.func,
  modelType: PropTypes.array,
  collapse: PropTypes.bool,
  onExport: PropTypes.bool,
  headers: PropTypes.array,
  collapseChildren: PropTypes.array,
  requestSort: PropTypes.func,
};

export default RowComponent;
