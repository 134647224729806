//UTILITIES
import React, { useEffect, useState } from "react";
import { useLocation, useParams, useNavigate, Outlet } from "react-router-dom";
import "./CompaniesDetails.scss";
import { useTranslation } from "react-i18next";
//COMPONENT
import GroupDefaultImage from "../../assets/images/group-default-image.png";
import GroupDefaultCover from "../../assets/images/defaultCover.png";
import { getCompanyById } from "../../core/services/companies";
import { Tabs, Tab, CardMedia } from "@mui/material";
import BackRep from "../../components/back-repository/BackRep";

const CompaniesDetails = () => {
  const [ t ] = useTranslation( "common" );

  const { id } = useParams() || {};

  let navigate = useNavigate();

  const location = useLocation().pathname.split( "/" );

  const [ data, setData ] = useState( [] );
  const handleChange = ( e ) => navigate( `${ e.target.name }/${ id }` );

  const getCompany = () => {
    getCompanyById( id ).then( ( res ) => {
      setData( res?.data?.data?.company );
    } );
  };
  useEffect( () => {
    getCompany();
  }, [] );
  return (
    <>
      <div className="companies-container">
        <BackRep onClick={ () => navigate( -1 ) } title={ t( "COMPANY" ) } />

        <div className="companies-about-group">
          <div className="companies-cover">
            <CardMedia
              component="img"
              height="200"
              image={
                data?.cover_picture
                  ? process.env.REACT_APP_IMAGE_URL + data.cover_picture
                  : GroupDefaultCover
              }
              alt=""
            />
            <div className="cover-image-absolute">
              <CardMedia
                component="img"
                height="60"
                image={
                  data.profile_picture
                    ? process.env.REACT_APP_IMAGE_URL + data.profile_picture
                    : GroupDefaultImage
                }
                className="cover-image-absolute"
                alt=""
              />
            </div>
          </div>
          <div className="title-body">
            <div className="about-title">
              <h2>{ data.name } </h2>
            </div>

            <p>{ data?.activity_domain_description }</p>

          </div>
          <div className="button-group">
            <Tabs
              value={ location?.[ 3 ] }
              onChange={ handleChange }
              textColor="primary"
              aria-label="secondary tabs example"
              className="tabsCompo"
            >
              <Tab
                sx={ { fontSize: 18 } }
                to={ `about/${ id }` }
                value="about"
                name="about"
                label={ t( "ABOUT" ) }
              />
              <Tab
                sx={ { fontSize: 18 } }
                to={ `group/${ id }` }
                value="groups"
                name="groups"
                label={ t( "GROUPS" ) }
              />
            </Tabs>
          </div>
        </div>

        <Outlet />
      </div>

    </>
  );
};

export default CompaniesDetails;
