//UTILITIES
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { Avatar, Skeleton } from "@mui/material";
import { Link } from "react-router-dom";
//COMPONENT
import { NotificationContext } from "../../context/NotificationContext";
import { acceptMeeting, useMeetingRequest } from "../../core/services/schedule";

import {
  NoGroupsInvitations,
  NoMeetings,
} from "../../components/Fallback/NoData";
import { Event } from "../../core/tracking/tracking";
import { CardSkeleton } from "../../components/skeletons/SkeletonsComponent";
import { useMediaQuery } from "react-responsive";
import {
  ClockSVG,
  ConnectionsSVG,
  VideoSVG,
} from "../../assets/icons/SVG";
import CancelMeetingHandle from "../meetings/CancelMeetingHandle";
import RingSVG from "../../assets/icons/RingSVG";
import * as groupsData from "../../core/services/groups";
import { AlertContext } from "../../context/AlertContext";
import Button from "../../components/button/Button";
import {
  DisplayDateTime,
  DisplayTooltip,
} from "../../components/display-component/DisplayComponent";
import { PermissionContext } from "../../context/PermissionContext";

export const MeetingRequests = ( props ) => {
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );
  const { updateHomeData } = props;

  return (
    !isSmallScreen && (
      <div className="rightBar">
        <MeetingRequest />
        <GroupsSuggestion
          link={ `../groups/discover/suggested` }
          updateHomeData={ updateHomeData }
        />
        <GroupsInvitation
          link={ `../groups/invitations` }
          updateHomeData={ updateHomeData }
        />
      </div>
    )
  );
};

export const GroupsSide = () => {
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );

  return (
    !isSmallScreen && (
      <div className="rightBar">
        <GroupsSuggestion link={ `../discover/suggested` } />
        <GroupsInvitation link={ `../invitations` } />
      </div>
    )
  );
};

const Card = ( props ) => {
  const { isLoading, children, title, icon } = props;
  const [ t ] = useTranslation( "common" );

  return (
    <div className="meetingCard">
      { isLoading ? (
        <LoadingCard />
      ) : (
        <div className="title">
          { t( title ) } { icon }
        </div>
      ) }
      <div className="rightBar-con" style={ { overflow: "overlay" } }>
        { isLoading ? <CardSkeleton count={ 1 } /> : children }
      </div>
    </div>
  );
};
const LoadingCard = () => {
  return (
    <div className="LoadingCard">
      <Skeleton
        animation="wave"
        variant="rounded"
        sx={ { height: "20px", width: "30%" } }
      />
      <Skeleton
        animation="wave"
        variant="rounded"
        sx={ { height: "20px", width: "10%" } }
      />
    </div>
  );
};

const ElementRow = ( props ) => {
  const { title, subtitle, hint, children, last, profile_image } = props;
  return (
    <>
      <div className="ElementRow">
        { profile_image && (
          <Avatar
            className="profile-image"
            alt={ title }
            src={ process.env.REACT_APP_IMAGE_URL + profile_image }
          />
        ) }
        <div className="element">
          <DisplayTooltip
            placement="left-start"
            title={
              hint ? (
                <div className="meeting-tooltip">
                  <p>{ hint }</p>
                </div>
              ) : (
                ""
              )
            }
          >
            <p className="Name">
              <strong>{ title }</strong>
              <br />
              <small>{ subtitle }</small>
            </p>
          </DisplayTooltip>
          { children }{ " " }
        </div>
      </div>
      { last ? null : <hr /> }
    </>
  );
};
const MeetingRequest = () => {
  const { permissions } = useContext( PermissionContext );
  const { updateNotificationCount } = useContext( NotificationContext );
  const [ t ] = useTranslation( "common" );
  const alert = useContext( AlertContext );
  const [ loadingAccept, setLoadingAccept ] = useState( false );
  const [ openRejectMeeting, setOpenRejectMeeting ] = useState( false );
  let payload = {
    activity_type_id: 1,
    per_page: 2,
    page: 1,
  };
  const { data, isLoading, refetch } = useMeetingRequest( payload );

  const handleConfirm = ( meeting ) => () => {
    setLoadingAccept( true );
    acceptMeeting( meeting.id ).then( ( res ) => {
      alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      refetch();
      setLoadingAccept( false );
      updateNotificationCount();
      if ( res?.data?.data === 1 )
        Event( "Meeting", `Confirm_Meeting_${ meeting.id }`, "HOME_PAGE_REQUESTS" );
    } );
  };

  return (
    <>
      <Card
        isLoading={ isLoading }
        title="MEETINGS_REQUESTS"
        icon={ <VideoSVG width={ 20 } height={ 20 } /> }
      >
        { data?.total === 0 ? (
          <NoMeetings title="NO_MEETING_REQUESTS" />
        ) : (
          <>
            { data?.data?.map( ( el, index ) => (
              <ElementRow
                key={ el.id }
                last={ data?.data?.length === index + 1 }
                title={ el.title }
                subtitle={
                  <>
                    <ClockSVG />{ " " }
                    <DisplayDateTime
                      data={ el?.start_date_time }
                      format="DD/MM/YYYY HH:mm"
                    />
                  </>
                }
                hint={ `${ t( "WITH" ) } ${ el?.user?.first_name } ${ el?.user?.last_name
                  }  ${ t( "FROM" ) } ${ el?.user?.pharmaceutical_company?.name } ` }
              >
                { openRejectMeeting?.[ el?.id ] && (
                  <CancelMeetingHandle
                    onClose={ () => setOpenRejectMeeting( false ) }
                    onSuccess={ () => refetch() }
                    homePage
                    id={ el?.id }
                  />
                ) }
                { ( permissions?.Meeting?.[ "accept-meeting" ] ||
                  permissions?.Meeting?.[ "cancel-meeting" ] ) && (
                    <div className="buttons">
                      { permissions?.Meeting?.[ "cancel-meeting" ] && (
                        <Button
                          onClick={ () => setOpenRejectMeeting( { [ el?.id ]: true } ) }
                          width="45%"
                          name="REJECT"
                          style={ { padding: "0px" } }
                        />
                      ) }

                      { permissions?.Meeting?.[ "accept-meeting" ] && (
                        <Button
                          loading={ loadingAccept }
                          disabled={ loadingAccept }
                          onClick={ handleConfirm( el ) }
                          width="45%"
                          style={ { padding: "0px" } }
                          isSelected
                          name="CONFIRM"
                        />
                      ) }
                    </div>
                  ) }
              </ElementRow>
            ) ) }
          </>
        ) }
      </Card>
      { data?.total > 2 && (
        <Link className="view-all" to={ `../meetings/requests` }>
          { `${ t( "VIEW_ALL_REQUEST" ) } ` }
        </Link>
      ) }
    </>
  );
};
export const GroupsSuggestion = ( props ) => {
  const { permissions } = useContext( PermissionContext );
  const { link, updateHomeData } = props;
  const alert = useContext( AlertContext );
  const [ t ] = useTranslation( "common" );
  const { data, refetch } = groupsData.useSuggestedGroups( {
    per_page: 2,
    page: 1,
  } );
  const onJoin = ( id ) => () => {
    groupsData.joinGroup( id ).then( ( res ) => {
      alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      if ( res?.data?.success ) {
        refetch();
        updateHomeData();
      }
    } );
  };
  return (
    <>
      <Card
        title={ t( "SUGGESTED_GROUPS" ) }
        icon={ <RingSVG width={ 20 } height={ 20 } /> }
      >
        { !data?.data?.data?.length ? (
          <NoGroupsInvitations />
        ) : (
          data?.data?.data?.map( ( el, index ) => (
            <ElementRow
              title={ el.title }
              subtitle={ `${ el?.users_count } ${ t( "MEMBERS" ) }` }
              profile_image={ el?.profile_image }
              key={ el.id }
              last={ data?.data?.data.length === index + 1 }
            >
              { permissions?.Group?.[ "join-company-group" ] && (
                <Button
                  name="JOIN"
                  onClick={ onJoin( el.id ) }
                  size="small"
                  style={ { padding: "0px" } }
                  isSelected
                />
              ) }
            </ElementRow>
          ) )
        ) }
      </Card>
      { data?.data?.total > 2 && (
        <Link className="view-all" to={ link }>
          { `${ t( "VIEW_ALL_GROUPS" ) } ` }
        </Link>
      ) }
    </>
  );
};
export const GroupsInvitation = ( props ) => {
  const { link, updateHomeData } = props;
  const { permissions } = useContext( PermissionContext );
  const { updateNotificationCount } = useContext( NotificationContext );

  const alert = useContext( AlertContext );
  const [ loadingAction, setLoadingAction ] = useState( {
    accept: false,
    reject: false,
  } );
  const [ t ] = useTranslation( "common" );
  const { data, refetch, isLoading } = groupsData.useInvitedGroups( {
    per_page: 2,
    page: 1,
  } );
  const onAccept = ( id ) => () => {
    setLoadingAction( { ...loadingAction, accept: true } );
    let data = {
      group_id: id,
      is_accepted: true,
    };
    groupsData.AcceptOrReject( data ).then( ( res ) => {
      alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      refetch();
      updateHomeData();
      updateNotificationCount();
      setLoadingAction( { ...loadingAction, accept: false } );
    } );
  };

  const onReject = ( id ) => () => {
    setLoadingAction( { ...loadingAction, reject: true } );
    let data = {
      group_id: id,
      is_accepted: false,
    };
    groupsData.AcceptOrReject( data ).then( ( res ) => {
      if ( res?.data?.success ) {
        refetch();
        updateNotificationCount();
      }
      alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      setLoadingAction( { ...loadingAction, reject: false } );
    } );
  };
  return (
    <>
      <Card
        title="GROUP_INVITATION"
        icon={ <ConnectionsSVG color="#71A2A7" width={ 20 } height={ 20 } /> }
      >
        { !data?.data?.data?.length ? (
          <NoGroupsInvitations />
        ) : (
          <>
            { data?.data?.data?.map( ( el, index ) => (
              <ElementRow
                title={ el.title }
                profile_image={ el?.profile_image }
                subtitle={ el?.pharmaceutical_company?.name }
                key={ el.id }
                last={ data?.data?.data.length === index + 1 }
              >
                { permissions?.Group?.[ "accept-or-reject-group-invitation" ] && (
                  <div className="buttons">
                    <Button
                      style={ { padding: "0px" } }
                      name="REJECT"
                      disabled={ loadingAction?.reject }
                      loading={ loadingAction?.reject }
                      onClick={ onReject( el.id ) }
                      size="small"
                    />
                    <Button
                      name="CONFIRM"
                      disabled={ loadingAction?.accept }
                      loading={ loadingAction?.accept }
                      onClick={ onAccept( el.id ) }
                      size="small"
                      isSelected
                      style={ { padding: "0px" } }
                    />
                  </div>
                ) }
              </ElementRow>
            ) ) }
          </>
        ) }
      </Card>
      { data?.data?.total > 2 && (
        <Link className="view-all" to={ link }>
          { `${ t( "VIEW_ALL_INVITATIONS" ) } ` }
        </Link>
      ) }
    </>
  );
};
