import * as React from "react";
import "./PrimaryButton.scss";
import { useTranslation } from "react-i18next";

export default function BasicButtons(props) {
  const {} = props;
  const [t] = useTranslation("common");

  return (
    <div className="btn-container">
      <div className="btn-primary">
        <button
          style={{ width: props.width }}
          type={props.type}
          onClick={props.onClick}
          className={props.loading ? "btn disabled" : `btn ${props.className}`}
          disabled={props.disabled}
        >
          {props.loading ? "Loading ..." : t(props.title)}
        </button>
      </div>
    </div>
  );
}
