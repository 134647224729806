import { useEffect, forwardRef, useState } from "react";
import PropTypes from "prop-types";
import { ListComponentSkeleton } from "../../skeletons/SkeletonsComponent";
import ViewMorePagination from "../../view-more-pagination/ViewMorePagination";
import { Box, Dialog, DialogContent, List, ListItem, Slide, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";


import "./ReactionsDetails.scss"
import { getBlogLikes } from "../../../core/services/blogs";
import { getPostlikes } from "../../../core/services/posts";
import ReationProfileRow from "./ReationProfileRow";
const Transition = forwardRef( function Transition( props, ref ) {
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

export function ReactionsDetail( props ) {
  const { data, onClose, checkAvailableReactions, blog } = props;
  const [ tabHeader, setTabHeader ] = useState( [] );
  const [ value, setValue ] = useState( 0 );
  const [ page, setPage ] = useState( 1 );
  const [ loadingUsers, setLoadingUsers ] = useState( false )
  const [ loadMore, setLoadMore ] = useState( false );
  const [ totalUsers, setTotalUsers ] = useState( false );
  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ users, setUsers ] = useState( [] )
  const handleTabChange = ( event, newValue ) => {
    setValue( newValue );
  };
  const handleLoadChange = () => {
    setPage( page + 1 );
    getData( page + 1 );
  }


  const getData = ( new_page ) => {
    let handleLoad = new_page ? setLoadMore : setLoadingUsers;

    handleLoad( true );

    let handleReactions = blog ? getBlogLikes : getPostlikes
    handleReactions( { ...( blog ? { blog_id: data?.id } : { post_id: data?.id } ), per_page: 5, page: new_page ? new_page : 1, sort: 2, like_type_id: value } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setTotalUsers( res.data.data?.total );
        setPage( res?.data?.data?.current_page );
        if ( new_page ) {
          setUsers( [ ...users, ...res?.data?.data?.data ] );
        } else {
          setUsers( res.data?.data?.data || [] );
        }

        setPageTotal( res.data.data?.last_page );
      }
      handleLoad( false );


    } )
  }

  const getAvailableReactions = () => {
    let available_reactions = checkAvailableReactions();
    setTabHeader( available_reactions )
    setValue( available_reactions?.[ 0 ]?.id?.toString() )
  }


  useEffect( () => {
    getAvailableReactions();
  }, [] )

  useEffect( () => {
    getData();
  }, [ value ] )

  return (
    <Dialog
      disablebackdropclick="true"
      fullWidth
      open={ true }
      onClose={ () => onClose() }
      TransitionComponent={ Transition }>
      <DialogContent>
        <TabContext value={ value }>
          <Box sx={ { borderBottom: 1, borderColor: "divider" } }>
            <TabList
              style={ { padding: "unset" } }
              variant="scrollable"
              onChange={ handleTabChange }
              aria-label="lab API tabs example"
            >
              { tabHeader?.map( ( d ) => {
                return (
                  <Tab
                    style={ { margin: "unset", paddign: "unset" } }
                    value={ d?.id?.toString() }
                    label={
                      <div className="reactions-headers">
                        <div>{ d.svgComponent }</div>
                        <div>{ d.count }</div>
                      </div>
                    }
                    key={ d?.id }

                  />
                );
              } ) }
            </TabList>
          </Box>
          { tabHeader?.map( ( d ) => {
            return (
              <TabPanel value={ d?.id?.toString() } key={ d?.id } className="reacted-users">
                { loadingUsers ?
                  <ListComponentSkeleton count={ 1 } />
                  : <List>
                    { users?.map( ( el, index ) => (
                      <ListItem divider={ index !== users?.length - 1 }>
                        <ReationProfileRow data={ el } reaction={ d } />
                      </ListItem>


                    ) ) }
                  </List> }
                { !loadingUsers && (
                  <ViewMorePagination
                    page={ page }
                    loading={ loadMore }
                    pageTotal={ pageTotal }
                    data={ users }
                    dataLeft={ users ? totalUsers - users?.length : 0 }
                    handleLoadChange={ handleLoadChange }
                  /> ) }
              </TabPanel>
            );
          } ) }
        </TabContext>

      </DialogContent>
    </Dialog>
  )
}


export default ReactionsDetail;

ReactionsDetail.defaultProps = {
  post: false,
};

ReactionsDetail.propTypes = {
  post: PropTypes.bool,
};