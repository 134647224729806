import React, { useContext, useEffect, useState } from "react";
import { getImagesPosts } from "../../../../../core/services/posts";
import PresentationPdfCard from "../../../../../components/cards/PresentationPdfCard";
import { NoData } from "../../../../../components/Fallback/NoData";
import PDF from "../../../../../assets/images/pdf-image.png";
import { ImageList, ImageListItem } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { AlertContext } from "../../../../../context/AlertContext";

function Presentations() {
  const alert = useContext( AlertContext );
  const [ presentation, setPresentations ] = useState( [] );
  const [ isLoading, setIsloading ] = useState( true );
  const [ noDataFound, setNodataFound ] = useState( false );
  let id = useParams();
  const setPresent = () => {
    setIsloading( true );
    let data = {
      type_ids: [ 4 ],
      per_page: 100,
    };
    getImagesPosts( id?.id, data ).then( ( res ) => {
      if ( res?.data?.success ) {
        setIsloading( false );
        setPresentations( res.data.data.data );
        setNodataFound( !res?.data?.data?.data?.length );
      } else {
        alert?.[ "warning" ](
          res?.data ? res?.data?.message : res?.message
        );
      }
    } );
  };
  useEffect( () => {
    setPresent();
  }, [] );
  return isLoading || noDataFound ? (
    <NoData
      title={ "No Presentation Available" }
      width="100%"
      NoData={ noDataFound }
      loading={ isLoading }
    />
  ) : (
    <div className="SectionMedia">
      <ImageList
        sx={ { width: "100%", minHeight: 450 } }
        cols={ 6 }
        rowHeight={ 190 }
      >
        { presentation.map( ( e, index ) => (
          <ImageListItem
            component={ Link }
            to={ `../../../../watch/description/${ e.id }/presentation` }
            key={ index }
            style={ { cursor: "pointer" } }
          >
            <PresentationPdfCard component="img" image={ PDF } title={ e.title } />
          </ImageListItem>
        ) ) }
      </ImageList>
    </div>
  );
}

export default Presentations;
