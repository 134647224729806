import React, { useState, useEffect, useContext } from "react";
import "./EarnPoints.scss";
import { Grid } from "@mui/material";
import * as userAPI from "../../../core/services/user";
import { NoData } from "../../../components/Fallback/NoData";
import RedemptionCard from "../../../components/cards/reward-redemption/RedemptionCard";
import { CatalogSkeleton } from "../../../components/skeletons/SkeletonsComponent";
import { AlertContext } from "../../../context/AlertContext";

const EarnPoints = () => {
  const [ data, setData ] = useState( [] );
  const [ isloading, setIsLoading ] = useState( true );

  const alert = useContext( AlertContext );

  const getAllRewards = () => {
    userAPI.getAllRewardsAchievement().then( ( res ) => {
      !res?.data?.success && alert?.warning( res?.data?.message );
      setIsLoading( false );
      setData( res?.data?.data );
    } );
  };

  useEffect( () => {
    getAllRewards();
  }, [] );

  return (
    <div className="earn-point">
      <Grid
        container
        spacing={ { xs: 2, md: 2 } }
        columns={ { xs: 2, sm: 15, md: 10, lg: 9, xl: 12 } }
      >
        { isloading ? (
          <CatalogSkeleton button count={ 10 } />
        ) : data?.length === 0 ? (
          <NoData />
        ) : (
          data?.map( ( e, index ) => (
            <Grid item xs={ 2 } sm={ 5 } md={ 3 } key={ index }>
              <RedemptionCard
                title={ e.title }
                description={ e.description }
                points={ e.points_to_be_earned }
                canEarn={ ( e?.slug === "accept_group_invitation" || e?.slug === "meeting" ) ? true : e.earn_status }
                id={ e.id }
              />
            </Grid>
          ) )
        ) }
      </Grid>
    </div>
  );
};
export default EarnPoints;
