import React, { useState } from "react";
import { Link, Outlet, useSearchParams } from "react-router-dom";
import SideComponent from "../../layout/sideBar/SideComponent";
import { useTranslation } from "react-i18next";
import { groupParamsByKey } from "../../core/functions/Functions";
import { useMediaQuery } from "react-responsive";
import { Tab, Tabs } from "@mui/material";
import FilterPopUp from "../../components/filter-popup/FilterPopUp";
const links1 = [
  { to: `browse`, name: "BROWSE" },
  { to: `myquestions`, name: "MY_QUESTIONS" },
];
const QuestionandAnswer = () => {
  const [ t ] = useTranslation( "common" );
  const [ searchParams ] = useSearchParams();
  const { search } = groupParamsByKey( searchParams ) || {};
  const [ activeTab, setActiveTab ] = useState( 0 );
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );
  const handleTabChange = ( event, newValue ) => {
    setActiveTab( newValue );
  };

  return (
    <div className="globalStructure">
      <SideComponent
        canSearch
        title="Q&A"
        placeholder={ `${ t( "SEARCH" ) }...` }
        addFilter={
          <FilterPopUp
            filterByAllSpecialities
            filterByCompanies
            filterByProducts
          /> }

      >
        <div className="navs">
          <Tabs
            onChange={ handleTabChange }
            value={ activeTab }
            orientation={ isSmallScreen ? "horizontal" : "vertical" }
            variant={ isSmallScreen && "scrollable" }
            allowScrollButtonsMobile
            indicatorColor="none"
          >
            { links1.map( ( e, index ) => (
              <Tab
                label={ t( e.name ) }
                key={ index }
                component={ Link }
                to={ e.to }
                className="links"
                value={ index }
                sx={ {
                  "&.Mui-selected": {
                    // Styles for the active tab
                    backgroundColor: "#d6f3f4",
                    color: "#02c0cc",
                    borderBottom: "2px solid #02c0cc",
                  },
                } }
              />
            ) ) }
          </Tabs>
        </div>
      </SideComponent>
      <div className="middleNoRightComponent">
        <Outlet context={ { search } } />
      </div>
    </div>
  );
};

export default QuestionandAnswer;
