//COMPONENT
import * as MUI from "@mui/material";
import { PreviousIcon, NextIcon } from "../../assets/icons/SVG";

//CSS
import "./TablePagination.scss";

export function TablePaginationSection( props ) {
  const { data, handlePageChange, totalPage, page, loading } = props;
  return (
    !loading && data?.length !== 0 && (
      <div className="table-pagination-section">
        <MUI.Pagination
          shape="rounded"
          size="small"
          count={ totalPage }
          page={ page }
          onChange={ handlePageChange }
          renderItem={ ( item ) => (
            <MUI.PaginationItem
              className={ item.selected ? "pagination-item-selected" : "" }
              components={ { previous: PreviousIcon, next: NextIcon } }
              { ...item }
            />
          ) }
        />
      </div>
    )
  );
}

export default TablePaginationSection;
