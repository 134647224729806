import React, { useContext, useEffect } from "react";
import { useAuth } from "../hooks/useAuth";
import { Outlet, useLocation, useNavigate, useParams } from "react-router-dom";
import { LanguageContext } from "../context/LanguageContext";

const GeneralLayout = () => {
  const { user } = useAuth();
  let navigate = useNavigate();
  const { lang } = useParams();
  const { pathname, search } = useLocation();
  const { language, setLanguage } = useContext(LanguageContext);

  useEffect(() => {
    setLanguage(lang === "en" ? "en" : "ro");
    lang !== "en" &&
      lang !== "ro" &&
      lang !== "" &&
      navigate(`/${language}${pathname}${search}`);
  }, [language]);

  return <Outlet />;
};

export default GeneralLayout;
