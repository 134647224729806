//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { Navigate, useNavigate, useOutletContext } from "react-router-dom";
import { UserContext } from "../../../context/UserContext";
import { useTranslation } from "react-i18next";
//COMPONENT
import * as MUI from "@mui/material";
import { filterDataPerPage } from "../../../core/variables/Variables";
import LoadMoreButton from "../../../components/load-more-button/LoadMoreButton";
import { NoData } from "../../../components/Fallback/NoData";
import { QASkeleton } from "../../../components/skeletons/SkeletonsComponent";
import QuestionHandle from "./QuestionHandle";
//CSS
import "./Browse.scss";
//API
import { getBlogs } from "../../../core/services/blogs";
import { REACT_APP_IMAGE_URL } from "../../../core/services/main";
import QuestionComponent from "./QuestionComponent";
import { useSearchParams } from "react-router-dom/dist";
import { groupParamsByKey } from "../../../core/functions/Functions";

const Browse = () => {
  const [ t ] = useTranslation( "common" );
  const { user } = useContext( UserContext );
  const [ show, setShow ] = useState( false );
  const [ searchParams, _ ] = useSearchParams();
  const { search } = groupParamsByKey( searchParams ) || {};
  let navigate = useNavigate();
  const [ QA, setQA ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );

  const getQA = ( new_page ) => {
    let handleLoad = new_page ? setIsLoadMore : setIsLoading;
    handleLoad( true );
    let current_page =
      new_page && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;
    getBlogs( {
      page: current_page,
      per_page: filterDataPerPage,
      is_gazette: false,
      name: search,
      sort: 3,
      speciality_ids: searchParams.getAll( "speciality_ids" ) || [],
      company_ids: searchParams.getAll( "company_ids" ) || [],
      product_ids: searchParams.getAll( "product_ids" ) || [],
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setPage( response.data.data?.current_page );
        if ( new_page ) {
          setQA( [ ...QA, ...response.data.data.data ] );
        } else {
          setQA( response.data?.data?.data || [] );
        }

        setTotalPage( response.data.data?.last_page );
      }
      handleLoad( false );
    } );
  };

  const updatePostCommentCount = ( postData, type, commentChildrenCount ) => {
    let array = [ ...QA ];
    let index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( type === "add" ) {
      array[ index ][ "comments_count" ] += 1;
    } else {
      //delete comment and its descendant
      array[ index ][ "comments_count" ] -= 1 + commentChildrenCount;
    }
    setQA( array );
  };

  const updatePostLikeCount = (
    postData,
    is_reacted,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {
    let array = [ ...QA ];
    let post_index = array.findIndex( ( item ) => item.id === postData?.id );
    //if is_reacted => unlike enfisam backend
    if ( is_reacted ) {
      //like
      if ( !array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] += 1; //if user already reacted and now he is changing reaction
      } else {
        array[ post_index ][ prevReaction?.key ] -= 1; //if user already reacted before then we remove the prev
      }
      array[ post_index ][ "is_reacted" ] = 1;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    } else {
      if ( array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] -= 1;
      }
      array[ post_index ][ "is_reacted" ] = 0;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ prevReaction?.key ] -= 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    }

    setQA( array );
  };

  const handleDetail = ( questionId ) => {
    navigate( `../answer-section/${ questionId }` );
  };

  useEffect( () => {
    getQA( false );
  }, [ search, searchParams ] );

  return (
    <div className="browse-container">
      <div className="browse-header">
        <div className="search-question">
          <MUI.Avatar
            src={ REACT_APP_IMAGE_URL + user?.profile_image }
            alt={ user?.first_name }
            className="doctorImage"
          />
          <MUI.ButtonBase className="search" onClick={ () => setShow( true ) }>
            { `${ t( "ASK_A_QUESTION" ) }...` }
          </MUI.ButtonBase>
        </div>
      </div>

      <div className="browse-body">
        { isLoading ? (
          <QASkeleton count={ 3 } />
        ) : QA?.length === 0 ? (
          <NoData title={ "NO_QUESTIONS_FOUND" } />
        ) : (
          <>
            { QA?.map( ( val ) => (
              <QuestionComponent
                data={ val }
                handleDetail={ () => handleDetail( val?.id ) }
                key={ val?.id }
                list
                updatePostLikeCount={ updatePostLikeCount }
                updatePostCommentCount={ updatePostCommentCount }
                tags={ val?.tags }
              />
            ) ) }
            { page < totalPage && !isLoading && (
              <LoadMoreButton
                isLoadMore={ isLoadMore }
                title="LOAD_MORE"
                onClick={ () => getQA( true ) }
              />
            ) }
          </>
        ) }
      </div>

      { show && (
        //isolated component for adding question
        <QuestionHandle
          onClose={ () => setShow( false ) }
          onSuccess={ () => getQA() }
        />
      ) }
    </div>
  );
};

export default Browse;
