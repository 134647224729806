//UTILITIES
import React, { useContext, useState } from "react";
import { AlertContext } from "../../context/AlertContext";
import * as yup from "yup";
import { useFormik } from "formik";
import { Event } from "../../core/tracking/tracking";
//COMPONENT
import DialogCustomTitle from "../../components/dialog/DialogCustomTitle";
import Button from "../../components/button/Button";
import * as MUI from "@mui/material";
import { FormInput } from "../../components/form/form-componenrt/FormComponent";
//CSS
import "./Meetings.scss";
//API
import { cancelMeeting } from "../../core/services/schedule";

const validationSchema = yup.object().shape( {
  description: yup.string().nullable().required( "FIELD_IS_REQUIRED" ),
  title: yup.string().nullable(),
  is_email: yup.bool(),
} );

export function CancelMeetingHandle( props ) {
  //CONTEXT
  const alert = useContext( AlertContext );
  //VARIABLES
  const { onClose, id, homePage, onSuccess } = props;
  const [ saveLoading, setSaveLoading ] = useState( false );

  //INITIATE FORM:
  const formiks = useFormik( {
    initialValues: {
      title: "Meeting Cancellation",
      is_email: true,
      description: "",
    },
    validationSchema: validationSchema,
    onSubmit: ( payload ) => handleSubmitForm( payload ),
  } );

  const handleSubmitForm = ( formValues ) => {
    setSaveLoading( true );
    cancelMeeting( id, { ...formValues } ).then( ( res ) => {
      alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
      if ( res?.data?.success ) {
        Event(
          "Meeting",
          "Cancel_Meeting",
          homePage ? "HOME_PAGE_REQUESTS" : " MEETING_PAGE_REQUESTS",
          id
        );
        onClose();
        onSuccess( id );
      }

      setSaveLoading( false );
    } );
  };

  return (
    <MUI.Dialog disablebackdropclick="true" fullWidth open={ true }>
      <DialogCustomTitle
        classNames={ `dialog-title-bb-color` }
        title={ "CANCEL_MEETING" }
        onClose={ () => {
          onClose();
        } }
      />

      <MUI.DialogContent className="cancel-meeting-component">
        <form onSubmit={ formiks.handleSubmit }>
          <FormInput
            label="REASON"
            rows={ 4 }
            placeholder="ENTER_REASON"
            name={ `description` }
            value={ formiks.values?.description }
            onChange={ ( e ) =>
              formiks.setFieldValue( "description", e.target.value )
            }
            helperText={
              formiks.touched?.description && formiks.errors?.description
            }
          />
          <div className="button-container">
            <Button
              onClick={ () => onClose() }
              name="CANCEL"
              width="15% !important"
            />
            <Button
              type="submit"
              isSelected
              disabled={ saveLoading }
              loading={ saveLoading }
              name={ "SUBMIT" }
              width="15% !important"
            />
          </div>
        </form>
      </MUI.DialogContent>
    </MUI.Dialog>
  );
}
export default CancelMeetingHandle;
