import React from "react";
import { Link } from "react-router-dom";
import "./NotFound.scss";
import { useTranslation } from "react-i18next";
import NoPageFound from "../../assets/icons/NoPageFound";

const NotFound = () => {

  const [ t ] = useTranslation( 'common' );

  return (
    <div className="middleComponent notFoundPage">
      <NoPageFound />


      <div className="title">{ t( "PAGE_NOT_FOUND" ) }</div>
      <Link to={ `home` }>{ t( "GO_HOME" ) }</Link>
    </div>
  )
};

export default NotFound;
