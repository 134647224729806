//UTILITIES
import React, { useState, useEffect, useContext } from "react";
import palette from "../../theme/color.scss";
import { useTranslation } from "react-i18next";
import * as yup from "yup";
import { useFormik } from "formik";

//COMPONENT
import { DeleteIcon, LikeSVG } from "../../assets/icons/SVG";
import Button from "../../components/button/Button";
import * as MUI from "@mui/material";
import { commentPerPage } from "../../core/variables/Variables";
import { FormInput } from "../../components/form/form-componenrt/FormComponent";
import { IconsButton } from "../../components/button/Button";
import ViewMorePagination from "../../components/view-more-pagination/ViewMorePagination";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import LinearLoaderComponent from "../../components/loader/LinearLoaderComponent";
import { AlertContext } from "../../context/AlertContext";
//CSS
import "./CommentComponent.scss";
//API
import {
  GetRepliesByCommentId,
  LikeAComment,
} from "../../core/services/comments";
import { DisplayDateTime } from "../../components/display-component/DisplayComponent";
import { PermissionContext } from "../../context/PermissionContext";

const HandleSchema = yup.object( {
  description: yup.string().nullable().required( "FIELD_IS_REQUIRED" ),
} );

export function CommentItem( props ) {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  const { setAlert } = useContext( AlertContext );
  const {
    user,
    data,
    post,
    blog,
    commentReplyId,
    setCommentReplyId,
    handleAddComment,
    handleSuccessResponse,
    handleDeleteComment,
  } = props;
  //VARIABLES
  const { permissions } = useContext( PermissionContext )
  const [ saveLoading, setSaveLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 0 );

  const [ page, setPage ] = useState( 1 );
  const [ replyData, setReplyData ] = useState( [] );
  const [ loading, setLoading ] = useState( false );
  const [ loadMore, setLoadMore ] = useState( false );
  const [ totalReplies, setTotalReplies ] = useState( 0 );
  const [ isReacted, setIsReacted ] = useState(
    data?.is_reacted === 1 ? true : false
  );

  //INITIATE FORM:
  const formiks = useFormik( {
    initialValues: {
      description: "",
    },
    validationSchema: HandleSchema,
    onSubmit: ( payload ) => handleAddReplyComment( payload ),
  } );

  //FUNCTIONS
  const handleAddReplyComment = ( formData ) => {
    setSaveLoading( true );
    handleAddComment( {
      ...formData,
      ...( post ? { post_id: post?.id } : { blog_id: blog?.id } ),
      parent_id: data?.id,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        formiks.setValues( { description: "" } );
        setSaveLoading( false );
        setCommentReplyId( null );
        handleSuccessResponse( "add" );
      } else {
        formiks.setValues( { description: "" } );
      }
      setSaveLoading( false );
    } );
  };

  const GetInitialReplyData = () => {
    setLoading( true );
    GetRepliesByCommentId( {
      per_page: commentPerPage,
      page: 1,
      comment_id: data?.id,
    } ).then( ( response ) => {
      if ( response?.data.success ) {
        setReplyData( response.data.data ? response.data.data.data : [] );
        setPage( response.data.data?.current_page );
        setTotalPage( response.data.data?.last_page );
        setTotalReplies( response.data.data?.total );
      }
      setSaveLoading( false );
      setLoading( false );
    } );
  };

  const handleLoadChange = () => {
    setPage( page + 1 );
    GetPaginatedData( page + 1 );
  };

  const GetPaginatedData = ( new_page ) => {
    setLoadMore( true );
    let old = [ ...replyData ];

    GetRepliesByCommentId( {
      comment_id: data?.id,
      per_page: commentPerPage,
      page: new_page,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setPage( response.data.data?.current_page );
        if ( response.data.data ) {
          response.data.data.data.map( ( item ) => old.push( item ) );
        }
        setReplyData( old );
        setTotalPage( response.data.data?.last_page );
      }

      setLoadMore( false );
    } );
  };

  const handleLikeComment = ( commentId, is_reacted ) => {
    if ( is_reacted ) {
      data[ "likes_count" ] += 1;
      data[ "is_reacted" ] = 1;
    } else {
      data[ "likes_count" ] -= 1;
      data[ "is_reacted" ] = 0;
    }
    LikeAComment( { comment_id: commentId, is_liked: is_reacted } ).then(
      ( response ) => {
        if ( !response.data?.success ) {
          if ( is_reacted ) {
            data[ "likes_count" ] -= 1;
            data[ "is_reacted" ] = 0;
          } else {
            data[ "likes_count" ] += 1;
            data[ "is_reacted" ] = 1;
          }
        }
        setAlert( {
          text: response.data ? response.data.message : response.message,
          error: response?.data?.success ? false : true,
        } );
      }
    );
  };

  const handleDeleteCommentAction = ( commentId, commentChildren ) => {
    setLoading( true );
    handleDeleteComment( commentId ).then( ( response ) => {
      if ( response.data?.success ) {
        handleSuccessResponse( "delete", commentChildren );
      }
      setLoading( false );

      setAlert( {
        text: response.data ? response.data.message : response.message,
        error: !response.data?.success,
      } );
    } );
  };
  useEffect( () => {
    if ( data?.children_count !== 0 ) {
      GetInitialReplyData();
    }
  }, [ data ] );



  return (
    <>
      <div className="comment-structure">
        <MUI.Avatar
          alt={
            data.user ? data.user.first_name + " " + data.user.last_name : ""
          }
          src={
            data?.user?.profile_image
              ? REACT_APP_IMAGE_URL + data.user?.profile_image
              : ""
          }
        />
        <div className="comment-content">
          <div>
            { data.user ? data.user.first_name + " " + data.user.last_name : "" }
          </div>
          <div className="comment-actions-content">
            <div className="reply-like">
              {/* <span>{ t( "LIKE" ) }</span> */ }

              <IconsButton
                icon={
                  <LikeSVG
                    fill={
                      data.is_reacted
                        ? palette.primaryColor
                        : palette.secondaryColor
                    }
                  />
                }
                onClick={ permissions?.Like?.[ 'like-comment' ] ? () => {
                  setIsReacted( !isReacted );
                  handleLikeComment( data.id, !isReacted );
                } : null }
              />

              <span className="like-number">{ data?.likes_count }</span>

              { !data?.parent_id && handleAddComment && (
                <>
                  |
                  <span
                    onClick={ () => {
                      if ( data?.id === commentReplyId ) {
                        setCommentReplyId( null );
                      } else {
                        setCommentReplyId( data.id );
                      }
                    } }
                    className="reply-button-link"
                  >
                    { t( "REPLY" ) }
                  </span>
                </>
              ) }
            </div>

            <div className="time-delete">
              { data.created_at ? <DisplayDateTime data={ data.created_at } calendar /> : "" }{ " " }
              { data?.user_id === user?.id && handleDeleteComment && (
                <>
                  |
                  <IconsButton
                    icon={ <DeleteIcon /> }
                    onClick={ () => handleDeleteCommentAction( data?.id, data?.children_count || data?.children?.length ) }
                  />
                </>
              ) }
            </div>
          </div>

          <div className="description">{ data.description }</div>
        </div>
      </div>
      { commentReplyId === data.id && handleAddComment && (
        <form onSubmit={ formiks.handleSubmit }>
          <div className="post-reply-a-comment">
            <div className="avatar">
              <MUI.Avatar
                src={
                  user?.profile_image
                    ? REACT_APP_IMAGE_URL + user.profile_image
                    : ""
                }
                alt={ user ? user.first_name + " " + user?.last_name : "" }
              />
            </div>
            <div className="input">
              <FormInput
                name="description"
                placeholder="ADD_A_COMMENT"
                value={ formiks?.values?.description }
                onChange={ ( e ) => {
                  formiks.setFieldValue( "description", e.target.value );
                } }
                helperText={
                  formiks.touched?.description && formiks.errors?.description
                }
              />
            </div>
            <div>
              <Button
                isSelected
                name="POST"
                type="submit"
                loading={ saveLoading }
                disabled={ saveLoading ? true : false }
              />
            </div>
          </div>
        </form>
      ) }
      { !loading ? (
        <div className="comment-replies">
          { data?.children_count !== 0 && (
            <>
              { replyData?.map( ( replyElement ) => {
                return (
                  <CommentItem
                    { ...props }
                    data={ replyElement }
                    key={ replyElement?.id }
                  />
                );
              } ) }
              <ViewMorePagination
                page={ page }
                pageTotal={ totalPage }
                handleLoadChange={ handleLoadChange }
                data={ replyData }
                dataLeft={ replyData ? totalReplies - replyData.length : 0 }
                loading={ loadMore }
              />
            </>
          ) }
        </div>
      ) : (
        <LinearLoaderComponent />
      ) }{ " " }
    </>
  );
}

export default CommentItem;
