//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { dataPerPage } from "../../../core/variables/Variables";
import { useSearchParams } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import { PermissionContext } from "../../../context/PermissionContext";

//API
import { deleteAddress, getSelfAddress } from "../../../core/services/address";
import { objectCleaner } from "../../../core/functions/Functions";


//SCSS
import "./Addresses.scss";

//COMPONENT
import { DeleteIcon, EditIcon, PlusIcon } from "../../../assets/icons/SVG";
import Button, { IconsButton } from "../../../components/button/Button";
import DialogComponent from "../../../components/dialog/Dialog";
import { AlertContext } from "../../../context/AlertContext";
import { NoData } from "../../../components/Fallback/NoData";
import { Card, CardContent } from "@mui/material";
import { ListComponentSkeleton } from "../../../components/skeletons/SkeletonsComponent";
import { AddressHandle } from "./AddressHandle";
import TablePaginationSection from "../../../components/table-pagination/TablePaginationSection";
import { StyledTab, StyledTabs } from "../../../theme/Theme";


export const Addresses = ( props ) => {
  const { permissions } = useContext( PermissionContext )
  const alert = useContext( AlertContext );
  const [ openUpdate, setOpenUpdate ] = useState( { handle: false, delete: false, data: null } );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ filter, setFilter ] = useState( {
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: searchParams.get( "page" ) || 1,
    is_work: searchParams.get( "is_work" ) || null,
  } );
  const [ t ] = useTranslation( "common" );

  const tabValues = [
    {
      id: 1,
      value: "all",
      name: "all",
      label: t( "ALL" ),
    },
    {
      id: 2,
      value: "addresses",
      name: "addresses",
      label: t( "WORK_ADDRESSES" ),
    },
  ];

  //get all user addresses
  const {
    data: addresses,
    loading,
    isFetching,
    refetch,
  } = useQuery( {
    queryKey: [ "self-addresses", filter ],
    queryFn: () => {
      return getSelfAddress( { ...filter } ).then( ( res ) => res?.data?.data );
    }, //return arrray of object containing day slug and name translated
  } );
  const handleTabChange = ( event, newValue ) => {
    let newFilters = {
      ...filter,
      is_work: newValue === "addresses" ? true : null,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };
  const handlePageChange = ( e, page ) => {
    let newFilters = {
      ...filter,
      page: page,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };

  //delete the addrress api, it takes the address id as data
  const handleDeleteResponse = ( data ) => {
    deleteAddress( data?.id ).then( ( res ) => {
      alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
    } );
  };


  useEffect( () => {
    setFilter( {
      per_page: searchParams.get( "per_page" ) || dataPerPage,
      page: +searchParams.get( "page" ) || 1,
      is_work: searchParams.get( "is_work" ) || null,
    } );
  }, [ searchParams ] );


  return (
    <div className="address-container">
      <div className="address-header">
        { permissions?.Address?.[ 'create-address' ] && (
          <Button
            isSelected
            name="NEW_ADDRESS"
            variant="contained"
            onClick={ () => setOpenUpdate( { data: null, handle: true, delete: false } ) }
            startIcon={ <PlusIcon size="13" /> }
          /> ) }


      </div>
      <StyledTabs
        value={ filter.is_work ? "addresses" : "all" }
        className="work-addresses-tabs"
        onChange={ handleTabChange }
      >
        { tabValues.map( ( element ) => (
          <StyledTab
            value={ element.value }
            name={ element.name }
            label={ element.label }
          />
        ) ) }
      </StyledTabs>
      { loading || isFetching ? (
        <ListComponentSkeleton count={ 10 } />
      ) : addresses?.addresses?.data.length !== 0 ? (
        addresses?.addresses?.data?.map( ( el ) => (
          <Card>
            <CardContent className="address-element">
              <div className="address-label">
                <div> { el?.address_name || `${ el?.country?.name }, ${ el?.city_name }` }</div>
                <div> { el?.address_type }</div>
              </div>
              <div className="address-actions">
                { permissions?.Address?.[ 'update-address' ] && (
                  <IconsButton
                    icon={ <EditIcon /> }
                    onClick={ ( event ) => {
                      setOpenUpdate( { data: el, handle: true, delete: false } );
                    } }
                  /> ) }
                <IconsButton
                  icon={ <DeleteIcon fill="red" /> }
                  onClick={ () => {
                    if ( addresses?.work_addresses_count === 1 && el?.address_type === "work" ) {
                      alert?.[ "warning" ]( t( "YOU_SHOULD_HAVE_AT_LEAST_ONE_WORK_ADDRESS" ) );
                    } else {
                      setOpenUpdate( { data: el, handle: false, delete: true } );
                    }

                  } }
                />
              </div>
            </CardContent>
          </Card>
        ) )
      ) : (
        <NoData />
      ) }
      <TablePaginationSection
        page={ filter?.page }
        totalPage={ addresses?.addresses?.last_page }
        data={ addresses?.addresses?.data }
        handlePageChange={ handlePageChange }
      />

      { openUpdate?.handle && (
        <AddressHandle
          data={ openUpdate?.data }
          onSuccess={ () => refetch() }
          onClose={ () => setOpenUpdate( { data: null, handle: false, delete: false } ) }
        />
      ) }
      { openUpdate?.delete && (
        <DialogComponent
          actions
          onClose={ () => setOpenUpdate( { data: null, handle: false, delete: false } ) }
          data={ openUpdate?.data }
          handleResponse={ ( data ) => handleDeleteResponse( data ) }
        />
      ) }
    </div>
  );
};
