//UTILITIES
import React from "react";
import { useTranslation } from "react-i18next";
//component
import "./PinnedGroupCard.scss";
import { Avatar } from "@mui/material";

function PinnedGroupCard( props ) {
  const { img, name, members, alt, style } = props;
  const [ t ] = useTranslation( "common" );
  return (
    <div style={ style } className="pinnedGroups">
      <div className="pinnedGroupContainer">
        <Avatar className="img" style={ { style } } src={ img } alt={ alt } />
        <div className="rightSidePart">
          <div className="groupName">
            <p> { `${ name }` }</p>
          </div>
          <p>{ `${ members } ${ t( "MEMBERS" ) }` }</p>
        </div>
      </div>
    </div>
  );
}

export default PinnedGroupCard;
