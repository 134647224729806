import React from "react";
import { RomanianFlagSVG, USAFlagSVG } from "../../assets/icons/SVG";
import {
  CelebrateSVG,
  InsightfulSVG,
  LikeLikeSVG,
  LoveLoveSVG,
  SupportSVG,
} from "../../assets/icons/SVG";
import supportSupSVG from "../../assets/icons/support.svg";
import likeSupSVG from "../../assets/icons/like.svg";
import loveSupSVG from "../../assets/icons/love.svg";
import insightfulSupSVG from "../../assets/icons/insightful.svg";
import celebrateSupSVG from "../../assets/icons/celebrate.svg";

//FRONTEND
export const commentPerPage = "2";
export const feedPerPage = "4";
export const chatPerPage = "5";
export const sessionsPerPage = "4";
export const filterPerPage = "10";
export const filterDataPerPage = "3";
export const dataPerPage = "10";
export const MobileBreakPoint = "900";
export const MobileOverBreakPoint = "901";
export const defaultLang = "ro";
export const DropDownOtherValue = {
  name: { en: "Other", ro: "Alte" },
  id: 0,
};
export const MeetAMedLanguages = [
  {
    id: "en",
    name: "en",
    icon: <USAFlagSVG />,
  },
  {
    id: "ro",
    name: "ro",
    icon: <RomanianFlagSVG />,
  },
];
export const ReactionsArray = [
  {
    id: 1,
    name: "like",
    svg: likeSupSVG,
    svgComponent: <LikeLikeSVG />,
    className: "primary-color-class-name",
    key: "liked_count",
  },
  {
    id: 2,
    name: "love",
    svg: loveSupSVG,
    svgComponent: <LoveLoveSVG />,
    className: "red-reaction",
    key: "loved_count",
  },
  {
    id: 3,
    name: "celebrate",
    svg: celebrateSupSVG,
    svgComponent: <CelebrateSVG />,
    className: "celebrate-class-name",
    key: "celebrated_count",
  },
  {
    id: 4,
    name: "support",
    svg: supportSupSVG,
    svgComponent: <SupportSVG />,
    className: "support-class-name",
    key: "supported_count",
  },
  {
    id: 5,
    name: "insightful",
    svg: insightfulSupSVG,
    svgComponent: <InsightfulSVG />,
    className: "insghtful-class-name",
    key: "insightful_count",
  },
];
export const sortBy = [
  { name: "Z to A", id: 1 },
  { name: "A to Z", id: 2 },
  { name: "Newest to Oldest", id: 3 },
  { name: "Oldest to Newest", id: 4 },
];
export const dateFormat = "DD-MM-YYYY";
export const dateTimeFormat = "DD-MM-YYYY HH:mm";

export const meetingDuration = [
  {
    text: "MEETING_DURATION",
    type: "meeting_duration",
    message: "You can select duration or add a custom duration",
    buttonTags: [ "15", "30", "45", "CUSTOM" ],
  },
  {
    text: "TIME_BETWEEN_MEETINGS",
    type: "break_duration",
    message: "You can select time or add a custom time",
    buttonTags: [ "NONE", "5", "10", "CUSTOM" ],
  },
];

export const fileTypes = {
  image: [ "JPG", "JPEG", "PNG" ],
  video: [ "MP4", "MOV", "OGG", "QT" ],
}

export const visibilityStatuses = [
  {
    name: 'PRIVATE',
    id: "private",

  },
  {
    name: 'PUBLIC',
    id: "public",
  },
];

