//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { AppContext } from "../../AppContext";
import { AlertContext } from "../../context/AlertContext";
import { useSearchParams } from "react-router-dom/dist";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
//API
import * as groupsData from "../../core/services/groups";

//COMPONENT
import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";
import SuggestedGroups from "../../components/cards/SuggestedGroups";

import { NoData } from "../../components/Fallback/NoData";
import { Grid } from "@mui/material";
import { groupParamsByKey } from "../../core/functions/Functions";
import { dataPerPage } from "../../core/variables/Variables";
import {
  CatalogSkeleton,
  GroupSkeleton,
} from "../../components/skeletons/SkeletonsComponent";
import { PermissionContext } from "../../context/PermissionContext";

function ViewAllSuggested() {
  const Alert = useContext( AlertContext );
  const { permissions } = useContext( PermissionContext )
  const { joinedGroups, setJoinedGroups } = useContext( AppContext );
  const [ searchParams ] = useSearchParams();
  const { search } = groupParamsByKey( searchParams ) || {};
  //VARIABLES
  const [ data, setData ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );

  const onJoin = ( id ) => {
    groupsData.joinGroup( id ).then( ( res ) => {
      Alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );

      if ( res?.data?.success ) {
        setJoinedGroups( joinedGroups + 1 );
        getAllSuggestedGroups( false );
      }
    } );
  };

  //GET PRODUCT IF NEW PAGE THEN LOAD MORE
  const getAllSuggestedGroups = ( pagination ) => {
    let handleLoad = pagination ? setIsLoadMore : setIsLoading;
    handleLoad( true );

    let new_data = [];
    let current_page =
      pagination && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;
    groupsData
      .getSuggestedGroups( {
        name: search,
        page: current_page,
        per_page: dataPerPage,
        sort: 2,
      } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          setPage( res?.data?.data?.current_page );
          setTotalPage( res.data.data?.last_page );
          if ( pagination ) {
            new_data = [ ...data, ...res?.data?.data?.data ];
          } else {
            new_data = res.data?.data?.data || [];
          }
          setData( new_data );
        } else {
          Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
        }
        handleLoad( false );
      } );
  };

  useEffect( () => {
    getAllSuggestedGroups( false );
  }, [ search ] );

  return (
    <div className="middleNoRightComponent">
      { isLoading ? (
        <GroupSkeleton count={ 3 } button />
      ) : data?.length === 0 ? (
        <NoData title={ "NO_GROUPS_FOUND" } width="100%" />
      ) : (
        <Grid
          container
          spacing={ { xs: 2, md: 3 } }
          columns={ { xs: 1, sm: 8, md: 12 } }
        >
          { data?.map( ( e ) => (
            <Grid item xs={ 2 } sm={ 4 } md={ 4 }>
              <SuggestedGroups
                key={ e.id }
                img={ REACT_APP_IMAGE_URL + e?.profile_image }
                groupName={ e?.title }
                members={ e?.users_count }
                notification={ e?.description }
                onJoin={ permissions?.Group?.[ 'join-company-group' ] ? () => onJoin( e.id ) : null }
                id={ e?.id }
                link={ permissions?.Group?.[ 'view-group' ] ? `../../groups/group-details/${ e?.id }/feed` : "#" }
              />
            </Grid>
          ) ) }
          { page < totalPage && !isLoading && (
            <LoadMoreButton
              isLoadMore={ isLoadMore }
              title="LOAD_MORE"
              onClick={ () => getAllSuggestedGroups( true ) }
            />
          ) }
        </Grid>
      ) }
    </div>
  );
}

export default ViewAllSuggested;
