//UTILTIES
import { useTranslation } from "react-i18next";
//COMPONENT
import { LinkButton } from "../button/Button";
import LinearLoaderComponent from "../loader/LinearLoaderComponent";
import "./ViewMore.scss";
const ViewMorePagination = (props) => {
  const [t] = useTranslation("common");
  const { data, handleLoadChange, pageTotal, dataLeft, page, loading } = props;

  return (
    data?.length !== 0 && (
      <div className="view-more-pagination-section">

        {!loading ? (
          page < pageTotal && dataLeft !== 0 && (
            <LinkButton
              text={`${t("VIEW")} ${dataLeft} ${t("MORE")}`}
              loading={loading}
              onClick={() => handleLoadChange("more")}
            />
          )
        ) : (
          <LinearLoaderComponent />
        )}
      </div>
    )
  );
};

export default ViewMorePagination;
