import axios from "axios";

export const translate = ( text, language ) => {
  return axios
    .post(
      "https://translation.googleapis.com/language/translate/v2",
      {},
      {
        params: {
          q: text,
          target: language,
          key: process.env.REACT_APP_GOOGLE_KEY,
        },
      }
    )
    .then( ( response ) => {
      return { success: true, data: response.data?.data?.translations?.[ 0 ]?.translatedText };
    } )
    .catch( ( error ) => {
      return { success: false, error: error, data: null };
    } );
};

//lang

export const detectlanguge = ( text ) => {
  return axios
    .post(
      " https://translation.googleapis.com/language/translate/v2/detect",
      {},
      {
        params: {
          q: text,
          key: process.env.REACT_APP_GOOGLE_KEY,
        },
      }
    )
    .then( ( response ) => {
      return { success: true, data: response.data.data.detections?.[ 0 ]?.[ 0 ] };
    } )
    .catch( ( error ) => {
      return { success: false, error: error, data: null };
    } );
};
