import { ChatContextProvider } from "../../context/ChatContext";

//COMPONENT
import ChatListManagement from "./ChatListManagement";
//CSS
import "./Chat.scss";
import Chat from "./Chat";

export const ChatLayout = () => {
  return (
    <div className="chat-layout">
      <ChatContextProvider>
        <div className="chat-bar-list">
          <ChatListManagement />
        </div>

        <div className="chat-bar-children">
          <Chat />
        </div>
      </ChatContextProvider>
    </div>
  );
};

export default ChatLayout;
