//UTILITIES
import React, { useEffect, useState, useContext } from "react";
import { AlertContext } from "../../../context/AlertContext";
import { UserContext } from "../../../context/UserContext";


//API
import * as userAPI from "../../../core/services/user";

//COMPONENT
import CardReward from "../../../components/cards/CardReward";
import { Grid } from "@mui/material";
import { NoData } from "../../../components/Fallback/NoData";
import { CatalogSkeleton } from "../../../components/skeletons/SkeletonsComponent";
import { dataPerPage } from "../../../core/variables/Variables";
import LoadMoreButton from "../../../components/load-more-button/LoadMoreButton";

//CSS
import "./Eshop.scss";
import { getAllCountries } from "../../../core/services/address";


/* REDEMPTION STATUSES:
1- pending
2- completed
3- rejected
*/


const Eshop = () => {
  const [ countries, setCountries ] = useState( [] );

  const [ data, setData ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ page, setPage ] = useState( 1 );

  const { user } = useContext( UserContext );
  const alert = useContext( AlertContext );

  //FUNCTIONS
  const getAllAtchievment = ( pagination ) => {
    let handleLoad = pagination ? setIsLoadMore : setIsLoading;
    handleLoad( true );

    let current_page =
      pagination && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;

    let payload = {
      page: current_page,
      per_page: dataPerPage,
    };
    userAPI.getAllRewards( payload ).then( ( res ) => {
      if ( res?.data?.success ) {
        setPage( res?.data?.data?.current_page );
        if ( pagination ) {
          setData( [ ...data, ...res?.data?.data?.data ] );
        } else {
          setData( res.data?.data?.data || [] );
        }

        setTotalPage( res.data.data?.last_page );
      } else {
        alert?.[ res?.data?.success ? "success" : "warning" ](
          res?.data ? res?.data?.message : res?.message
        );
      }
      handleLoad( false );
    } );
  };

  const getCountries = () => {
    getAllCountries().then( ( res ) => {
      res?.data?.success && setCountries( res.data?.data );
    } );
  };

  useEffect( () => {
    getAllAtchievment( false );
    getCountries();
  }, [] );
  {
  }
  return (
    <div className="eshop">
      <Grid
        container
        spacing={ { xs: 1, md: 2 } }
        columns={ { xs: 1, sm: 10, md: 9, lg: 9, xl: 12 } }
      >
        { isLoading ? (
          <CatalogSkeleton
            image
            button
            count={ 10 }
          />
        ) : data.length === 0 ? (
          <NoData />
        ) : (
          data?.map( ( e ) => (
            <Grid item xs={ 1 } sm={ 5 } md={ 3 } key={ e.name }>
              <CardReward
                rewardId={ e.id }
                userTotalPoint={ user.total_loyalty_points }
                title={ e.name }
                description={ e.description }
                points={ e.points }
                image={ e.path }
                showPopup={ true }
                showTitle={ true }
                showDescription={ true }
                showButton={ true }
                showTag={ true }
                countries={ countries }
                withDetails={ true }
                eShop={ true }
                waitingConfirmation={ e?.redeemed_reward?.redemption_status_id === 1 }

              />
            </Grid>
          ) )
        ) }
        { page < totalPage && !isLoading && (
          <LoadMoreButton
            isLoadMore={ isLoadMore }
            title="LOAD_MORE"
            onClick={ () => getAllAtchievment( true ) }
          />
        ) }
      </Grid>
    </div>
  );
};
export default Eshop;
