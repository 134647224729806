import * as React from "react";

const HomeSVG = (props) => (
  <svg
    width="27"
    height="22"
    viewBox="0 0 27 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ pointerEvents: "none" }}
  >
    <path
      d="M11.245 22V14.2353H16.4215V22H22.8921V11.6471H26.7744L13.8333 0L0.89209 11.6471H4.77444V22H11.245Z"
      fill="#71A2A7"
    />
  </svg>
);

export default HomeSVG;
