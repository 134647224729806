//UTILITIES
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom/dist";
//COMPONENT
import SideComponent from "../../layout/sideBar/SideComponent";
import { Outlet, Link } from "react-router-dom";
import { groupParamsByKey } from "../../core/functions/Functions";
import { Tab, Tabs } from "@mui/material";
import { useMediaQuery } from "react-responsive";
// gazettes should be changed to blog for now we are just changing the names in the website
const links = [
  { to: `blogs`, name: "BLOG" },
  { to: `my-blogs`, name: "MY_BLOG" },
  { to: `drafts`, name: "DRAFTS" },
];

function GazetteMain() {
  const [ t ] = useTranslation( "common" );
  const [ searchParams ] = useSearchParams();
  const { search, sort } = groupParamsByKey( searchParams ) || {};
  const [ activeTab, setActiveTab ] = useState( 0 );
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );
  const handleTabChange = ( event, newValue ) => {
    setActiveTab( newValue );
  };

  useEffect( () => {
    let active_link = links?.findIndex( ( el ) => window.location.pathname.includes( `/${ el?.to }` ) )
    setActiveTab( active_link || 0 )
  }, [ window.location.pathname ] )

  return (
    <div className="globalStructure">
      <SideComponent
        style={ { maxHeight: "60vh" } }
        sortBy
        title={ t( "BLOG" ) }
        placeholder={ "SEARCH_BY_TITLE" }
      >
        <div className="navs">
          <Tabs
            value={ activeTab }
            onChange={ handleTabChange }
            orientation={ isSmallScreen ? "horizontal" : "vertical" }
            variant={ isSmallScreen && "scrollable" }
            allowScrollButtonsMobile
            indicatorColor="none"
          >
            { links.map( ( e, index ) => (
              <Tab
                label={ t( e.name ) }
                key={ index }
                component={ Link }
                to={ e.to }
                className="links"
                value={ index }
                sx={ {
                  "&.Mui-selected": {
                    // Styles for the active tab
                    backgroundColor: "#d6f3f4",
                    color: "#02c0cc",
                    borderBottom: "2px solid #02c0cc",
                  },
                } }
              ></Tab>
            ) ) }
          </Tabs>
        </div>
      </SideComponent>

      <div className="middleNoRightComponent">
        <Outlet context={ { search, sort } } />
      </div>
    </div>
  );
}

export default GazetteMain;
