import { api } from "./main";
export const getAllNotifications = ( data ) => {
  return api
    .get( `v1/notifications`, {
      params: data,
    } )
    .then( ( res ) => res )
    .catch( ( error ) => error );
};


export const GetNotificationsCount = ( payload ) => {
  return api
    .get( `v1/notifications-count`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
export const ReadAllNotifications = ( payload ) => {
  return api
    .patch( `v1/read-all-notifications`, { params: { ...payload } } )
    .then( ( response ) => {
      return response;
    } )
    .catch( ( error ) => {
      return error;
    } );
};
export const ReadSingleNotification = ( payload ) => {
  //only to true
  return api.patch( `v1/change-read-notification/${ payload }`, { read: true } ).then( ( response ) => {
    return response;
  } )
    .catch( ( error ) => {
      return error;
    } );
}