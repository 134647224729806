//UTILITIES
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useSearchParams } from "react-router-dom";
import PropTypes from "prop-types";
import { useDebouncedCallback } from 'use-debounce';
//COMPONENT
import { SearchBar } from "../../components/form/Search/SearchBar";
import { FormSort } from "../../components/form/form-componenrt/FormComponent";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../core/functions/Functions";
import { Tab, Tabs } from "@mui/material";

function SideComponent( props ) {
  const [ t ] = useTranslation( "common" );

  const {
    title,
    placeholder,
    canEdit,
    children,
    sortBy,
    addFilter,
    canSearch,
    name,
  } = props;
  const { pathname } = useLocation();

  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ sort, setSort ] = useState( searchParams.get( "sort" ) || 1 );
  const [ search, setSearch ] = useState( searchParams.get( "search" ) || "" );


  const handleSortChange = ( value ) => {
    let url = {
      ...groupParamsByKey( searchParams ),
      sort: value,
      page: 1,
    };
    setSort( value );
    setSearchParams( objectCleaner( url ) );
  };

  //seaching the filter data when user removes its hand from the keyboard
  const handleSearch = useDebouncedCallback(
    // function
    ( value ) => {
      let url = {
        ...groupParamsByKey( searchParams ),
        search: value,
        page: 1,
      };
      setSearchParams( objectCleaner( url ) );
    },
    // delay in ms
    500
  );

  useEffect( () => {
    setSearch( searchParams.get( "search" ) || "" );
    setSort( searchParams.get( "sort" ) || 1 );
  }, [ pathname ] );

  return (
    <div className=" sideBar sideComponent">
      <div className="headerSide">
        <h1 className="sideType">{ t( title ) } </h1>
        { sortBy && (
          <FormSort
            placeholder={ "SORT_BY" }
            value={ sort }
            onChange={ ( e ) => {
              handleSortChange( e?.target?.value );
            } }
          />
        ) }
        <div className="SortingFiltering">
          { canSearch && (
            <SearchBar
              placeholder={ placeholder }
              value={ search }
              onChange={ ( e ) => {
                setSearch( e?.target?.value )
                handleSearch( e?.target?.value )

              } }
              disabled={ canEdit }
              name={ name }
            />
          ) }

          { addFilter && addFilter }
        </div>
      </div>

      <div style={ props.style }>{ children }</div>
    </div>
  );
}

export default SideComponent;

SideComponent.defaultProps = {
  canSearch: true,
  canEdit: false,
  sortBy: false,
  filter: false,
};
SideComponent.propTypes = {
  canSearch: PropTypes.bool,
  canEdit: PropTypes.bool,
  sortBy: PropTypes.bool,
  filter: PropTypes.bool,
  filters: PropTypes.object,
  title: PropTypes.string,
  placeholder: PropTypes.string,
};
