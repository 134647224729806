import LoaderComponent from "../loader/LoaderComponent";
import { useTranslation } from "react-i18next";
import "./NoData.scss";
import { NoDataFoundSVG, NotAllowedSVG } from "../../assets/icons/SVG";
import NoDataFound from "../../assets/images/landingPageImages/NoDataFound.png";

//REACT
import React from "react";
import PropTypes from "prop-types";
import { GroupsImage, ScheduleImage } from "../../assets/images/svg";
import { t } from "i18next";

export function NoData(props) {
  const [t] = useTranslation("common");
  const { normal, variant, title, centered, svgIcon } = props;

  return (
    <div
      className={`no-data-found ${normal ? "" : "absolute-position"} ${
        !centered ? "not-centered" : ""
      }`}
    >
      {variant === "text" ? (
        t(title)
      ) : (
        <>
          {svgIcon}
          <div>{t(title)}</div>
        </>
      )}
    </div>
  );
}

export default NoData;
NoData.defaultProps = {
  normal: true,
  centered: true,
  variant: "component",
  title: "NO_DATA_FOUND",
  svgIcon: <NoDataFoundSVG />,
};
NoData.propTypes = {
  normal: PropTypes.bool,
  centered: PropTypes.bool,
  variant: PropTypes.string,
  title: PropTypes.string,
  svgIcon: PropTypes.any,
};
export function NoPresentation(props) {
  const [t] = useTranslation("common");
  const { loading, title, NoData } = props;

  return (
    <div className="no-pres-container">
      {loading ? <LoaderComponent /> : null}
      {NoData && (
        <div className="noDataFound">
          <NotAllowedSVG />
          <h1>{title ? t(title) : t("NO_DATA_FOUND")}</h1>
        </div>
      )}
    </div>
  );
}
export const NoMeetings = (props) => {
  const { title } = props;
  const [t] = useTranslation("common");

  return (
    <div className="NoMeetings">
      <ScheduleImage />
      <strong>{title ? t(title) : t("NO_DATA_FOUND")}</strong>
    </div>
  );
};
export const NoGroupsInvitations = (props) => {
  const { title } = props;
  const [t] = useTranslation("common");

  return (
    <div className="NoMeetings">
      <GroupsImage />
      <strong>{title ? t(title) : t("NO_DATA_FOUND")}</strong>
    </div>
  );
};
export const NoDataFoundlandingPage = () => {
  return (
    <div className="no-data-found-landing-page">
      <img src={NoDataFound} alt={t("NO_DATA_FOUND")} />
      <p>{t("NO_DATA_FOUND")}</p>
    </div>
  );
};
