//UTILITIES
import React, { useState } from "react";
import { useOutletContext } from "react-router-dom";

//component
import { LockIcon } from "../../../../assets/icons/SVG";
import LoadMoreButton from "../../../../components/load-more-button/LoadMoreButton";
import Post from "../../../../components/post/Post";
import NoDataFound from "../../../../assets/icons/NoDataFound";
import "./Feed.scss";


const Feed = () => {
  const { groupDetail, LoadMore, totalPage, loadMoreLoader, page } =
    useOutletContext();
  const [ data, setData ] = useState( groupDetail?.posts?.data || [] );


  const updatePostCommentCount = ( postData, type, commentChildrenCount ) => {
    let array = [ ...data ];
    let index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( type === "add" ) {
      array[ index ][ "comments_count" ] += 1;
    } else {
      //delete comment and its descendant
      array[ index ][ "comments_count" ] -= ( 1 + commentChildrenCount );
    }
    setData( array );
  };

  //false=>like
  const updatePostLikeCount = ( postData, is_reacted, reactionTypeId, reaction, prevReaction ) => {

    let array = [ ...data ];
    let post_index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( is_reacted ) {

      //like
      if ( !array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] += 1 //if user already reacted and now he is changing reaction
      } else {

        array[ post_index ][ prevReaction?.key ] -= 1; //if user already reacted before then we remove the prev
      }
      array[ post_index ][ "is_reacted" ] = 1;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ 'user_like' ] = [ { like_type_id: reactionTypeId } ];

    } else {
      //unlike
      if ( array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] -= 1;
      }
      array[ post_index ][ "is_reacted" ] = 0;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ prevReaction?.key ] -= 1;
      array[ post_index ][ 'user_like' ] = [ { like_type_id: reactionTypeId } ];
    }

    setData( array );
  };

  return !groupDetail?.is_joined ? (
    <JoinToHaveAccess />
  ) : !groupDetail?.posts?.data?.length ? (
    <NoDataFound className="joinToHaveAccess" />
  ) : (
    <div style={ { marginTop: "10px" } } className="FeedComponent">
      { groupDetail?.posts?.data?.map( ( post, index ) => (
        <Post
          list
          data={ post }
          key={ index }
          updatePostLikeCount={ updatePostLikeCount }
          updatePostCommentCount={ updatePostCommentCount }
          image={ post?.user?.profile_image }
          userName={ `${ post?.user?.first_name }  ${ post?.user?.last_name }` }
        />

      ) ) }
      { page < totalPage && (
        <LoadMoreButton
          loadMoreLoader={ loadMoreLoader }
          title="LOAD_MORE"
          onClick={ LoadMore }
        />
      ) }
    </div>
  );
};

export default Feed;
export const JoinToHaveAccess = () => (
  <div className="joinToHaveAccess">
    <LockIcon />
    <p>Join to have access</p>
  </div>
);
