import React, { useState, useEffect, useContext } from "react";
import { Box, Tab, Tabs, Typography } from "@mui/material";
import { useTranslation } from "react-i18next";

import { useParams } from "react-router-dom";
import * as discover from "../../../../core/services/connections";
import PropTypes from "prop-types";
import {
  getUserById,
  getUserProfessionalInfo,
} from "../../../../core/services/user";
import {
  UserHeader,
  UserPersonalInfo,
  UserProssionalDetails,
  UserGroupsInfo,
} from "./user-components/UserProfile";
import "./DiscoverUser.scss";
import { getUserGroupsById } from "../../../../core/services/groups";
import BackRep from "../../../../components/back-repository/BackRep";
import { PermissionContext } from "../../../../context/PermissionContext";
export default function BasicTabs() {
  const [ t ] = useTranslation( "common" );
  const { permissions } = useContext( PermissionContext )
  let userId = useParams();
  const [ userData, setUserData ] = useState( [] );
  const [ userProfession, setUserProfession ] = useState( [] );
  const [ value, setValue ] = useState( 0 );
  const [ buttonLoader, setButtonLoader ] = useState( false );
  const [ userGroups, setUserGroups ] = useState( [] );
  const [ isLoading, setIsloading ] = useState( true );
  const handleChange = ( _, newValue ) => setValue( newValue );
  const getUser = () => {
    getUserById( userId.id ).then( ( res ) => {
      if ( res?.data?.success ) {
        setUserData( res.data?.data );
        setButtonLoader( false );
        setIsloading( false );
      }
    } );
  };
  const getUserProfessions = () => {
    getUserProfessionalInfo( userId.id ).then( ( res ) => {
      if ( res?.data?.success ) {
        let profession = res.data?.data;
        let halfWay = Math.ceil( res.data.data.length / 2 );
        let firstHalf = [];
        let secondHalf = [];
        if ( profession.length ) {
          for ( var i = 0; i < halfWay; i++ ) {
            firstHalf.push( profession[ i ] );
          }
          for ( var i = halfWay; i < profession.length; i++ ) {
            secondHalf.push( profession[ i ] );
          }
          let finalData = [ { array: firstHalf }, { array: secondHalf } ];
          setUserProfession( finalData );
        } else {
          setUserProfession( [] );
        }
      }
    } );
  };
  const getUserGroups = () => {
    getUserGroupsById( userId.id ).then( ( res ) => {
      setUserGroups( res.data?.data );
    } );
  };
  useEffect( () => {
    getUser();
    getUserProfessions();
    getUserGroups();
  }, [] );
  const handleConnect = () => {
    setButtonLoader( true );
    discover
      .createConnection( {
        requested_user_id: userData.id,
      } )
      .then( ( res ) => {
        res?.data?.success && getUser();
      } );
  };
  const handleFriendRequest = () => {
    setButtonLoader( true );
    let data = { user_id: userData.id, is_accepted: true };
    discover.AcceptReject( data ).then( ( res ) => {
      res?.data?.success && getUser();
    } );
  };

  const handleCancel = () => {
    setButtonLoader( true );
    discover
      .AcceptReject( {
        user_id: userData.id,
        is_accepted: false,
      } )
      .then( ( res ) => {
        res?.data?.success && getUser();
      } );
  };
  const handleAcceptOrReject = ( process ) => {
    if ( process === "accept" ) {
      handleFriendRequest();
    } else {
      handleCancel();
    }
  };
  function TabPanel( props ) {
    const { children, value, id, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={ value !== index }
        id={ `simple-tabpanel-${ index }` }
        aria-labelledby={ `simple-tab-${ index }` }
        { ...other }
      >
        { value === index && (
          <Box sx={ { p: 3 } }>
            <Typography>{ children }</Typography>
          </Box>
        ) }
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <div className="discover-user-container">
      <BackRep to={ `../discover` } title={ t( "USERS_INFORMATION" ) } />
      <UserHeader
        data={ userData }
        onClick={
          userData.is_friend
            ? handleCancel
            : userData.is_pending === 1
              ? handleCancel
              : userData.is_requested === 1
                ? "1"
                : handleConnect
        }
        onAcceptReject={ handleAcceptOrReject }
        onClickTitle={
          userData.is_friend
            ? "UNFRIEND"
            : userData.is_pending === 1
              ? "CANCEL_REQUEST"
              : userData.is_requested === 1
                ? "ACCEPT"
                : "CONNECT"
        }
        loading={ isLoading }
        buttonLoader={ buttonLoader }
      />
      <div className="discover-user-information">
        <Tabs
          value={ value }
          onChange={ handleChange }
          aria-label="basic tabs example"
          scrollButtons="auto"
          variant="scrollable"
        >
          <Tab label={ t( "USERS_INFORMATION" ) } value={ 0 } />
          { +userData.portal_id === 3 ? (
            <Tab label={ t( "PROFESSIONAL_DETAILS" ) } value={ 1 } />
          ) : null }
          { permissions?.Group?.[ 'view-groups-by-other-user' ] && ( <Tab label={ t( "GROUPS" ) } value={ 2 } /> ) }
        </Tabs>
        <TabPanel value={ value } index={ 0 }>
          <UserPersonalInfo data={ userData } />
        </TabPanel>
        { +userData.portal_id === 3 ? (
          <TabPanel value={ value } index={ 1 }>
            <UserProssionalDetails data={ userProfession } />
          </TabPanel>
        ) : null }

        { permissions?.Group?.[ 'view-groups-by-other-user' ] && ( <TabPanel value={ value } index={ 2 }>
          <UserGroupsInfo data={ userGroups } />
        </TabPanel> ) }
      </div>
    </div>
  );
}
