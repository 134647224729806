//UTILITIES
import React, { useContext, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
import { useTranslation } from "react-i18next";
import { NotificationContext } from "../../context/NotificationContext";
import { objectCleaner } from "../../core/functions/Functions";
import { DisplayDateTime } from "../../components/display-component/DisplayComponent";
//COMPONENT
import { NoData } from "../../components/Fallback/NoData";
import { dataPerPage, dateTimeFormat } from "../../core/variables/Variables";
import TablePaginationSection from "../../components/table-pagination/TablePaginationSection";
import { ListComponentSkeleton } from "../../components/skeletons/SkeletonsComponent";
import * as MUI from "@mui/material";
import { VideoSVG } from "../../assets/icons/NotificationsSVG";
import { TrophySVG } from "../../assets/icons/TrophySVG";
import RingSVG from "../../assets/icons/RingSVG";
import {
  CheckCircleSVG,
  ClockIcon,
  ConnectionsSVG,
} from "../../assets/icons/SVG";
//CSS
import "./UserNotifications.scss";

//API
import {
  ReadAllNotifications,
  ReadSingleNotification,
  getAllNotifications,
} from "../../core/services/notification";
import { useQuery } from "@tanstack/react-query";
import { LinkButton } from "../../components/button/Button";

const UserNotifications = () => {
  const [ t ] = useTranslation( "common" );
  let navigate = useNavigate();
  //hooks
  const { notificationCount, updateNotificationCount } =
    useContext( NotificationContext );
  const alert = useContext( AlertContext );
  const [ searchParams, setSearchParams ] = useSearchParams();
  //VARIABLES
  const filters = {
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: +searchParams.get( "page" ) || 1,
    is_read: searchParams.get( "is_read" ),
  };
  const { refetch, isFetching, data } = useQuery( {
    queryKey: [ "notifications" ],
    queryFn: () =>
      getAllNotifications( objectCleaner( filters ) ).then( ( res ) => res?.data ),
  } );



  const markAllRead = () => {
    ReadAllNotifications().then( ( res ) => {
      if ( res?.data?.success ) {
        updateNotificationCount();
      }
      alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );


    } )
  }



  const handleSingleRead = ( notificationItem ) => {
    navigate( `..${ notificationItem?.notification_url }` );
    ReadSingleNotification( notificationItem?.id ).then( ( res ) => {
      if ( res?.data?.success ) {
        updateNotificationCount();
      } else {
        alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
      }
    } );
  };


  useEffect( () => {

    refetch();
  }, [ notificationCount, searchParams ] );

  return (
    <div className="middleNoLeftRightComponent">
      <MUI.Card className="user-notification-component">
        <div className="cardHeader">
          <h1>{ t( "NOTIFICATIONS" ) }</h1>

          <MUI.ToggleButtonGroup
            color="primary"
            value={ searchParams.get( "is_read" ) || "null" }
            exclusive
            onChange={ ( e ) =>
              setSearchParams(
                objectCleaner( { page: 1, is_read: e?.target?.value } )
              )
            }
            aria-label="Platform"
          >
            <MUI.ToggleButton value={ "null" }>{ t( "ALL" ) }</MUI.ToggleButton>
            <MUI.ToggleButton value={ "false" }> { t( "UNREAD" ) } </MUI.ToggleButton>
          </MUI.ToggleButtonGroup>
          { data?.data?.data?.length !== 0 && notificationCount?.total !== 0 && (
            <div>
              { " " }
              <LinkButton
                className="read-all-button"
                onClick={ () => markAllRead() }
                text={ t( "MARK_ALL_AS_READ" ) }
                endIcon={ <CheckCircleSVG fill={ "black" } /> }
              />
            </div>
          ) }
        </div>
        { !isFetching ? (
          data?.data?.data?.length !== 0 ? (
            <MUI.List
              style={ { paddingTop: "0px" } }
              className="list-component-container"
            >
              { data?.data?.data?.map( ( item, index ) => (
                <MUI.ListItem
                  disableRipple
                  key={ index }
                  className={ `notification-item ${ item?.pivot.is_read ? "" : "unread-notification"
                    } ${ item?.notification_url ? "cursor-pointer" : "" }` }
                  onClick={
                    item?.notification_url ? () => handleSingleRead( item ) : null
                  }
                >
                  <MUI.ListItemIcon>
                    { item?.notification_type_id === 1 &&
                      item?.title === "Meeting" ? (
                      <VideoSVG />
                    ) : ( item?.notification_type_id === 1 &&
                      item?.title === "Accept meeting" ) ||
                      item?.notification_type_id === 5 ||
                      item?.notification_type_id === 6 ? (
                      <TrophySVG />
                    ) : item?.notification_type_id === 2 ? (
                      <VideoSVG />
                    ) : item?.notification_type_id === 3 ||
                      item?.notification_type_id === 4 ? (
                      <ConnectionsSVG />
                    ) : (
                      <RingSVG />
                    ) }
                  </MUI.ListItemIcon>

                  <div className="user-notification-content">
                    <div className="header">
                      <div className="primary-text">{ item?.title }</div>{ " " }
                      <div className="description">
                        <div
                          dangerouslySetInnerHTML={ {
                            __html: item?.description,
                          } }
                        ></div>
                      </div>
                    </div>
                    <div className="notification-date">
                      <ClockIcon />
                      <DisplayDateTime
                        data={ item?.created_at }
                        format={ dateTimeFormat }
                      />
                    </div>
                  </div>
                </MUI.ListItem>
              ) ) }
            </MUI.List>
          ) : (
            <NoData />
          )
        ) : (
          <ListComponentSkeleton count={ dataPerPage } />
        ) }
      </MUI.Card>

      <TablePaginationSection
        page={ +searchParams.get( "page" ) || 1 }
        loading={ isFetching }
        totalPage={ data?.data?.last_page }
        data={ data?.data?.data || [] }
        handlePageChange={ ( e, page ) => {
          setSearchParams( { page: page } );
        } }
      />
    </div>
  );
};

export default UserNotifications;
