//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { AlertContext } from "../../context/AlertContext";
import { useOutletContext } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";
import { useInnerWidth } from "../../hooks/useInnerWidth";
import { Link } from "react-router-dom";
//COMPONENT
import { CatalogSkeleton } from "../../components/skeletons/SkeletonsComponent";
import NoData from "../../components/Fallback/NoData";
import { dataPerPage } from "../../core/variables/Variables";
import SuggestedGroups from "../../components/cards/SuggestedGroups";
import { Navigation, Pagination } from "swiper/modules";
import SwiperCore from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
// Import Swiper styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

//API
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import * as groupsData from "../../core/services/groups";
import { PermissionContext } from "../../context/PermissionContext";

SwiperCore.use( [ Navigation, Pagination ] );

const DiscoverGroups = () => {
  const [ t ] = useTranslation( "common" );
  const { permissions } = useContext( PermissionContext );
  let screenWidth = useInnerWidth();
  const { search } = useOutletContext( {} ) || {};
  const Alert = useContext( AlertContext );
  const [ suggestedGroups, setSuggestedGroups ] = useState( {
    page: 1,
    totalPage: 1,
    data: [],
  } );
  const [ otherGroups, setOtherGroups ] = useState( {
    page: 1,
    totalPage: 1,
    data: [],
  } );
  const [ isLoadingSugg, setIsloadingSugg ] = useState( false );
  const [ isLoadingOther, setIsloadingOther ] = useState( false );

  //GET GROUP DATA FUNCTION
  const getOtherGroupsData = ( pagination ) => {
    !pagination && setIsloadingOther( true );

    let new_data = [];
    let current_page =
      pagination && otherGroups?.page + 1 <= otherGroups?.totalPage
        ? otherGroups?.page < otherGroups?.totalPage
          ? otherGroups?.page + 1
          : otherGroups?.page
        : 1;
    groupsData
      .getOtherGroups( {
        name: search,
        page: current_page,
        per_page: dataPerPage,
        sort: 2,
      } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          if ( pagination ) {
            new_data = [ ...otherGroups?.data, ...res?.data?.data?.data ];
          } else {
            new_data = res.data?.data?.data || [];
          }
          setOtherGroups( {
            page: current_page,
            data: new_data,
            totalPage: res?.data?.data?.last_page,
          } );
        }
        !pagination && setIsloadingOther( false );
      } );
  };

  const getSuggestedGroupsData = ( pagination ) => {
    !pagination && setIsloadingSugg( true );

    let new_data = [];
    let current_page =
      pagination && suggestedGroups?.page + 1 <= suggestedGroups?.totalPage
        ? suggestedGroups?.page < suggestedGroups?.totalPage
          ? suggestedGroups?.page + 1
          : suggestedGroups?.page
        : 1;
    groupsData
      .getSuggestedGroups( {
        name: search,
        page: current_page,
        per_page: dataPerPage,
        sort: 2,
      } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          if ( pagination ) {
            new_data = [ ...suggestedGroups?.data, ...res?.data?.data?.data ];
          } else {
            new_data = res.data?.data?.data || [];
          }
          setSuggestedGroups( {
            page: current_page,
            data: new_data,
            totalPage: res?.data?.data?.last_page,
          } );
        }
        !pagination && setIsloadingSugg( false );
      } );
  };

  const onJoin = ( id ) => {
    groupsData.joinGroup( id ).then( ( res ) => {
      Alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
      if ( res?.data?.success ) {
        getSuggestedGroupsData();
        getOtherGroupsData();
      }
    } );
  };

  useEffect( () => {
    getSuggestedGroupsData( false );
    getOtherGroupsData( false );
  }, [ search ] );

  return (
    <div className=" middleNoRightComponent discover-groups">
      <div className="SuggHeader">
        <p>{ t( "SUGGESTED_GROUPS" ) }</p>
        { suggestedGroups?.data?.length > 1 && (
          <Link className="LinksHeader" to={ `../discover/suggested` }>
            { t( "VIEW_ALL" ) }
          </Link>
        ) }
      </div>
      { isLoadingSugg ? (
        <CatalogSkeleton />
      ) : suggestedGroups?.data?.length === 0 ? (
        <NoData title={ "NO_GROUPS_FOUND" } width="100%" />
      ) : (
        <div>
          <Swiper
            onReachEnd={ () => {
              if ( suggestedGroups?.page > suggestedGroups?.totalPage ) {
                getSuggestedGroupsData( true );
              }
            } }
            spaceBetween={ 50 }
            slidesPerView={ screenWidth > 900 ? 3 : screenWidth > 600 ? 2 : 1 }
            navigation
          >
            { suggestedGroups?.data?.map( ( e ) => (
              <SwiperSlide>
                <SuggestedGroups
                  key={ e?.id }
                  img={ REACT_APP_IMAGE_URL + e?.profile_image }
                  groupName={ e?.title }
                  alt={ e?.title }
                  members={ e?.users_count }
                  notification={ e?.description }
                  onJoin={
                    permissions?.Group?.[ "join-company-group" ]
                      ? () => onJoin( e.id )
                      : null
                  }
                  id={ e?.id }
                  link={
                    permissions?.Group?.[ "view-group" ]
                      ? `../../groups/group-details/${ e?.id }/feed`
                      : "#"
                  }
                />
              </SwiperSlide>
            ) ) }
          </Swiper>
        </div>
      ) }

      <div className="OtherHeader">
        <p style={ { fontSize: "20px", fontWeight: "bold", lineHeight: "23px" } }>
          { t( "OTHER_GROUPS" ) }
        </p>
        { otherGroups?.data?.length > 1 && (
          <Link className="LinksHeader" to={ `../discover/others` }>
            { t( "VIEW_ALL" ) }
          </Link>
        ) }
      </div>
      { isLoadingOther ? (
        <CatalogSkeleton />
      ) : otherGroups?.data?.length === 0 ? (
        <NoData title={ "NO_GROUPS_FOUND" } width="100%" />
      ) : (
        <div style={ { marginBottom: "30px" } }>
          <Swiper
            onReachEnd={ () => {
              if ( otherGroups?.page > otherGroups?.totalPage ) {
                getOtherGroupsData( true );
              }
            } }
            spaceBetween={ 50 }
            slidesPerView={ screenWidth > 900 ? 3 : screenWidth > 600 ? 2 : 1 }
            navigation
          >
            { otherGroups?.data?.map( ( e ) => (
              <SwiperSlide>
                <SuggestedGroups
                  key={ e?.id }
                  img={ REACT_APP_IMAGE_URL + e?.profile_image }
                  groupName={ e?.title }
                  alt={ e?.title }
                  members={ e?.users_count }
                  notification={ e?.description }
                  onJoin={
                    permissions?.Group?.[ "join-company-group" ]
                      ? () => onJoin( e.id )
                      : null
                  }
                  id={ e?.id }
                  link={
                    permissions?.Group?.[ "view-group" ]
                      ? `../../groups/group-details/${ e?.id }/feed`
                      : "#"
                  }
                />
              </SwiperSlide>
            ) ) }
          </Swiper>
        </div>
      ) }
    </div>
  );
};

export default DiscoverGroups;
