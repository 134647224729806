//UTILTIES
import React, { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { Outlet, Link } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
//COMPONENT
import { IconButton, Tab, Tabs } from "@mui/material";
import { CameraSVG } from "../../assets/icons/SVG";
import { Avatar, Badge } from "@mui/material";
import "./AccountDetails.scss";
import EditSVG from "../../assets/icons/EditSVG";
import { PermissionContext } from "../../context/PermissionContext";
import Button from "../../components/button/Button";
import { ImageCropper } from "../../components/popup/Popup";
import { FormSingleDashedUpload } from "../../components/form/form-componenrt/FormComponent";
import ReactAvatarEditor from "react-avatar-editor";
import * as userInfo from "../../core/services/user";
import LoaderComponent from "../../components/loader/LoaderComponent";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import SideComponent from "../../layout/sideBar/SideComponent";
import { useMediaQuery } from "react-responsive";
import { AlertContext } from "../../context/AlertContext";

const AccountDetails = () => {
  const alert = useContext( AlertContext );
  const [ t ] = useTranslation( "common" );
  const [ activeTab, setActiveTab ] = useState( 0 );
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );

  const handleTabChange = ( event, newValue ) => {
    setActiveTab( newValue );
  };
  const { permissions } = useContext( PermissionContext );
  const links = [
    { to: `personal-information`, name: "PERSONAL_DETAILS" },
    { to: `professional-details`, name: "PROFESSIONAL_DETAILS" },
    {
      to: `document`,
      name: "DOCUMENTS",
      disabled: !permissions?.Document?.[ "view-user-documents" ],
    },
    { to: `settings`, name: "SETTINGS" },
    { to: `addresses`, name: "ADDRESSES" },
  ];
  const { user, getSelfUser } = useContext( UserContext );
  const [ saveLoading, setSaveLoading ] = useState( false )
  const [ isEdit, setEdit ] = useState( false );
  const [ show, setShow ] = useState( false );
  const [ editor, setEditor ] = useState( null );
  const [ chosenImage, setChoseImage ] = useState( null );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ imageState, setImageState ] = useState( {
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    borderRadius: 0,
    preview: null,
  } );
  const [ headerData, setHeaderData ] = useState( {} );
  const [ modifiedHeaderData, setModifiedHeaderData ] = useState( {} );

  const handleTextArea = ( e ) => {
    setModifiedHeaderData( {
      ...modifiedHeaderData,
      [ e.target.name ]: e.target.value,
    } );
  };
  const handleEdit = () => setEdit( !isEdit );

  const handleSave = () => {
    let data = {
      user_id: user?.id,
      bio: modifiedHeaderData.bio,
    };
    userInfo.updateUserBio( data ).then( ( res ) => { } );
    setEdit( !isEdit );
    setHeaderData( modifiedHeaderData );
  };

  const handleModalShowHide = () => setShow( !show );
  const handleImage = ( value ) => {
    setChoseImage( value );
  };

  const setPersonalInformation = () => {
    setIsLoading( true );
    userInfo.getSelf().then( ( res ) => {
      if ( res?.data?.success ) {
        let data = {
          ...res.data.data,
          user_id: user?.id,
          user_title_id: res.data.data.title_id,
        };
        setHeaderData( data );
        setModifiedHeaderData( data );
        setIsLoading( false );
      } else {
        alert?.[ "warning" ](
          res?.data ? res?.data?.message : res?.message
        );


      }
    } );
  };

  useEffect( () => {
    setPersonalInformation();
  }, [] );

  const handleScale = ( e ) => {
    const scale = parseFloat( e.target.value );
    setImageState( { ...imageState, scale: scale } );
  };

  const handlePositionChange = ( position ) => {
    setImageState( { ...imageState, position: position } );
  };

  const onCrop = () => {

    if ( editor !== null ) {
      setSaveLoading( true )
      const url = editor.getImageScaledToCanvas().toDataURL();
      fetch( url )
        .then( ( res ) => res.blob() )
        .then( ( blob ) => {
          setEditor( blob );
          setHeaderData( { ...headerData, profileImage: blob } );
          let data = new FormData();
          data.append( "user_id", user.id );
          data.append( "profile_image", blob );
          userInfo.updateUserImage( data ).then( ( res ) => {
            if ( res?.data?.success ) {
              setChoseImage( null );
              handleModalShowHide();
              setPersonalInformation();
              getSelfUser();
            }
            setSaveLoading( false )
          } );
        } );
    }
  };

  const setEditorRef = ( editor ) => setEditor( editor );

  useEffect( () => {
    let active_link = links?.findIndex( ( el ) => window.location.pathname.includes( `/${ el?.to }` ) )
    setActiveTab( active_link || 0 )
  }, [ window.location.pathname ] )


  if ( isLoading )
    return (
      <div style={ { height: "50vh", display: "flex", alignItems: "center" } }>
        <LoaderComponent />
      </div>
    );
  else
    return (
      <div
        style={ { flexDirection: "column" } }
        className="globalStructure profileComponent"
      >
        <SideComponent title={ t( "ACCOUNT_DETAILS" ) } canSearch={ false }>
          <div className="navs">
            <Tabs
              onChange={ handleTabChange }
              value={ activeTab }
              orientation={ isSmallScreen ? "horizontal" : "vertical" }
              variant={ isSmallScreen && "scrollable" }
              allowScrollButtonsMobile
              indicatorColor="none"
            >
              { links?.filter( ( el ) => !el?.disabled )?.map( ( e, index ) => (
                <Tab
                  label={ t( e.name ) }
                  key={ index }
                  component={ Link }
                  to={ e.to }
                  className="links"
                  value={ index }
                  sx={ {
                    "&.Mui-selected": {
                      // Styles for the active tab
                      backgroundColor: "#d6f3f4",
                      color: "#02c0cc",
                      borderBottom: "2px solid #02c0cc",
                    },
                  } }
                ></Tab>
              ) ) }
            </Tabs>
          </div>
        </SideComponent>
        <div
          className={ `accountDetails middleNoRightComponent ${ !isEdit ? "" : "opened"
            }` }
        >
          <div className={ `header ${ headerData.bio ? "borderBottom" : "" }` }>
            <div style={ { display: "flex", gap: "15px" } }>

              <Badge
                overlap="circular"
                anchorOrigin={ {
                  vertical: "bottom",
                  horizontal: "right",
                } }
                className="camera-badge"
                badgeContent={ <CameraSVG /> }
                onClick={ handleModalShowHide }
              >
                <Avatar
                  style={ { width: "50px", height: "50px" } }
                  alt=""
                  src={ REACT_APP_IMAGE_URL + headerData?.profile_image }
                />
              </Badge>

              <div className="text">
                <p>{ headerData.first_name + " " + headerData?.last_name }</p>
                <p>{ headerData.email }</p>
              </div>
            </div>

          </div>

          <div className="textField">
            <h6>{ t( "BIO" ) } <IconButton
              aria-label="delete"
              color="secondary"
              className="svgBackground"
              onClick={ handleEdit }
              style={ isEdit ? { display: "none" } : null }
            >
              <EditSVG />
            </IconButton></h6>
            { isEdit ? (
              <textarea
                style={ { resize: "none" } }
                name="bio"
                onChange={ handleTextArea }
              >
                { headerData.bio }
              </textarea>
            ) : headerData.bio ? (
              <p className="text">{ headerData.bio } </p>
            ) : null }

            { isEdit && (
              <div className="buttonGrouping">
                <Button name="CANCEL" width="15%" onClick={ handleEdit } />
                <Button
                  type="link"
                  name="SAVE"
                  width="15%"
                  onClick={ handleSave }
                  isSelected
                />
              </div>
            ) }
          </div>
        </div>
        <div className="middleNoRightComponent accountBody">
          <Outlet context={ [ headerData, setHeaderData ] } />
        </div>
        <ImageCropper
          onBackdropClick={ () => setChoseImage( null ) }
          onHideState={ show }
          onHide={ handleModalShowHide }
          onCloseClick={ () => {
            setChoseImage( null );
            handleModalShowHide();
          } }
        >
          <div className="imageCropperBody">
            { chosenImage === null ? (
              <FormSingleDashedUpload
                downloadTitle={ "UPLOAD_IMAGE" }
                onChange={ handleImage }
                value={ chosenImage }
              />
            ) : (
              <div className="avatarCropper">
                <ReactAvatarEditor
                  scale={ parseFloat( imageState.scale ) }
                  ref={ setEditorRef }
                  position={ imageState.position }
                  onPositionChange={ handlePositionChange }
                  rotate={ parseFloat( imageState.rotate ) }
                  borderRadius={ 100 }
                  image={ chosenImage }
                  className="editor-canvas"
                />
                <div style={ { marginTop: "10px" } }>
                  <input
                    name="scale"
                    type="range"
                    onChange={ handleScale }
                    min={ "1" }
                    max="2"
                    step="0.01"
                    defaultValue="1"
                  />
                </div>
                <div
                  style={ {
                    width: "100%",
                    display: "flex",
                    justifyContent: "flex-end",
                    gap: "3%",
                    marginTop: "10px",
                  } }
                >
                  <Button
                    onClick={ () => {
                      setChoseImage( null );
                      handleModalShowHide();
                    } }
                    type="submit"
                    name="CANCEL"
                    width="25%"
                  />
                  <Button
                    type="submit"
                    name="SAVE"
                    width="25%"
                    loading={ saveLoading }
                    disabled={ saveLoading }
                    onClick={ onCrop }
                    isSelected
                  />
                </div>
              </div>
            ) }
          </div>
        </ImageCropper>
      </div>
    );
};

export default AccountDetails;
