import { initializeApp } from "firebase/app";
import {
  getFirestore,
  collection,
  addDoc,
  serverTimestamp,
  onSnapshot,
  query,
  orderBy,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { getStorage, } from "firebase/storage";
import { getMessaging, getToken, onMessage, isSupported } from 'firebase/messaging';
import { FCMToken } from "./fcm-notification";
import { REACT_APP_FIREBASE_KEY } from "./main";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  measurementId: process.env.REACT_APP_FIREBASE_MEASUREMENT_ID,
};

const app = initializeApp( firebaseConfig );
export const db = getFirestore( app );
export const storage = getStorage( app );

//check support for safari 
const supported = await isSupported();
export const messaging = supported ? getMessaging( app ) : null;

/* So our firebase firestore is as follow:
- first collection : unreadMessages
  has a document that should be userId 
  has a bool field "has_unread"
      a map field "chatRooms" containing all unread chatRooms as object to facilitate the access on the front

-second collection : chat-rooms
  has a document that should be roomId
  has a sub collection : messages
    sub collection has fields:  
      uID , receiverID , chatID(roomId),displayName, text, type,timestamp
      
REMARKS:
- always when using collection to get data have it with odd segment as firebase doesn't accept even segment
  ex: collection( db, process.env.REACT_APP_FIREBASE_CHAT_COLLECTION, roomId, "messages"  => this is a collection of 3 segment
*/

//check 
//check the the user has unread messages
function setupUnreadMessagesListener( receiverId, updateBadgeCallback ) {
  if ( !receiverId ) {
    return;
  }
  try {
    //we have to convert to string to not have an error from firebase
    const userDocRef = doc( db, process.env.REACT_APP_FIREBASE_UNREAD_COLLECTION, receiverId?.toString() );

    // Set up a real-time listener using onSnapshot
    const unsubscribe = onSnapshot( userDocRef, ( doc ) => {
      if ( doc.exists() ) {
        const data = doc.data();

        const hasUnread = data ? data.has_unread : false;
        const chatRooms = data ? data.chatRooms : {};
        //getting both has_unread field of the user and all the unread chatRooms
        updateBadgeCallback( hasUnread, chatRooms );
      } else {
        console.error( 'Document does not exist' );
      }
    } );

    // The unsubscribe function can be used to stop listening when needed
    return unsubscribe;
  } catch ( error ) {
    console.error( 'Error setting up unread messages listener:', error );
  }
}



//when user click on the chat icon , the unread message for that user will be resetted
async function markMessageAsUnread( receiverId, newValue, roomId ) {
  try {
    if ( !receiverId ) {
      console.error( 'User ID is not available in storage' );
      return;
    }
    /*
    we had to convert it to string because database was throwing error when it was integer
    */
    //accessing the collection
    const userDocRef = doc( db, process.env.REACT_APP_FIREBASE_UNREAD_COLLECTION, receiverId.toString() );

    // Get the existing document data
    const docSnapshot = await getDoc( userDocRef );
    const existingData = docSnapshot.exists() ? docSnapshot.data() : {};

    // Update the chatRooms field based on the value of newValue
    if ( newValue ) {
      // If newValue is true, add the roomId to chatRooms
      const updatedChatRooms = { ...existingData.chatRooms, [ roomId ]: true };
      await setDoc( userDocRef, { chatRooms: updatedChatRooms, has_unread: true } );
    } else {
      // If newValue is false, remove the roomId from chatRooms
      if ( existingData.chatRooms && existingData.chatRooms[ roomId ] ) {
        const updatedChatRooms = { ...existingData.chatRooms };
        delete updatedChatRooms[ roomId ];
        const hasUnreadChatRooms = Object.keys( updatedChatRooms ).length > 0;
        await setDoc( userDocRef, { chatRooms: updatedChatRooms, has_unread: hasUnreadChatRooms ? true : false } );
      }
    }
  } catch ( error ) {
    console.error( 'Error updating unread status:', error );
  }
}

/*params are when user is sending a message that should include a reference 
like when sending a message about a company's product
*/
async function sendMessage( roomId, user, text, receiverID, type, params ) {
  try {
    await addDoc( collection( db, process.env.REACT_APP_FIREBASE_CHAT_COLLECTION, roomId, "messages" ), {
      ...params,
      uID: user.id,
      receiverID: receiverID,
      chatID: roomId,
      displayName: user.first_name,
      text: text,
      type: type,
      timestamp: serverTimestamp(),
    } );
    // Mark the message as unread for the recipient
    await markMessageAsUnread( receiverID, true, roomId );
  } catch ( error ) {
    console.error( error );
  }
}

function getMessages( roomId, callback ) {
  return onSnapshot(
    query(
      collection( db, process.env.REACT_APP_FIREBASE_CHAT_COLLECTION, roomId, "messages" ),
      orderBy( "timestamp", "asc" )
    ),
    ( querySnapshot ) => {
      const messages = [];
      querySnapshot.forEach( ( doc ) => {
        // Get the data for each message document and add it to the messages array
        const messageData = doc.data();
        messages.push( {
          id: doc.id,
          ...messageData,
        } );
      } );

      // Group messages by date
      const messagesByDate = messages.reduce( ( acc, message ) => {
        const dateMillis = message?.timestamp?.toMillis();
        if ( dateMillis ) {
          const date = new Date( dateMillis ).toDateString();
          if ( !acc[ date ] ) {
            acc[ date ] = [];
          }
          acc[ date ].push( message );
        }
        return acc;
      }, {} );

      callback( messagesByDate );
    }
  );
}

export const requestPermission = () => {
  if ( !messaging ) return;
  Notification.requestPermission().then( ( permission ) => {

    if ( permission === "granted" ) {


      return getToken( messaging, { vapidKey: REACT_APP_FIREBASE_KEY } )
        .then( ( currentToken ) => {

          if ( currentToken ) {
            FCMToken( { fcm_token: currentToken } ).then( ( res ) => res );

          } else {

            console.log( 'Failed to generate the app registration token.' );
          }
        } )
        .catch( ( err ) => {

          console.log( 'An error occurred when requesting to receive the token.', err );
        } );
    } else {

      console.log( "User Permission Denied." );
    }
  } ).catch( ( err ) => {

    console.log( 'An error occurred when requesting permission', err );
  } );

}



//for notification : to listen to new notifications
export const onMessageListener = () =>

  new Promise( ( resolve ) => {
    onMessage( messaging, ( payload ) => {
      resolve( payload );
    } );
  } );




export { sendMessage, getMessages, markMessageAsUnread, setupUnreadMessagesListener };
