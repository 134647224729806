import * as React from "react";

export const TrophySVG = (props) => (
  <svg
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ pointerEvents: "none" }}
  >
    <path
      d="M22.5552 3.27778H19.7775V0.5H5.88856V3.27778H3.11079C1.58301 3.27778 0.333008 4.52778 0.333008 6.05556V7.44444C0.333008 10.9861 2.99967 13.875 6.43023 14.3056C7.30523 16.3889 9.18023 17.9583 11.4441 18.4167V22.7222H5.88856V25.5H19.7775V22.7222H14.2219V18.4167C16.4858 17.9583 18.3608 16.3889 19.2358 14.3056C22.6663 13.875 25.333 10.9861 25.333 7.44444V6.05556C25.333 4.52778 24.083 3.27778 22.5552 3.27778ZM3.11079 7.44444V6.05556H5.88856V11.3611C4.27745 10.7778 3.11079 9.25 3.11079 7.44444ZM22.5552 7.44444C22.5552 9.25 21.3886 10.7778 19.7775 11.3611V6.05556H22.5552V7.44444Z"
      fill="#FEC901"
    />
  </svg>
);
