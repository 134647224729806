import React, { useContext, useEffect, useState } from "react";
import "./Photos.scss";
import { getImagesPosts } from "../../../../../core/services/posts";
import { NoData } from "../../../../../components/Fallback/NoData";
import { REACT_APP_IMAGE_URL } from "../../../../../core/services/main";
import { CardMedia } from "@mui/material";
import { ImageList, ImageListItem } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import { AlertContext } from "../../../../../context/AlertContext";

function Photos() {
  const alert = useContext( AlertContext );
  const [ images, setImages ] = useState( [] );
  const [ isLoading, setIsloading ] = useState( true );
  const [ noDataFound, setNodataFound ] = useState( false );
  let id = useParams();
  const setImgs = () => {
    setIsloading( true );
    let data = {
      type_ids: [ 2 ],
      per_page: 100,
    };
    getImagesPosts( id?.id, data ).then( ( res ) => {
      if ( res?.data?.success ) {
        setIsloading( false );
        setImages( res.data.data.data );
        if ( !res?.data?.data?.data?.length ) {
          setNodataFound( true );
        }
      } else {
        alert?.[ "warning" ](
          res?.data ? res?.data?.message : res?.message
        );
      }
    } );
  };
  useEffect( () => {
    setImgs();
  }, [] );
  return isLoading || noDataFound ? (
    <NoData
      title={ "No Photos Available" }
      width="100%"
      NoData={ noDataFound }
      loading={ isLoading }
    />
  ) : (
    <div className="SectionMedia">
      <ImageList sx={ { width: "100%", height: 450 } } cols={ 6 } rowHeight={ 190 }>
        { images.map( ( e, index ) => (
          <ImageListItem
            key={ index }
            component={ Link }
            to={ `../../../../watch/description/${ e.id }/image` }
          >
            <CardMedia
              component="img"
              height="140"
              required
              image={ REACT_APP_IMAGE_URL + e.post_media[ 0 ].path }
              alt={ "" }
              className="Photos"
              style={ { cursor: "pointer" } }
            />
          </ImageListItem>
        ) ) }
      </ImageList>
    </div>
  );
}

export default Photos;
