import React from "react";
import "./CategoriesCard.scss";
import Button from "../../button/Button";
import { useTranslation } from "react-i18next";

const CategoriesCard = (props) => {
  const [t] = useTranslation("common");
  const { title, link, id } = props;

  return (
    <>
      <p className="title">{title}</p>
      <p className="description">{t("DOCTOR_DESCRIPTION")}</p>
      <Button
        href={link}
        isSelected
        name="REGISTER"
        width={"305px"}
        onClick={() => {
          sessionStorage.setItem("profession_id", id);
        }}
        disabled={id != 5}
      />
    </>
  );
};

export default CategoriesCard;
