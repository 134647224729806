//REACT
import React from "react";
import { useTranslation } from "react-i18next";
import NoChats from "../../assets/icons/noChats.svg";
//CSS
import "./ResponseMessage.scss";


function ChatNotFound( props ) {
  const [ t ] = useTranslation( "common" );
  const { handleOpenContact } = props;
  return (
    <div className="chat-not-found">
      <div>  <img alt="" src={ NoChats } /></div>
      <div>{ t( "YOU_DONT_HAVE_A_MESSAGE_SELECTED" ) }</div>
      <div
        className={ handleOpenContact ? "link-to-contacts" : "" }
        onClick={ handleOpenContact ? () => handleOpenContact() : null }
      >
        { t( "CHOOSE_ONE_FROM_YOUR_EXISTING_MESSAGES" ) }
      </div>
    </div>
  );
}

export default ChatNotFound;
