import React from "react";
import { useTranslation } from "react-i18next";
//COMPONENT
import palette from "../../theme/color.scss";
import "./TitleComponent.scss";
import PropTypes from "prop-types";
import { BackIcon } from "../../assets/icons/SVG";
import { IconsButton } from "../button/Button";

export function TitleComponent(props) {
  const [t] = useTranslation("common");
  const { endIcon, title, handleBack, handleEndAction, classNames } = props;
  return (
    <div className="page-title">
      <div
        className="start"
        style={{ color: handleBack ? palette.primaryColor : "black" }}
      >
        {handleBack ? (
          <>
            <div className="icon-space">
              <IconsButton icon={<BackIcon />} onClick={() => handleBack()} />
            </div>
            <div
              onClick={() => handleBack()}
              className={`cursor-pointer ${classNames}`}
            >
              {t(title)}
            </div>
          </>
        ) : (
          <div className={`${classNames}`}>{t(title)}</div>
        )}
      </div>
      <div className="end">
        {handleEndAction ? (
          <IconsButton icon={endIcon} onClick={() => handleEndAction()} />
        ) : (
          endIcon
        )}
      </div>
    </div>
  );
}
export default TitleComponent;
TitleComponent.defaultProps = {
  classNames: "title",
};
TitleComponent.propTypes = {
  classNames: PropTypes.string,
  title: PropTypes.string,
  handleBack: PropTypes.func,
  handleEndAction: PropTypes.func,
};
