import React, { useEffect, useState } from "react";

//styling
import "./Description.scss";

//routing
import { useParams } from "react-router-dom";

//package
import TimeMe from "timeme.js";

//apis
import { getPostById } from "../../../core/services/posts";

//reusable components
import { NoData } from "../../../components/Fallback/NoData";
import LinkWrapper from "../../../components/new/react-player/link";
import Post from "../../../components/post/Post";
import { PostSkeleton } from "../../../components/skeletons/SkeletonsComponent";
import BackRep from "../../../components/back-repository/BackRep";

//translation
import { useTranslation } from "react-i18next";

function Description() {
  //routing hooks
  let param = useParams();

  //STATE
  const [ isLoading, setIsLoading ] = useState( true );
  const [ postInfo, setPostInfo ] = useState( [] );
  const [ Interuption, setInteruption ] = useState( "" );

  const [ timer, settimer ] = useState();
  const [ estimated, setestimated ] = useState();
  const [ linkis, setlinkis ] = useState();
  const [ open, setopen ] = useState( false );
  const [ t ] = useTranslation( "common" );

  useEffect( () => {
    getPostById( param.id ).then( ( res ) => {
      setIsLoading( false );
      setestimated( res?.data?.data?.time_estimation );
      setInteruption( res?.data?.data?.post_media?.[ 0 ]?.post_interruption );
      setPostInfo( { ...res?.data?.data, comments: [] } );
    } );
  }, [] ); //get post by id

  const updatePostLikeCount = (
    postData,
    is_reacted,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {
    if ( is_reacted ) {
      setPostInfo( {
        ...postInfo,
        ...( !postInfo?.is_reacted && {
          likes_count: ( postInfo?.likes_count || 0 ) + 1,
        } ),
        ...( postInfo?.is_reacted && {
          [ prevReaction.key ]: ( postInfo[ prevReaction.key ] || 0 ) - 1,
        } ),
        is_reacted: 1,
        [ reaction ]: ( postInfo[ reaction ] || 0 ) + 1,

        user_like: [
          {
            like_type_id: reactionTypeId,
          },
        ],
      } );
    } else {
      setPostInfo( {
        ...postInfo,
        ...( postInfo?.is_reacted && { likes_count: postInfo?.likes_count - 1 } ),
        is_reacted: 0,
        [ prevReaction.key ]: postInfo[ prevReaction.key ] - 1,
        user_like: [
          {
            like_type_id: reactionTypeId,
          },
        ],
      } );
    }
  };
  const updatePostCommentCount = ( postData, type, commentChildrenCount ) => {
    if ( type === "add" ) {
      setPostInfo( {
        ...postInfo,
        comments_count: postInfo?.comments_count + 1,
      } );
    } else {
      //delete comment and its descendant
      setPostInfo( {
        ...postInfo,
        comments_count: postInfo?.comments_count - ( 1 + commentChildrenCount ),
      } );
    }
  };

  useEffect( () => {
    showInteruptions();
  }, [ timer ] );

  useEffect( () => {
    TimeMe.resetAllRecordedPageTimes();
    TimeMe.initialize( {
      currentPageName: "DescriptionPage", // current page
      idleTimeoutInSeconds: -1, // seconds Will stop
    } );
    TimeMe.startTimer();
  }, [] );

  useEffect( () => {
    TimeMe.callWhenUserLeaves( () => {
      settimer( TimeMe.getTimeOnCurrentPageInSeconds() );
    } );

    TimeMe.callWhenUserReturns( () => {
      settimer( TimeMe.getTimeOnCurrentPageInSeconds() );
    } );
  }, [] );

  function format( seconds ) {
    const date = new Date( seconds * 1000 );
    const hh = date.getUTCHours();
    const mm = date.getUTCMinutes();
    const ss = pad( date.getUTCSeconds() );
    if ( hh ) {
      return `${ hh }:${ pad( mm ) }:${ ss }`;
    }
    if ( mm ) {
      return `00:${ mm }:${ ss }`;
    }
    return `00:00:${ ss }`;
  }

  function pad( string ) {
    return ( "0" + string ).slice( -2 );
  }

  const showInteruptions = () => {
    if (
      postInfo?.type?.slug === "presentation" &&
      Interuption.length > 0
    ) {
      let stoptimearray = [];
      let surveyslink = [];
      Interuption.map( ( i ) => {
        surveyslink.push( i.path );
        let inter = i.interruption_at.toString();
        const arr = inter.split( ":" );
        arr.push( i.path );
        stoptimearray.push( arr );
        stoptimearray.map( ( i ) => {
          if ( i[ 0 ] == "00" && i[ 1 ] == "00" ) {
            const stime = i[ 2 ];
            setlinkis( i[ 3 ] );
            if ( Math.round( timer ) == stime ) {
              setopen( true );
              TimeMe.stopTimer();
              let data = {
                stopped_at: format( stime ),
                total: estimated,
              };

            }
          } else if ( arr[ 0 ] == "00" && arr[ 1 ] != "00" ) {
            const stime = i[ 1 ] * 60 + +i[ 2 ];
            if ( Math.round( timer ) == stime ) {
              setopen( true );
              TimeMe.stopTimer();
              setlinkis( i[ 3 ] );
              let data = {
                stopped_at: format( stime ),
                total: estimated,
              };

            }
          } else if ( i[ 0 ] != "00" ) {
            const stime = i[ 0 ] * 3600 + i[ 1 ] * 60 + +i[ 2 ];
            if ( Math.round( timer ) == stime ) {
              setopen( true );
              TimeMe.stopTimer();
              setlinkis( i[ 3 ] );
              let data = {
                stopped_at: format( stime ),
                total: estimated,
              };
            }
          }
        } );
      } );
    }
  };

  return (
    <div className="post-description-container">
      <div style={ { display: "flex", alignItems: "center" } }>
        <BackRep to={ -1 } title={ t( "FEED" ) } />
      </div>

      { isLoading ? (
        <PostSkeleton count={ 1 } />
      ) : postInfo.length === 0 ? (
        <NoData title={ "NO_DESCRIPTION" } />
      ) : (
        <>
          <Post
            data={ postInfo }
            updatePostCommentCount={ updatePostCommentCount }
            updatePostLikeCount={ updatePostLikeCount }
            halfWidth
          />

          { open ? (
            <LinkWrapper
              title=""
              onClick={ () => setopen( false ) }
              link={ linkis }
            />
          ) : null }
        </>
      ) }
    </div>
  );
}

export default Description;
