//UTILITIES
import React, { forwardRef, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useTranslation } from "react-i18next";
import { objectCleaner } from "../../../core/functions/Functions";
import { AlertContext } from "../../../context/AlertContext";
import { useQuery } from "@tanstack/react-query";
//COMPONENT
import Button from "../../../components/button/Button";
import { Controller, useForm } from "react-hook-form";
import * as MUI from "@mui/material";
import {
  FormDropdownList,
  FormInput,
  FormPaginationDropdownList,
  FormPhoneInput,
} from "../../../components/form/form-componenrt/FormComponent";
import { phoneNumberSchema } from "../../../core/validation/form-validation";
//CSS
import "./Eshop.scss";
import { REACT_APP_IMAGE_URL } from "../../../core/services/main";
import { getCities, getRegions } from "../../../core/services/gio-db";
import { redeemAward } from "../../../core/services/RewardsRedemption";

import { DeleteArrow } from "../../../assets/images/svg";
import { getSelfAddress } from "../../../core/services/address";

const Transition = forwardRef( function Transition( props, ref ) {
  return <MUI.Slide direction="up" ref={ ref } { ...props } />;
} );



export function RedeemPoints( props ) {
  //====DECLARATIONS====
  const [ t ] = useTranslation( "common" );
  let addressTypeArray = [
    {
      name: t( 'OTHER' ),
      enum: 'other',
    }, {
      name: t( 'WORK' ),
      enum: 'work',
    } ]

  const alert = useContext( AlertContext );
  //yup schema
  const AddressSchema = yup.object( {
    new_address: yup.boolean(),
    is_save: yup.boolean(),
    address_id: yup
      .object()
      .nullable()
      .when( "new_address", {
        is: false,
        then: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
      } ),
    address: yup.string().nullable().required( "FIELD_IS_REQUIRED" ),
    phone_number: phoneNumberSchema,
    country_code: yup
      .object()
      .nullable()
      .when( "new_address", {
        is: true,
        then: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
      } ),
    city_name: yup
      .object()
      .nullable()
      .when( "new_address", {
        is: true,
        then: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
      } ),
    state: yup
      .object()
      .nullable()
      .when( "new_address", {
        is: true,
        then: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
      } ),
    postal_code: yup.string().nullable().required( "FIELD_IS_REQUIRED" ),
    address_type: yup
      .object()
      .nullable()
      .when( "is_save", {
        is: true,
        then: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
        otherwise: yup.object().nullable(),
      } ),
    address_name: yup.string()
      .nullable()
      .when( "is_save", {
        is: true,
        then: yup.string().nullable().required( "FIELD_IS_REQUIRED" ),
        otherwise: yup.string().nullable(),
      } ),
  } );
  //desctructuring Props
  const { rewardId, onHideState, onHide, redeemed } = props;
  const { countries, image, points, title } = props;

  const [ updateAddress, setUpdateAddress ] = useState( false )

  //get all user addresses
  const { data: addressData, isLoading } = useQuery( {
    queryKey: [ "self-addresses", updateAddress ],
    queryFn: () => {
      return getSelfAddress().then( ( res ) => res?.data?.data );
    }, //return arrray of object containing day slug and name translated
  } );



  //for disabling button when api is called
  const [ saveLoading, setSaveLoading ] = useState( false );
  //gio-db loadings
  const [ stateLoading, setStateLoading ] = useState( false );
  const [ cityLoading, setCityLoading ] = useState( false );
  const [ countryLoading, setCountryLoading ] = useState( false );
  //gio db states with paginatiin
  const [ address, setAddress ] = useState( [] );
  const [ stateData, setStateData ] = useState( {
    per_page: 10,
    page: 1,
    data: [],
    total: 0,
  } );
  const [ cityData, setCityData ] = useState( {
    per_page: 10,
    page: 1,
    data: [],
    total: 0,
  } );



  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    watch,
    getValues,
    formState: { errors },
  } = useForm( {
    defaultValues: {},
    resolver: yupResolver( AddressSchema ),
    reValidateMode: "onChange" | "onBlur",
  } );

  //====FUNCTIONS====
  const GetCityData = async ( isPaginated, filter ) => {
    let new_page = cityData?.page + 1;
    let offset = isPaginated
      ? cityData?.page == 1
        ? 0
        : ( cityData?.page - 1 ) * cityData?.per_page
      : 0;

    if ( watch( "state" ) ) {
      setCityLoading( true );
      getCities(
        watch( "country_code" )?.country_code,
        watch( "state" )?.isoCode,
        offset,
        10,
        filter
      )
        .then( ( res ) => {
          if ( isPaginated ) {
            setCityData( {
              ...cityData,
              page: new_page,
              data: res
                ? [ ...cityData?.data, ...res?.data ]
                : [ ...cityData?.data ],
              total: res
                ? Math.ceil( res?.metadata?.totalCount / 10 )
                : cityData?.total / 10,
            } );
          } else {
            setCityData( {
              ...cityData,
              page: 1,
              data: res ? res?.data : [],
              total: res
                ? Math.ceil( res?.metadata?.totalCount / 10 )
                : cityData?.total / 10,
            } );
          }

          setCityLoading( false );
        } )
        .catch( () => {
          setCityData( { per_page: 10, page: 1, data: [], total: 0 } );
        } );
    } else {
      setCityData( { per_page: 10, page: 1, data: [], total: 0 } );
    }
  };
  const GetStateData = async ( isPaginated, filter ) => {
    let new_page = stateData?.page + 1;
    let offset = isPaginated
      ? stateData?.page == 1
        ? 0
        : ( stateData?.page - 1 ) * stateData?.per_page
      : 0;

    if ( watch( "country_code" ) ) {
      setStateLoading( true );
      getRegions( watch( "country_code" )?.country_code, offset, 10, filter )
        .then( ( res ) => {
          if ( isPaginated ) {
            setStateData( {
              ...stateData,

              page: new_page,
              data: res
                ? [ ...stateData?.data, ...res?.data ]
                : [ ...stateData?.data ],
              total: res
                ? Math.ceil( res?.metadata?.totalCount / 10 )
                : stateData?.total / 10,
            } );
          } else {
            setStateData( {
              ...stateData,
              page: 1,
              data: res ? res?.data : [],
              total: res
                ? Math.ceil( res?.metadata?.totalCount / 10 )
                : stateData?.total / 10,
            } );
          }
        } )
        .catch( () => {
          setStateData( { per_page: 10, page: 1, data: [], total: 0 } );
          setStateLoading( false );
        } ).finally( () => {
          setStateLoading( false );
        } );
    } else {
      setStateData( { per_page: 10, page: 1, data: [], total: 0 } );
    }
  };

  const handleModal = ( e ) => {
    onHide( onHideState );
  };

  const handleSubmitForm = ( value ) => {
    setSaveLoading( true );

    let payload = {
      reward_id: rewardId,
      ...value,
      address_id: !value.new_address ? value?.address_id?.id : null,
      country_id: value?.country_code?.id,
      country_code: value?.country_code?.country_code,
      city_name: value?.city_name?.name,
      state_code: value?.state?.isoCode,
      state: value?.state?.name,
      address_type: value?.address_type?.enum,
    };

    redeemAward( objectCleaner( payload ) ).then( ( res ) => {
      if ( res?.data?.success ) {
        setUpdateAddress( !updateAddress );
        onHide();
        redeemed( true );
      }
      alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      setSaveLoading( false );

    } );
  };

  const resetToInitialAddress = ( initialAddress ) => {
    /*
    EXPLANATION:
    if new address is checked then he has to enter new address
    if old address we should by default add the first in the array
    this function is used in 2 places that's why it's reusable
    */
    reset( {
      address: initialAddress?.address,
      address_type: addressTypeArray?.find( ( el ) => el?.enum === initialAddress?.address_type ),
      country_code: initialAddress?.country,
      city_name: initialAddress?.city_name ? {
        name: initialAddress?.city_name, id: 0
      } : null,
      state: initialAddress?.state ? { isoCode: initialAddress?.state_code, name: initialAddress?.state } : null,
      postal_code: initialAddress?.postal_code,
      phone_number: initialAddress?.phones?.[ 0 ]?.contacts || "",
      address_id: {
        ...initialAddress, name: initialAddress?.address_name || `${ initialAddress?.country?.name }, ${ initialAddress?.city_name }`,
      },
      new_address: false,
      address_name: initialAddress?.address_name || ""

    } )
  }

  useEffect( () => {
    if ( addressData ) {
      let all_addresses = addressData?.addresses?.map( ( el ) => { return { ...el, name: el?.address_name || `${ el?.country?.name }, ${ el?.city_name }` } } )
      setAddress( all_addresses )
      //NOTES:only if the default selected address doesn;t have state code to get the list of state
      if ( addressData?.[ 0 ]?.state === null ) {
        GetStateData( false, "" );
      }
      resetToInitialAddress( all_addresses?.[ 0 ] )


    }
  }, [ addressData ] )



  return (
    <MUI.Dialog
      open={ onHideState }
      TransitionComponent={ Transition }
      keepMounted
      className="redeem-poper"
      aria-describedby="alert-dialog-slide-description"
      maxWidth={ "lg" }
    >
      <div className="address-popup-container">
        <MUI.IconButton className="closeButton" onClick={ handleModal }>
          <DeleteArrow />
        </MUI.IconButton>
        <div className="left-sides">
          <h3 className="header-address">{ t( "ADDRESS" ) }</h3>

          <form
            className="body-address"
            onKeyDown={ ( e ) => e.key === "Enter" && e.preventDefault() }
            onSubmit={ handleSubmit( handleSubmitForm ) }
          >
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormDropdownList
                  required={ !watch( 'new_address' ) }
                  disabled={ watch( "new_address" ) }
                  label={ "SELECT_ADDRESS" }
                  placeholder={ 'SELECT_ADDRESS' }
                  value={ value }
                  data={ address }
                  onChange={ ( value ) => {
                    reset( {
                      address: value?.address,
                      country_code: value?.country,
                      city_name: value?.city_name ? {
                        name: value?.city_name, id: 0
                      } : null,
                      state: value?.state_code ? { isoCode: value?.state_code, name: value?.state } : null,
                      postal_code: value?.postal_code,
                      phone_number: value?.phones?.[ 0 ]?.contacts || "",
                      address_id: value,
                      new_address: false,
                      address_name: value?.address_name || "",
                      address_type: value?.address_type ? addressTypeArray?.find( ( el ) => el?.enum === value?.address_type ) : null,

                    } )
                  } }
                  accessValue="id"
                  //if the address already have a name then display the name otherwise the address
                  accessName={ "name" }
                />
              ) }
              name={ `address_id` }
              control={ control }
            />

            <Controller
              name={ `new_address` }
              control={ control }
              render={ ( {
                field: { value },
              } ) => (
                <MUI.FormControlLabel
                  control={
                    <MUI.Switch
                      checked={ value }
                      onChange={ ( e ) => {
                        if ( e?.target.checked ) {
                          reset( {
                            address: "",
                            country_code: null,
                            city_name: null,
                            state: null,
                            postal_code: "",
                            phone_number: "",
                            address_id: null,
                            new_address: e?.target?.checked,
                            address_name: "",
                            address_type: null,

                          } )
                        } else {
                          resetToInitialAddress( address?.[ 0 ] )
                        }

                      } }
                      inputProps={ { "aria-label": "controlled" } }
                    />
                  }
                  label={ t( "NEW_ADDRESS" ) }
                />
              ) }
            />
            <div className="redeem-address-details">
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormDropdownList
                    // input is disabled if the address is not new and there is incomplete information
                    disabled={ !watch( "new_address" ) && watch( 'address_id' )?.country?.country_code !== "" }
                    required
                    label="COUNTRY"
                    placeholder="SELECT_COUNTRY"
                    name="country_code"
                    data={ countries }
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value );
                      setValue( "state", null, { shouldValidate: true } );
                      setValue( "city_name", null, { shouldValidate: true } );
                      clearErrors( [ "city_id", "state", 'country_code' ] );
                      GetStateData( false, "" );
                    } }
                    loading={ countryLoading }
                    helperText={ error?.message }
                    accessName="name"
                    accessValue="country_code"
                  />
                ) }
                name={ `country_code` }
                control={ control }
              />
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPaginationDropdownList
                    required
                    // input is disabled if the address is not new and there is incomplete information
                    disabled={ !watch( "new_address" ) && watch( 'address_id' )?.state_code !== null }
                    label="DISTRICT"
                    name="state"
                    data={ stateData }
                    loading={ stateLoading }
                    noOptionsText={
                      !watch( "country_code" )
                        ? "SELECT_COUNTRY_FIRST"
                        : "NO_OPTIONS"
                    }
                    placeholder="ENTER_DISTRICT"
                    value={ value }
                    accessValue={ "isoCode" }
                    handleResetData={ GetStateData }
                    onChange={ ( value ) => {
                      onChange( value );
                      setValue( "city_name", null, { shouldValidate: true } );
                      clearErrors( [ "city_name", "state" ] );
                      GetCityData( false, "" );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name="state"
                control={ control }
              />

              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPaginationDropdownList
                    required
                    // input is disabled if the address is not new and there is incomplete information
                    disabled={ !watch( "new_address" ) && watch( 'address_id' )?.city_name !== null }
                    data={ cityData }
                    label="CITY"
                    placeholder="SELECT_CITY"
                    name="city_name"
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value );
                      clearErrors( [ "city_name" ] );
                    } }
                    handleResetData={ GetCityData }
                    helperText={ error?.message }
                    loading={ cityLoading }
                    noOptionsText={
                      !watch( "state" ) ? "SELECT_DISTRICT_FIRST" : "NO_OPTIONS"
                    }
                  />
                ) }
                name="city_name"
                control={ control }
              />
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormInput
                    required
                    // input is disabled if the address is not new and there is incomplete information
                    disabled={ !watch( "new_address" ) && watch( 'address_id' )?.address !== "" }
                    label={ "ADDRESS" }
                    placeholder={ "ENTER_ADDRESS" }
                    name="address"
                    value={ value }
                    onChange={ ( value ) => {

                      onChange( value )
                      clearErrors( [ 'address' ] )
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name={ `address` }
                control={ control }
              />

              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormPhoneInput
                    required
                    // input is disabled if the address is not new and there is incomplete information
                    disabled={ !watch( "new_address" ) && watch( 'address_id' )?.phones?.[ 0 ] }
                    label={ "PHONE_NUMBER" }
                    placeholder={ "ENTER_PHONE_NUMBER" }
                    name="phone_number"
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value );
                      clearErrors( [ "phone_number" ] );
                    } }
                    helperText={ error?.message }
                  />
                ) }
                name={ `phone_number` }
                control={ control }
              />


              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormInput
                    // input is disabled if the address is not new and there is incomplete information
                    disabled={ !watch( "new_address" ) && watch( 'address_id' )?.postal_code !== null }
                    required
                    label={ "POSTAL_CODE" }
                    placeholder={ "ENTER_CODE" }
                    name="postal_code"
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value )
                      clearErrors( [ "postal_code" ] );

                    } }
                    helperText={ error?.message }
                  />
                ) }
                name={ `postal_code` }
                control={ control }
              />

            </div>







            { watch( "new_address" ) && (
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <MUI.FormControlLabel
                    control={ <MUI.Checkbox /> }
                    label={ t( "SAVE_ADDRESS" ) }
                    checked={ value }
                    onChange={ onChange }
                  />
                ) }
                name={ `is_save` }
                control={ control }
              /> ) }
            {/*if this is a new address and it will be saved or it it's a already saved address */ }
            { ( watch( "is_save" ) === true || !watch( 'new_address' ) ) && (
              <div className="redeem-address-details">

                <Controller
                  render={ ( {
                    field: { onChange, value },
                    fieldState: { error },
                  } ) => (
                    <FormInput
                      // input is disabled if  there is incomplete information
                      disabled={ watch( "address_id" )?.address_name && watch( "address_id" )?.address_name !== "" }
                      required
                      label={ "ADDRESS_NAME" }
                      placeholder={ "ENTER_NAME" }
                      name="address_name"
                      value={ value }
                      onChange={ ( value ) => {
                        clearErrors( [ 'address_name' ] )
                        onChange( value )

                      } }
                      helperText={ error?.message }
                    />
                  ) }
                  name={ `address_name` }
                  control={ control }
                />
                <Controller
                  render={ ( {
                    field: { onChange, value },
                    fieldState: { error },
                  } ) => (
                    <FormDropdownList
                      // input is disabled if  there is incomplete information
                      disabled={ watch( "address_id" )?.address_type && watch( "address_id" )?.address_type !== "" }
                      required
                      label="TYPE"
                      placeholder="SELECT_TYPE"
                      name="address_type"
                      data={ addressTypeArray }
                      value={ value }
                      onChange={ ( value ) => onChange( value ) }
                      helperText={ error?.message }
                      accessName="name"
                      accessValue="enum"
                    />
                  ) }
                  name={ `address_type` }
                  control={ control }
                /></div>
            ) }
            <div className="button-address">
              <Button name="CANCEL" onClick={ handleModal } />
              <Button
                loading={ saveLoading }
                disabled={ saveLoading }
                isSelected
                name="CONTINUE"
                type="submit"
              />
            </div>
          </form>
        </div>
        <div className="right-side">
          <h5>{ t( "REDEEMED_PRODUCT" ) }</h5>
          <div className="image-address">
            <img src={ REACT_APP_IMAGE_URL + image } alt="" />
            <div className="redeemed-by">
              <div>{ title }</div>
              <div className="points-address">
                <b>
                  { points }
                  { "\n" } { t( points > 1 ? "POINTS" : "POINT" ) }
                </b>
              </div>
            </div>
          </div>
          <hr />
          <h6 style={ { fontWeight: "700" } }>{ t( "DESCRIPTION" ) }</h6>
          <div className="description-address">
            <p>{ props.desc }</p>
          </div>
          <div className="reddeem-total-container">
            <div className="total-address">
              <p>{ t( "TOTAL_POINTS" ) } </p>
              <p>{ t( "REDEEMED_POINTS" ) } </p>
              <p>{ t( "POINTS" ) }</p>
            </div>
            <div className="number-address">
              <p>{ props.userTotalPoint }</p>
              <p>{ props.points }</p>
              <p>{ props.userTotalPoint - props.points }</p>
            </div>
          </div>
        </div>
      </div>
    </MUI.Dialog>
  );
}

export default RedeemPoints;
