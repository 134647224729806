import React, { useEffect, useState } from "react";
import { Link, Outlet } from "react-router-dom";
import SideComponent from "../../layout/sideBar/SideComponent";
import { useTranslation } from "react-i18next";
import FilterPopUp from "../../components/filter-popup/FilterPopUp";
import { Tab, Tabs } from "@mui/material";
import { useMediaQuery } from "react-responsive";

const links = [
  { to: `requests`, name: "REQUESTS" },
  { to: `my-connections`, name: "CONNECTIONS" },
  { to: `discover`, name: "DISCOVER" },
];

const Connections = () => {
  const [ activeTab, setActiveTab ] = useState( 0 );
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );
  const [ t ] = useTranslation( "common" );
  const handleTabChange = ( event, newValue ) => {
    setActiveTab( newValue );
  };

  useEffect( () => {
    let active_link = links?.findIndex( ( el ) => window.location.pathname.includes( `/${ el?.to }` ) )
    setActiveTab( active_link || 0 )
  }, [ window.location.pathname ] )

  return (
    <div className="globalStructure">
      <SideComponent
        canSearch
        placeholder="SEARCH_BY_NAME"
        title={ t( "CONNECTIONS" ) }
        addFilter={
          <FilterPopUp
            filterByAllSpecialities
            filterByRoles /> }
      >
        <div className="navs">
          <Tabs
            onChange={ handleTabChange }
            value={ activeTab }
            orientation={ isSmallScreen ? "horizontal" : "vertical" }
            variant={ isSmallScreen && "scrollable" }
            allowScrollButtonsMobile
            indicatorColor="none"
          >
            { links.map( ( e, index ) => (
              <Tab
                label={ t( e.name ) }
                key={ index }
                component={ Link }
                to={ e.to }
                className="links"
                value={ index }
                sx={ {
                  "&.Mui-selected": {
                    // Styles for the active tab
                    backgroundColor: "#d6f3f4",
                    color: "#02c0cc",
                    borderBottom: "2px solid #02c0cc",
                  },
                } }
              ></Tab>
            ) ) }
          </Tabs>
        </div>
      </SideComponent>
      <div className="middleNoRightComponent">
        <Outlet />
      </div>
    </div>
  );
};

export default Connections;
