//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { AlertContext } from "../../context/AlertContext";
import { NotificationContext } from "../../context/NotificationContext";
import { AppContext } from "../../AppContext";
import { useTranslation } from "react-i18next";
import { UserContext } from "../../context/UserContext";
import { useSearchParams } from "react-router-dom/dist";
//COMPONENT
import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";
import { NoGroupsInvitations } from "../../components/Fallback/NoData";
import { Grid } from "@mui/material";
import GroupsInvitation from "../../components/cards/GroupsInvitation";
import { dataPerPage } from "../../core/variables/Variables";
import { GroupSkeleton } from "../../components/skeletons/SkeletonsComponent";
import { groupParamsByKey } from "../../core/functions/Functions";
//API
import * as groupsData from "../../core/services/groups";
//CSS
import "./Groups.scss";

function Invitations() {
  const [ t ] = useTranslation( "common" );
  const { joinedGroups, setJoinedGroups } = useContext( AppContext );
  const [ searchParams ] = useSearchParams();
  const { search } = groupParamsByKey( searchParams ) || {};
  const { getSelfUser } = useContext( UserContext );
  const { updateNotificationCount } = useContext( NotificationContext );
  const Alert = useContext( AlertContext );
  //VARIABLES
  const [ data, setData ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( false );
  const [ totalPage, setTotalPage ] = useState( 1 );
  const [ isLoadMore, setIsLoadMore ] = useState( false );
  const [ loadingActions, setLoadingActions ] = useState( { accept: false, reject: false } );
  const [ page, setPage ] = useState( 1 );

  const getAllInvitations = ( pagination ) => {
    let handleLoad = pagination ? setIsLoadMore : setIsLoading;
    handleLoad( true );

    let new_data = [];
    let current_page =
      pagination && page + 1 <= totalPage
        ? page < totalPage
          ? page + 1
          : page
        : 1;
    groupsData
      .getInvitation( {
        name: search,
        page: current_page,
        per_page: dataPerPage,
        sort: 2,
      } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          setPage( res?.data?.data?.current_page );
          setTotalPage( res.data.data?.last_page );
          if ( pagination ) {
            new_data = [ ...data, ...res?.data?.data?.data ];
          } else {
            new_data = res.data?.data?.data || [];
          }
          setData( new_data );
        } else {
          Alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
        }
        handleLoad( false );
      } );
  };

  const handleInviteResponse = ( id, response ) => {
    setLoadingActions( { accept: response, reject: response === false ? true : false } )
    let data = {
      group_id: id,
      is_accepted: response,
    };
    groupsData.AcceptOrReject( data ).then( ( res ) => {
      if ( res?.data?.success ) {
        getAllInvitations( false );
        getSelfUser();
        updateNotificationCount()
        setJoinedGroups( response ? joinedGroups + 1 : joinedGroups - 1 );
      }

      Alert?.[ res?.data?.success ? "success" : "warning" ](
        res.data ? res.data?.message : res?.message
      );
      setLoadingActions( { accept: false, reject: false } )
    } );
  };

  useEffect( () => {
    getAllInvitations( false );
  }, [ search ] );


  return (
    <div className=" middleNoRightComponent">
      { isLoading ? (
        <GroupSkeleton count={ 3 } button />
      ) : data?.length === 0 ? (
        <NoGroupsInvitations />
      ) : (
        <Grid
          container
          spacing={ { xs: 2, md: 3 } }
          columns={ { xs: 4, sm: 8, md: 12 } }
        >
          { data?.map( ( e ) => (
            <Grid item xs={ 4 } sm={ 4 } md={ 4 }>
              <GroupsInvitation
                key={ e?.id }
                img={ REACT_APP_IMAGE_URL + e?.profile_image }
                groupName={ e?.title }
                loadingActions={ loadingActions }
                members={ e?.users_count }
                notification={ e?.description }
                onReject={ () => handleInviteResponse( e?.id, false ) }
                onAccept={ () => handleInviteResponse( e?.id, true ) }
                id={ e.id }
              />
            </Grid>
          ) ) }
          { page < totalPage && !isLoading && (
            <LoadMoreButton
              isLoadMore={ isLoadMore }
              title="LOAD_MORE"
              onClick={ () => getAllInvitations( true ) }
            />
          ) }
        </Grid>
      ) }
    </div>
  );
}

export default Invitations;
