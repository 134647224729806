import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

//COMPONENT
import TitleComponent from "../../../components/title-component/TitleComponent";
import { dateFormat } from "../../../core/variables/Variables";
import { Dialog, DialogContent } from "@mui/material";
import DialogCustomTitle from "../../../components/dialog/DialogCustomTitle";
import NoData from "../../../components/Fallback/NoData";
import { DisplayDateTime } from "../../../components/display-component/DisplayComponent";
//CSS
import "./MyRedemptions.scss";
import { UserContext } from "../../../context/UserContext";





export function RedemptionDetail( props ) {
    //TRANSLATION
    const [ t ] = useTranslation( "common" );
    const { data, onClose } = props;
    const { user } = useContext( UserContext )

    return (
        <Dialog
            disablebackdropclick="true"
            fullWidth
            open={ true }
            maxWidth="xl">
            <DialogCustomTitle
                title={ "REDEMPTION_DETAIL" }
                onClose={ () => onClose() } />

            <DialogContent>
                { !data ?
                    <NoData />
                    : (
                        <>

                            <div className="redemption-detail-content">
                                <div className="body-item">
                                    <div>{ t( "REWARD" ) }</div>
                                    <div>{ data?.reward?.name }</div>
                                </div>

                                <div className="body-item">
                                    <div>{ t( "REDEMPTION_DATE" ) }</div>
                                    <div>
                                        <DisplayDateTime data={ data?.created_at } format={ dateFormat } />
                                    </div>
                                </div>

                                <div className="body-item">
                                    <div>{ t( "REDEEM_BY" ) }</div>
                                    <div>
                                        { user?.first_name + " " + user?.last_name }
                                    </div>
                                </div>

                                <div className="body-item">
                                    <div>{ t( "POINTS" ) }</div>
                                    <div>{ data?.amount }</div>
                                </div>
                                <div className="body-item">
                                    <div>{ t( "STATUS" ) }</div>
                                    <div>
                                        { data?.redemption_status?.name }
                                    </div>
                                </div>
                                { data?.reasons?.[ 0 ] && (
                                    <div className="body-item">
                                        <div>{ t( "REASON" ) }</div>
                                        <div>
                                            { data?.reasons?.[ 0 ]?.reason }
                                        </div>
                                    </div> ) }
                                <div className="body-item">
                                    <div>{ t( "UPDATED_DATE" ) }</div>
                                    <div>
                                        { data?.redemption_status?.id !== 1 ? (
                                            <DisplayDateTime
                                                data={
                                                    data?.redemption_status ? data?.updated_at : "-"
                                                }
                                                format={ dateFormat }
                                            />
                                        ) : (
                                            "-"
                                        ) }
                                    </div>
                                </div>

                            </div>
                            <TitleComponent title='REDEMPTION_ADDRESS' classNames='title18' />

                            <div className="redemption-detail-content">

                                <div className="body-item">
                                    <div>{ t( "COUNTRY" ) }</div>
                                    <div>
                                        { data?.address?.country?.name || data?.country?.name || "-" }
                                    </div>
                                </div>
                                <div className="body-item">
                                    <div>{ t( "STATE" ) }</div>
                                    <div>
                                        { data?.address?.state || data?.state || "-" }
                                    </div>
                                </div>

                                <div className="body-item">
                                    <div>{ t( "CITY" ) }</div>
                                    <div>
                                        { data?.address?.city_name || data?.city_name || "-" }
                                    </div>
                                </div>

                                <div className="body-item">
                                    <div>{ t( "POSTAL_CODE" ) }</div>
                                    <div>
                                        { data?.address?.postal_code || data?.postal_code || "-" }
                                    </div>
                                </div>
                                <div className="body-item">
                                    <div>{ t( "ADDRESS" ) }</div>
                                    <div>
                                        { data?.address?.address || data?.address_line || "-" }
                                    </div>
                                </div>
                                <div className="body-item">
                                    <div>{ t( "PHONE_NUMBER" ) }</div>
                                    <div>
                                        { data?.address?.phones?.[ 0 ]?.contacts || data?.phone_number || "-" }
                                    </div>
                                </div>
                            </div>


                        </> ) }
            </DialogContent>
        </Dialog>
    );
}

export default RedemptionDetail;
