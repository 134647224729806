//REACT UTILITIES
import React, { forwardRef, useContext, useEffect, useState } from "react";
import * as yup from "yup";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useQuery } from "@tanstack/react-query";
import { AlertContext } from "../../../context/AlertContext";
//COMPONENT
import { Dialog, DialogContent, IconButton, Slide } from "@mui/material";
import {
  FormDropdownList,
  FormInput,
  FormPaginationDropdownList,
  FormPhoneInput,
} from "../../../components/form/form-componenrt/FormComponent";
import { DeleteArrow } from "../../../assets/images/svg";
import { phoneNumberSchema } from "../../../core/validation/form-validation";
//API
import { getCities, getRegions } from "../../../core/services/gio-db";
import {
  addAddress,
  getAllCountries,
  updateAddress,
} from "../../../core/services/address";
import DialogCustomTitle from "../../../components/dialog/DialogCustomTitle";
import Button from "../../../components/button/Button";
import { useTranslation } from "react-i18next";

const Transition = forwardRef( function Transition( props, ref ) {
  return <Slide direction="up" ref={ ref } { ...props } />;
} );

export const AddressHandle = ( props ) => {
  const alert = useContext( AlertContext );
  const [ t ] = useTranslation( "common" );
  const [ saveLoading, setSaveLoading ] = useState( false );
  const { onClose, data, onSuccess } = props; //data in case it's an edit
  //gio-db loadings
  const [ stateLoading, setStateLoading ] = useState( false );
  const [ cityLoading, setCityLoading ] = useState( false );
  const [ stateData, setStateData ] = useState( {
    per_page: 10,
    page: 1,
    data: [],
    total: 0,
  } );
  const [ cityData, setCityData ] = useState( {
    per_page: 10,
    page: 1,
    data: [],
    total: 0,
  } );
  const addressTypeData = [
    { enum: "work", name: t( "WORK" ) },
    {
      enum: "other",
      name: t( "OTHER" ),
    },
  ];
  //get all user countries
  const { data: countries, countryLoading } = useQuery( {
    queryKey: [ "all-countries" ],
    queryFn: () => {
      return getAllCountries().then( ( res ) => res?.data?.data );
    }, //return arrray of object containing day slug and name translated
  } );

  //yup schema
  const AddressSchema = yup.object( {
    address: yup.string().required( "FIELD_IS_REQUIRED" ),
    address_name: yup.string().required( "FIELD_IS_REQUIRED" ),
    phone_number: phoneNumberSchema,
    country_code: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
    city_name: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
    state: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
    postal_code: yup.string().required( "FIELD_IS_REQUIRED" ),
    address_type: yup.object().nullable().required( "FIELD_IS_REQUIRED" ),
  } );

  //INITIATE USE FORM
  const {
    control,
    handleSubmit,
    reset,
    clearErrors,
    setValue,
    watch,
    getValues,
    defaultValues,
    formState: { errors },
  } = useForm( {
    defaultValues: {
      address: data?.address || "",
      country_code: data?.country || null,
      city_name: data?.city_name
        ? {
          name: data?.city_name,
          id: 0,
        }
        : null,
      state: data?.state_code
        ? { isoCode: data?.state_code, name: data?.state }
        : null,
      postal_code: data?.postal_code || "",
      phone_number: data?.phones?.[ 0 ]?.contacts || "",
      address_name: data?.address_name || "",
      address_type: data
        ? addressTypeData?.find( ( el ) => el?.enum === data?.address_type )
        : null,
    },
    resolver: yupResolver( AddressSchema ),
    reValidateMode: "onChange" | "onBlur",
  } );


  //FUNCTIONS
  //====FUNCTIONS====
  const GetCityData = async ( isPaginated, filter ) => {
    let new_page = cityData?.page + 1;
    let offset = isPaginated
      ? cityData?.page == 1
        ? 0
        : ( cityData?.page - 1 ) * cityData?.per_page
      : 0;

    if ( watch( "state" ) ) {
      setCityLoading( true );
      getCities(
        watch( "country_code" )?.country_code,
        watch( "state" )?.isoCode,
        offset,
        10,
        filter
      )
        .then( ( res ) => {
          if ( isPaginated ) {
            setCityData( {
              ...cityData,
              page: new_page,
              data: res
                ? [ ...cityData?.data, ...res?.data ]
                : [ ...cityData?.data ],
              total: res
                ? Math.ceil( res?.metadata?.totalCount / 10 )
                : cityData?.total / 10,
            } );
          } else {
            setCityData( {
              ...cityData,
              page: 1,
              data: res ? res?.data : [],
              total: res
                ? Math.ceil( res?.metadata?.totalCount / 10 )
                : cityData?.total / 10,
            } );
          }

          setCityLoading( false );
        } )
        .catch( () => {
          setCityData( { per_page: 10, page: 1, data: [], total: 0 } );
        } );
    } else {
      setCityData( { per_page: 10, page: 1, data: [], total: 0 } );
    }
  };
  const GetStateData = async ( isPaginated, filter ) => {
    let new_page = stateData?.page + 1;
    let offset = isPaginated
      ? stateData?.page == 1
        ? 0
        : ( stateData?.page - 1 ) * stateData?.per_page
      : 0;

    if ( watch( "country_code" ) ) {
      setStateLoading( true );
      getRegions( watch( "country_code" )?.country_code, offset, 10, filter )
        .then( ( res ) => {
          if ( isPaginated ) {
            setStateData( {
              ...stateData,

              page: new_page,
              data: res
                ? [ ...stateData?.data, ...res?.data ]
                : [ ...stateData?.data ],
              total: res
                ? Math.ceil( res?.metadata?.totalCount / 10 )
                : stateData?.total / 10,
            } );
          } else {
            setStateData( {
              ...stateData,
              page: 1,
              data: res ? res?.data : [],
              total: res
                ? Math.ceil( res?.metadata?.totalCount / 10 )
                : stateData?.total / 10,
            } );
          }
        } )
        .catch( () => {
          setStateData( { per_page: 10, page: 1, data: [], total: 0 } );
          setStateLoading( false );
        } ).finally( () => {
          setStateLoading( false );
        } );
    } else {
      setStateData( { per_page: 10, page: 1, data: [], total: 0 } );
    }
  };

  const handleSubmitForm = ( value ) => {
    setSaveLoading( true );

    let payload = {
      ...( data && { address_id: data?.id } ),
      ...value,
      is_default: data ? data?.is_default : false,
      country_id: value?.country_code?.id,
      country_code: value?.country_code?.country_code,
      city_name: value?.city_name?.name,
      state_code: value?.state?.isoCode,
      state: value?.state?.name,
      address_type: value?.address_type?.enum,
    };


    let handleApi = data ? updateAddress : addAddress;

    handleApi( payload ).then( ( res ) => {
      if ( res?.data?.success ) {
        onSuccess();
        onClose();
      }
      alert?.[ res?.data?.success ? "success" : "warning" ]( res?.data ? res?.data?.message : res?.message );
      setSaveLoading( false );
    } );
  };

  useEffect( () => {
    GetStateData( false, "" );
    GetCityData( false, "" );
  }, [] );
  return (
    <>
      { " " }
      <Dialog
        TransitionComponent={ Transition }
        disablebackdropclick="true"
        fullWidth
        open={ true }
      >
        <DialogCustomTitle
          title={ `${ data ? "EDIT" : "ADD" }_ADDRESS` }
          onClose={ () => onClose() }
        />
        <DialogContent>
          <form
            className="address-handle-container"
            onSubmit={ handleSubmit( handleSubmitForm ) }
          >
            <div>
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormInput
                    required
                    label={ "ADDRESS_NAME" }
                    placeholder={ "ENTER_NAME" }
                    name="address_name"
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value )
                      clearErrors( [ 'address_name' ] )

                    } }
                    helperText={ error?.message }
                  />
                ) }
                name={ `address_name` }
                control={ control }
              />
              <Controller
                render={ ( {
                  field: { onChange, value },
                  fieldState: { error },
                } ) => (
                  <FormDropdownList
                    disabled={ data }
                    required
                    label="TYPE"
                    placeholder="SELECT_TYPE"
                    name="address_type"
                    data={ addressTypeData }
                    value={ value }
                    onChange={ ( value ) => {
                      onChange( value );
                      clearErrors( [ 'address_type' ] )
                    } }
                    helperText={ error?.message }
                    accessName="name"
                    accessValue="enum"
                  />
                ) }
                name={ `address_type` }
                control={ control }
              />
            </div>
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormDropdownList
                  required
                  label="COUNTRY"
                  placeholder="SELECT_COUNTRY"
                  name="country_code"
                  data={ countries || [] }
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value );
                    setValue( "state", null, { shouldValidate: true } );
                    setValue( "city_name", null, { shouldValidate: true } );
                    clearErrors( [ "city_id", "state", "country_code" ] );
                    GetStateData( false, "" );
                  } }
                  loading={ countryLoading }
                  helperText={ error?.message }
                  accessName="name"
                  accessValue="country_code"
                />
              ) }
              name={ `country_code` }
              control={ control }
            />
            {/* District Input */ }
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormPaginationDropdownList
                  required
                  label="DISTRICT"
                  name="state"
                  data={ stateData }
                  loading={ stateLoading }
                  noOptionsText={
                    !watch( "country_code" )
                      ? "SELECT_COUNTRY_FIRST"
                      : "NO_OPTIONS"
                  }
                  placeholder="ENTER_DISTRICT"
                  value={ value }
                  accessValue={ "isoCode" }
                  handleResetData={ GetStateData }
                  onChange={ ( value ) => {
                    onChange( value );
                    setValue( "city_name", null, { shouldValidate: true } );
                    clearErrors( [ "city_name", "state" ] );
                    GetCityData( false, "" );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name="state"
              control={ control }
            />
            {/* City Input */ }
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormPaginationDropdownList
                  required
                  data={ cityData }
                  label="CITY"
                  placeholder="SELECT_CITY"
                  name="city_name"
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value );
                    clearErrors( [ "city_name" ] );
                  } }
                  handleResetData={ GetCityData }
                  helperText={ error?.message }
                  loading={ cityLoading }
                  noOptionsText={
                    !watch( "state" ) ? "SELECT_DISTRICT_FIRST" : "NO_OPTIONS"
                  }
                />
              ) }
              name="city_name"
              control={ control }
            />
            {/* Address Input */ }
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  required
                  label={ "ADDRESS" }
                  placeholder={ "ENTER_ADDRESS" }
                  name="address"
                  value={ value }
                  onChange={ ( value ) => {

                    onChange( value )
                    clearErrors( [ "address" ] );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name={ `address` }
              control={ control }
            />
            {/* phone number */ }
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormPhoneInput
                  required
                  label={ "PHONE_NUMBER" }
                  placeholder={ "ENTER_PHONE_NUMBER" }
                  name="phone_number"
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value );
                    clearErrors( [ "phone_number" ] );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name={ `phone_number` }
              control={ control }
            />

            {/* Postal Code Input */ }
            <Controller
              render={ ( {
                field: { onChange, value },
                fieldState: { error },
              } ) => (
                <FormInput
                  required
                  label={ "POSTAL_CODE" }
                  placeholder={ "ENTER_CODE" }
                  name="postal_code"
                  value={ value }
                  onChange={ ( value ) => {
                    onChange( value )
                    clearErrors( [ "postal_code" ] );
                  } }
                  helperText={ error?.message }
                />
              ) }
              name={ `postal_code` }
              control={ control }
            />
            <div className="address-action">
              <Button name="CANCEL" onClick={ onClose } />
              <Button
                loading={ saveLoading }
                disabled={ saveLoading }
                isSelected
                name="SAVE"
                type="submit"
              />
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </>
  );
};
