import PrivacyPolicy from "../../assets/footerPDF/PrivacyPolicy.pdf";
import GeneralTermsOfUse from "../../assets/footerPDF/GeneralTermsOfUse.pdf";
import SpecificTermsOfUse from "../../assets/footerPDF/SpecificTermsOfUse.pdf";
import palette from "../../theme/color.scss";
import { LogoSVG } from "../../assets/icons/SVG";
import { Link } from "react-router-dom";
import {
  AppStoreSVG,
  FacebookSVG,
  LinkedInSVG,
  PlayStoreSVG,
} from "../../assets/icons/LandingPageSVG";
import "./Footer.scss";
import { useTranslation } from "react-i18next";
import { DisplayTooltip } from "../../components/display-component/DisplayComponent";

export const Footer = () => {
  const [ t ] = useTranslation( "common" );
  return (
    <footer className="footer">
      <div className="sections">
        <div className="logo-description">
          <Link to={ `../homepage` }>
            <LogoSVG />
          </Link>
        </div>

        <div className="about-us">
          <p className="title">{ t( "ABOUT US" ) }</p>
          <a href={ `../contact-us` }> { t( "CONTACT US" ) }</a>
          <a href={ PrivacyPolicy } target="_blank">
            { t( "PRIVACY POLICY" ) }
          </a>
          <a href={ GeneralTermsOfUse } target="_blank">
            { t( "GENERAL TERMS OF USE" ) }
          </a>
          <a href={ SpecificTermsOfUse } target="_blank">
            { t( "SPECIFIC TERMS OF USE" ) }
          </a>
        </div>
        <DisplayTooltip
          title="Coming Soon"
          followCursor
          componentsProps={ {
            tooltip: {
              sx: {
                marginTop: "15px",
                fontSize: "0.8rem",
                backgroundColor: palette.primaryColor,
              },
            },
            arrow: {
              backgroundColor: palette.primaryColor,
            },
          } }
        >
          <div className="support">
            <p className="title">{ t( "SUPPORT" ) }</p>
            <a> { t( "SUPPORT" ) }</a>
            <a> { t( "HELP" ) }</a>
            <a> { t( "FAQ" ) }</a>
          </div></DisplayTooltip>

        <DisplayTooltip
          title="Coming Soon"
          followCursor
          componentsProps={ {
            tooltip: {
              sx: {
                marginTop: "15px",
                fontSize: "0.8rem",
                backgroundColor: palette.primaryColor,
              },
            },
            arrow: {
              backgroundColor: palette.primaryColor,
            },
          } }
        >
          <div className="keep-in-touch">
            <p className="title">{ t( "KEEP IN TOUCH" ) }</p>
            <div className="social-platforms">
              <Link to={ "https://www.linkedin.com/" }>
                <LinkedInSVG />
              </Link>
              <Link to={ "https://www.facebook.com/" }>
                <FacebookSVG />
              </Link>
              <Link to={ "https://play.google.com" }>
                <PlayStoreSVG />
              </Link>
              <Link to={ "https://www.apple.com/app-store/" }>
                <AppStoreSVG />
              </Link>
            </div>
          </div>
        </DisplayTooltip>
      </div>
      <hr />
      <div className="all-rights-reserved">
        © Meet@Med 2023. { t( "ALL RIGHST RESERVED." ) }
      </div>
    </footer>
  );
};

