//UTILITIES

import React, { useContext, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

import "./CardReward.scss";

//COMPONENT
import DialogComponent from "../dialog/Dialog";
import { Typography, CardContent } from "@mui/material";
import Button from "../../components/button/Button";
import {
  AlertDialogSlide,
  ShowDescDialog,
} from "../../components/popup/Popup";
import RedeemPoints from "../../pages/rewardsDetails/Eshop/RedeemPoints";
import { PermissionContext } from "../../context/PermissionContext";
import { LazyLoadImage } from "react-lazy-load-image-component";

const CardReward = ( props ) => {
  const permissions = useContext( PermissionContext )
  const { title, description, points, image, showPopup, showButton } = props;
  const { waitingConfirmation, withDetails, userTotalPoint, defaultImage } = props;
  const { countries, newAddress, rewardId } = props;

  const [ t ] = useTranslation( "common" );
  const [ openNotice, setOpenNotice ] = useState( false );
  const [ showDesc, setShowDesc ] = useState( false );
  const [ showredeemed, setShowredeemed ] = useState( false );
  const [ showModal, setShowModal ] = useState( false );

  const handleModalShowHide = () => {
    setShowDesc( false );
    setOpenNotice( true );
  };

  const handleNoticeResponse = () => {
    setTimeout( () => {
      setOpenNotice( false );
    }, 0 );
    setShowModal( true );
  };
  const handleModalShowHideredeemed = () => setShowredeemed( !showredeemed );

  const handleClickredeemed = () => {
    setShowredeemed( !showredeemed );
  };
  const rewardPermissions = () => {
    //if the user is not waiting the confirmation of the admin and is redeeming the reward
    if ( !waitingConfirmation && permissions?.Redemption?.[ 'redeem-award' ] ) {
      return true
    } else {
      return false
    }


  }

  return (
    <div style={ { maxWidth: "500px" } } className="reward-card-jh">
      <div className="card-image cursor-pointer"
        onClick={ () => withDetails && setShowDesc( true ) }

      >
        <LazyLoadImage
          alt={ 'image' }
          src={ image ? process.env.REACT_APP_IMAGE_URL + image : defaultImage }
          onError={ ( { target } ) => {
            // Handle image loading error
            target.src = defaultImage;

          } }

        />
      </div>

      <CardContent>
        <div
          style={ {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          } }
        >
          <div
            className="title"
            onClick={ () => withDetails && setShowDesc( true ) }
          >
            { title }
          </div>
          { points && (
            <p style={ { fontSize: "15px", color: "#02C0CC" } }>
              <span>{ points } </span>
              { points === 1 ? "Pt" : "Pts" }
            </p>
          ) }
        </div>

        <Typography
          className="description cursor-pointer"
          color="text.secondary"
          onClick={ () => withDetails && setShowDesc( true ) }
        >
          { description }
        </Typography>
        { showButton && rewardPermissions && ( <div className="cardd-footer">

          <Button
            isSelected
            name={ waitingConfirmation ? t( "WAITING FOR CONFIRMATION" ) : t( "REDEEM" )

            }
            width="100%"
            onClick={ showPopup ? handleModalShowHide : () => { } }
            disabled={ waitingConfirmation }
          />

        </div> ) }
      </CardContent>
      { showDesc && (
        <ShowDescDialog
          onCancel={ () => setShowDesc( false ) }
          title={ title }
          body={ description }
          image={ image }
          onHideState={ showDesc }
          onHide={ () => {
            withDetails && setShowDesc( false );
          } }
        />
      ) }

      { openNotice && (
        <DialogComponent
          message={
            userTotalPoint < points
              ? `YOU_DONT_HAVE_ENOUGH_POINTS`
              : `${ t( "ARE_YOU_SURE_YOU_WANT_TO_REDEEM" ) } ${ title }?\n${ t(
                "A_TOTAL_OF"
              ) } ${ points } ${ points > 1 ? t( "POINTS" ) : t( "POINT" ) } ${ t(
                "WILL_BE_DEDUCTED_FROM_YOUR_BALANCE"
              ) }`
          }
          actions={ userTotalPoint < points ? false : true }
          onClose={ () => setOpenNotice( false ) }
          handleResponse={ handleNoticeResponse }
        />
      ) }
      { showModal && (
        <RedeemPoints
          userTotalPoint={ userTotalPoint }
          image={ image }
          points={ points }
          desc={ description }
          title={ title }
          onHideState={ showModal }
          onHide={ () => setShowModal( false ) }
          popupAction={ handleClickredeemed }
          countries={ countries }
          newAddress={ newAddress }
          rewardId={ rewardId }
          redeemed={ handleModalShowHideredeemed }
        />
      ) }
      { showredeemed && (
        <AlertDialogSlide
          title={ `REWARD_SUCCESSFULLY_REDEEMED` }
          body={ `${ points } ${ points > 1 ? t( "POINTS" ) : t( "POINT" ) } ${ t(
            "WILL_BE_DEDUCTED_FROM_YOUR_BALANCE"
          ) }` }
          onHideState={ showredeemed }
          onHide={ handleModalShowHideredeemed }
          popupAction={ handleClickredeemed }
          onHideAddressPopup={ () => setShowModal( false ) }
          redirect={ true }
          buttonTitle="OK"
        />
      ) }
    </div>
  );
};
export default CardReward;
CardReward.defaultProps = {
  defaultImage: null,
};

CardReward.propTypes = {
  defaultImage: PropTypes.any,
};
