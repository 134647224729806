//UTILTIIES
import React, { useState, useEffect, useContext } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useTranslation } from "react-i18next";
import { ChatContext } from "../../context/ChatContext";
//COMPONENT
import ViewMorePagination from "../../components/view-more-pagination/ViewMorePagination";
import { List } from "@mui/material";
import ChatContact from "./ChatContacts";
import ChatElement from "./ChatElement";
import { ChatListIcon } from "../../assets/icons/SVG";
import TitleComponent from "../../components/title-component/TitleComponent";

//API

//CSS
import "./Chat.scss";
import { ListComponentSkeleton } from "../../components/skeletons/SkeletonsComponent";
import NoData from "../../components/Fallback/NoData";
import { GetLatestChat } from "../../core/services/chat";
import { GetFriendsList } from "../../core/services/connections";
import { FormSearchBar } from "../../components/form/form-componenrt/FormComponent";
import { chatPerPage } from "../../core/variables/Variables";
import { UserContext } from "../../context/UserContext";

export function ChatListManagement( props ) {
  const [ t ] = useTranslation( "common" );
  const { user } = useContext( UserContext )
  const { chatUser } = useContext( ChatContext );
  const [ openSearchContact, setOpenSearchContact ] = useState( false );
  const [ friends, setFriends ] = useState( [] );
  const [ page, setPage ] = useState( 1 );
  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ totalFriends, setTotalFriends ] = useState( 0 );
  const [ filter, setFilter ] = useState( { name: "" } );
  const [ latestChat, setLatestChat ] = useState( [] );

  const [ loadMore, setLoadMore ] = useState( false );
  const [ loadingChats, setLoadingChats ] = useState( false );
  const [ loadingConnections, setLoadingConnections ] = useState( false );
  //FUNCTIONS
  const toggleSearchContact = () => {
    setOpenSearchContact( true );
  };


  const GetChats = () => {
    //latest chat are only 15 as max from backend
    setLoadingChats( true )
    GetLatestChat( {
      ...filter,
      sort: 2,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setLatestChat( response.data?.data || [] );

      }
      setLoadingChats( false )
    } );
  };

  const [ name, setname ] = useState( filter?.name );
  const debounced = useDebouncedCallback(
    // function
    ( value ) => {
      setFilter( { name: value } );
    },
    // delay in ms
    500
  );

  //GET CONNECTION IF NEW PAGE THEN LOAD MORE
  const GetConnection = ( new_page ) => {
    let handleLoad = new_page ? setLoadMore : setLoadingConnections;
    handleLoad( true );
    let current_page =
      new_page && page + 1 <= pageTotal
        ? page < pageTotal
          ? page + 1
          : page
        : 1;
    GetFriendsList( {
      page: current_page,
      per_page: chatPerPage,
      ...filter,
      user_name: filter?.name,
      sort: 2,
    } ).then( ( response ) => {
      if ( response.data?.success ) {
        setTotalFriends( response.data.data?.total );
        setPage( response.data.data?.current_page );
        if ( new_page ) {
          setFriends( [ ...friends, ...response.data.data.data ] );
        } else {
          setFriends( response.data?.data?.data || [] );
        }

        setPageTotal( response.data.data?.last_page );
      }
      handleLoad( false );
    } );
  };



  useEffect( () => {
    GetConnection( false );
    GetChats( false );
  }, [ filter ] );

  useEffect( () => {
    if ( chatUser?.new ) {
      GetChats( false );
    }
  }, [ chatUser?.new ] );

  return (
    <div className="chat-list-container">
      <TitleComponent
        title="CHAT"
        endIcon={ <ChatListIcon /> }
        handleEndAction={ () => toggleSearchContact() }
      />
      <FormSearchBar
        placeholder={ "SEARCH_BY_NAME" }
        variant="outlined"
        value={ name }
        onChange={ ( value ) => {
          setname( value );
          debounced( value );
        } }
      />
      <h4 className="latest-chats">{ t( "LATEST_CHATS" ) }</h4>
      { !loadingChats ? (
        latestChat?.length === 0 ? (
          <NoData variant="text" centered={ false } text="NO_CHATS_FOUND" />
        ) : (
          <>
            <List className="other-chat-list">
              { latestChat?.map( ( item, index ) => {
                return (
                  <ChatElement
                    data={ item?.user?.[ 0 ] }
                    chatlist={ true }
                    pinned={ item?.is_pinned }
                    key={ item.id }
                    index={ index }
                    reloadData={ GetChats }
                    length={ latestChat?.length }
                  />
                );
              } ) }

            </List>
          </>
        )
      ) : (
        <ListComponentSkeleton count={ 2 } />
      ) }

      <h4 className="other-chats">{ t( "OTHER_CHATS" ) }</h4>
      { !loadingConnections ? (
        friends?.length === 0 ? (
          <NoData variant="text" centered={ false } text="NO_CHATS_FOUND" />
        ) : (
          <>
            <List className="other-chat-list">
              { friends?.map( ( item, index ) => {
                return (
                  <ChatElement
                    data={ item?.user_info }
                    key={ item.id }
                    index={ index }
                    length={ friends?.length }
                  />
                );
              } ) }
              <ViewMorePagination
                page={ page }
                loading={ loadMore }
                pageTotal={ pageTotal }
                data={ friends }
                dataLeft={ friends ? totalFriends - friends.length : 0 }
                handleLoadChange={ () => GetConnection( true ) }
              />
            </List>
          </>
        )
      ) : (
        <ListComponentSkeleton count={ 5 } />
      ) }

      { openSearchContact && (
        <ChatContact onClose={ () => setOpenSearchContact( false ) } />
      ) }
    </div>
  );
}

export default ChatListManagement;
