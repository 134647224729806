//UTILITIES
import React, { useContext, useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import { AlertContext } from "../../context/AlertContext";
import { useTranslation } from "react-i18next";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { NotificationContext } from "../../context/NotificationContext";
//COMPONENT
import { NoData } from "../../components/Fallback/NoData";
import {
  groupParamsByKey,
  objectCleaner,
} from "../../core/functions/Functions";
import { dataPerPage } from "../../core/variables/Variables";
import TablePaginationSection from "../../components/table-pagination/TablePaginationSection";
import { ListComponentSkeleton } from "../../components/skeletons/SkeletonsComponent";
import * as MUI from "@mui/material";
//CSS
import "./Connections.scss";
//API
import * as request from "../../core/services/connections";
import Button from "../../components/button/Button";


const Request = () => {
  const [ t ] = useTranslation( "common" );

  const alert = useContext( AlertContext );
  const { updateNotificationCount } = useContext( NotificationContext );
  const [ searchParams, setSearchParams ] = useSearchParams();
  // const { search } = groupParamsByKey(searchParams) || {};
  const { search, ...others } = groupParamsByKey( searchParams ) || {};

  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ filter, setFilter ] = useState( {
    role_ids: searchParams.getAll( "role_ids" ) || [],
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: searchParams.get( "page" ) || 1,
  } );
  const [ isLoading, setIsloading ] = useState( true );

  const [ data, setData ] = useState( [] );

  const handleRequestsResponse = ( type, item ) => {
    request
      .AcceptReject( {
        connection_id: item?.id,
        is_accepted: type === "accept" ? true : false,
      } )
      .then( ( res ) => {
        if ( res?.data?.success ) {
          getConnections();
          updateNotificationCount();
        }
        alert?.[ res?.data?.success ? "success" : "error" ]( res?.data ? res?.data?.message : res?.message );
      } );
  };

  const getConnections = () => {
    setIsloading( true );

    let searchData = {
      per_page: filter?.per_page,
      page: filter?.page,
      is_sent: false,
      name: search,
    };
    request.getReqConn( searchData ).then( ( res ) => {
      if ( res?.data?.success ) {
        setData( res?.data?.data ? res?.data?.data?.data : [] );
        setPageTotal( res.data.data?.last_page );
      } else {
        alert?.[ "warning" ](
          res?.data ? res?.data?.message : res?.message
        );
      }
      setIsloading( false );
    } );
  };

  useEffect( () => {
    setFilter( {
      role_ids: searchParams.getAll( "role_ids" ) || [],
      per_page: searchParams.get( "per_page" ) || dataPerPage,
      page: +searchParams.get( "page" ) || 1,
    } );
  }, [ searchParams ] );

  useEffect( () => {
    getConnections();
  }, [ filter, search ] );

  const handlePageChange = ( e, page ) => {
    let newFilters = {
      ...filter,
      page: page,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };

  return (
    <MUI.Card className="my-connections-component">
      <MUI.CardHeader className="my-connections-header" title={ t( "REQUESTS" ) } />
      <MUI.CardContent>
        { !isLoading ? (
          data?.length !== 0 ? (
            <>
              <MUI.List style={ { paddingTop: "0px" } }>
                { data?.map( ( item, index ) => (
                  <>
                    <MUI.ListItem
                      disableRipple
                      key={ index }
                      className="request-list"
                    >
                      <div className="profile-info">
                        <MUI.ListItemAvatar className="item-avatar">
                          <MUI.Avatar
                            src={
                              item?.user?.profile_image
                                ? REACT_APP_IMAGE_URL +
                                item?.user?.profile_image
                                : ""
                            }
                            alt={
                              item?.user?.first_name +
                              " " +
                              item?.user?.last_name
                            }
                            variant={ "rounded" }
                          />
                        </MUI.ListItemAvatar>

                        <MUI.ListItemText
                          primary={
                            <div className="primary-text">
                              { item?.user?.first_name +
                                " " +
                                item?.user?.last_name }
                            </div>
                          }
                          primaryTypographyProps={ {
                            style: { whiteSpace: "normal" },
                          } }
                          secondary={
                            <div className={ "secondary-text" }>
                              { item?.user?.role?.name }{ item?.user?.portal_id === 2 ? ` ${ t( 'FROM' ) } ${ item?.user?.pharmaceutical_company?.name }` : "" }
                            </div>
                          }
                        />
                      </div>
                      <div className="action-buttons">
                        <Button
                          width="45%"
                          name="REJECT"
                          style={ { padding: "0px" } }
                          onClick={ () => handleRequestsResponse( "remove", item ) }
                          className="button"
                        //if I sent a request to someone and still pending then i can only remove it
                        />
                        <Button
                          width="45%"
                          name="ACCEPT"
                          style={ { padding: "0px" } }
                          isSelected
                          onClick={ () => handleRequestsResponse( "accept", item ) }
                          className="button"
                        />
                      </div>
                    </MUI.ListItem>
                    <MUI.Divider variant="fullWidth" />
                  </>
                ) ) }
              </MUI.List>
              <TablePaginationSection
                page={ filter?.page }
                totalPage={ pageTotal }
                data={ data }
                handlePageChange={ handlePageChange }
              />
            </>
          ) : (
            <NoData title={ "NO_REQUESTS_AVAILABLE" } />
          )
        ) : (
          <ListComponentSkeleton count={ 10 } />
        ) }
      </MUI.CardContent>
    </MUI.Card>
  );
};
export default Request;
