import * as React from "react";
import Button from "../../button/Button";
import { useTranslation } from "react-i18next";
import "./RedemptionCard.scss";

let links = [
  `../../home`,
  `../../account-details`,
  `../../groups/invitations`,
  `../../notifications`,
  `../../meetings/requests`,
  `../../watch/all`,
];
export default function RedemptionCard( props ) {
  const { title, points, description, canEarn, id } = props;
  const [ t ] = useTranslation( "common" );

  return (
    <div className="redemption-card-container">
      <div className="redemtion-card-header">
        <h5>{ title }</h5>
        <h6>{ points } Pts</h6>
      </div>
      <div className="redemtion-card-body">
        <p>{ description }</p>
      </div>
      <div className="redemtion-card-footer">
        <Button
          href={ links?.[ id - 1 ] }
          disabled={ !canEarn }
          isSelected={ canEarn }
          width="100%"
          name={ canEarn ? "EARN" : "EARNED" }
        />
      </div>
    </div>
  );
}
