import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import "./schedule.scss";
import i18next from "i18next";
import { useMediaQuery } from "react-responsive";
import { Calendar, momentLocalizer } from "react-big-calendar";
import "react-big-calendar/lib/css/react-big-calendar.css";
import palette from "../../../theme/color.scss";

import moment from "moment";
import LoaderComponent from "../../../components/loader/LoaderComponent";
import * as scheduleData from "../../../core/services/schedule";
import { AlertContext } from "../../../context/AlertContext";
import CustomToolbar from "../../../components/scheduler-component/CustomToolbar";
import CustomEvent from "../../../components/scheduler-component/CustomEvents";
import MeetingsByType from "../MeetingsByType";
import Button, { IconsButton } from "../../../components/button/Button";
import {
  CalendarViewSVG,
  ListViewSVG,
  WeeklyAvailabilty,
} from "../../../assets/icons/SVG";
import { useTranslation } from "react-i18next";
import { PermissionContext } from "../../../context/PermissionContext";
import { ButtonBase } from "@mui/material";

const localizer = momentLocalizer( moment );

const Schedule = ( props ) => {
  const { permissions } = useContext( PermissionContext );
  const [ t ] = useTranslation( "common" );
  const [ viewOption, setViewOption ] = useState( "week" )
  const [ isLoading, setIsLoading ] = useState( true );
  const [ listView, setListView ] = useState( false );
  const [ availData, setAvailData ] = useState( {} );
  const [ date, setDate ] = useState( new Date() );

  const [ eventList, setEventList ] = useState( [ {} ] );

  const Alert = useContext( AlertContext );

  const changeDate = ( value ) => setDate( value );
  const isSmallScreen = useMediaQuery( { query: "(max-width: 900px)" } );

  useEffect( () => {
    setIsLoading( true );
    scheduleData.getSchedule().then( ( res ) => {
      if ( res?.data.success ) {
        setAvailData(
          res?.data?.data?.schedule.length > 0
            ? res?.data.data?.schedule[ 0 ]
            : null
        );
        setEventList(
          res?.data?.data?.events?.map( ( item ) => ( {
            ...item,
            end: new Date( item?.end_date_time ),
            start: new Date( item?.start_date_time ),
          } ) )
        );

      }
      // Alert?.[ res?.data?.success ? "success" : "error" ](
      //   res?.data ? res?.data?.message : res?.data
      // );
      setIsLoading( false );

    } );
  }, [] );
  const handleCancelEvent = ( id ) => {
    var array = eventList;
    const items = array.filter( ( item ) => item.id !== id );
    setEventList( items );
  };

  const CheckBetween = ( calendarDate, startTime, endTime ) => {
    let start_time = new Date( `2022-05-01T${ startTime }` );
    let end_time = new Date( `2022-05-01T${ endTime }` );
    let calendar_time = null;
    if ( calendarDate ) {
      calendar_time = new Date(
        `2022-05-01T${ ( "0" + calendarDate.getHours() ).slice( -2 ) }:${ (
          "0" + calendarDate.getMinutes()
        ).slice( -2 ) }:00`
      );
    }

    return (
      calendar_time.getTime() <= end_time.getTime() &&
      calendar_time.getTime() >= start_time.getTime()
    );
  };

  const slotStyleGetter = ( slot ) => {
    let backgroundColor = palette.whiteColor;
    let slotDate = moment( slot );

    let dayInt = slotDate.day();
    let dayOfWeek = slotDate.format( "d" );
    let slot_day = slotDate.format( `d YYYY-MM-DD` );

    if ( dayInt === 0 || dayInt === 6 ) {
      backgroundColor = palette.calendarWeekendColor;
    }

    if ( availData ) {
      //CHECK IF availData WEEKDAYS HAVE THIS DAY AS A KEY
      //CHECK IF availData EXCEPTION HAVE THAT SPECIFIC DATE AS A KEY
      //Day of week is to lowercase because react big calendar english is "Monday" , romanian is "luni"
      if (
        availData?.weekdays?.hasOwnProperty( dayOfWeek ) &&
        slot > new Date( availData.start_date ) &&
        !availData?.schedule_exceptions.hasOwnProperty( slot_day )
      ) {
        availData?.weekdays?.[ dayOfWeek ]?.map( ( time_slot ) => {
          if ( CheckBetween( slot, time_slot.start_time, time_slot.end_time ) ) {
            backgroundColor = palette.lightInputColor;
          }
        } );
      }
      if ( availData?.schedule_exceptions?.hasOwnProperty( slot_day ) ) {
        if ( availData?.schedule_exceptions?.[ slot_day ]?.time?.length !== 0 ) {
          availData?.schedule_exceptions?.[ slot_day ]?.time?.map( ( time_slot ) => {
            if ( CheckBetween( slot, time_slot.start_time, time_slot.end_time ) ) {
              backgroundColor = palette.lightInputColor;
            }
          } );
        }
      }
    }
    return {
      style: {
        backgroundColor: backgroundColor,
        height: "100%",
      },
    };
  };

  const checkSchedulePermission = useMemo( () => {
    if ( ( !availData && !permissions?.Schedule?.[ "create-schedule" ] ) || ( availData && !permissions?.Schedule?.[ "update-schedule" ] ) ) {
      return false
    }
    else {
      return true
    }

  }, [ permissions ] )

  if ( isLoading )
    return (
      <div style={ { height: "50vh", display: "flex", alignItems: "center" } }>
        <LoaderComponent />
      </div>
    );
  else
    return (
      <div className="scheduler-component-container">

        <div className="scheduler-actions">
          { checkSchedulePermission && (
            isSmallScreen ? (
              <ButtonBase component={ Link } to="set-schedule">
                <WeeklyAvailabilty />
              </ButtonBase>
            ) : (
              <Button
                isSelected
                className="set-schedule-action"
                name={ t( "SET_WEEKLY_AVAILABILITY" ) }
                href="set-schedule"

              />
            ) ) }
        </div>
        <CustomToolbar
          { ...props }
          listView={ listView }
          setListView={ setListView }
          changeDate={ changeDate }
          onView={ ( view ) => setViewOption( view ) }
          view={ viewOption }
          selectedDate={ date }
        />
        { !listView ? (
          <div className="calendar-wrapper">
            <Calendar
              culture={ i18next.language }
              slotPropGetter={ slotStyleGetter }
              className="demo"
              localizer={ localizer }
              events={ eventList }
              date={ date ? date : new Date() }
              min={ new Date( 2024, 2, 28, 7, 0, 0 ) } // 7:00 AM requested by the client
              max={ new Date( 2024, 2, 28, 21, 0, 0 ) } //21:00 PM requested by the client
              step={ 15 }
              components={ {
                week: {
                  header: ( { date, localizer } ) =>
                    localizer.format( date, "dddd,MMM DD" ),
                },
                event: ( { localizer, ...props } ) => (
                  <CustomEvent { ...props } handleCancelEvent={ handleCancelEvent } />
                ),
                toolbar: ( { localizer, ...props } ) => (
                  <></>
                ),

              } }
              view={ viewOption }
              startAccessor="start"
              endAccessor="end"
              defaultView="week"
              style={ { height: '100%' } } // Ensure the calendar fills the container height
            />
          </div>
        ) : (
          <MeetingsByType type={ 2 } selectedDate={ date } />
        ) }
      </div>
    );
};

export default Schedule;
