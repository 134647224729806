//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";
import { useOutletContext } from "react-router-dom/dist";
import { useSearchParams } from "react-router-dom";
import { UserContext } from "../../context/UserContext";
import PropTypes from "prop-types";
//COMPONENT
import { MeetingElement } from "./MeetingElement";
import { dataPerPage } from "../../core/variables/Variables";
import { objectCleaner } from "../../core/functions/Functions";
import { ListComponentSkeleton } from "../../components/skeletons/SkeletonsComponent";
import TablePaginationSection from "../../components/table-pagination/TablePaginationSection";
import { NoData } from "../../components/Fallback/NoData";

//CSS
import "./Meetings.scss";
//API
import * as requests from "../../core/services/schedule";




//PLEASE NOTE THAT THERE IS 3 TYPES:
//1-PENDING
//2-ACCEPTED
//3-REJECTED
//THIS COMPONENT IS COMMON FOR ALL THE SCHEDULE FILTERING
const MeetingsByType = ( props ) => {
  const { type, history, selectedDate } = props;
  const { user } = useContext( UserContext );
  const [ t ] = useTranslation( "common" );
  const [ data, setData ] = useState( [] );
  const { search } = useOutletContext( {} ) || {};
  const [ isLoading, setIsloading ] = useState( false );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ filter, setFilter ] = useState( {
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: searchParams.get( "page" ) || 1,
  } );


  const getDocRequests = () => {
    let apiCall = history
      ? requests.getMeetingHistory
      : requests.getDoctorMeetingsEvents;
    setIsloading( true );
    apiCall( {
      user_id: user?.id,
      activity_type_id: type,
      ...filter,
      name: search,
      start_date: dayjs( selectedDate || new Date() ).format( 'YYYY-MM-DD' ), //FOR rep meetingby id api
    } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setData( res?.data?.data ? res?.data?.data?.data : [] );
        setPageTotal( res?.data?.data?.last_page );
      }
      setIsloading( false );
    } );
  };



  const handlePageChange = ( e, page ) => {
    let newFilters = {
      ...filter,
      page: page,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };
  useEffect( () => {
    setFilter( {
      per_page: searchParams.get( "per_page" ) || dataPerPage,
      page: +searchParams.get( "page" ) || 1,
    } );
  }, [ searchParams ] );

  useEffect( () => {
    getDocRequests();
  }, [ filter, search, type, history, selectedDate ] );

  return isLoading ? (
    <ListComponentSkeleton count={ dataPerPage } />
  ) : data?.length === 0 ? (
    <NoData variant="text" title={ t( "NO_REQUESTS_AVAILABLE" ) } />
  ) : (
    <>
      <div className="event-list-container">
        { data?.map( ( request ) => (
          <MeetingElement
            { ...props }
            key={ request?.id }
            meeting={ request }
            type={ type }
            updateData={ () => getDocRequests() }

          />

        ) ) }
      </div>
      <TablePaginationSection
        page={ filter?.page }
        totalPage={ pageTotal }
        data={ data }
        handlePageChange={ handlePageChange }
      />

    </>
  );
};

export default MeetingsByType;
MeetingsByType.defaultProps = {
  history: false,
  type: 1,
};

MeetingsByType.propTypes = {
  history: PropTypes.bool,
  type: PropTypes.number,
};
