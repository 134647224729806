import React from "react";

const NoPageFound = ( props ) => {
  return (
    <svg width="287" height="152" viewBox="0 0 287 152" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clip-path="url(#clip0_17893_52338)">
        <path d="M182.772 117.123C204.023 95.8724 204.023 61.4186 182.772 40.1681C161.522 18.9176 127.068 18.9175 105.817 40.1681C84.5668 61.4186 84.5668 95.8725 105.817 117.123C127.068 138.373 161.522 138.373 182.772 117.123Z" fill="#02C0CC" />
        <path d="M81.8845 86.7227V104.131H1.07807L0 90.6506L46.7589 16.4717H64.3968L45.2921 48.4381L22.0297 86.7227H81.8845ZM69.173 16.4717V128.626H46.9887V16.4717H69.173Z" fill="#02C0CC" />
        <path opacity="0.4" d="M264.851 145.504H73.521L85.0572 139.009H253.311L264.851 145.504Z" fill="#2FEDF2" />
        <path d="M264.533 145.504H73.521V151.186H264.533V145.504Z" fill="#02C0CC" />
        <path d="M186.479 134.825L97.3262 137.118L75.354 76.3268L164.507 74.0293L186.479 134.825Z" fill="#424242" />
        <path d="M228.135 139.659L138.982 141.952L97.3218 136.986L186.474 134.688L228.135 139.659Z" fill="#333333" />
        <path d="M182.149 131.321L100.163 133.433L80.6206 79.3268L162.611 77.2148L182.149 131.321Z" fill="#C1F6F7" />
        <path d="M97.3218 136.985L138.982 141.952L139.115 146.962L97.6134 141.32L97.3218 136.985Z" fill="#1A1A1A" />
        <path d="M138.982 141.952L228.135 139.659L228.232 143.415L139.114 146.963L138.982 141.952Z" fill="black" />
        <path d="M160.636 79.0705L179.533 131.388L182.149 131.321L162.611 77.2148L80.6206 79.3268L81.2701 81.1162L160.636 79.0705Z" fill="#122138" />
        <path d="M75.354 76.3262L74.8105 77.0375L97.6134 141.319L97.3218 136.985L75.354 76.3262Z" fill="black" />
        <path d="M182.692 139.084L161.719 139.628L151.919 138.457L172.893 137.918L182.692 139.084Z" fill="#424242" />
        <path d="M150.987 5.42985C150.987 5.42985 154.159 19.7982 149.131 18.265C144.099 16.7275 143.652 5.21777 143.652 5.21777L150.987 5.42985Z" fill="#1A1A1A" />
        <path d="M157.495 83.5462C159.391 89.7097 161.533 95.4623 163.508 98.7363C166.261 107.162 169.805 108.708 169.805 108.708L176.109 106.42C177.104 106.009 177.188 104.25 176.945 103.278L171.276 74.2412L171.183 74.2766" fill="#FCBC76" />
        <path d="M171.188 74.2769L162.757 48.0984C146.573 45.4872 152.414 62.9351 152.414 62.9351C153.245 67.9322 155.184 76.0486 157.495 83.5509" fill="white" />
        <path d="M174.13 73.5211C174.13 73.5211 162.903 71.4534 157.663 79.6361L160.022 85.8217L160.756 85.8482C166.155 86.0338 171.466 84.4476 175.871 81.3283L174.13 73.5211Z" fill="white" />
        <path d="M172.981 75.7565C172.981 75.7565 170.825 77.8596 166.889 79.0703C162.952 80.2765 158.662 80.2897 158.967 79.3398C159.271 78.3898 159.466 78.7212 159.466 78.7212L160.062 79.7153C160.062 79.7153 168.488 78.951 172.208 75.8847L171.992 74.4531C171.992 74.4531 172.699 74.5106 172.981 75.7565Z" fill="#ADADAD" />
        <path d="M112.115 47.5812L139.305 40.1982L162.762 48.1424L112.115 47.5812Z" fill="white" />
        <path d="M164.587 131.771L164.481 116.073L165.302 90.5087C165.992 80.8724 164.083 55.9001 158.763 47.8323L145.438 43.1798L144.077 45.4995L141.899 46.8559L131.962 42.2432L113.471 47.311L118.022 132.973L164.587 131.771Z" fill="white" />
        <path d="M143.042 48.624C143.736 52.1763 144.381 55.7419 144.991 59.3119C145.561 62.8863 146.082 66.4696 146.582 70.0572C147.562 77.2325 148.291 84.4432 148.963 91.6539C149.493 98.8778 149.935 106.115 150.085 113.361C150.085 116.988 150.16 120.607 150.037 124.23C149.957 127.853 149.962 128.551 149.741 132.161L149.506 132.165C149.802 124.937 149.639 120.611 149.418 113.383C149.14 106.155 148.702 98.9353 148.106 91.7246C147.501 84.5183 146.771 77.3209 145.919 70.1368C145.057 62.957 144.098 55.7817 142.958 48.6417L143.042 48.624Z" fill="#ADADAD" />
        <path d="M140.37 51.6063C140.145 51.6107 139.919 51.5179 139.765 51.3589C139.597 51.2086 139.5 50.9833 139.495 50.7624C139.491 50.5282 139.584 50.3117 139.743 50.1483C140.056 49.8257 140.631 49.8125 140.953 50.1262C141.121 50.2852 141.21 50.5017 141.214 50.7315C141.218 50.9568 141.134 51.1821 140.975 51.3368C140.816 51.5003 140.6 51.6019 140.37 51.6063Z" fill="#ADADAD" />
        <path d="M147.011 123.593C146.538 123.602 146.145 123.213 146.145 122.74C146.136 122.268 146.516 121.883 146.989 121.874C147.47 121.866 147.855 122.254 147.863 122.727C147.863 123.2 147.492 123.584 147.02 123.593C147.011 123.593 147.011 123.593 147.011 123.593ZM145.672 108.315C145.645 107.842 146.008 107.431 146.489 107.413C146.962 107.387 147.364 107.749 147.391 108.222C147.417 108.695 147.055 109.106 146.573 109.123C146.564 109.132 146.556 109.132 146.547 109.132C146.083 109.141 145.698 108.779 145.672 108.315ZM144.691 93.9066C144.656 93.4338 145 93.0141 145.482 92.9743C145.955 92.939 146.366 93.2924 146.41 93.7652C146.445 94.2379 146.092 94.6489 145.619 94.693C145.601 94.693 145.584 94.693 145.566 94.693C145.12 94.6975 144.735 94.3617 144.691 93.9066ZM143.308 79.525C143.255 79.0522 143.6 78.6237 144.073 78.5751C144.545 78.522 144.974 78.8667 145.014 79.3394C145.067 79.8122 144.731 80.2319 144.258 80.2805C144.232 80.2894 144.205 80.2894 144.179 80.2894C143.746 80.2982 143.361 79.9712 143.308 79.525ZM141.581 65.1743C141.519 64.7016 141.855 64.2642 142.327 64.2067C142.791 64.1449 143.224 64.4718 143.286 64.9446C143.348 65.4173 143.021 65.8503 142.548 65.9122C142.513 65.9122 142.477 65.9122 142.447 65.9122C142.018 65.921 141.642 65.6117 141.581 65.1743Z" fill="#ADADAD" />
        <path d="M135.298 47.5105L132.214 42.3985L130.252 25.7283L140.635 22.7812L146.056 45.4295C146.556 49.2248 138.205 50.0024 135.298 47.5105Z" fill="#FCBC76" />
        <path d="M142.252 39.5885C143.042 39.4736 143.78 39.1953 144.452 38.8064L140.639 22.7812L130.256 25.7283L131.184 33.6017L131.413 33.9596L137.604 38.4088C138.978 39.4029 140.634 39.8227 142.252 39.5885Z" fill="#E3923C" />
        <path d="M144.333 38.3417C147.214 38.081 149.586 35.8321 150.214 32.7614L152.096 23.5846C152.356 22.3033 152.392 20.9866 152.189 19.6965C151.433 14.907 150.399 9.7818 149.308 7.06454L149.144 5.74788C147.152 0.77729 142.362 0.741944 137.44 1.18377C132.037 1.66979 130.344 5.77439 129.775 11.6552L126.744 14.6861C126.483 17.3415 127.044 20.0323 128.334 22.3342L132.85 31.0648C133.093 31.5331 133.428 31.9396 133.844 32.2666L139.755 36.9102C141.077 37.9794 142.707 38.4875 144.333 38.3417Z" fill="#FCBC76" />
        <path d="M129.554 24.2522C129.554 24.2522 131.621 14.7219 130.937 10.7675C130.937 10.7675 149.816 15.8044 151.583 6.46853C151.583 6.46853 153.558 0.0310621 146.909 0.0885001C140.087 0.145938 125.506 -1.80254 125.767 9.92365C126.098 24.9371 129.554 24.2522 129.554 24.2522Z" fill="#1A1A1A" />
        <path d="M131.997 21.4912C133.305 23.338 133.217 25.6444 131.803 26.6473C130.389 27.6503 128.18 26.9699 126.872 25.123C125.564 23.2762 125.653 20.9698 127.066 19.9669C128.485 18.9639 130.689 19.6487 131.997 21.4912Z" fill="#FCBC76" />
        <path opacity="0.67" d="M128.025 43.2812L137.281 53.8233L142.999 48.6274L128.025 43.2812Z" fill="#707070" />
        <path opacity="0.67" d="M149.158 43.9141L146.467 51.1998L147.814 51.2308L149.158 43.9141Z" fill="#707070" />
        <path opacity="0.67" d="M142.998 48.6279L139.782 53.329L143.334 49.8297L146.763 51.8268L142.998 48.6279Z" fill="#707070" />
        <path d="M144.665 39.6992L146.728 39.7434L145.371 43.256L144.665 39.6992Z" fill="#ADADAD" />
        <path d="M146.728 39.7432L149.158 43.914L146.869 51.8979L142.999 48.6284L146.728 39.7432Z" fill="white" />
        <path d="M131.812 38.793L129.708 38.8239C129.947 38.9697 131.966 40.4056 131.966 40.4056L131.812 38.793Z" fill="#ADADAD" />
        <path d="M129.708 38.8232L127.937 43.3653L139.782 53.3285L142.999 48.6275L131.833 40.3034" fill="white" />
        <path opacity="0.42" d="M156.271 102.549L118.327 101.669C118.168 92.2407 118.526 70.1669 116.144 60.2787C116.144 60.2787 114.695 48.7513 110.586 49.8603L105.523 82.9667L105.408 118.715L109.402 112.45C112.415 116.418 120.96 121.605 125.878 121.119C151.8 118.556 164.529 109.631 164.529 109.631L156.271 102.549Z" fill="#707070" />
        <path d="M112.234 48.9111C112.756 49.2116 112.901 49.7727 113.096 50.2499C113.259 50.7492 113.396 51.2573 113.502 51.7698C113.688 52.7992 113.785 53.8287 113.869 54.8582C113.957 55.8876 113.94 56.9215 113.922 57.9466C113.913 58.976 113.882 60.0011 113.829 61.0261C113.75 62.0512 113.639 63.0674 113.529 64.088C113.418 65.1042 113.33 66.1249 113.136 67.1322C112.963 68.144 112.804 69.1514 112.601 70.1588C112.362 71.1573 112.168 72.1647 111.885 73.1544L111.801 73.1455C112.031 71.1175 112.283 69.0984 112.473 67.0659C112.681 65.0379 112.848 63.0055 112.972 60.9731C113.127 58.9407 113.197 56.9038 113.197 54.867C113.184 53.8508 113.158 52.8302 113.047 51.8184C112.999 51.3103 112.919 50.811 112.813 50.3162C112.747 50.0731 112.694 49.8213 112.597 49.5916C112.517 49.3618 112.384 49.11 112.212 48.9951L112.234 48.9111Z" fill="#ADADAD" />
        <path d="M117.717 82.0042L117.85 79.9541C117.797 80.6168 117.757 81.3061 117.717 82.0042Z" fill="#ADADAD" />
        <path d="M116.233 85.7639C115.72 80.727 118.897 69.4073 116.105 57.8048C116.105 57.8048 119.825 44.8769 109.247 48.089C106.203 49.0124 101.909 63.8447 101.396 69.7343L100.336 85.2337C99.8232 89.2588 100.738 90.8803 101.135 94.9142" fill="white" />
        <path d="M101.131 94.9145L103.477 116.374C103.676 117.554 104.569 119.467 105.762 119.498L123.21 118.689C123.21 118.689 117.298 96.2577 116.229 85.7598" fill="#FCBC76" />
        <path d="M110.714 112.269L116.577 99.1553L156.232 100.074L156.563 113.13C156.559 113.13 107.918 126.452 110.714 112.269Z" fill="#FCBC76" />
        <path d="M128.701 99.4382L124.756 98.9434C124.756 98.9434 154.836 114.01 156.771 113.131C160.809 111.293 164.786 108.96 164.786 108.96L156.227 100.079L128.701 99.4382Z" fill="#E3923C" />
        <path d="M128.507 92.8389C128.507 92.8389 125.047 92.9007 123.717 93.1526C122.383 93.4 118.53 99.2145 118.53 99.2145L126.271 99.4177L128.507 92.8389Z" fill="#FCBC76" />
        <path d="M172.058 104.64L169.787 92.1006L128.507 92.8384L124.999 99.2362C124.999 99.2317 174.55 118.027 172.058 104.64Z" fill="#FCBC76" />
        <path d="M118.871 83.8737C118.871 83.8737 105.059 78.9605 96.7437 88.2699L98.4712 96.594L99.3991 96.7751C106.203 98.1139 113.259 97.1816 119.489 94.1286L118.871 83.8737Z" fill="white" />
        <path d="M116.957 86.4808C116.957 86.4808 113.798 88.712 108.558 89.4366C103.323 90.1656 97.8792 89.3041 98.458 88.1597C99.0368 87.0154 99.2179 87.4793 99.2179 87.4793L99.7702 88.8622C99.7702 88.8622 110.608 89.6133 115.954 86.4852L115.972 84.6251C115.972 84.6206 116.856 84.8371 116.957 86.4808Z" fill="#ADADAD" />
        <path d="M162.492 64.3086C163.027 66.0317 163.424 67.8123 163.773 69.6194C163.963 70.5163 164.1 71.4353 164.264 72.3455C164.41 73.2601 164.542 74.1835 164.679 75.1069C164.794 76.0392 164.918 76.967 165.024 77.8993C165.112 78.8404 165.227 79.7727 165.293 80.727C165.448 82.6225 165.554 84.5444 165.532 86.5238L165.452 86.5592L163.893 75.4692L162.413 64.3439L162.492 64.3086Z" fill="#ADADAD" />
        <path d="M154.521 92.3824C154.521 92.3824 163.128 86.2896 168.541 86.1924L174.558 90.7742L173.821 99.655L167.454 92.9656L154.521 92.3824Z" fill="#FCBC76" />
        <path d="M113.471 47.3115C114.386 48.5575 115.101 49.9227 115.68 51.3366C116.277 52.746 116.732 54.2085 117.116 55.6886C117.452 57.1776 117.766 58.6754 117.876 60.2041C117.951 60.9641 117.978 61.7285 118.018 62.4884C118 63.2572 118 64.0171 117.925 64.7815L117.841 64.7903C117.677 63.2925 117.487 61.7991 117.222 60.3146C116.979 58.83 116.639 57.3588 116.286 55.8963C115.928 54.4338 115.517 52.9846 115.044 51.5531C114.567 50.126 114.041 48.7077 113.4 47.3513L113.471 47.3115Z" fill="#ADADAD" />
        <path d="M109.159 50.9215L108.859 60.1734C108.77 63.2529 108.66 66.3281 108.686 69.4032C108.633 72.474 108.708 75.5491 108.916 78.5933C109.018 80.1176 109.172 81.6375 109.451 83.1176C109.711 84.5978 110.105 86.0735 110.9 87.2532L110.86 87.3062C109.968 86.1972 109.504 84.6906 109.164 83.2149C108.828 81.7215 108.629 80.1883 108.479 78.6507C108.183 75.5756 108.112 72.4784 108.121 69.39C108.139 66.2972 108.249 63.2132 108.417 60.1292C108.589 57.0496 108.806 53.9745 109.102 50.9082L109.159 50.9215Z" fill="#ADADAD" />
        <path d="M127.442 22.1407C127.442 22.1407 129.572 21.5089 130.34 23.8594" stroke="#E3923C" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M128.507 23.7485L129.368 22.4805" stroke="#E3923C" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M159.072 56.2846C156.726 46.1711 148.844 43.2638 148.764 43.2329L148.345 42.5127C148.46 42.5348 157.159 45.7955 159.567 56.1697L159.072 56.2846Z" fill="#3763A6" />
        <path d="M158.975 65.2363C157.809 65.0816 156.762 64.5028 156.006 63.5927C155.215 62.6383 154.844 61.4321 154.959 60.195C155.114 58.5735 156.125 57.1287 157.61 56.4306C157.725 56.3776 157.857 56.4129 157.928 56.519C157.999 56.625 157.981 56.762 157.888 56.8459C157.133 57.5396 156.66 58.4851 156.563 59.5013C156.351 61.7723 158.025 63.7915 160.296 64.008C161.03 64.0787 161.772 63.9461 162.444 63.628C162.558 63.575 162.691 63.6104 162.762 63.7164C162.832 63.8224 162.815 63.9594 162.722 64.0433C161.754 64.9314 160.451 65.3733 159.148 65.2495C159.09 65.2495 159.033 65.2407 158.975 65.2363Z" fill="#C9C9C9" />
        <path d="M158.975 65.2363C157.809 65.0816 156.762 64.5028 156.006 63.5927C155.215 62.6383 154.844 61.4321 154.959 60.195C155.114 58.5735 156.125 57.1287 157.61 56.4306C157.725 56.3776 157.857 56.4129 157.928 56.519C157.999 56.625 157.981 56.762 157.888 56.8459C157.133 57.5396 156.66 58.4851 156.563 59.5013C156.351 61.7723 158.025 63.7915 160.296 64.008C161.03 64.0787 161.772 63.9461 162.444 63.628C162.558 63.575 162.691 63.6104 162.762 63.7164C162.832 63.8224 162.815 63.9594 162.722 64.0433C161.754 64.9314 160.451 65.3733 159.148 65.2495C159.09 65.2495 159.033 65.2407 158.975 65.2363Z" fill="#264473" />
        <path d="M159.603 64.8603C157.142 64.5378 155.352 62.311 155.587 59.819C155.825 57.2697 158.096 55.3875 160.645 55.6305C163.195 55.8647 165.077 58.1401 164.834 60.6895C164.595 63.2388 162.324 65.1166 159.775 64.878C159.718 64.8736 159.656 64.8692 159.603 64.8603Z" fill="#3763A6" />
        <path d="M159.762 63.6323C157.955 63.3937 156.643 61.7633 156.815 59.9341C156.992 58.0608 158.657 56.6823 160.526 56.859C162.4 57.0313 163.778 58.7014 163.602 60.5704C163.425 62.4393 161.759 63.8223 159.89 63.6455C159.846 63.6455 159.806 63.6411 159.762 63.6323Z" fill="#264473" />
        <path d="M115.813 91.4635L113.171 91.2116C112.137 91.1144 111.209 90.6151 110.555 89.811C109.901 89.0069 109.601 87.9995 109.711 86.97L112.04 65.2983C112.402 63.3189 113.909 61.8696 115.884 61.6001C117.859 61.3262 119.706 62.3071 120.58 64.1009L128.887 84.509C129.307 85.5385 129.258 86.6961 128.759 87.6858C128.259 88.6799 127.358 89.4045 126.28 89.6829L124.376 90.1733L124.248 89.6829L126.152 89.1924C127.084 88.9539 127.871 88.3176 128.304 87.4605C128.737 86.5989 128.776 85.5915 128.414 84.699L120.116 64.3086C119.343 62.7312 117.709 61.8608 115.95 62.1038C114.196 62.3468 112.853 63.6281 112.539 65.3734L110.215 87.0275C110.118 87.92 110.379 88.7992 110.949 89.4973C111.518 90.1954 112.323 90.624 113.22 90.7123L115.862 90.9642L115.813 91.4635Z" fill="#3763A6" />
        <path d="M123.253 90.0854C123.205 89.4227 123.7 88.8439 124.362 88.7953C125.025 88.7467 125.604 89.2415 125.652 89.9043C125.701 90.567 125.206 91.1458 124.543 91.1944C123.881 91.243 123.306 90.7482 123.253 90.0854Z" fill="#3763A6" />
        <path d="M114.682 91.229C114.633 90.5662 115.128 89.9874 115.791 89.9388C116.454 89.8902 117.033 90.3851 117.081 91.0478C117.13 91.7106 116.635 92.2894 115.972 92.338C115.309 92.3866 114.731 91.8917 114.682 91.229Z" fill="#3763A6" />
        <path d="M112.048 69.3451L111.611 69.0933C113.056 66.5925 114.739 65.2538 116.621 65.1168C119.634 64.9003 122.016 67.8517 122.118 67.9755L121.72 68.2892C121.698 68.2582 119.405 65.4173 116.657 65.6205C114.955 65.7486 113.405 66.999 112.048 69.3451Z" fill="#3763A6" />
        <path d="M115.221 61.8475C115.203 61.6796 113.555 45.2126 128.507 41.9121L128.268 42.5263C113.759 45.7251 115.707 61.6266 115.725 61.7856L115.221 61.8475Z" fill="#3763A6" />
        <path d="M138.612 20.0327C138.603 20.5673 138.342 20.9958 138.033 20.9914C137.723 20.987 137.48 20.5452 137.494 20.0106C137.503 19.4759 137.763 19.0474 138.072 19.0518C138.382 19.0606 138.625 19.498 138.612 20.0327Z" fill="#1A1A1A" />
        <path d="M148.91 19.392C148.902 19.9266 148.641 20.3552 148.332 20.3508C148.022 20.3464 147.779 19.9046 147.793 19.3699C147.801 18.8353 148.062 18.4067 148.371 18.4112C148.681 18.4156 148.924 18.853 148.91 19.392Z" fill="#1A1A1A" />
        <path d="M144.271 17.8721C144.271 17.8721 145.464 21.3537 146.131 23.7352C146.798 26.1166 142.698 25.6881 142.698 25.6881" stroke="#E3923C" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M138.037 26.8945C138.037 26.8945 139.575 30.6987 143.379 29.6118" stroke="#E3923C" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M118.389 99.1865C118.389 99.1865 115.548 99.0319 113.913 102.624C113.913 102.624 117.368 99.9288 118.389 99.1865Z" fill="#E3923C" />
        <path d="M154.521 92.3818L167.843 92.9606L174.156 99.8576L165.488 94.0343L154.521 92.3818Z" fill="#E3923C" />
        <path d="M165.457 86.7138L168.947 85.8213L174.404 90.7256L168.961 86.4398L165.457 86.7138Z" fill="#E3923C" />
        <path d="M169.831 88.9941C170.33 89.193 170.75 89.489 171.188 89.7629C171.62 90.0457 172.031 90.355 172.451 90.651C172.853 90.9735 173.269 91.2828 173.657 91.6186C174.051 91.9544 174.439 92.2946 174.802 92.6702L174.788 92.6878L172.319 90.8322L171.081 89.9043C170.666 89.6039 170.255 89.2769 169.827 89.0162L169.831 88.9941Z" fill="#E3923C" />
        <path d="M170.131 91.3086C170.631 91.5074 171.05 91.8034 171.488 92.0774C171.921 92.3601 172.332 92.6694 172.751 92.9655C173.153 93.288 173.569 93.5973 173.958 93.9331C174.351 94.2689 174.74 94.6091 175.102 94.9846L175.089 95.0023L172.619 93.1466L171.382 92.2188C170.966 91.9183 170.556 91.5914 170.127 91.3307L170.131 91.3086Z" fill="#E3923C" />
        <path d="M170.282 93.9385C170.781 94.1373 171.201 94.4333 171.638 94.7073C172.071 94.99 172.482 95.2993 172.902 95.5953C173.304 95.9179 173.719 96.2272 174.108 96.563C174.501 96.8987 174.89 97.2389 175.252 97.6145L175.239 97.6322L172.769 95.7765L171.532 94.8486C171.117 94.5482 170.706 94.2212 170.277 93.9606L170.282 93.9385Z" fill="#E3923C" />
        <path d="M134.873 20.9781C134.869 20.903 134.864 20.8323 134.864 20.7571C134.851 18.844 136.393 17.2799 138.306 17.2667C140.219 17.2534 141.783 18.7954 141.797 20.7085C141.81 22.6217 140.268 24.1858 138.355 24.199C136.517 24.2123 135.001 22.7896 134.873 20.9781Z" stroke="white" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M152.953 19.6968C152.966 21.61 151.424 23.174 149.511 23.1873C147.598 23.2005 146.034 21.6586 146.021 19.7454C146.007 17.8323 147.549 16.2682 149.462 16.255C151.376 16.2417 152.94 17.7793 152.953 19.6968Z" stroke="white" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M141.771 19.9803C141.771 19.9803 143.989 17.8375 146.017 19.7418" stroke="white" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M130.181 19.6533L134.865 20.7579" stroke="white" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M147.576 14.9254C147.576 14.9254 148.738 13.5602 150.55 15.027" stroke="#1A1A1A" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M135.616 16.0873C135.616 16.0873 136.981 14.5718 139.818 15.7692" stroke="#1A1A1A" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M135.965 74.5725L127.097 75.4032L127.102 72.0894L135.969 71.2588L135.965 74.5725Z" fill="#3763A6" />
        <path d="M286.121 86.7227V104.131H205.314L204.236 90.6506L250.995 16.4717H268.633L249.528 48.4381L226.266 86.7227H286.121ZM273.409 16.4717V128.626H251.225V16.4717H273.409Z" fill="#02C0CC" />
        <path d="M241.686 118.849C250.386 118.849 255.714 120.51 256.046 120.616C256.607 120.797 256.916 121.394 256.735 121.955C256.554 122.516 255.953 122.821 255.396 122.644C255.228 122.591 243.423 118.955 226.342 122.67C225.767 122.794 225.197 122.432 225.074 121.857C224.95 121.283 225.312 120.713 225.886 120.589C231.891 119.282 237.233 118.849 241.686 118.849Z" fill="#3763A6" />
        <path d="M235.487 111.992C235.642 111.7 238.602 101.162 237.034 101.017C235.469 100.871 236.163 101.83 235.306 102.541C234.449 103.252 232.651 92.6925 231.82 94.5703C230.989 96.4481 232.531 98.7058 231.343 98.1977C230.154 97.6896 228.016 87.9252 226.491 88.8442C224.967 89.7632 227.172 93.1874 225.882 93.2978C224.592 93.4083 217.491 90.152 216.572 94.5571C215.653 98.9621 225.387 99.6779 226.354 100.893C227.327 102.108 221.516 102.66 224.415 103.756C227.309 104.856 232.209 106.27 230.397 106.725C230.013 106.822 224.764 104.701 225.038 108.461C225.17 110.282 235.487 111.992 235.487 111.992Z" fill="#2BA167" />
        <path d="M235.509 113.158C235.509 113.158 233.728 99.1611 226.019 97.0801" stroke="#1D402F" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M240.97 123.387C249.152 123.387 255.785 122.485 255.785 121.372C255.785 120.259 249.152 119.357 240.97 119.357C232.788 119.357 226.156 120.259 226.156 121.372C226.156 122.485 232.788 123.387 240.97 123.387Z" fill="#1D402F" />
        <path d="M240.537 120.819C240.82 120.647 248.733 113.087 247.461 112.164C246.188 111.241 246.294 112.42 245.198 112.593C244.103 112.769 247.933 102.766 246.259 103.959C244.589 105.152 244.765 107.883 244.001 106.84C243.237 105.797 246.374 96.3022 244.593 96.3199C242.813 96.3332 242.963 100.402 241.801 99.8413C240.634 99.2802 236.185 92.8604 233.15 96.1829C230.114 99.5055 238.125 105.077 238.341 106.619C238.558 108.157 233.282 105.673 235.209 108.095C237.139 110.516 240.634 114.232 238.841 113.697C238.461 113.582 235.028 109.084 233.344 112.46C232.531 114.095 240.537 120.819 240.537 120.819Z" fill="#2BA167" />
        <path d="M239.95 124.058C239.95 124.058 245.565 108.886 239.989 103.164" stroke="#1D402F" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M243.153 125.361C243.056 125.043 242.124 114.139 243.688 114.289C245.252 114.439 244.39 115.252 245.097 116.109C245.804 116.967 249.551 106.933 250.015 108.934C250.479 110.936 248.539 112.866 249.803 112.588C251.066 112.31 254.999 103.12 256.324 104.308C257.65 105.497 254.844 108.448 256.085 108.797C257.331 109.146 264.918 107.282 264.993 111.78C265.068 116.277 255.374 115.155 254.194 116.167C253.015 117.179 258.613 118.809 255.564 119.344C252.515 119.883 247.434 120.355 249.131 121.142C249.493 121.31 255.043 120.21 254.071 123.85C253.602 125.618 243.153 125.361 243.153 125.361Z" fill="#2BA167" />
        <path d="M241.447 128.176C241.447 128.176 247.279 113.087 255.246 112.486" stroke="#1D402F" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M244.257 112.602C244.111 112.306 241.447 101.689 243.016 101.587C244.584 101.486 243.864 102.422 244.699 103.156C245.534 103.889 247.628 93.3824 248.406 95.2867C249.184 97.1865 247.58 99.4045 248.782 98.9273C249.983 98.4502 252.396 88.7476 253.894 89.7107C255.391 90.6739 253.094 94.0363 254.38 94.1821C255.665 94.3279 262.854 91.2704 263.649 95.702C264.445 100.129 254.698 100.575 253.695 101.759C252.692 102.948 258.48 103.659 255.555 104.676C252.63 105.696 247.69 106.973 249.493 107.477C249.877 107.583 255.179 105.608 254.804 109.359C254.614 111.184 244.257 112.602 244.257 112.602Z" fill="#2BA167" />
        <path d="M243.029 115.654C243.029 115.654 246.369 99.8279 254.136 97.959" stroke="#1D402F" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M238.328 123.639C238.434 123.325 239.689 112.452 238.12 112.558C236.552 112.664 237.387 113.499 236.658 114.334C235.925 115.169 232.478 105.029 231.952 107.017C231.431 109.005 233.313 110.989 232.058 110.675C230.804 110.362 227.145 101.057 225.784 102.206C224.424 103.354 227.145 106.385 225.891 106.699C224.636 107.013 217.107 104.923 216.899 109.416C216.692 113.91 226.412 113.075 227.561 114.122C228.709 115.169 223.067 116.631 226.098 117.259C229.129 117.886 234.193 118.509 232.474 119.247C232.107 119.402 226.593 118.138 227.455 121.81C227.874 123.586 238.328 123.639 238.328 123.639Z" fill="#2BA167" />
        <path d="M238.536 124.787C238.536 124.787 234.564 111.249 226.62 110.414" stroke="#1D402F" stroke-width="0.441831" stroke-miterlimit="10" stroke-linecap="round" />
        <path d="M250.412 142.027L249.763 142.181C243.838 143.578 237.405 143.631 231.427 142.336L226.134 121.429C235.425 123.514 245.433 123.589 254.786 121.636L255.776 121.429L250.412 142.027Z" fill="#02C0CC" />
        <path d="M226.827 124.142C228.802 124.646 233.49 125.622 240.153 125.622C244.395 125.622 249.445 125.216 255.096 124.054L255.688 121.778C255.626 121.778 255.56 121.787 255.493 121.8C238.412 125.516 226.607 121.88 226.439 121.827C226.368 121.805 226.297 121.792 226.222 121.783L226.827 124.142Z" fill="#24416E" />
        <path d="M240.153 124.699C231.453 124.699 226.125 123.037 225.793 122.931C225.232 122.75 224.923 122.154 225.104 121.593C225.285 121.031 225.886 120.727 226.443 120.903C226.611 120.956 238.416 124.593 255.498 120.877C256.072 120.753 256.642 121.115 256.766 121.69C256.889 122.264 256.527 122.834 255.953 122.958C249.948 124.266 244.606 124.699 240.153 124.699Z" fill="#02C0CC" />
      </g>
      <defs>
        <clipPath id="clip0_17893_52338">
          <rect width="286.121" height="151.19" fill="white" />
        </clipPath>
      </defs>
    </svg>




  );
};

export default NoPageFound;
