import React, { useContext, useEffect, useState } from "react";
import "./Groups.scss";
import * as feedDetails from "../../core/services/posts";
import { NoData } from "../../components/Fallback/NoData";
import { AppContext } from "../../AppContext";
import Post from "../../components/post/Post";
import { likePost } from "../../core/services/posts";
import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";

import {
  OnlyTextPostSkeleton,
  PostSkeleton,
} from "../../components/skeletons/SkeletonsComponent";
import "react-datepicker/dist/react-datepicker.css";
import { PermissionContext } from "../../context/PermissionContext";
import { useOutletContext } from "react-router-dom";
import { filterDataPerPage } from "../../core/variables/Variables";
import { GroupsSide } from "../rightBar/RightBar";
import { AlertContext } from "../../context/AlertContext";

const Groups = () => {
  const alert = useContext( AlertContext );
  const { permissions } = useContext( PermissionContext );
  const { joinedGroups } = useContext( AppContext );
  const { search } = useOutletContext( {} ) || {};

  const [ data, setData ] = useState( [] );
  const [ isLoading, setIsloading ] = useState( true );
  const [ page, setPage ] = useState( 1 );
  const [ totalPage, setTotalPage ] = useState( true );
  const [ loadMoreLoader, setLoadMoreLoader ] = useState( false );

  const getFeeds = () => {
    setIsloading( true );

    let searchData = {
      per_page: filterDataPerPage,
      name: search,
    };

    feedDetails.getNewestPosts( searchData ).then( ( res ) => {
      if ( res?.data?.success ) {
        setData( res?.data?.data?.data );

        setTotalPage( res.data?.data?.last_page );
        setPage( res.data?.data?.current_page );
      } else {
        alert?.[ "warning" ](
          res?.data ? res?.data?.message : res?.message
        );
      }
      setIsloading( false );
    } );
  };
  const LoadMore = () => {
    setLoadMoreLoader( true );
    let oldData = [ ...data ];
    let dataToSend = {
      page: page + 1,
      per_page: filterDataPerPage,
    };

    feedDetails.getNewestPosts( dataToSend ).then( ( res ) => {
      if ( res?.data?.success ) {
        setLoadMoreLoader( false );
        if ( res.data?.data === null ) {
          setData( [] );
        } else {
          let array = res.data?.data?.data;
          array.map( ( val ) => {
            val.newComment = false;
            val.liked = false;
            val.comments = [];
            val.commentsLoader = true;
          } );
          array.map( ( val ) => {
            oldData.push( val );
          } );
          setData( oldData );
          setTotalPage( res.data?.data?.last_page );
          setPage( res.data?.data?.current_page );
        }
      }
    } );
  };
  const handleLike = ( type, id, reactionTypeId, reaction, prevReaction ) => {
    let dataToLike = {
      like_type_id: reactionTypeId,
      is_liked: false,
    };
    let currentPost = [ ...data ];
    currentPost.map( ( val ) => {
      if ( val.id === id ) {
        if ( type === "add" ) {
          if ( val?.is_reacted === 1 ) {
            if ( val.hasOwnProperty( prevReaction?.key ) ) {
              if ( val[ prevReaction?.key ] !== 0 ) {
                val[ prevReaction?.key ] -= 1;
              }
            }
            if ( val.hasOwnProperty( reaction ) ) {
              val[ reaction ] += 1;
            }
            val.likes_count -= 1;

            val.is_reacted = 0;
            likePost( id, {
              is_liked: true,
              like_type_id: reactionTypeId,
            } ).then( ( res ) => res );
          } else {
            if ( val.hasOwnProperty( prevReaction?.key ) ) {
              if ( val[ prevReaction?.key ] !== 0 ) {
                val[ prevReaction?.key ] -= 1;
              }
            }
            if ( val.hasOwnProperty( reaction ) ) {
              val[ reaction ] += 1;
            }
            val.likes_count += 1;

            val.is_reacted = 1;
            likePost( id, {
              is_liked: false,
              like_type_id: reactionTypeId,
            } ).then( ( res ) => res );
          }
          ////////// on editttt
        } else {
          if ( val.hasOwnProperty( prevReaction?.key ) ) {
            if ( val[ prevReaction?.key ] !== 0 ) {
              val[ prevReaction?.key ] -= 1;
            }
          }
          if ( val.hasOwnProperty( reaction ) ) {
            val[ reaction ] += 1;
          }

          likePost( id, dataToLike ).then( ( res ) => {
            if ( res?.data?.success ) {
            } else {
            }
          } );
        }
      }
    } );
    setData( currentPost );
  };

  useEffect( () => {
    if ( permissions?.Posts?.[ "view-posts-by-group-id" ] ) {
      const timer = setTimeout( () => {
        getFeeds();
      }, 1000 );
      return () => {
        clearTimeout( timer );
      };
    }
  }, [ search, joinedGroups, permissions?.Posts?.[ "view-posts-by-group-id" ] ] );

  const updatePostCommentCount = ( postData, type, commentChildrenCount ) => {
    let array = [ ...data ];
    let index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( type === "add" ) {
      array[ index ][ "comments_count" ] += 1;
    } else {
      //delete comment and its descendant
      array[ index ][ "comments_count" ] -= ( 1 + commentChildrenCount );
    }
    setData( array );
  };

  //false=>like
  const updatePostLikeCount = (
    postData,
    is_reacted,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {

    let array = [ ...data ];
    let post_index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( is_reacted ) {
      //like
      if ( !array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] += 1; //if user already reacted and now he is changing reaction
      } else {
        array[ post_index ][ prevReaction?.key ] -= 1; //if user already reacted before then we remove the prev
      }
      array[ post_index ][ "is_reacted" ] = 1;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    } else {
      //unlike
      if ( array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] -= 1;
      }
      array[ post_index ][ "is_reacted" ] = 0;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ prevReaction?.key ] -= 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    }

    setData( array );
  };

  return (
    <>
      <div className="middleComponent">
        { !permissions?.Posts?.[ "view-posts-by-group-id" ] ? (
          "Permission Denied"
        ) : isLoading ? (
          <>
            <OnlyTextPostSkeleton count={ 2 } />
            <PostSkeleton count={ 1 } />
          </>
        ) : data?.length === 0 ? (
          <NoData title={ "NO_FEED_FOUND" } />
        ) : (
          <>
            { data?.map( ( post, index ) => (
              <Post
                list
                data={ post }
                key={ index }
                updatePostLikeCount={ updatePostLikeCount }
                updatePostCommentCount={ updatePostCommentCount }
              // image={post?.user?.profile_image}
              // userName={`${post?.user?.first_name}  ${post?.user?.last_name}`}
              />
            ) ) }
            { page < totalPage && (
              <LoadMoreButton
                loadMoreLoader={ loadMoreLoader }
                title="LOAD_MORE"
                onClick={ LoadMore }
              />
            ) }
          </>
        ) }
      </div>
      <GroupsSide />
    </>
  );
};

export default Groups;
