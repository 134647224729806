import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { Badge } from "@mui/material";
import { NavLink } from "react-router-dom";
import {
  BriefCaseSVG,
  CompaniesSVG,
  ConnectionsSVG,
  EprescriptionSVG,
  GazetteSVG,
  QnASVG,
  WatchSVG,
  WebBinarsSVG,
  WebinarsSVG,
} from "../../assets/icons/SVG";
import { PermissionContext } from "../../context/PermissionContext";
import { useMediaQuery } from "react-responsive";
import { NotificationContext } from "../../context/NotificationContext";
import { useNavigate } from "react-router-dom";
import { DisplayTooltip } from "../../components/display-component/DisplayComponent";

const HomeSide = () => {
  const navigate = useNavigate();
  const { permissions } = useContext( PermissionContext );
  const { notificationCount } = useContext( NotificationContext );
  const [ t ] = useTranslation( "common" );
  const links = [
    {
      image: <BriefCaseSVG />,
      to: `../catalog/all`,
      name: "PRODUCT_CATALOG",
      type: 1,
      visible: true,
    },
    {
      image: <GazetteSVG />,
      to: `../blog/blogs`,
      name: "BLOG",
      upComing: "Coming Soon",
      type: 1,
      visible: true,
    },
    {
      image: <QnASVG />,
      to: `../question-and-answer`,
      name: "Q&A",
      upComing: "Coming Soon",
      type: 1,
      visible: true,
    },

    { image: <WatchSVG />, to: `../watch/all`, name: "WATCH", type: 1, visible: true, },

    {
      image: <ConnectionsSVG />,
      to: `../connections/requests`,
      name: "CONNECTIONS",
      type: 1,
      notification:
        notificationCount?.pending_friend_request +
        notificationCount?.accepted_friend_request || 0,
      visible: true,
    },
    {
      image: <EprescriptionSVG />,
      to: `../EprescriptionSVG`,
      name: "E-PRESCRIPTION",
      upComing: "Coming Soon",
      type: 2,
      visible: true,
    },
    {
      image: <CompaniesSVG />,
      to: `../companies`,
      name: "COMPANIES",
      type: 1,
      visible: permissions?.Company?.[ "view-suggested-companies" ],
    },
    { image: <WebBinarsSVG />, to: `../`, name: "WEBINARS", type: 2, visible: true, },
  ];
  const isSmallScreen = useMediaQuery( { query: "(min-width: 900px)" } );

  return isSmallScreen ? (
    <div className="sideBar sideComponent">
      { links
        .filter( ( i ) => i.visible )
        .map( ( e, index ) =>
          e.type === 2 ? (
            <DisplayTooltip
              key={ index }
              title={ t( "COMING_SOON" ) }
              placement="right-start"

            >
              <div className="links" style={ { cursor: "default" } }>
                <div className="navCompo">
                  <p className="navTypo">{ e.image }</p>
                </div>
                { t( e.name ) }
              </div>
            </DisplayTooltip>

          ) : (
            <NavLink
              to={ e.to }
              className={ ( ac ) => ( ac.isActive ? "links active" : "links" ) }
              key={ index }
            >
              <div className="navCompo">
                <p className="navTypo">
                  { " " }
                  <Badge
                    variant="dot"
                    color="error"
                    onClick={ () => navigate( `groups/your-feed` ) }
                    invisible={ !e?.notification || e?.notification === 0 }
                  >
                    { e.image }
                  </Badge>


                </p>
              </div>
              { t( e.name ) }
            </NavLink>
          )
        ) }
    </div>
  ) : null;
};

export default HomeSide;
