import React from "react";
import "./ResponseMessage.scss";

export function ResponseMessage( props ) {
  const { message } = props;
  return (
    message && (
      <div className="ErrorMessage">
        <span className="Message">{ message }</span>
      </div>
    )
  );
}

export default ResponseMessage;
