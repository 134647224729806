import { api } from "./main";
//get a quiz by quiz id
export const getQuizByQuizId = (quiz_id) => {
  return api
    .get(`v1/quizzes/${quiz_id}`)
    .then((res) => res)
    .catch((err) => err);
};
//answer a quiz
export const answerQuiz = (payload) => {
  return api
    .post(`v1/answer-quiz`, payload)
    .then((res) => res)
    .catch((err) => err);
};
