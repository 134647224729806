import React, { useEffect, useState, useContext } from "react";
import { AlertContext } from "../../context/AlertContext";
import { useInView } from "react-intersection-observer";
//API
import { getUserPostDetails } from "../../core/services/posts";
import { getFeaturedPosts } from "../../core/services/featuredPosts";
//CSS
import "./Home.scss";
//COMPONENT
import LoadMoreButton from "../../components/load-more-button/LoadMoreButton";
import Post from "../../components/post/Post";
import { MeetingRequests } from "../rightBar/RightBar";
import { NoFileSVG } from "../../assets/icons/SVG";
import logo192 from "../../assets/images/logo192.png";
import NoData from "../../components/Fallback/NoData";
import {
  OnlyTextPostSkeleton,
  PostSkeleton,
} from "../../components/skeletons/SkeletonsComponent";


const mergeArrays = ( arr1, arr2 ) => {
  const mergedArray = arr1?.flatMap( ( elem, index ) =>
    index % 3 === 2 && arr2?.length > index / 3
      ? [ elem, arr2?.[ Math.floor( index / 3 ) ] ]
      : [ elem ]
  );

  return mergedArray?.concat( arr2?.slice( Math?.floor( arr1.length / 3 ) ) );
};

const Home = () => {
  const { ref, inView } = useInView();
  const alert = useContext( AlertContext );
  const [ featuredPosts, setFeaturedPosts ] = useState( { data: [], page: 1, totalPage: 1 } );
  const [ posts, setPosts ] = useState( { data: [], page: 1, totalPage: 1 } );
  const [ data, setData ] = useState( [] );
  const [ isLoading, setIsLoading ] = useState( true );
  const [ loadMore, setLoadMore ] = useState( false );


  const GetAllData = async ( loadMore ) => {
    let handleLoad = loadMore ? setLoadMore : setIsLoading
    /* we are calling 2 functions 
    featured post and normal posts 
    after getting the data of both of them we are concatenating the array in a way to have
    each 3 of posts  , to have 1 featured post
    they have similar function that's why it's getData for both
    */
    handleLoad( true )
    const featuredPostsData = await getData( loadMore, getFeaturedPosts, featuredPosts, setFeaturedPosts, true );
    const postsData = await getData( loadMore, getUserPostDetails, posts, setPosts, false );

    if ( featuredPostsData && postsData ) {
      let data = mergeArrays( postsData, featuredPostsData );
      setData( data )
      handleLoad( false )

    }
  }

  /*
  loadMore => to check if it's the first render or it's because of a loadMore(scroll)
  handleAPI => to check which api to call as they have same function
  state =>to check which state to call as they have same function
  handleState => to check which handleState to call as they have same function
  isFeatured => to check the map of the function as featured post have different approach
  */
  const getData = async ( loadMore, handleApi, state, handleState, isFeatured ) => {
    let oldData = [ ...state?.data ];

    //checking if we have to call the api or not
    if ( ( loadMore && state?.page < state?.totalPage ) || !loadMore ) {
      let payload = {
        page: loadMore ? state?.page + 1 : 1,
        per_page: 5,
      };
      // Returning the Promise here
      return handleApi( { ...payload } ).then( ( res ) => {

        if ( res?.data?.success ) {
          let res_data = res?.data?.data;
          //checking if it's featured to render the map functionality
          let new_data = [];
          let initialData = isFeatured ? [ ...res_data?.data?.map( ( val ) => {
            return {
              ...val,
              is_featured: true,
              post_media: [ { path: val?.path } ],
            };
          } ) ] : [ ...res_data?.data ];
          if ( loadMore ) {

            new_data = [ ...oldData, ...initialData ];
          } else {

            new_data = [ ...initialData ]
          }

          handleState( {
            data: new_data,
            page: res_data?.current_page,
            totalPage: res_data?.last_page,
          } );
          // Returning new_data here
          return new_data;
        }
      } );
    } else {
      // Returning oldData here
      return oldData;
    }
  };


  useEffect( () => {
    //function that call the 2 needed one :feartured post and normal posts to concatenate them
    GetAllData( false );
  }, [] );



  const updatePostCommentCount = ( postData, type, commentChildrenCount ) => {
    let array = [ ...data ];
    let index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( type === "add" ) {
      array[ index ][ "comments_count" ] += 1;
    } else {
      //delete comment and its descendant
      array[ index ][ "comments_count" ] -= ( 1 + commentChildrenCount );
    }
    setData( array );
  };

  const updatePostLikeCount = (
    postData,
    is_reacted,
    reactionTypeId,
    reaction,
    prevReaction
  ) => {

    let array = [ ...data ];
    let post_index = array.findIndex( ( item ) => item.id === postData?.id );
    if ( is_reacted ) {
      //like
      if ( !array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] += 1; //if user already reacted and now he is changing reaction
      } else {
        array[ post_index ][ prevReaction?.key ] -= 1; //if user already reacted before then we remove the prev
      }
      array[ post_index ][ "is_reacted" ] = 1;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    } else {
      //unlike
      if ( array[ post_index ][ "is_reacted" ] ) {
        array[ post_index ][ "likes_count" ] -= 1;
      }
      array[ post_index ][ "is_reacted" ] = 0;
      array[ post_index ][ reaction ] += 1;
      array[ post_index ][ prevReaction?.key ] -= 1;
      array[ post_index ][ "user_like" ] = [ { like_type_id: reactionTypeId } ];
    }
    setData( array );
  };


  useEffect( () => {
    inView && GetAllData( true );
  }, [ inView ] );


  return (
    <div className="globalStructure">
      <div className="middleComponent suggestions-page">
        { isLoading ? (
          <>
            <OnlyTextPostSkeleton count={ 2 } />
            <PostSkeleton count={ 1 } />
          </>
        ) : ( data?.length === 0 ) ? (

          <NoData svgIcon={ <NoFileSVG /> } title="NO_FEED_FOUND" />

        )
          :
          data?.map( ( postElement ) => (
            <Post
              list
              halfWidth //for videos
              data={ postElement }
              key={ postElement?.id }
              isAdmin={ postElement?.is_featured }
              updatePostLikeCount={ updatePostLikeCount }
              updatePostCommentCount={ updatePostCommentCount }
              image={ postElement?.is_featured ? logo192 : postElement?.user?.profile_image }
              userName={ postElement?.is_featured ? "Meet@Med" : `${ postElement?.user?.first_name } ${ postElement?.user?.last_name }` }
              reactions={ postElement?.is_featured ? false : true }
            />
          ) )
        }
        { ( ( featuredPosts?.page < featuredPosts?.totalPage ) || ( posts?.page < posts?.totalPage ) ) && (
          <LoadMoreButton
            ref={ ref }
            loadMoreLoader={ loadMore }
            title="LOAD_MORE"
            onClick={ () => GetAllData( true ) }
          />
        ) }
      </div>

      {/* to update data when user accept group invitation or group suggestions */ }
      <MeetingRequests
        updateHomeData={ () => {
          GetAllData( false )
        } } />
    </div>
  );
};

export default Home;
