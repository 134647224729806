import React from "react";
import "./SearchBar.scss";
import SearchIcon from "@mui/icons-material/Search";
import { InputAdornment, OutlinedInput } from "@mui/material";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
export const SearchBar = (props) => {
  const { placeholder, value } = props;
  const [t] = useTranslation("common");
  return (
    <>
      <OutlinedInput
        className="SearchBar"
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon style={{ color: "#02C0CC", marginLeft: "10px" }} />
          </InputAdornment>
        }
        {...props}
        value={value || ""}
        placeholder={t(placeholder)}
      />
    </>
  );
};
SearchBar.defaultProps = {
  placeholder: "SEARCH...",
};
SearchBar.propTypes = {
  /**
   * The current value of the input field.
   * @type {string}
   */
  value: PropTypes.string.isRequired,
};
