//#region IMPORTS
//====REACT AND JAVASCRIPT UTILITIES
import React, { forwardRef } from "react";
import dayjs from "dayjs";
//====COMPONENTS====
import {
    FormCalendarInput,
} from "../../../../components/form/form-componenrt/FormComponent";
import TimeInterval from "../../../../components/form/time-interval/TimeInterval";
//====SCSS====
import "./SetSchedule.scss";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, Slide } from "@mui/material";
import { CloseIcon } from "../../../../assets/icons/SVG";
import { useTranslation } from "react-i18next";
import Button from "../../../../components/button/Button";
//#endregion

const Transition = forwardRef( function Transition( props, ref ) {
    return <Slide direction="up" ref={ ref } { ...props } />;
} );

const newInterval = { start_time: "00:00:00", end_time: "00:00:00" };

//#region  ADDING EXCEPTION FORM COMPONENT
const ExceptionsHandle = ( props ) => {
    const { setExceptionDialog, formiks } = props;

    const { exceptions } = formiks.values;
    const [ t ] = useTranslation( 'common' )

    const handleDeleteException = ( name, value ) => {
        formiks.setFieldValue( "exceptions", {
            ...exceptions,
            [ name ]: exceptions?.[ name ]?.time?.length
                ? {
                    ...exceptions?.[ name ],
                    time: exceptions?.[ name ].time.filter( ( i, index ) => index !== value ),
                }
                : null,
        } );
    };

    const TimeChangeException = ( day ) => ( index, e, type ) => {
        formiks.setFieldValue( "exceptions", {
            ...exceptions,
            [ day ]: {
                ...exceptions[ day ],
                time: exceptions?.[ day ]?.time?.map( ( el, i ) =>
                    i !== index
                        ? el
                        : {
                            ...exceptions?.[ day ]?.time[ index ],
                            [ `${ type }_time` ]: dayjs( e ).format( "HH:mm:ss" ),
                        }
                ),
            },
        } );
    };

    const handleAddTime = ( val ) => {
        //when adding time from the calendar => we convert it to d => (0-6)
        exceptions[ dayjs( val ).format( "d YYYY-MM-DD" ) ] = {
            day_off_reason: "",
            is_day_off: false,
            time: [ newInterval ],
            //the date object to be formatted later on the display
            exact_date: val,
        };


        formiks.setFieldValue( "exceptions", exceptions );
    };

    //add another interval in the expection field
    const handleAddException = ( name ) => () => {
        formiks.setFieldValue( "exceptions", {
            ...exceptions,
            [ name ]: {
                ...exceptions?.[ name ],
                time: [ ...exceptions?.[ name ]?.time, newInterval ],
            },
        } );
    };
    return (

        <Dialog
            open={ true }
            TransitionComponent={ Transition }
            keepMounted
            onClose={ () => setExceptionDialog( false ) }
            className={ `SchedulePopUpCancelMeeting` }
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                { t( 'SELECT_EXCEPTIONS' ) }
                <IconButton
                    aria-label="close"
                    onClick={ () => setExceptionDialog( false ) }
                    className="close-container"
                >
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent><div className="exception-field">
                { Object.keys( exceptions )?.map( ( day, index ) =>
                (
                    <TimeInterval
                        key={ index }
                        exceptionTime
                        disabled={ exceptions?.[ day ]?.is_day_off }
                        checkBoxValue={ day }
                        day={ exceptions?.[ day ] }
                        onRemove={ () => {
                            let currentExeptions = formiks?.values?.exceptions
                            delete currentExeptions[ day ];
                            formiks.setFieldValue( "exceptions", currentExeptions );
                        } }
                        time={ exceptions?.[ day ]?.time }
                        handleDelete={ handleDeleteException }
                        handleAdd={ handleAddException( day ) }
                        timeChange={ TimeChangeException( day ) }
                        inputClassName={ `formInput ${ !exceptions?.[ day ]?.is_day_off ? "" : "disabled"
                            }` }
                        handleCheckBox={ ( e ) =>
                            formiks.setFieldValue( "exceptions", {
                                ...exceptions,
                                [ e.target.value ]: {
                                    ...exceptions[ e.target.value ],
                                    is_day_off: !exceptions[ e.target.value ]?.is_day_off,
                                },
                            } )
                        }
                        checked={ !exceptions?.[ day ]?.is_day_off }
                    />
                )
                ) }
            </div>
                <div className="cancel-meet-message-container">
                    <FormCalendarInput
                        label="ADD_NEW_DATE"
                        onChange={ handleAddTime }
                        minDate={ new Date( formiks.values?.start_date ) }
                        maxDate={ new Date( formiks.values?.end_date ) }

                        placeholderText="YYYY/MM/DD"
                        name="exception_date"
                    />

                </div></DialogContent>
            <DialogActions>
                <Button
                    onClick={ () => setExceptionDialog( false ) }
                    isSelected
                    name={ Object.keys( exceptions ).length ? "ADD" : "CONTINUE" }
                    width="25% !important"
                />
            </DialogActions>
        </Dialog>



    );
};

export default ExceptionsHandle;
//#endregion