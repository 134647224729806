import { useContext, useState } from "react";

import { InputTextBootstrap } from "../../../components/form/form-componenrt/FormComponent";
import Form from "../../../components/form/forms/Form";
import PrimaryButton from "../../../components/primary-button/PrimaryButton";
import { useNavigate } from "react-router-dom";
import * as auth from "../../../core/services/auth";
import "./ForgotPassword.scss";
import ResponseMessage from "../../../components/responseMessage/ResponseMessage";
import { AlertContext } from "../../../context/AlertContext";
function ForgotPassword() {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [emailSentMessage, setEmailSentMessage] = useState("");
  const Alert = useContext(AlertContext);
  const onChangeInput = (e) => {
    setEmail(e.target.value);
    setEmailSentMessage("");
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    let data = {
      email: email,
      portal_id: 3,
    };
    auth.ForgetPassword(data).then((res) => {
      setEmailSentMessage(res?.data ? res?.data?.message : res?.message);
      res?.data?.success && navigate(`../email-sent`);
      Alert?.[res?.data?.success ? "success" : "warning"](
        res?.data ? res?.data?.message : res?.message
      );
    });
  };
  return (
    <div className="page-container loginProcess-container">
      <Form onSubmit={handleSubmit} title={"FORGOT_PASSWORD_NO_?"}>
        {" "}
        <>
          <InputTextBootstrap
            label="EMAIL"
            type="email"
            name="email"
            value={email}
            placeHolder={"ENTER_EMAIL"}
            onChange={onChangeInput}
          />
          <div style={{ marginTop: "15px" }}>
            <PrimaryButton title="CONTINUE" width="100%" />
          </div>
          <ResponseMessage message={emailSentMessage} />
        </>
      </Form>
    </div>
  );
}

export default ForgotPassword;
