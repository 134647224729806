import React, { useEffect, useState } from "react";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { useParams, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
//COMPONENT
import { AskQuestionSVG } from "../../assets/icons/CtaSVG";
import QuestionHandle from "../questionandanswer/browse/QuestionHandle"
import { dateFormat } from "../../core/variables/Variables";
import { isInnerHTMLContentEmpty } from "../../core/functions/valueFunctions";
import FileSVG from "../../assets/icons/FileSVG";
import { IconsButton, LinkButton } from "../../components/button/Button";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { DocumentPopUp } from "../../components/popup/Popup";
import BackRep from "../../components/back-repository/BackRep";
import * as MUI from "@mui/material";
import CarouselComponent from "../../components/carousel-component/Carousel";
import LinkedPresentation from "../rightBar/LinkedPresentation";
import { NoData } from "../../components/Fallback/NoData";
import { DisplayDateTime } from "../../components/display-component/DisplayComponent";
//api
import * as productsAPI from "../../core/services/products";
//CSS
import "./Catalog.scss";
import palette from "../../theme/color.scss"



function CatalogInformation() {
  const [ t ] = useTranslation( "common" );
  const { product_id } = useParams() || {};
  const [ data, setData ] = useState( null )
  const [ isLoading, setIsLoading ] = useState( true );
  const [ productData, setProductData ] = useState( null );
  const [ openMessage, setOpenMessage ] = useState( false );
  const [ addedFields, setAddedFields ] = useState( {} );

  const [ onHideState, setOnHideState ] = useState( false );
  const [ path, setPath ] = useState( "" );
  const [ images, setImages ] = useState( [] );
  const [ linkedPresentationData, setLinkedPresentationData ] = useState( [] );

  const openModal = ( path ) => {
    setOnHideState( !onHideState );
    setPath( path );
  };

  const GetProductData = () => {
    setIsLoading( true );
    productsAPI.getProductById( product_id ).then( ( response ) => {
      if ( response.data?.success ) {
        let added_fields = {};

        response.data?.data?.dynamic_field_value?.map( ( item ) => {
          /* EXPLANATION:
      backend has not the ability to check whether the tetxbox input is empty or not 
      that's we are checking this from our part
      */
          if ( item?.dynamic_field?.field_type?.name === "Textbox" && isInnerHTMLContentEmpty( item?.value ) ) {
            return;
          }
          added_fields[ item.dynamic_field?.name ] = item;
          if ( item.has_other && item?.value ) {
            //WE ARE OVERRIDING THE IS_CATEGORICAL AND FIELD TYPE PROPERTIES TO MATCH THE CONDITIONS
            added_fields[ t( "CUSTOM" ) + " " + item.dynamic_field?.name ] = {
              ...item,
              is_categorical: false,
              field_type: { name: "string" },
            };
          }
        } );

        let all_presentation = response?.data?.data?.post?.concat(
          response?.data?.data.product_presentations
        );
        setData( response?.data?.data )
        setImages(
          response?.data?.data ? response?.data?.data?.product_media : []
        );
        setAddedFields( {
          [ t( 'DESCRIPTION' ) ]: response.data?.data?.description
            ? response.data?.data?.description
            : t( "NO_DESCRIPTION" ),
          ...( Object.keys( added_fields ).length !== 0 && { ...added_fields } )
        } );
        setProductData( {
          all_data: response.data.data,
          displayed_data: {
            COMPANY: (
              <Link
                className="cursor-pointer hreflink"
                to={ `../companies-details/about/${ response?.data?.data?.pharmaceutical_company_id }` }
              >
                { response.data.data?.pharmaceutical_company?.name }
              </Link>
            ),

            MANUFACTURER: response.data.data?.manufacturer || t( "NOT_AVAILABLE" ),

            DISTRIBUTOR: response.data.data?.distributor || t( "NOT_AVAILABLE" ),
            THERAPEUTICAL_AREA:
              response.data.data?.therapeutic_area?.length !== 0
                ? response.data.data?.therapeutic_area
                  ?.map( ( el ) => el?.name )
                  .join( ", " )
                : t( "NOT_AVAILABLE" ),
            CATEGORY:
              response.data.data?.category.length !== 0
                ? response.data.data?.category[ 0 ].name
                : t( "NO_CATEGORY" ),
          }
        } );

        setLinkedPresentationData( all_presentation );
      }
      setIsLoading( false );
    } );
  };

  useEffect( () => {
    GetProductData();
  }, [] );

  return (
    <div className="globalStructure ">
      <div className="middleNoLeftComponent productInformation fade-in">
        { isLoading ? (
          <MUI.Skeleton />
        ) : productData?.length === 0 ? (
          <NoData />
        ) : (
          <>
            <BackRep to={ `../catalog/all` } title={ t( "PRODUCTS" ) } />
            <div className="carousel">
              <div
                style={ { position: "relative" } }
                className="carousel-item-component"
              >
                <CarouselComponent data={ images } />
              </div>

              <MUI.Card className="product-description">
                <MUI.CardContent className="content">
                  <div className='header'>
                    <div className="title">{ productData?.all_data?.name }</div>
                    <div className="icon-space">
                      <LinkButton
                        className="ask-question"
                        text="ASK_A_QUESTION"
                        endIcon={ <AskQuestionSVG /> }
                        onClick={ () => setOpenMessage( true ) } />
                    </div>

                  </div>
                  <div className="product-information">
                    <div className="non-dynamic-info">
                      { productData &&
                        Object.keys( productData?.displayed_data )?.map(
                          ( productItem, index ) => (
                            <div className="row-style" key={ index }>

                              <div className="title">{ t( productItem ) }</div>
                              <div className="body">
                                { productData?.displayed_data?.[ productItem ] }
                              </div>
                            </div>
                          )
                        ) }

                    </div>
                    <MUI.Divider sx={ { borderWidth: "1px", backgroundColor: palette.primaryColor80 } } />



                    { addedFields &&
                      Object.keys( addedFields )?.map(
                        ( addedFieldsItem, addedFieldsIndex ) => (
                          <div
                            className="row-style"
                            key={ `custom-${ addedFieldsIndex }` }
                          >
                            <div className="title">{ addedFieldsItem }</div>
                            <div className="body">
                              { !addedFields?.[ addedFieldsItem ]
                                ?.is_categorical ? (
                                addedFields?.[ addedFieldsItem ]?.dynamic_field
                                  ?.field_type?.name === "Date" ? (
                                  <DisplayDateTime
                                    data={ addedFields?.[ addedFieldsItem ]?.value }
                                    format={ dateFormat }
                                  />
                                ) : addedFields?.[ addedFieldsItem ]
                                  ?.dynamic_field?.field_type?.name ===
                                  "File" ||
                                  addedFields?.[ addedFieldsItem ]?.dynamic_field
                                    ?.field_type?.name === "Image" ? (
                                  addedFields?.[ addedFieldsItem ]?.value ? (
                                    <IconsButton
                                      icon={ <FileSVG /> }
                                      onClick={ () => {
                                        window.open(
                                          REACT_APP_IMAGE_URL +
                                          addedFields?.[ addedFieldsItem ]
                                            ?.value
                                        );
                                      } }
                                    />
                                  ) : (
                                    t( "NOT_AVAILABLE" )
                                  )
                                ) : addedFields?.[ addedFieldsItem ]?.dynamic_field
                                  ?.field_type?.name === "Textbox" ? (

                                  <div
                                    dangerouslySetInnerHTML={ {
                                      __html: addedFields?.[ addedFieldsItem ]?.value,
                                    } }
                                  ></div>
                                ) : addedFields?.[ addedFieldsItem ]?.value ? (
                                  addedFields?.[ addedFieldsItem ]?.value
                                ) : (
                                  t( "NOT_AVAILABLE" )
                                )
                              ) : addedFields?.[ addedFieldsItem ]?.field_category
                                .length !== 0 ? (
                                addedFields?.[ addedFieldsItem ]?.field_category
                                  ?.map( ( obj ) => obj.name )
                                  .join( ", " )
                              ) : (
                                t( "NOT_AVAILABLE" )
                              ) }
                            </div>
                          </div>
                        )
                      ) }
                  </div>
                </MUI.CardContent>
              </MUI.Card>

            </div>
          </>
        ) }
        { onHideState && (
          <DocumentPopUp
            onHideState={ onHideState }
            onHide={ () => {
              setOnHideState( false );
            } }
            pdf={ path }
          />
        ) }
        { openMessage && (
          <QuestionHandle
            onClose={ () => setOpenMessage( false ) }
            type="product"
            topicData={ data }
          />
        ) }
      </div>
      <LinkedPresentation data={ linkedPresentationData } loading={ isLoading } />
    </div>
  );
}
export default CatalogInformation;
