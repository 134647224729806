import "./variables.scss";
import { Drawer, Dialog, Badge } from "@mui/material";
import styled from "@emotion/styled";
import * as MUI from "@mui/material";
import palette from "./color.scss";

export const ChatContactDrawer = styled(Drawer)({
  "& .MuiDrawer-paper": {
    boxSizing: "border-box",
    maxWidth: "300px",
    fontSize: "18px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflowX: "hidden",
    overflowY: "auto",
    padding: "10px 20px",
  },
});
export const NoticeDialog = styled(Dialog)({
  "& .MuiDialogTitle-root": {
    border: "none",
    padding: "unset",
  },
});

export const ThemeBadge = styled(Badge)({
  "& .MuiBadge-badge": {
    top: 5,
    right: 2,
    color: "red",
    backgroundColor: palette.whiteColor,
    border: `2px solid red`,
    padding: "0px",
  },
});

export const StyledFilterMenu = styled((props) => (
  <MUI.Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 4,
    marginTop: theme.spacing(1),
    minWidth: 280,
    maxWidth: 300,
    overflow: "visible",
    boxShadow:
      "rgb(255, 255, 255) 0 0 0 0, rgba(0, 0, 0, 0.05) 0 0 0 1px, rgba(0, 0, 0, 0.1) 0 10px 15px -3px, rgba(0, 0, 0, 0.05) 0 4px 6px -2px",

    "& .MuiMenuItem-root": {
      cursor: "default",
      backgroundColor: "transparent",
    },
  },
}));
