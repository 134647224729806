import React, { forwardRef } from "react";
import { Button, LinearProgress } from "@mui/material";
import { useTranslation } from "react-i18next";

import "./LoadMoreButton.scss";
const BasicButtons = forwardRef( ( props, ref ) => {
  const [ t ] = useTranslation( "common" );

  return (
    <div className="load-more-button-container" ref={ ref }>
      { props.isLoadMore ? <LinearProgress /> : <Button onClick={ props.onClick } variant="text">
        { props.loadMoreLoader ? t( "LOADING_DATA" ) : t( props.title ) }
      </Button> }
    </div>
  );
} );
export default BasicButtons;
