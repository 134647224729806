import React from "react";

const UserSVG = (props) => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
    style={{ pointerEvents: "none" }}
  >
    <path
      d="M10.833 10C13.5955 10 15.833 7.7625 15.833 5C15.833 2.2375 13.5955 0 10.833 0C8.07051 0 5.83301 2.2375 5.83301 5C5.83301 7.7625 8.07051 10 10.833 10ZM10.833 12.5C7.49551 12.5 0.833008 14.175 0.833008 17.5V20H20.833V17.5C20.833 14.175 14.1705 12.5 10.833 12.5Z"
      fill="#71A2A7"
    />
  </svg>
);

export default UserSVG;
