import { Card, CardContent, CardMedia } from "@mui/material";
import React from "react";
import "./PresentationPdfCard.scss";

function PresentationPdfCard( props ) {
  const { title, ...cardMedia } = props;
  return (
    <Card className="cardCompo">
      <CardMedia component="video" className="VideosPresent" { ...cardMedia } />
      <CardContent className="content"><div>{ title }</div></CardContent>
    </Card>
  );
}

export default PresentationPdfCard;
