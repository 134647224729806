import { api } from "./main";

export const GetLatestChat = ( payload ) => {
  return api
    .get( `v1/chats`, {
      params: { ...payload },
    } )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const SendChat = ( payload ) => {
  return api
    .post( `v1/chats`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};

export const PinChat = ( payload ) => {
  return api
    .patch( `v1/pin-chat/${ payload?.user_chat_id }`, payload )
    .then( ( res ) => res )
    .catch( ( err ) => err );
};
