//UTILITIES
import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";

//COMPONENT
import GroupDefaultImage from "../../assets/images/group-default-image.png";
import * as MUI from "@mui/material";
import { NoData } from "../../components/Fallback/NoData";
import TablePaginationSection from "../../components/table-pagination/TablePaginationSection";
import { ListComponentSkeleton } from "../../components/skeletons/SkeletonsComponent";
import { dataPerPage } from "../../core/variables/Variables";
import { REACT_APP_IMAGE_URL } from "../../core/services/main";
import { objectCleaner } from "../../core/functions/Functions";

//api
import { joinGroup, leaveGroup } from "../../core/services/groups";
import { getGroupsByCompanyId } from "../../core/services/groups";

//css

import "./CompaniesAbout.scss";
import Button from "../../components/button/Button";
import { PermissionContext } from "../../context/PermissionContext";

const CompaniesGroup = () => {
  const [ t ] = useTranslation( "common" );
  const { permissions } = useContext( PermissionContext )
  let { id } = useParams();
  let navigate = useNavigate();
  const [ data, setData ] = useState( [] );
  const [ searchParams, setSearchParams ] = useSearchParams();
  const [ loading, setLoading ] = useState( false );
  const [ saveLoading, setSaveLoading ] = useState( null );
  const [ pageTotal, setPageTotal ] = useState( 0 );
  const [ filter, setFilter ] = useState( {
    per_page: searchParams.get( "per_page" ) || dataPerPage,
    page: searchParams.get( "page" ) || 1,
  } );

  const getCompanyGroups = () => {
    setLoading( true );
    getGroupsByCompanyId( { company_id: id, ...filter } ).then( ( res ) => {
      if ( res?.data?.success ) {
        setData( res.data.data ? res?.data?.data?.data : [] );
        setPageTotal( res?.data?.data?.last_page );
      }

      setLoading( false );
    } );
  };

  const handlePageChange = ( e, page ) => {
    let newFilters = {
      ...filter,
      page: page,
    };
    setSearchParams( objectCleaner( newFilters ) );
  };
  useEffect( () => {
    setFilter( {
      per_page: searchParams.get( "per_page" ) || dataPerPage,
      page: +searchParams.get( "page" ) || 1,
    } );
  }, [ searchParams ] );
  useEffect( () => {
    getCompanyGroups();
  }, [ filter ] );

  const handleJoinGroup = ( id, index ) => {
    setSaveLoading( `${ id }-${ index }` );

    joinGroup( id ).then( ( res ) => {
      if ( res?.data?.success ) {
        getCompanyGroups();
      }
      setSaveLoading( null );
    } );
  };
  const handleLeaveGroup = ( group_id, index ) => {
    setSaveLoading( `${ id }-${ index }` );
    leaveGroup( group_id ).then( ( res ) => {
      if ( res?.data?.success ) {
        getCompanyGroups();
      }
      setSaveLoading( null );
    } );
  };

  //checking user permission before the visibility of the section
  const checkUserGroupPermissions = ( userStatus ) => {

    if ( ( userStatus === "joined" && permissions?.Group?.[ 'leave-company-group' ] ) ||

      ( userStatus === "invited" && permissions?.Group?.[ 'accept-or-reject-group-invitation' ] ) ||
      ( userStatus === "has_left" && permissions?.Group?.[ 'join-company-group' ] )

    ) { return true }
    else {
      return false
    }

  }


  return (
    <MUI.Card>
      <MUI.CardContent>
        { loading ? (
          <ListComponentSkeleton count={ dataPerPage } />
        ) : data?.length !== 0 ? (
          <MUI.List
            style={ { paddingTop: "0px" } }
            className="list-component-container"
          >
            { data?.map( ( item, index ) => (
              <div className="companies-group-listItem" key={ index }>
                <div
                  className="left-side cursor-pointer"
                  onClick={ permissions?.Group?.[ 'view-group' ] ? () => {
                    navigate( `../../groups/group-details/${ item.id }/feed` );
                  } : null }
                >
                  <MUI.Avatar
                    className="avatar-style-50"
                    alt={ item?.title }
                    src={
                      item.profile_image
                        ? REACT_APP_IMAGE_URL + item.profile_image
                        : GroupDefaultImage
                    }
                    variant={ "rounded" }
                  />

                  <div className="description">
                    <div className="description-title">{ item?.title }</div>

                    <div className="description-sub-title">
                      { item.users_count + " " + t( "MEMBERS" ) }
                    </div>
                  </div>
                </div>

                { checkUserGroupPermissions( item?.user_status ) && ( <div className="right-side">
                  <Button
                    onClick={ () =>
                      item?.is_joined === 1
                        ? handleLeaveGroup( item.id, index )
                        : handleJoinGroup( item.id, index )
                    }
                    isSelected
                    disabled={ saveLoading && saveLoading === `${ item?.id }-${ index }` }
                    loading={ saveLoading && saveLoading === `${ item?.id }-${ index }` }
                    name={ item?.is_joined === 1 ? t( "LEAVE_GROUP" ) : t( "JOIN" ) }
                  />
                </div> ) }
              </div>
            ) ) }
          </MUI.List>
        ) : (
          <NoData />
        ) }

        <TablePaginationSection
          page={ filter?.page }
          totalPage={ pageTotal }
          data={ data }
          handlePageChange={ handlePageChange }
        />
      </MUI.CardContent>
    </MUI.Card>
  );
};

export default CompaniesGroup;


