import * as React from "react";
import { useTranslation } from "react-i18next";

import "./Form.scss";
export default function Form(props) {
  const [t] = useTranslation("common");

  const { onSubmit, title, children } = props;
  return (
    <form className="formClass" onSubmit={onSubmit}>
      <h1>{t(title)}</h1>
      {children}
    </form>
  );
}
