import React, { Fragment, forwardRef, useEffect, useState } from "react";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import htmlToDraft from "html-to-draftjs";
import draftToHtml from "draftjs-to-html";
import * as MUI from "@mui/material";
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";

import palette from "../../../theme/color.scss";
import unorderedlistIcon from "../../../../src/assets/textEditorIcons/unorderedlistIcon.png";
import orderedlistIcon from "../../../../src/assets/textEditorIcons/orderedlistIcon.png";
import boldIcon from "../../../../src/assets/textEditorIcons/boldIcon.png";
import italicIcon from "../../../../src/assets/textEditorIcons/italicIcon.png";
import underlineIcon from "../../../../src/assets/textEditorIcons/underlineIcon.png";
import undoIcon from "../../../../src/assets/textEditorIcons/undoIcon.png";
import redoIcon from "../../../../src/assets/textEditorIcons/redoIcon.png";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { Editor } from "react-draft-wysiwyg";
import moment from "moment";
import { Form } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import Downshift from "downshift";
import { defaultLang, fileTypes, sortBy } from "../../../core/variables/Variables";
import {
  EyeSVG,
  UploadImage,
  DeleteIcon,
  CalendarSVG,
  MoreInfoSVG,
  SearchIcon,
  BlockEyeSVG,
  AttachIcon,
  SendIcon,
} from "../../../assets/icons/SVG";
import PDF from "../../../assets/images/pdf-image.png";
import ReactDatePicker from "react-datepicker";
import { FileUploader } from "react-drag-drop-files";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import "./FormComponent.scss";
import PropTypes from "prop-types";
import { useDebouncedCallback } from "use-debounce";
import PhoneInput from "react-phone-number-input";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import { REACT_APP_IMAGE_URL } from "../../../core/services/main";
import { IconsButton } from "../../button/Button";
import { DisplayTooltip } from "../../display-component/DisplayComponent";

const RenderFileInfo = ( sizeMax, fileType, aspectRatio ) => {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  return (
    <div className="file-requirements-tooltip">
      <div>{ t( "REQUIREMENTS" ) }:</div>
      <li>{ fileType?.toString() }</li>

      <li>
        { t( "UP_TO" ) } { sizeMax } MB
      </li>
      { aspectRatio && (
        <li>
          { t( "RATIO" ) } { aspectRatio }
        </li>
      ) }
    </div>
  );
};

export const InputTextBootstrap = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    label,
    type,
    placeHolder,
    value,
    error,
    name,
    onChange,
    isValid,
    isInvalid,
    text,
    className,
    onBlur,
    pattern,
    disabled,
  } = props;
  return (
    <Form.Group
      className="form-input"
      style={ { width: "100%", minHeight: "40px" } }
    >
      { label && (
        <Form.Label>
          { t( label ) }
          { props.notReq && "*" }
        </Form.Label>
      ) }

      <Form.Control
        required
        type={ type }
        placeholder={ t( placeHolder ) }
        value={ value }
        name={ name }
        onChange={ onChange }
        isValid={ isValid }
        isInvalid={ isInvalid }
        className={ className }
        pattern={ pattern }
        onBlur={ onBlur }
        disabled={ disabled }
        onClick={ props.onClick }
      />

      { error && (
        <Form.Control.Feedback className="invalid-input" type="invalid">
          { t( error ) }
        </Form.Control.Feedback>
      ) }

      <Form.Text id="passwordHelpBlock">{ text }</Form.Text>
    </Form.Group>
  );
};
export const FormSwitch = ( props ) => {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  const { value, label, onChange } = props;
  return (
    <div className="form-input-wrapper">
      <MUI.FormGroup aria-label="position" row>
        <MUI.FormControlLabel
          className="form-switch-label"
          control={
            <MUI.Switch
              checked={ value }
              color="primary"
              value={ value }
              onChange={ ( e ) => onChange( e.target.checked ) }
            />
          }
          label={ <b style={ { color: palette.primaryColor } }>{ t( label ) }</b> }
          labelPlacement="start"
        />
      </MUI.FormGroup>
    </div>
  );
};

export const InputPassword = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    label,
    type,
    placeHolder,
    value,
    error,
    name,
    onChange,
    isValid,
    isInvalid,
    text,
    className,
    onBlur,
    pattern,
    showPassword,
    onPasswordShownClick,
    passwordShown,
  } = props;
  return (
    <div style={ { position: "relative" } }>
      <Form.Group
        className="form-input"
        style={ { width: "100%", minHeight: "40px" } }
      >
        <Form.Label> { t( label ) }</Form.Label>
        <Form.Control
          required
          type={ type }
          placeholder={ t( placeHolder ) }
          value={ value }
          name={ name }
          onChange={ onChange }
          isValid={ isValid }
          isInvalid={ isInvalid }
          className={ className }
          pattern={ pattern }
          onBlur={ onBlur }
          style={ { position: "relative" } }
        />

        <Form.Control.Feedback className="invalid-input" type="invalid">
          { t( error ) }
        </Form.Control.Feedback>

        <Form.Text id="passwordHelpBlock">{ text }</Form.Text>
      </Form.Group>
      { showPassword ? (
        <div
          style={
            props.style
              ? props.style
              : { position: "absolute", right: "5%", bottom: "30%" }
          }
        >
          <MUI.InputAdornment position="end">
            <MUI.IconButton
              aria-label="toggle password visibility"
              onClick={ onPasswordShownClick }
              edge="end"
              className="eye"
            >
              { passwordShown ? (
                <VisibilityOff
                  style={ { width: "18px", color: "#71A2A7 !important" } }
                />
              ) : (
                <Visibility
                  style={ { width: "18px", color: "#71A2A7 !important" } }
                />
              ) }
            </MUI.IconButton>
          </MUI.InputAdornment>
        </div>
      ) : null }
    </div>
  );
};

export function CustomSelect( props ) {
  const [ t ] = useTranslation( "common" );

  return (
    <div className="select-field">
      <label>{ t( props.label ) }</label>
      <Form.Group
        className="form-input"
        style={ { width: "100%", minHeight: "40px" } }
      >
        <Form.Select
          name={ props.name }
          onChange={ ( value ) => props.onChange( value ) }
          value={ props.value }
          isValid={ props.isValid }
          required
          placeholder={ " Select Option" }
          disabled={ props.disabled }
        >
          { props.option && <option valiue=""> Select Option</option> }
          { props?.selectedValue && (
            <option value={ props?.selectedValue?.id } selected>
              { props?.selectedValue?.name }
            </option>
          ) }
          { props.options?.map(
            ( val ) =>
              val?.id !== props?.selectedValue?.id && (
                <option value={ val.id } key={ val?.id }>
                  { val.name }
                </option>
              )
          ) }
          { props.other && <option value="0">Other</option> }
        </Form.Select>
      </Form.Group>
      { props.error && (
        <span
          style={ {
            color: "#dc3545",
            fontSize: "0.875em",
          } }
        >
          { props.errorMessage }
        </span>
      ) }
    </div>
  );
}


//****************FORM PORTAL CHECKBOX (TO BE USED EVERYWHERE) NOW ONLY
export const FormCheckBox = ( props ) => {
  const { onChange, name, helperText, value, formComponent } = props;
  const { disabled, checked, label, className } = props;
  const [ t ] = useTranslation( "common" );

  return (
    <MUI.FormGroup className={ className }>
      <MUI.FormControlLabel
        control={
          <MUI.Checkbox
            name={ name }
            checked={ checked }
            value={ value }
            disabled={ disabled }
            onChange={ onChange }
            inputProps={ { "aria-label": "controlled" } }
          />
        }
        label={ label }
        className="labelException"
      />
      { helperText !== "" && (
        <MUI.FormControl>
          <MUI.FormHelperText>{ helperText }</MUI.FormHelperText>
        </MUI.FormControl>
      ) }
    </MUI.FormGroup>
  );
};

/**************************FORM SINGLE NEW CHECKBOX (NEW STRUCTURE (ONLY IF FEW COMPONENT)********************** */
export const FormSingleCheckBox = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    onChange,
    checked,
    label,
    value,
    booleanValue,
    disabled,
    isDay,
    required,
    helperText,
  } = props;
  const returnRomDay = ( day ) => {
    const romDay = day.split( " " );
    return t( romDay[ 0 ] );
  };
  return (
    <MUI.FormGroup>
      <MUI.FormControlLabel
        control={
          <MUI.Checkbox
            disabled={ disabled || false }
            checked={ !booleanValue ? checked : value }
            value={ value }
            onChange={ ( e, values ) => onChange( values ) }
            inputProps={ { "aria-label": "controlled" } }
          />
        }
        label={
          <span>
            { t( isDay ? returnRomDay( label ) : label ) }
            { required ? "*" : "" }
          </span>
        }
      />
      { helperText !== "" && (
        <MUI.FormControl>
          <MUI.FormHelperText>{ t( helperText ) }</MUI.FormHelperText>
        </MUI.FormControl>
      ) }
    </MUI.FormGroup>
  );
};
FormSingleCheckBox.defaultProps = {
  booleanValue: true,
};

FormSingleCheckBox.propTypes = {
  booleanValue: PropTypes.bool,
};

export const FormTagInput = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    value,
    required,
    onChange,
    placeholder,
    helperText,
  } = props;
  const [ inputValue, setInputValue ] = useState( "" );
  const [ selectedItem, setSelectedItem ] = useState( value );

  useEffect( () => {
    setSelectedItem( value );
  }, [ value ] );

  // function handleInputChange( event ) {
  //   const newSelectedItem = [ ...selectedItem ];
  //   const duplicatedValues = newSelectedItem.indexOf(
  //           event.target.value.trim()
  //         );
  //         if ( duplicatedValues !== -1 ) {
  //                 setInputValue( "" );
  //                 return;
  //               }
  //               if ( !event.target.value.replace( /\s/g, "" ).length ) return;

  //   const newValue = event.target.value;
  //   setInputValue( newValue );
  //   if ( newValue.trim() !== "" && newValue.endsWith( " " ) ) {
  //     const newSelectedItem = [ ...selectedItem, newValue.trim() ];
  //     onChange( newSelectedItem );
  //     setInputValue( "" );
  //   }
  // }

  function handleInputChanges( event ) {
    if ( event.target.value.endsWith( " " ) ) {
      const newSelectedItem = [ ...selectedItem ];
      const duplicatedValues = newSelectedItem.indexOf(
        event.target.value.trim()
      );

      if ( duplicatedValues !== -1 ) {
        setInputValue( "" );
        return;
      }
      if ( !event.target.value.replace( /\s/g, "" ).length ) return;

      newSelectedItem.push( event.target.value.trim() );
      onChange( newSelectedItem );

      setInputValue( "" );
    }

  }

  const handleRemove = ( event ) => {
    if (
      selectedItem.length &&
      !inputValue.length &&
      event.key === "Backspace"
    ) {
      onChange( selectedItem.slice( 0, selectedItem.length - 1 ) );

    }

  }

  const handleDelete = ( item ) => () => {
    const newSelectedItem = [ ...selectedItem ];
    newSelectedItem.splice( newSelectedItem.indexOf( item ), 1 );
    onChange( newSelectedItem );

  };

  function handleInputChange( event ) {
    setInputValue( event.target.value );
  }

  return (
    <div style={ props.style } className="form-input-wrapper">
      <MUI.InputLabel>
        { t( props.label ) }
        <span className="required-start">{ required ? "*" : "" }</span>
      </MUI.InputLabel>
      <Downshift
        id="downshift-multiple"
        inputValue={ inputValue }
        selectedItem={ selectedItem }
      >
        { ( { getInputProps } ) => {
          const { onBlur, onChange, onFocus, ...inputProps } = getInputProps( {
            onChange: handleInputChanges,
            onKeyDown: handleRemove
          } );
          return (
            <div>
              <MUI.TextField
                placeholder={ t( placeholder ) }
                className="tag-input-field"
                fullWidth
                size="small"
                variant="outlined"
                helperText={ t( helperText ) }
                InputProps={ {
                  startAdornment: selectedItem.map( ( item ) => (
                    <MUI.Chip
                      className="tag-input-chip"
                      color="primary"
                      variant="outlined"
                      key={ item }
                      tabIndex={ -1 }
                      label={ item }
                      onDelete={ handleDelete( item ) }
                    />
                  ) ),
                  onBlur,
                  onChange: ( event ) => {
                    handleInputChange( event );
                    onChange( event );
                  },
                  onFocus,
                } }
                { ...inputProps }
              />
            </div>
          );
        } }
      </Downshift>
    </div>
  );
};

/**************************FORM RADIO BUTTON (TAKE DATA)********************** */
export const FormRadioButton = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    row,
    onChange,
    data,
    labelIcon,
    required,
    label,
    value,
    helperText,
    notObject,
  } = props;
  const handleOnChange = ( newValue ) => {
    if (
      ( notObject && newValue === value ) ||
      ( !notObject && newValue?.id === value?.id )
    ) {
      onChange( "" );
    } else {
      onChange( newValue );
    }
  };

  return (
    <div className="form-input-wrapper">
      { label && (
        <MUI.InputLabel>
          { t( label ) }
          { required && <span className="required-start">*</span> }{ " " }
          { labelIcon && <span>{ labelIcon }</span> }
        </MUI.InputLabel>
      ) }
      <MUI.FormControl>
        <MUI.RadioGroup row={ row } value={ notObject ? value : value?.id }>
          { data?.map( ( item ) => {
            return (
              <MUI.FormControlLabel
                value={ item.id }
                key={ item?.id }
                control={
                  <MUI.Radio
                    checked={
                      notObject ? value == item?.id : value?.id == item.id
                    }
                  />
                }
                label={ item.name }
                onClick={ () => handleOnChange( notObject ? item?.id : item ) }
              />
            );
          } ) }
        </MUI.RadioGroup>
        <MUI.FormHelperText>{ t( helperText ) }</MUI.FormHelperText>
      </MUI.FormControl>
    </div>
  );
};
FormRadioButton.defaultProps = {
  row: false,
  required: false,
  notObject: false,
  helperText: "",
};

FormRadioButton.propTypes = {
  row: PropTypes.bool,
  helperText: PropTypes.string,
  required: PropTypes.bool,
  notObject: PropTypes.bool,
};

/**************************FORM PASSWORD ********************** */
export const FormPassword = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const { label, placeholder, value, onChange, required, helperText } = props;
  const [ showPassword, setShowPassword ] = useState( false );
  return (
    <div className="form-input-wrapper">
      <MUI.InputLabel>
        { t( label ) }
        <span className="required-start">{ required ? "*" : "" }</span>
      </MUI.InputLabel>
      <MUI.TextField
        fullWidth
        size="small"
        placeholder={ t( placeholder ) }
        variant="outlined"
        type={ showPassword ? "text" : "password" }
        onChange={ ( e ) => onChange( e.target.value ) }
        value={ value }
        helperText={ t( helperText ) }
        inputProps={ {
          autocomplete: "new-password",
          form: {
            autocomplete: "off",
          },
        } }
        InputProps={ {
          endAdornment: (
            <MUI.InputAdornment
              position={ "end" }
              sx={ {
                backgroundColor: palette.whiteColor,
              } }
            >
              <MUI.IconButton onClick={ () => setShowPassword( !showPassword ) }>
                { showPassword ? <BlockEyeSVG /> : <EyeSVG /> }
              </MUI.IconButton>
            </MUI.InputAdornment>
          ),
        } }
      />
    </div>
  );
};

export const DropList = ( props ) => {
  const [ selectedOption, setSelectedOption ] = useState( null );

  const { label } = props;
  return (
    <div className="selectInput">
      <label> { label }</label>
      <MUI.Select
        defaultValue={ selectedOption }
        onChange={ setSelectedOption }
        options={ props?.options }
      />
    </div>
  );
};

export const FormInput = ( { label, required, ...props } ) => {
  const {
    helperText,
    disabled,
    placeholder,
    className,
    labelIcon,
    wrapperClassName,
  } = props;
  const { value, onChange, type, name, variant, rows } = props;
  const [ t ] = useTranslation( "common" );
  return (
    <div className={ `form-input-wrapper ${ wrapperClassName }` }>
      { label && (
        <MUI.InputLabel>
          { t( label ) }
          { required && <span className="required-start">*</span> }{ " " }
          { labelIcon && <span>{ labelIcon }</span> }
        </MUI.InputLabel>
      ) }
      <MUI.TextField
        { ...props }
        fullWidth
        size="small"
        rows={ rows }
        multiline={ rows !== 1 }
        variant={ variant }
        type={ type }
        className={ className }
        value={ value }
        placeholder={ t( placeholder ) }
        helperText={ t( helperText ) }
        disabled={ disabled }
        onChange={ onChange }
        name={ name }
        autoComplete="new-password"
        inputProps={ {
          autocomplete: "new-password",
          form: {
            autocomplete: "off",
          },
        } }
      />
    </div>
  );
};
FormInput.defaultProps = {
  rows: 1,
  wrapperClassName: "",
};
FormInput.propTypes = {
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  name: PropTypes.string,
  wrapperClassName: PropTypes.string,
  label: PropTypes.string,
  onChange: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  value: PropTypes.any,
  rows: PropTypes.number,
};

export const FormSingleDashedUpload = ( props ) => {
  const {
    downloadTitle,
    required,
    onChange,
    type,
    accept,
    value,
    name,
    handleDeleteImage,
    error,
    aspectRatio,
  } = props;
  const [ t ] = useTranslation( "common" );

  const handleOnChange = ( files ) => {
    onChange( files );
  };

  let file_type =
    type === "File"
      ? accept
      : type === "Video"
        ? fileTypes?.video
        : fileTypes?.image;

  let size_max = type === "File" ? 10 : type === "Video" ? 10 : 1;
  return (
    <div className="form-input-wrapper" style={ { width: "100%" } }>
      <label>{ t( props.label ) }</label>
      <div className="form-upload-section">
        <FileUploader
          name={ name }
          classes={ "multiple-file-upload" }
          multiple={ false }
          handleChange={ ( files ) => handleOnChange( files ) }
          children={
            <>
              <UploadImage />
              <span>
                { t( downloadTitle ) }
                <span className="error-start">
                  { required ? "*" : "" }{ " " }
                  <DisplayTooltip
                    placement={ "bottom-end" }
                    title={ RenderFileInfo( size_max, file_type, aspectRatio ) }
                  >
                    <span className="cursor-pointer">
                      <MoreInfoSVG />
                    </span>
                  </DisplayTooltip>
                </span>
              </span>
            </>
          }
        />
      </div>
      { error && (
        <MUI.FormControl>
          <MUI.FormHelperText>{ t( error ) }</MUI.FormHelperText>
        </MUI.FormControl>
      ) }
      { value && (
        <div className="list-uploaded-images">
          <div className="title">Uploaded { type || "Images" }</div>

          <MUI.List>
            <MUI.ListItemButton
              onClick={ () => {
                type === "File" &&
                  window.open( `https://docs.google.com/viewer?url=${ process.env.REACT_APP_IMAGE_URL + value
                    }
                  &embedded=true`);
              } }
              className="image-uploaded-item"
            >
              <MUI.ListItemAvatar>
                <MUI.Avatar
                  variant={ type === "file" ? "square" : "rounded" }
                  alt="gazette-media"
                  src={
                    type === "File"
                      ? PDF
                      : value
                        ? !( value instanceof File )
                          ? process.env.REACT_APP_IMAGE_URL + value
                          : URL.createObjectURL( value )
                        : ""
                  }
                />
              </MUI.ListItemAvatar>
              <MUI.ListItemText primary={ value?.name || value } />
              <MUI.ListItemIcon>
                <MUI.IconButton
                  onClick={ ( e ) => {
                    e.stopPropagation();
                    handleDeleteImage( value );
                  } }
                >
                  <DeleteIcon />
                </MUI.IconButton>{ " " }
              </MUI.ListItemIcon>
            </MUI.ListItemButton>
          </MUI.List>
        </div>
      ) }
    </div>
  );
};

export const DatePicker = ( props ) => {
  const CustomDatePicker = forwardRef( ( props, ref ) => (
    <div className="custom-date-picker" ref={ ref }>
      <InputTextBootstrap placeHolder="YYYY-MM-DD" { ...props } />
      <div className="customValue">
        <CalendarSVG />
      </div>
    </div>
  ) );
  return <ReactDatePicker { ...props } customInput={ <CustomDatePicker /> } />;
};
export const TimePicker = ( props ) => {
  const { value, className, disabled, onChange, excludeTimes, ...others } =
    props;
  return (
    <ReactDatePicker
      onChange={ onChange }
      showTimeSelect
      showTimeSelectOnly
      timeFormat="HH:mm"
      timeIntervals={ 15 }
      timeCaption="Time"
      readOnly={ disabled }
      dateFormat="HH:mm"
      value={ value ? moment( `2022-08-23T${ value }` ).format( "HH:mm" ) : null }
      className={ className }
      excludeTimes={ excludeTimes }
      { ...others }
    />
  );
};

//DROPDOWN LIST
export const FormDropdownList = ( props ) => {
  const {
    data,
    noOptionsText,
    className,
    loading,
    onChange,
    helperText,
    labelIcon,
    accessName,
    accessValue,
  } = props;
  const { label, placeholder, disabledOptions, variant, disabled, required } =
    props;
  const { value, formComponent, filterDropdown } = props;
  const [ val, setVal ] = useState( null );
  useEffect( () => {
    if ( filterDropdown && data ) {
      setVal(
        value || value === 0 ? data?.find( ( item ) => item?.id === value ) : null
      );
    } else {
      setVal( value );
    }
  }, [ data, value ] );

  //TRANSLATION
  const [ t ] = useTranslation( "common" );

  return (
    <div
      className={ `${ className } ${ formComponent ? `form-input-wrapper` : "non-form-wrapper"
        }` }
    >
      { label && (
        <MUI.InputLabel>
          { t( label ) }
          { required && <span className="required-start">*</span> }{ " " }
          { labelIcon && <span>{ labelIcon }</span> }
        </MUI.InputLabel>
      ) }

      <MUI.Autocomplete
        size="small"
        disabled={ disabled }
        fullWidth
        getOptionDisabled={ ( option ) =>
          disabledOptions?.includes( option?.[ accessValue ] )
        }
        disableClearable={ required }
        ListboxProps={ { style: { maxHeight: 200, overflow: "auto" } } }
        getOptionLabel={ ( option ) => option?.[ accessName ] }
        options={ data }
        value={ val }
        isOptionEqualToValue={ ( option, value ) =>
          option?.[ accessValue ] == value?.[ accessValue ]
        }
        loadingText={ t( "LOADING" ) }
        noOptionsText={ t( noOptionsText ) }
        loading={ loading }
        onChange={ ( event, selected ) => {
          if ( !disabled ) {
            onChange(
              filterDropdown
                ? selected
                  ? selected?.[ accessValue ]
                  : null
                : selected
            );
          }
        } }
        renderInput={ ( params ) => (
          <MUI.TextField
            { ...params }
            variant={ variant }
            placeholder={ t( placeholder ) }
            helperText={ t( helperText ) }
            disabled={ disabled }
            InputProps={ {
              ...params.InputProps,
              autocomplete: "new-password",
              form: {
                autocomplete: "off",
              },
              endAdornment: (
                <React.Fragment>
                  { loading ? (
                    <MUI.CircularProgress color="inherit" size={ 20 } />
                  ) : null }
                  { params.InputProps.endAdornment }
                </React.Fragment>
              ),
            } }
          />
        ) }
      />
    </div>
  );
};

FormDropdownList.defaultProps = {
  accessName: "name",
  accessValue: "id",
  variant: "outlined",
  className: "",
  value: null,
  filterDropdown: false,
  noOptionsText: "NO_OPTIONS",
  formComponent: true,
  disabledOptions: [],
};

FormDropdownList.propTypes = {
  accessName: PropTypes.string,
  accessValue: PropTypes.string,
  noOptionsText: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.string,
  formComponent: PropTypes.bool,
  filterDropdown: PropTypes.bool,
  disabledOptions: PropTypes.array,
  value: PropTypes.any,
};
export const FormTextEditor = ( props ) => {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  const { value, onChange, helperText, label, required, labelIcon } = props;

  const [ editorState, setEditorState ] = useState( EditorState.createEmpty() );

  useEffect( () => {
    if ( value ) {
      const html = value;
      const contentBlock = htmlToDraft( html );
      const contentState = ContentState.createFromBlockArray(
        contentBlock.contentBlocks
      );
      setEditorState( EditorState.createWithContent( contentState ) );
    }
  }, [] );

  const handleOnChange = ( newState ) => {
    setEditorState( newState );
    let new_value = draftToHtml( convertToRaw( newState.getCurrentContent() ) );
    onChange( new_value );
  };

  return (
    <>
      <div className="RichEditor-controls">
        { label && (
          <MUI.InputLabel>
            { t( label ) }
            { required && <span className="required-start">*</span> }{ " " }
            { labelIcon && <span>{ labelIcon }</span> }
          </MUI.InputLabel>
        ) }
        <Editor
          toolbarClassName="toolbarTextEditor"
          wrapperClassName="wrapperTextEditor"
          editorClassName="editorTextEditor"
          editorState={ editorState }
          onEditorStateChange={ handleOnChange }
          toolbar={ {
            options: [ "inline", "blockType", "list", "history" ],
            blockType: {
              inDropdown: false,
              options: [ "Normal", "H1", "H2" ],
              className: "texteditor-blocktype-style",
            },
            list: {
              inDropdown: false,

              dropdownClassName: undefined,
              options: [ "unordered", "ordered" ],
              unordered: {
                icon: unorderedlistIcon,
                className: "editor-buttonIcon-className",
              },
              ordered: {
                icon: orderedlistIcon,
                className: "editor-buttonIcon-className",
              },
            },
            inline: {
              inDropdown: false,
              options: [ "bold", "italic", "underline" ],
              bold: {
                icon: boldIcon,
                className: "editor-buttonIcon-className",
              },
              italic: {
                icon: italicIcon,
                className: "editor-buttonIcon-className",
              },
              underline: {
                icon: underlineIcon,
                className: "editor-buttonIcon-className",
              },
            },
            history: {
              inDropdown: false,
              className: undefined,
              component: undefined,

              options: [ "undo", "redo" ],
              undo: {
                icon: undoIcon,
                className: "editor-buttonIcon-className",
              },
              redo: {
                icon: redoIcon,
                className: "editor-buttonIcon-className",
              },
            },
          } }
        />
        <MUI.FormHelperText>{ t( helperText ) }</MUI.FormHelperText>
      </div>
    </>
  );
};

export const FormMultiSelect = ( props ) => {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  const {
    name,
    label,
    data,
    required,
    validation,
    toggleError,
    value,
    onChange,
    languageSelect,
    helperText,
    loading,
    placeholder,
    labelIcon,
    noOptionsMessage,
  } = props;

  const selected_values =
    value !== null && value?.length
      ? data?.filter( ( item ) => value.includes( item?.id ) )
      : [];
  const handleOnChange = ( newValue ) => {
    let array = [];
    newValue.map( ( item ) => array.push( item.id ) );
    const hasError = validation ? validation( array ) : null;
    hasError && toggleError( name, hasError.message, hasError.error );

    onChange( array );
  };

  return (
    <div style={ props.style } className="form-input-wrapper">
      { label && (
        <MUI.InputLabel>
          { t( label ) }
          { required && <span className="required-start">*</span> }{ " " }
          { labelIcon && <span>{ labelIcon }</span> }
        </MUI.InputLabel>
      ) }

      <MUI.Autocomplete
        multiple
        size="small"
        fullWidth
        options={ data }
        ListboxProps={ { style: { maxHeight: 200, overflow: "auto" } } }
        getOptionLabel={ ( option ) => option.name }
        loading={ loading }
        noOptionsText={ noOptionsMessage ? t( noOptionsMessage ) : t( "NO_OPTIONS" ) }
        loadingText={ t( "LOADING" ) }
        value={ selected_values }
        isOptionEqualToValue={ ( option, value ) => option.id === value.id }
        onChange={ ( event, newValue ) => {
          handleOnChange( newValue );
        } }
        renderInput={ ( params ) => (
          <MUI.TextField
            { ...params }
            placeholder={ t( placeholder ) }
            helperText={ t( helperText ) }
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <Fragment>
                  { loading ? (
                    <MUI.CircularProgress color="inherit" size={ 20 } />
                  ) : null }
                  { params.InputProps.endAdornment }
                </Fragment>
              ),
            } }
          />
        ) }
        renderTags={ ( tagValue, getTagProps ) =>
          tagValue.map( ( option, index ) => (
            <MUI.Chip
              key={ option.id }
              color="primary"
              variant="outlined"
              label={ option.name }
              { ...getTagProps( { index } ) }
              disabled={
                languageSelect && option.code === defaultLang ? true : false
              }
            />
          ) )
        }
      />
    </div>
  );
};

//TO BE USED IN PAGINATION DROPDOWNS (FULLY FUNCTIONAL)
export const FormPaginationDropdownList = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    label,
    value,
    placeholder,
    data,
    disabled,
    formComponent,
    noOptionsText,
    loading,
    onChange,
    accessName,
    required,
    name,
    helperText,
    accessValue,
    handleResetData,
  } = props;

  const [ inputValue, setInputValue ] = useState( "" );
  const handleOnChange = ( selected ) => {
    onChange( selected ? selected : null );
    if ( !selected ) {
      setInputValue( "" );
      handleResetData( false, "" );
    }
  };

  const handleScroll = async ( event ) => {
    const target = event.target;
    if (
      target.scrollHeight - target.scrollTop === target.clientHeight &&
      data?.page < data?.total
    ) {
      handleResetData( true, inputValue );
    }
  };
  const debouncedFilterOption = useDebouncedCallback(
    // function
    ( value ) => {
      handleResetData( false, value );
    },
    // delay in ms
    500
  );

  return (
    <div className="form-input-wrapper">
      { label && (
        <MUI.InputLabel>
          { t( label ) }
          <span className="required-start">
            { required && !formComponent ? "*" : "" }
          </span>
        </MUI.InputLabel>
      ) }

      <MUI.Autocomplete
        name={ name }
        size="small"
        disabled={ disabled ? disabled : false }
        fullWidth
        // inputValue={inputValue}
        onInputChange={ ( event, newInputValue, reason ) => {
          if ( reason === "input" ) {
            setInputValue( newInputValue );
            debouncedFilterOption( newInputValue );
          }
        } }
        onClose={ () => setInputValue( "" ) } // Reset inputValue when the dropdown is closed
        disableClearable={ false }
        ListboxProps={ {
          onScroll: handleScroll,
          style: { maxHeight: 200 },
        } }
        getOptionLabel={ ( option ) => option?.[ accessName ] }
        options={ data?.data || [] }
        value={ value }
        filterOptions={ ( options ) => options }
        isOptionEqualToValue={ ( option, value ) =>
          option?.[ accessValue ] === value?.[ accessValue ]
        }
        loadingText={ t( "LOADING" ) }
        noOptionsText={ t( noOptionsText ) }
        loading={ loading }
        onChange={ ( event, selected ) => {
          handleOnChange( selected );
        } }
        renderInput={ ( params ) => (
          <MUI.TextField
            { ...params }
            name={ name }
            placeholder={ t( placeholder ) }
            helperText={ t( helperText ) }
            InputProps={ {
              ...params.InputProps,
              endAdornment: (
                <>
                  { loading ? (
                    <MUI.CircularProgress color="inherit" size={ 20 } />
                  ) : null }
                  { params.InputProps.endAdornment }
                </>
              ),
            } }
          />
        ) }
      />
    </div>
  );
};
FormPaginationDropdownList.defaultProps = {
  accessName: "name",
  accessValue: "id",
  variant: "outlined",
  staticData: false,
  value: null,
  noOptionsText: "NO_OPTIONS",
};

FormPaginationDropdownList.propTypes = {
  accessName: PropTypes.string,
  accessValue: PropTypes.string,
  variant: PropTypes.string,
  staticData: PropTypes.bool,
  value: PropTypes.object,
};
/**************************FORM PHONE INPUT ********************** */
export const FormPhoneInput = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const { required, onChange, labelIcon, label, value, helperText, disabled } =
    props;
  return (
    <div className="form-input-wrapper">
      { label && (
        <MUI.InputLabel>
          { t( label ) }
          { required && <span className="required-start">*</span> }{ " " }
          { labelIcon && <span>{ labelIcon }</span> }
        </MUI.InputLabel>
      ) }
      <PhoneInput
        international
        className="phone-input-style"
        defaultCountry="RO"
        value={ value }
        onChange={ ( value, country ) => onChange( value ) }
        disabled={ disabled }
      />
      { helperText && (
        <MUI.FormControl>
          <MUI.FormHelperText>{ t( helperText ) }</MUI.FormHelperText>
        </MUI.FormControl>
      ) }
    </div>
  );
};
export const FormGoogleMap = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    required,
    label,
    disabled,
    handleChangeLocations,
    helperText,
    value,
  } = props;

  const [ search, setSearch ] = useState( value );

  return (
    <div className="form-input-wrapper">
      <MUI.InputLabel>
        { t( label ) }
        <span className="required-start">{ required ? "*" : "" }</span>
      </MUI.InputLabel>

      { !disabled && (
        <ReactGoogleAutocomplete
          autocomplete="new-password"
          autoComplete="new-password"
          className="ReactGoogleAutocomplete"
          types={ [ "address" ] }
          apiKey={ "AIzaSyCCP5CF3Rvo2L_z6jppuDjIhuIaxyvel-4" }
          value={ search }
          onPlaceSelected={ ( plc ) => {
            handleChangeLocations( plc );
            setSearch( plc?.formatted_address || "" );
          } }
          options={ {
            componentRestrictions: { country: "ro" },
          } }
          onChange={ ( e ) => {
            handleChangeLocations( null );
            setSearch( e.target.value );
          } }
        />
      ) }

      { helperText !== "" && (
        <MUI.FormControl>
          <MUI.FormHelperText>{ t( helperText ) }</MUI.FormHelperText>
        </MUI.FormControl>
      ) }
    </div>
  );
};
FormGoogleMap.defaultProps = {
  value: "",
  disabled: false,
  required: false,
  helperText: "",
};

FormGoogleMap.propTypes = {
  value: PropTypes.string,
  helperText: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
};
/**************************FORM SEARCH BAR ********************** */
export const FormSearchBar = ( props ) => {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  const { placeholder, variant, value, onChange } = props;
  return (
    <MUI.TextField
      type="text"
      fullWidth
      size="small"
      placeholder={ t( placeholder ) }
      variant={ variant }
      onChange={ ( e ) => onChange( e.target.value ) }
      value={ value }
      InputProps={ {
        startAdornment: (
          <MUI.InputAdornment className="input-adorment" position="start">
            <SearchIcon />
          </MUI.InputAdornment>
        ),
      } }
      inputProps={ {
        autocomplete: "new-password",
        form: {
          autocomplete: "off",
        },
      } }
    />
  );
};
export const FormSort = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const { value, placeholder, onChange } = props;

  return (
    <MUI.Select
      fullWidth
      value={ value }
      placeholder={ t( placeholder ) }
      onChange={ onChange }
      size="small"
    >
      { sortBy?.map( ( item ) => {
        return (
          <MUI.MenuItem key={ item.id } value={ item.id }>
            { item.name }
          </MUI.MenuItem>
        );
      } ) }
    </MUI.Select>
  );
};
/**************************FORM CALENDAR INPUT********************** */
export const FormCalendarInput = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    required,
    label,
    value,
    onChange,
    minDate,
    disabled,
    helperText,
    className,
    disableBefore,
    disabledAfter,
    maxDate,
    shouldDisableDate,
    labelIcon,
  } = props;

  const selected_value = value ? new Date( value ) : null;

  const handleOnChange = ( new_value ) => {
    onChange(
      new_value !== null ? moment( new_value ).format( "YYYY-MM-DD" ) : null
    );
  };

  const disabledDate = ( date ) => {
    let date_pick = moment( date ).format( "YYYY-MM-DD" );

    if ( shouldDisableDate ) {
      if ( shouldDisableDate.includes( date_pick ) ) {
        return false;
      } else {
        return true;
      }
    } else if ( disabledAfter ) {
      if ( date > new Date() ) {
        return true;
      } else {
        return false;
      }
    } else if ( disableBefore ) {
      if ( date < new Date() ) {
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  };

  return (
    <div className={ `${ className } form-input-wrapper` }>
      { label && (
        <MUI.InputLabel>
          { t( label ) }
          { required && <span className="required-start">*</span> }{ " " }
          { labelIcon && <span>{ labelIcon }</span> }
        </MUI.InputLabel>
      ) }
      <LocalizationProvider dateAdapter={ AdapterMoment }>
        <DesktopDatePicker
          className="date-picker-style"
          fullWidth
          maxDate={ maxDate }
          minDate={ minDate }
          inputFormat="DD-MM-YYYY"
          shouldDisableDate={ disabledDate }
          value={ selected_value }
          disabled={ disabled }
          onChange={ ( e ) => handleOnChange( e ) }
          renderInput={ ( params ) => (
            <MUI.TextField
              size="small"
              fullWidth
              { ...params }
              disabled={ disabled }
              error={ false }
              helperText={ t( helperText ) }
            />
          ) }
          PaperProps={ {
            sx: {
              "& .MuiPickersDay-root": {
                "&.Mui-selected": {
                  color: palette.whiteColor,
                },
              },
            },
          } }
        />
      </LocalizationProvider>{ " " }
    </div>
  );
};
FormCalendarInput.defaultProps = {
  maxDate: null,
  minDate: null,
  className: "",
};

FormCalendarInput.propTypes = {
  maxDate: PropTypes.string,
  minDate: PropTypes.string,
  className: PropTypes.string,
};

/**************************FORM NUMBER BUTTON ********************** */
export const FormNumberInput = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    label,
    endAdornment,
    value,
    placeholder,
    required,
    onChange,
    disabled,
    labelIcon,
    helperText,
  } = props;

  return (
    <div className="form-input-wrapper">
      { label && (
        <MUI.InputLabel>
          { t( label ) }
          { required && <span className="required-start">*</span> }{ " " }
          { labelIcon && <span>{ labelIcon }</span> }
        </MUI.InputLabel>
      ) }
      <MUI.TextField
        type="number"
        className={ props.className }
        fullWidth
        size="small"
        value={ value }
        disabled={ disabled }
        placeholder={ t( placeholder ) }
        variant="outlined"
        min={ 0 }
        onChange={ ( e ) => onChange( e.target.value ) }
        helperText={ t( helperText ) }
        InputProps={ {
          endAdornment: endAdornment ? (
            <MUI.InputAdornment position="start">
              { t( endAdornment ) }
            </MUI.InputAdornment>
          ) : (
            ""
          ),
        } }
        inputProps={ {
          autocomplete: "new-password",
          form: {
            autocomplete: "off",
          },
        } }
      />
    </div>
  );
};
/**************************FORM SINGLE UPLOAD (IF FILE OR IMAGE WITHOUT DASH) ********************** */
export const FormSingleUpload = ( props ) => {
  const [ t ] = useTranslation( "common" );
  const {
    type,
    required,
    label,
    labelIcon,
    accept,
    onChange,
    downloadTitle,
    value,
    helperText,
  } = props;

  let file_type =
    type === "File"
      ? accept
      : type === "Video"
        ? fileTypes?.video
        : fileTypes?.image;

  const handleOnChange = ( files ) => {
    onChange( files );
  };

  const handleRemoveMedia = () => {
    onChange( null );
  };
  let size_max = type === "File" ? 10 : type === "Video" ? 10 : 1;

  return (
    <div className="form-input-wrapper">
      { label && (
        <MUI.InputLabel style={ props.style }>
          { t( label ) }
          { required && <span className="required-start">*</span> }{ " " }
          { labelIcon && <span>{ labelIcon }</span> }
          <DisplayTooltip
            title={
              <div className="file-requirements-tooltip">
                <div>{ t( "REQUIREMENTS" ) }</div>
                <li>{ file_type.toString() }</li>
                <li>
                  { t( "UP_TO" ) }
                  { size_max } MB{ " " }
                </li>
              </div>
            }
          >
            { " " }
            <span className="cursor-pointer">
              <MoreInfoSVG />
            </span>
          </DisplayTooltip>
        </MUI.InputLabel>
      ) }

      { !value ? (
        <>
          <div className="single-upload-section">
            <FileUploader
              types={ file_type }
              maxSize={ size_max }
              multiple={ false }
              handleChange={ ( value ) => {
                handleOnChange( value );
              } }
              onTypeError={ ( error ) => {
                onChange( null, true, "INVALID_FILE_TYPE" );
                setTimeout( function () {
                  onChange( null, false, "" );
                }, 1000 );
              } }
              onSizeError={ ( error ) => {
                onChange( null, true, "FILE_TOO_BIG" );
                setTimeout( function () {
                  onChange( null, false, "" );
                }, 1000 );
              } }
              name="file"
              children={
                <div className="upload-component-style">{ t( downloadTitle ) }</div>
              }
            />
          </div>
          <MUI.FormControl>
            <MUI.FormHelperText>{ t( helperText ) }</MUI.FormHelperText>
          </MUI.FormControl>
        </>
      ) : (
        <div className="list-uploaded-images">
          <MUI.List>
            <MUI.ListItem
              className="image-uploaded-item"
              secondaryAction={
                <IconsButton
                  icon={ <DeleteIcon /> }
                  onClick={ () => handleRemoveMedia() }
                />
              }
            >
              { type === "Image" ? (
                <MUI.ListItemAvatar>
                  <MUI.Avatar
                    variant="rounded"
                    src={
                      value
                        ? value instanceof File
                          ? URL.createObjectURL( value )
                          : REACT_APP_IMAGE_URL + value
                        : ""
                    }
                  ></MUI.Avatar>
                </MUI.ListItemAvatar>
              ) : (
                <AttachIcon
                  fill={ palette.primaryColor }
                  style={ { margin: "0px 10px" } }
                />
              ) }
              <MUI.ListItemText
                primary={ value?.name ? value.name : value?.split( "/" ).pop() }
              />
            </MUI.ListItem>
          </MUI.List>
        </div>
      ) }
    </div>
  );
};
export const FormSendMessageInput = ( props ) => {
  //TRANSLATION
  const [ t ] = useTranslation( "common" );
  const {
    loading,
    placeholder,
    onChange,
    disabled,
    onSend,
    value,

    handleDelete,
  } = props;

  const isAttachement = value?.isAttachement;

  return (
    <MUI.TextField
      fullWidth
      size="small"
      placeholder={ loading ? `${ t( "SENDING" ) }...` : t( placeholder ) }
      variant="outlined"
      type={ "text" }
      onChange={ onChange }
      value={
        value?.value ? ( isAttachement ? value?.value?.name : value?.value ) : ""
      }
      disabled={ disabled }
      onKeyDown={ ( e ) => {
        if ( e.key === "Enter" ) {
          //for the e, prevent default in onSend Function
          onSend( e );
        }
      } }
      InputProps={ {
        startAdornment: (
          <MUI.InputAdornment
            position="start"
            sx={ {
              backgroundColor: palette.whiteColor,
            } }
          >
            { isAttachement ? (
              <MUI.IconButton onClick={ handleDelete }>
                <DeleteIcon />
              </MUI.IconButton>
            ) : (
              " "
            ) }
          </MUI.InputAdornment>
        ),
        endAdornment: (
          <MUI.InputAdornment
            position={ "end" }
            sx={ {
              backgroundColor: palette.whiteColor,
            } }
          >
            { loading ? (
              <div style={ { display: "flex" } }>
                <MUI.CircularProgress size={ 20 } />
              </div>
            ) : ( value?.value &&
              !isAttachement &&
              value?.value?.trim() !== "" ) ||
              ( value?.value && isAttachement ) ? (
              <MUI.IconButton onClick={ onSend }>
                <SendIcon />
              </MUI.IconButton>
            ) : (
              " "
            ) }
          </MUI.InputAdornment>
        ),
      } }
      inputProps={ {
        autocomplete: "new-password",
        form: {
          autocomplete: "off",
        },
      } }
    />
  );
};
