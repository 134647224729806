import React from "react";
import { useTranslation } from "react-i18next";
//MUI
import * as MUI from "@mui/material";
import { IconsButton } from "../button/Button";
import { PlusIcon } from "../../assets/icons/SVG";
import palette from "../../theme/color.scss";

export function TableCustomHeader(props) {
  const [t] = useTranslation("common");
  const { onAdd, actions, collapse, requestSort, onFilter } = props;

  const handleRequestSort = (e, item) => {
    requestSort(item);
  };
  return (
    <MUI.TableHead>
      <MUI.TableRow>
        {props?.headers.map((item, index) => (
          <MUI.TableCell
            key={item.value}
            sortDirection={
              props.sortedBy === item.sorted ? props.orderBy : false
            }
          >
            {item.sorted ? (
              <MUI.TableSortLabel
                direction={
                  props.sortedBy === item.sorted ? props.orderBy : "asc"
                }
                active={props.sortedBy === item.sorted}
                onClick={(e) => handleRequestSort(e, item.sorted)}
              >
                {t(item.name)}
              </MUI.TableSortLabel>
            ) : (
              t(item.name)
            )}
          </MUI.TableCell>
        ))}
        {collapse && <MUI.TableCell align="right"></MUI.TableCell>}
        {actions && (
          <MUI.TableCell align="right">
            {onAdd && (
              <IconsButton
                icon={<PlusIcon fill={palette.primaryColor} />}
                onClick={() => onAdd()}
              />
            )}
            {onFilter && onFilter}
          </MUI.TableCell>
        )}
      </MUI.TableRow>
    </MUI.TableHead>
  );
}

export default TableCustomHeader;
