import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from "i18next-http-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { Cookies } from "react-cookie";
// not like to use this?
// have a look at the Quick start guide
// for passing in lng and translations on init
const cookies = new Cookies();

i18n
  .use(Backend)
  .use(new LanguageDetector(null, { lookupCookie: "language" }))
  .use(initReactI18next)
  .init({
    fallbackLng: "ro",
    lng: cookies.get("language") || "ro",
    ns: ["translations"],
    defaultNS: "translations",
    load: "languageOnly",
    debug: false,
    detection: {
      order: ["cookies", "path", "navigator"],
      lookupFromPathIndex: 0,
      checkWhitelist: true,
      caches: ["cookie"],
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      defaultTransParent: "div",
    },
  });

export default i18n;
