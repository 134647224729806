//UTILITIES
import { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom/dist";
import { useTranslation } from "react-i18next";

//COMPONENT
import ChatMessenger from "./ChatMessenger";
//API

import { UserContext } from "../../context/UserContext";
import { ChatContext } from "../../context/ChatContext";
import { ChatSkeleton } from "../../components/skeletons/SkeletonsComponent";
import { getUserById } from "../../core/services/user";
import ChatNotFound from "../../components/responseMessage/ChatNotFound";
import ChatContact from "./ChatContacts";
import { useInnerWidth } from "../../hooks/useInnerWidth";

export function Chat( props ) {

  //CONTEXT
  const screenWidth = useInnerWidth();
  const { user } = useContext( UserContext );
  const { chatUser, setChatUser } = useContext( ChatContext );
  const { chatId } = useParams();
  const [ openContacts, setOpenContacts ] = useState( false );
  const [ loading, setLoading ] = useState( false );
  useEffect( () => {
    if ( chatId ) {
      setLoading( true );
      getUserById( chatId ).then( ( res ) => {
        if ( res?.data?.success ) {
          setChatUser( { user: res?.data?.data?.user, new: false } );
        }
        setLoading( false );
      } );
    } else {
      //to add the necessary loader
      setLoading( true );
      setTimeout( () => {
        setLoading( false );
      }, 2000 );
    }
  }, [] );
  return (
    <div className="chat-content-detail">
      <div className="chat-detail">
        { !loading ? (
          chatUser?.user ? (
            <ChatMessenger handleOpenContacts={ () => setOpenContacts( true ) } />
          ) : (
            <ChatNotFound
              handleOpenContact={
                screenWidth < 900 ? () => setOpenContacts( true ) : null
              }
            />
          )
        ) : (
          <ChatSkeleton />
        ) }
      </div>


      { openContacts && <ChatContact onClose={ () => setOpenContacts( false ) } /> }
    </div>
  );
}

export default Chat;
