import React, { useState } from "react";
import { Avatar, Skeleton } from "@mui/material";
import FileSVG from "../../../../../assets/icons/FileSVG";
import { REACT_APP_IMAGE_URL } from "../../../../../core/services/main";
import Button from "../../../../../components/button/Button";
import { NoData } from "../../../../../components/Fallback/NoData";
import { DocumentPopUp } from "../../../../../components/popup/Popup";
import "../DiscoverUser.scss";
import { useTranslation } from "react-i18next";

export function UserHeader(props) {
  const [t] = useTranslation("common");

  const [number, setNumber] = useState(false);
  let limit = 700;
  const { data, loading } = props;
  return (
    <div className="discover-user-heading">
      <div className="discover-user-header">
        {loading ? (
          <Skeleton variant="circular" width={47} height={47} />
        ) : (
          <Avatar
            style={{ width: "47px", height: "47px" }}
            alt={data?.first_name}
            src={REACT_APP_IMAGE_URL + data?.profile_image}
            variant="dot"
          />
        )}

        <div className="discover-user-profile" style={{ width: "100%" }}>
          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "15%" }} />
          ) : (
            <h5>{`${data?.first_name} ${data?.last_name} `}</h5>
          )}
          {loading ? (
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "10%" }} />
          ) : (
            <p>{`${data?.role?.name} - ${data?.mutual_count} ${t(
              "MUTUAL_CONNECTIONS"
            )}`}</p>
          )}
        </div>
      </div>
      <div className="discover-user-bio">
        {loading ? (
          <>
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "30%" }} />
            <Skeleton variant="text" sx={{ fontSize: "1rem", width: "19%" }} />
          </>
        ) : data.bio ? (
          <>
            <h5>{t("BIO")}</h5>
            {number ? (
              <p className="para-with-normal">{data?.bio}</p>
            ) : (
              <p className="para-with-less"> {data?.bio} </p>
            )}
            {data?.bio < limit ? (
              ""
            ) : data?.bio?.length > limit ? (
              <>
                {!number ? (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setNumber(!number)}
                  >
                    {t("SHOW_MORE")}
                  </span>
                ) : (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setNumber(!number)}
                  >
                    {t("SHOW_LESS")}
                  </span>
                )}
              </>
            ) : null}
          </>
        ) : null}

        <div className="user-bio-button">
          {props.loading ? (
            <Skeleton variant="text" sx={{ height: "50px", width: "150px" }} />
          ) : props.onClick === "1" ? (
            <div className="request-accept-reject-container">
              <Button
                disabled={props.buttonLoader}
                name="Reject"
                onClick={() => props.onAcceptReject("reject")}
              />
              <Button
                disabled={props.buttonLoader}
                isSelected
                name="Accept"
                onClick={() => props.onAcceptReject("accept")}
              />
            </div>
          ) : (
            <Button
              disabled={props.buttonLoader}
              onClick={props.onClick}
              isSelected
              name={props.onClickTitle}
            />
          )}
        </div>
      </div>
    </div>
  );
}
export function UserPersonalInfo(props) {
  const [t] = useTranslation("common");
  const { data } = props;
  return (
    <div className="user-personal-info">
      <div className="user-info">
        <div>{t("TITLE")}</div>
        <div>{data?.title?.name}</div>
      </div>

      <div className="user-info">
        <div>{t("FIRST_NAME")}</div>
        <div>{data?.first_name}</div>
      </div>
      <div className="user-info">
        <div>{t("LAST_NAME")}</div>
        <div>{data?.last_name}</div>
      </div>
      <div className="user-info">
        <div>{t("ROLE")}</div>
        <div>{data.role?.name}</div>
      </div>
      {/* only friends (as doctors )can check your email and your phone number */}
      {(data?.is_friend || (!data?.is_friend && data?.portal_id !== 3)) && (
        <>
          <div className="user-info">
            <div>{t("PHONE_NUMBER")}</div>
            <div>{data?.phone_number}</div>
          </div>
          <div className="user-info">
            <div>{t("EMAIL")}</div>
            <div>{data?.email}</div>
          </div>
        </>
      )}
    </div>
  );
}
export function UserProssionalDetails(props) {
  const { data } = props;

  return (
    <div className="user-personal-info">
      {data?.length !== 0 ? (
        data?.map((values) =>
          values?.array.map((val) => (
            <div className="user-info">
              {!val.is_categorical ? (
                <>
                  <div>{val.name}</div>
                  {val?.field_type?.name === "Image" ||
                  val?.field_type?.name === "File" ? (
                    <div
                      className="cursor-pointer"
                      onClick={() => {
                        window.open(
                          REACT_APP_IMAGE_URL +
                            val.dynamic_field_value?.[0]?.value
                        );
                      }}
                    >
                      <FileSVG />
                    </div>
                  ) : (
                    <div>{val?.dynamic_field_value?.[0]?.value}</div>
                  )}
                </>
              ) : (
                <>
                  <div>{val.name}</div>
                  {val?.dynamic_field_value?.[0]?.field_category?.length >= 1
                    ? val?.dynamic_field_value?.[0]?.field_category.map(
                        (val) => <div>{val.name}</div>
                      )
                    : ""}
                </>
              )}
            </div>
          ))
        )
      ) : (
        <NoData title="NO_DETAILS_FOUND" />
      )}
    </div>
  );
}
export const UserGroupsInfo = (props) => {
  const { data } = props;
  const [t] = useTranslation("common");

  return (
    <div className="user-groups-container">
      {data?.length ? (
        data?.map((val) => (
          <div className="user-groups-card">
            <Avatar
              style={{ width: "47px", height: "47px" }}
              alt={val?.title}
              src={REACT_APP_IMAGE_URL + val?.profile_image}
              variant="square"
            />
            <div className="user-group-info">
              <h6>{val?.title}</h6>
              <p>
                {val?.users_count > 1
                  ? `${val?.users_count} ${t("MEMBERS")}`
                  : `${val?.users_count} ${t("MEMBER")}`}
              </p>
            </div>
          </div>
        ))
      ) : (
        <NoData
          title={"NO_GROUPS_FOUND"}
          style={{ width: "100%", height: "250px" }}
          NoData={true}
        />
      )}
    </div>
  );
};
