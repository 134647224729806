export function isInnerHTMLContentEmpty( htmlContent ) {
    // Create a temporary element to hold the HTML content
    var tempElement = document.createElement( 'div' );

    // Set the HTML content to the temporary element
    tempElement.innerHTML = htmlContent;

    // Check if the temporary element contains any visible content
    var visibleText = tempElement.innerText.trim(); // Get the trimmed inner text
    var visibleHtml = tempElement.innerHTML.trim(); // Get the trimmed inner HTML

    // Check if either the inner text or inner HTML is empty

    return visibleText === '';
}