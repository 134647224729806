import React, { useContext, useEffect } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import AccountDetails from "../../pages/accountDetails/AccountDetails";
import PersonalInformation from "../../pages/accountDetails/account-info/PersonalInformation";
import ProfessionalDetails from "../../pages/accountDetails/account-info/ProfessionalDetails";
import Schedule from "../../pages/meetings/Schedule/schedule";
import Settings from "../../pages/accountDetails/settings/Settings";
import Home from "../../pages/home/Home";
import NotFound from "../../pages/notFound/NotFound";
import Groups from "../../pages/groups/Groups";
import Login from "../../pages/login/Login";
import ResetPassword from "../../pages/login/reset-password/ResetPassword";
import EmailSent from "../../pages/login/email-sent/EmailSent";
import PasswordChanged from "../../pages/login/password-changed/PasswordChanged";
import Register from "../../pages/register/Register";
import Catalog from "../../pages/catalog/Catalog";
import CatalogInformation from "../../pages/catalog/CatalogInformation";
import Description from "../../pages/watch/description/Description";
import GazetteList from "../../pages/gazette/GazetteList";
import GazetteInformation from "../../pages/gazette/GazetteInformation";
import GazetteHandle from "../../pages/gazette/GazetteHandle.js";
import RewardsDetails from "../../pages/rewardsDetails/RewardsDetails";
import MyPoints from "../../pages/rewardsDetails/MyPoints/MyPoints.js";
import Eshop from "../../pages/rewardsDetails/Eshop/Eshop";
import MyRedemptions from "../../pages/rewardsDetails/MyRedemptions/MyRedemptions";
import EarnPoints from "../../pages/rewardsDetails/EarnPoints/EarnPoints";
import Connections from "../../pages/connections/Connections";
import MyConnections from "../../pages/connections/MyConnections";
import Discover from "../../pages/connections/discover/Discover";
import RequestsConnections from "../../pages/connections/Requests";
import Companies from "../../pages/companies/Companies";
import CompaniesDetails from "../../pages/companies/CompaniesDetails";
import CompaniesAbout from "../../pages/companies/CompaniesAbout";
import CompaniesGroup from "../../pages/companies/CompaniesGroup";
import UserNotifications from "../../pages/user-notification/UserNotifications";
import QuestionandAnswer from "../../pages/questionandanswer/QuestionandAnswer";
import Browse from "../../pages/questionandanswer/browse/Browse";
import MyQuestions from "../../pages/questionandanswer/myquestions/MyQuestions";
import QuestionDetail from "../../pages/questionandanswer/myquestions/QuestionDetail";
import Meetings from "../../pages/meetings/meetings";
import SetSchedule from "../../pages/meetings/Schedule/setSchedule/SetSchedule";
import ForgotPassword from "../../pages/login/forgot-password/ForgotPassword";
import Profession from "../../pages/profession/Profession";
import Watch from "../../pages/watch/Watch";
import ResponsiveSideBar from "../../layout/sideBar/ResponsiveSideBar";
import Invitation from "../../pages/groups/Invitations";
import DiscoverGroups from "../../pages/groups/DiscoverGroups";
import GroupDetails from "../../pages/groups/groupDetails/GroupDetails";
import Feed from "../../pages/groups/groupDetails/feed/Feed";
import Media from "../../pages/groups/groupDetails/media/Media";
import Photos from "../../pages/groups/groupDetails/media/photos/Photos";
import Videos from "../../pages/groups/groupDetails/media/videos/Videos";
import Presentations from "../../pages/groups/groupDetails/media/presentations/Presentations";
import Members from "../../pages/groups/groupDetails/members/Members";
import JoinedGroups from "../../pages/groups/JoinedGroups";
import ViewAllSuggested from "../../pages/groups/ViewAllSuggested";
import ViewAllOtherGroups from "../../pages/groups/ViewAllOtherGroups";
import GroupsMain from "../../pages/groups/GroupsMain";
import ReactGA from "react-ga4";
import DiscoverUser from "../../pages/connections/discover/discover-user-info/DiscoverUser";
import GazetteMain from "../../pages/gazette/GazetteMain";
import Chat from "../../pages/chat/Chat";
import { AuthProvider } from "../../hooks/useAuth";
import { ProtectedLayout } from "../../layout/ProtectedLayout";
import HomeSide from "../../layout/sideBar/HomeSide";
import { PermissionContext } from "../../context/PermissionContext";
import GeneralLayout from "../../layout/GeneralLayout";
import Document from "../../pages/accountDetails/document/Document";
import ChatLayout from "../../pages/chat/ChatLayout";
import LandingPage from "../../pages/landingPage/LandingPage";
import MeetingsByType from "../../pages/meetings/MeetingsByType";
import ContactUs from "../../pages/contact-us/ContactUs";
import { Addresses } from "../../pages/accountDetails/addresses/Addresses.js";

const TRACKING_ID = "G-CBZ4ZD8KEB"; // OUR_TRACKING_ID
ReactGA.initialize( TRACKING_ID );

const MainRoute = () => {
  const { permissions } = useContext( PermissionContext );

  useEffect( () => {
    ReactGA.send( {
      hitType: window.location.pathname,
      page: window.location.search,
    } );
  }, [] );

  return (
    <AuthProvider>
      <Routes>
        <Route index element={ <Navigate to="en" replace /> } />
        <Route path={ `:lang` } element={ <GeneralLayout /> }>
          <Route
            element={
              <ProtectedLayout
                footer
                requireLogin={ false }
                className="landing-main"
              />
            }
          >
            <Route path="homepage" element={ <LandingPage /> } />
            <Route path="contact-us" element={ <ContactUs /> } />
          </Route>
          <Route
            element={
              <ProtectedLayout requireLogin={ false } className="login-main" />
            }
          >
            <Route index element={ <Navigate to={ `homepage` } /> } />
            <Route path="login" element={ <Login /> } />
            <Route path="forgot-password" element={ <ForgotPassword /> } />
            <Route path="email-sent" element={ <EmailSent /> } />
            <Route path="new-password/:token" element={ <ResetPassword /> } />
            <Route path="password-changed" element={ <PasswordChanged /> } />
            <Route path="choose-your-profession" element={ <Profession /> } />
            <Route
              path="choose-your-profession/:invitedToken"
              element={ <Profession /> }
            />
            <Route path="register" element={ <Register /> } />
          </Route>

          <Route element={ <ProtectedLayout requireLogin={ true } /> }>
            <Route index element={ <Navigate to={ `home` } /> } />
            <Route path="home" element={ [ <HomeSide />, <Home /> ] } />
            <Route path="connections" element={ <Connections /> }>
              <Route index element={ <Navigate to="my-connections" replace /> } />
              <Route path="my-connections" element={ <MyConnections /> } />
              <Route path="requests" element={ <RequestsConnections /> } />
              <Route path="discover" element={ <Discover /> } />
              <Route path="discover/user-info/:id" element={ <DiscoverUser /> } />
            </Route>
            <Route path="question-and-answer" element={ <QuestionandAnswer /> }>
              <Route index element={ <Navigate to="browse" replace /> } />
              <Route path="browse" element={ <Browse /> } />
              <Route
                path="myquestions"
                element={ <MyQuestions type="MyQuestions" /> }
              />
              <Route path="answer-section/:id" element={ <QuestionDetail /> } />
            </Route>
            { permissions?.Posts?.[ "view-posts-by-group-id" ] && ( <Route path="groups" element={ <GroupsMain /> }>
              <Route index />
              <Route path="your-feed" element={ <Groups /> } />
              {/* <Route path="discover"  element={<Suggested />} /> */ }
              { permissions?.Group?.[ "view-group-invitations" ] && ( <Route path="invitations" element={ <Invitation /> } /> ) }
              { permissions?.Group?.[ "view-suggested-groups" ] && ( <Route path="discover" element={ <DiscoverGroups /> } /> ) }
              <Route path="joined-groups" element={ <JoinedGroups /> } />
              { permissions?.Group?.[ "view-suggested-groups" ] && ( <Route path="discover/suggested" element={ <ViewAllSuggested /> } /> ) }
              { permissions?.Group?.[ "view-suggested-groups" ] && ( <Route path="discover/others" element={ <ViewAllOtherGroups /> } /> ) }
              { permissions?.Group?.[ "view-group" ] && ( <Route path="group-details/:id" element={ <GroupDetails /> }>
                <Route index />
                <Route path="feed" element={ <Feed /> } />
                {/* <Route path="about" element={<About />} /> */ }
                <Route path="members" element={ <Members /> } />
                <Route path="media" element={ <Media /> }>
                  <Route index path="schedule" element={ <Schedule /> } />
                  <Route index element={ <Navigate to="photos" replace /> } />
                  <Route path="photos" element={ <Photos /> } />
                  <Route path="videos" element={ <Videos /> } />
                  <Route path="presentations" element={ <Presentations /> } />
                </Route>
              </Route> ) }
            </Route> ) }


            <Route path="meetings" element={ <Meetings /> }>
              { permissions?.Schedule?.[ "view-specific-user-schedule" ] ?
                <Route index element={ <Navigate to="schedule" replace /> } />
                : permissions?.Meeting?.[ "view-specific-doctor-meetings" ] ?
                  <Route index element={ <Navigate to="requests" replace /> } />
                  :
                  <Route index element={ <Navigate to="history" replace /> } /> }


              { permissions?.Schedule?.[ "view-specific-user-schedule" ] && (
                <>
                  <Route index path="schedule" element={ <Schedule /> } />

                  <Route path="schedule/set-schedule" element={ <SetSchedule /> } />
                </> ) }

              { permissions?.Meeting?.[ "view-specific-doctor-meetings" ] && (
                <><Route path="requests" element={ <MeetingsByType type={ 1 } /> } />
                  <Route path="rejected" element={ <MeetingsByType type={ 3 } /> } />
                </>
              ) }
              { permissions?.Meeting?.[ 'view-doctor-specific-meetings-history' ] &&
                ( <Route path="history" element={ <MeetingsByType history /> } /> ) }
            </Route>


            <Route path="account-details" element={ <AccountDetails /> }>
              <Route
                index
                element={ <Navigate to="personal-information" replace /> }
              />
              <Route
                path="personal-information"
                element={ <PersonalInformation /> }
              />
              <Route
                path="professional-details"
                element={ <ProfessionalDetails /> }
              />
              { permissions?.Document?.[ "view-user-documents" ] && (
                <Route path="document" element={ <Document /> } />
              ) }
              <Route path="settings" element={ <Settings /> } />
              <Route path="addresses" element={ <Addresses /> } />
            </Route>
            <Route path="bookmarks" element={ <></> } />


            <Route path="rewards-details" element={ <RewardsDetails /> }>
              {/* If user has permission for my-points, use it as the default route */ }
              { permissions?.Achievements?.[ "view-user-achievements" ] ? (
                <Route index element={ <Navigate to="my-points" replace /> } />
              ) : permissions?.Reward?.[ 'view-all-rewards' ] ? (
                /* If user does not have permission for my-points, choose a different default route */
                <Route index element={ <Navigate to="e-shop" replace /> } />
              ) : permissions?.Redemption?.[ 'view-user-redemptions' ] ?
                <Route index element={ <Navigate to="my-redemptions" replace /> } />
                : <Route index element={ <Navigate to="earn-points" replace /> } /> }
              { permissions?.Achievements?.[ "view-user-achievements" ] && ( <Route path="my-points" element={ <MyPoints /> } /> ) }
              { permissions?.Reward?.[ 'view-all-rewards' ] && ( <Route path="e-shop" element={ <Eshop /> } /> ) }
              { permissions?.Redemption?.[ 'view-user-redemptions' ] && ( <Route path="my-redemptions" element={ <MyRedemptions /> } /> ) }
              { permissions?.Achievements?.[ 'view-all-achievements' ] && ( <Route path="earn-points" element={ <EarnPoints /> } /> ) }
            </Route>
            { permissions?.Company?.[ "view-suggested-companies" ] && (
              <>
                <Route path="companies" element={ <Companies /> } />
                <Route path="companies-details" element={ <CompaniesDetails /> }>
                  <Route path="about/:id" element={ <CompaniesAbout /> } />
                  <Route path="groups/:id" element={ <CompaniesGroup /> } />
                </Route>
              </>
            ) }
            { permissions?.Notification?.[ "view-all-user-notification" ] && (
              <Route path="notifications" element={ <UserNotifications /> } />
            ) }
            <Route path="catalog" element={ <Catalog /> }>
              <Route index />
              <Route path="all" element={ <Catalog /> }></Route>
            </Route>
            <Route
              path="catalog/:product_id"
              element={ <CatalogInformation /> }
            />
            <Route path="watch/all" element={ <Watch /> } />
            <Route
              path="watch/description/:id/:type"
              element={ <Description /> }
            />
            <Route path="blog" element={ <GazetteMain /> }>
              <Route index element={ <Navigate to="blogs" replace /> } />
              <Route path="blogs" element={ <GazetteList type="gazettes" /> } />
              <Route
                path="my-blogs"
                element={ <GazetteList type="Mygazettes" /> }
              />
              <Route path="drafts" element={ <GazetteList type="drafts" /> } />
            </Route>
            <Route path="blog/blogs/:id" element={ <GazetteInformation /> } />
            <Route path="blog/add-blog" element={ <GazetteHandle create /> } />
            <Route path="blog/edit-blog/:id" element={ <GazetteHandle /> } />
            <Route path="*" element={ <NotFound /> } />
            <Route path="chat/:chatId?" element={ <ChatLayout /> } />
            {/*conditional param*/ }
          </Route>
        </Route>
      </Routes>
    </AuthProvider>
  );
};

export default MainRoute;
