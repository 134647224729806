//#region IMPORTS
//====REACT AND JAVASCRIPT UTILITIES
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AlertContext } from "../../../../context/AlertContext";
import { UserContext } from "../../../../context/UserContext";
import { LanguageContext } from "../../../../context/LanguageContext";
import { useQuery } from "@tanstack/react-query";
import { useFormik } from "formik";
import * as yup from "yup";
import dayjs from "dayjs";
//====COMPONENTS====
import { FormPrompt } from "../../../../components/form/formPrompt/FormPrompt";
import { InfoSVG } from "../../../../assets/icons/SVG";
import Button from "../../../../components/button/Button";
import * as mui from "@mui/material";
import {
  FormCalendarInput,
  FormNumberInput,
} from "../../../../components/form/form-componenrt/FormComponent";
import TimeInterval from "../../../../components/form/time-interval/TimeInterval";
import {
  SchedulePopUp,
} from "../../../../components/popup/Popup";
import BackRep from "../../../../components/back-repository/BackRep";
//====SCSS====
import "./SetSchedule.scss";
//====APIS====
import * as scheduleData from "../../../../core/services/schedule";
import ExceptionsHandle from "./ExceptionsHandle";
import LoaderComponent from "../../../../components/loader/LoaderComponent";
import { meetingDuration } from "../../../../core/variables/Variables";
import CancelMeetingHandle from "../../CancelMeetingHandle";
import { DisplayTooltip } from "../../../../components/display-component/DisplayComponent";
//#endregion


const newInterval = { start_time: "00:00:00", end_time: "00:00:00" };

const returnWeekdays = ( weekDays, days ) => {
  let weekdaysArray = [];
  Object.keys( weekDays )?.map( ( item ) => {
    weekDays[ item ]?.map( ( time ) => {
      weekdaysArray.push( {
        ...time,
        weekday_id: +item === 0 ? 7 : +item,//sunday is 0
      } );
    } );
  } );
  return weekdaysArray;
};

//payload formatter for exceptions key
const returnException = ( exception ) => {
  let exceptions = [];
  Object.keys( exception || {} )?.map( ( item ) => {
    const day = item.split( " " );
    exceptions.push( {
      ...exception[ item ],
      date: day[ 1 ],
    } );
  } );
  return exceptions;
};

const SetSchedule = () => {
  const [ t ] = useTranslation( "common" );

  //context
  const { user } = useContext( UserContext );
  const alert = useContext( AlertContext );
  const { language } = useContext( LanguageContext );

  const navigate = useNavigate();

  //state hook
  const [ openCancelSingleEvent, setOpenCancelSingleEvent ] = useState( false );
  const [ saveLoading, setSaveLoading ] = useState( false )
  const [ loading, setLoading ] = useState( false )
  const [ conflictDialog, setConflictDialog ] = useState( false );
  const [ exceptionDialog, setExceptionDialog ] = useState( false );
  const [ schedule, setSchedule ] = useState( {} );
  const [ isNew, setIsNew ] = useState( false );
  const [ conflict, setConflict ] = useState( [] );
  const openModal = () => setConflictDialog( !conflictDialog );
  const setScheduleSchema = yup.object().shape( {
    break_duration: yup.string().nullable().required( 'FIELD_IS_REQUIRED' ),
    custom_break_duration: yup
      .number().nullable()
      .when( 'break_duration', {
        is: 'CUSTOM',
        then: yup.number().nullable().required( 'FIELD_IS_REQUIRED' ),
        otherwise: yup.number().nullable()
      } ),
    meeting_duration: yup.string().nullable().required( 'FIELD_IS_REQUIRED' ),
    custom_meeting_duration: yup
      .number().nullable()
      .when( 'meeting_duration', {
        is: 'CUSTOM',
        then: yup.number().nullable().required( 'FIELD_IS_REQUIRED' ),
        otherwise: yup.number().nullable()
      } ),
    start_date: yup
      .string().nullable()
      .test( 'is-valid-date', 'THE_START_DATE_MUST_BE_TODAY_OR_LATER', function ( value ) {
        const date = new Date( value );
        const currentDate = new Date();
        const inputDate = new Date( date.getFullYear(), date.getMonth(), date.getDate() );
        return !isNaN( inputDate ) && inputDate >= currentDate.setHours( 0, 0, 0, 0 );
      } )
      .required( 'FIELD_IS_REQUIRED' ),

    end_date: yup
      .string().nullable()
      .test( 'is-valid-date', 'INVALID_END_DATE', function ( value, context ) {
        const date = new Date( value );
        const start_date = new Date( context.parent.start_date );
        return !isNaN( date ) && date >= start_date;
      } )
      .required( 'FIELD_IS_REQUIRED' ),

    // ... other fields in your object schema
  } );
  //get days of the week with caching
  const { data: days } = useQuery( {
    queryKey: [ "days", language ],
    queryFn: () => {
      return scheduleData.getDays().then( ( res ) => res?.data?.data );
    }, //return arrray of object containing day slug and name translated
  } );


  //initiate the values 
  const formiks = useFormik( {
    initialValues: {
      meeting_duration: "",
      custom_meeting_duration: "",
      break_duration: "",
      custom_break_duration: "",
      exceptions: {},
      schedule_exceptions: {},
      weekdays: {},
      force_update: false,
      schedule_id: null,
      user_id: user?.id,

    },

    validationSchema: setScheduleSchema,
    enableReinitialize: true,
    onSubmit: ( payload ) => {

      HandleSave( {
        ...payload,
        break_duration: payload?.break_duration === "CUSTOM" ?
          payload?.custom_break_duration.toString()
          : payload.break_duration === "NONE" ?
            "0" :
            payload.break_duration.toString(),
        meeting_duration: payload?.meeting_duration === "CUSTOM" ?
          payload?.custom_meeting_duration.toString() :
          payload.meeting_duration.toString(),
        weekdays: returnWeekdays( payload.weekdays, days ),
        exceptions: returnException( payload.exceptions ),
      } );
    },
  } );


  const { weekdays, exceptions } = formiks.values;
  const handleDelete = ( value, day ) => {
    formiks.setFieldValue( "weekdays", {
      ...weekdays,
      [ day ]: weekdays[ day ].length
        ? weekdays[ day ].filter( ( _, index ) => index !== value )
        : null,
    } );
  };
  //add a time interval in weekly availability
  const handleAdd = ( dayInt ) => () => {
    formiks.setFieldValue( "weekdays", {
      ...weekdays,
      [ dayInt ]: [ ...( weekdays?.[ dayInt ] || [] ), newInterval ],
    } );
  };
  //handle checkboxes in weekly availability
  const checkboxHandler = ( dayInt ) => () => {
    formiks.setFieldValue( "weekdays", {
      ...weekdays,
      [ dayInt ]: !weekdays?.[ dayInt ]?.length ? [ newInterval ] : null,
    } );
  };

  //update time in weekly availability
  const TimeChange = ( index, e, type, day ) => {
    let weekdays_copy = { ...formiks.values.weekdays }
    weekdays_copy[ day ][ index ] = {
      //reset the end time if the start changes
      [ `start_time` ]: type === "start" ? dayjs( e ).format( "HH:mm:ss" ) : weekdays_copy[ day ][ index ]?.start_time,
      [ `end_time` ]: type === "start" ? null : dayjs( e ).format( "HH:mm:ss" ),
    }
    formiks.setFieldValue( "weekdays", weekdays_copy )


  };

  // Submit update/create schedule
  const HandleSave = ( payload ) => {
    setSaveLoading( true )
    scheduleData?.[ isNew ? "createSchedule" : "updateSchedule" ]( payload ).then(
      ( res ) => {
        if ( res?.data?.success ) {
          //in case no conflict the data is saved and the user will be redirected
          if ( !res?.data?.data?.is_conflict ) {
            alert?.[ res?.data?.success ? "success" : "warning" ](
              res?.data?.message
            );
            navigate( -1 );
          } else {
            //in case of conflic
            setConflict( res?.data?.data?.accepted.concat( res?.data?.data?.pending ) );
            res?.data?.data?.is_conflict && openModal();
          }
        } else {
          alert?.[ res?.data?.success ? "success" : "warning" ](
            res?.data?.message
          );
        }
        setSaveLoading( false )
      }
    );
  };



  //confirm when conflict to be removed when implementing the Figma ui
  const onConfirm = () => {
    let payload = {
      ...formiks.values,
      force_update: true, //forcing update means cancel all meetings with conflict with the new schedule
      break_duration:
        formiks.values?.break_duration === "CUSTOM" ?
          formiks.values?.custom_break_duration.toString() :
          formiks.values.break_duration === "NONE"
            ? "0" :
            formiks.values.break_duration.toString(),
      meeting_duration: formiks.values?.meeting_duration === "CUSTOM" ?
        formiks.values?.custom_meeting_duration?.toString()
        : formiks.values?.meeting_duration?.toString(),
      weekdays: returnWeekdays( formiks.values.weekdays, days ),
      exceptions: returnException( formiks.values.exceptions ),
    };

    scheduleData.updateSchedule( payload ).then( ( res ) => {
      if ( res?.data?.success ) {
        openModal();
        navigate( -1 )
      }
      alert?.[ res?.data?.success ? "success" : "warning" ](
        res?.data?.message
      );

    } );
  };


  //get schedule info
  const getScheduleData = () => {
    setLoading( true )
    scheduleData.getSchedule().then( ( res ) => {
      let schedule_data = res?.data?.data?.schedule?.[ 0 ] || {}
      //checking if the schedule is new or not
      setIsNew( !Boolean( res?.data?.data?.schedule?.length ) );
      setSchedule( schedule_data );
      let findMeetingDuration = meetingDuration?.[ 0 ]?.buttonTags.includes( schedule_data?.meeting_duration )
      let findBreakDuration = meetingDuration?.[ 1 ]?.buttonTags.includes( schedule_data?.break_duration )
      /*
      we are checking
      1- if user has already break_duration not equal to 0 and not between the array of option then it's custom
      2- if the user doesn't have a schedule the default value will be custom and the default value for custom should be null
      3- if value is 0 we should put it NONE to break meeting case
*/
      formiks.setValues( {
        ...schedule_data,
        weekdays: schedule_data?.weekdays || {},
        start_date: schedule_data?.start_date || "",
        end_date: schedule_data?.end_date || "",
        break_duration: !findBreakDuration ? schedule_data?.break_duration === "0" ? "NONE" : "CUSTOM" : schedule_data?.break_duration,
        custom_break_duration: !findBreakDuration && schedule_data?.break_duration !== "0" ? schedule_data?.break_duration ? schedule_data?.break_duration : null : "",
        meeting_duration: !findMeetingDuration ? "CUSTOM" : schedule_data?.meeting_duration,
        custom_meeting_duration: !findMeetingDuration ? schedule_data?.meeting_duration ? schedule_data?.meeting_duration : null : "",
        exceptions: schedule_data?.schedule_exceptions || {},
        force_update: false,
        schedule_id: schedule_data?.id || null,
        user_id: user?.id,
      } )
      setLoading( false )
    } );
  };

  useEffect( () => {
    //get self schedule
    getScheduleData();
  }, [] );


  const handleSuccessCancelEvent = ( eventID ) => {
    //after cancelling event , removing it from the list without backend
    let new_conflict_data = conflict.filter( ( i ) => i.id !== eventID );
    setConflict( new_conflict_data );
    if ( new_conflict_data?.length === 0 ) {
      //if no other meeting conflicts
      setConflictDialog( false );
      formiks.handleSubmit();
    };
  };

  return (
    <>
      <BackRep to={ `..` } title={ t( "SCHEDULE" ) } />
      { loading ? (
        <div style={ { height: "50vh", display: "flex", alignItems: "center" } }>
          <LoaderComponent />
        </div>
      ) : <form className="set-schedule" onSubmit={ formiks.handleSubmit }>
        <h2>{ t( "WEEKLY_AVAILABILITY" ) }</h2>
        <FormPrompt hasUnsavedChanges={ formiks.dirty } />
        {/* region meeting duration and break duration */ }
        <div className="meetingTimeDuration">
          { meetingDuration.map( ( el, index ) => (
            <div className="selectiveButtons" key={ index }>
              <div className="ButtonTitle">
                <p>{ t( el.text ) }</p>
                <DisplayTooltip
                  arrow={ false }
                  placement="right-start"
                  title={ t( el.message ) }
                >
                  <mui.IconButton>
                    <InfoSVG />
                  </mui.IconButton>
                </DisplayTooltip>

              </div>
              <div className="ButtonsGroup">
                { el.buttonTags.map( ( item, index ) => {
                  return (
                    <mui.ToggleButton
                      value={ item }
                      onClick={ () => {
                        formiks.setFieldValue( el.type, item );
                        formiks.setFieldError( `custom_${ el.type }`, '' );

                      } }
                      className={
                        formiks.values?.[ el?.type ] === item ? "active" : "inactive"
                      }
                      variant="outlined"
                      key={ index }
                      name={ el.type }
                      selected={ formiks.values?.[ el?.type ] === item }

                    >
                      { t( item ) }
                    </mui.ToggleButton> )
                } ) }
              </div>
              { formiks?.values?.[ el.type ] === "CUSTOM" && (
                <FormNumberInput
                  placeholder={ `Choose ${ t( el.text ) }` }
                  onChange={ ( value ) => {
                    formiks?.setFieldValue( `custom_${ el.type }`, value )

                  } }
                  name={ `custom_${ el.type }` }
                  value={ formiks.values?.[ `custom_${ el.type }` ] }
                  helperText={ formiks?.errors?.[ `custom_${ el.type }` ] }
                />
              ) }


            </div>
          ) ) }
        </div>
        <div className="datePickerCompo">
          <div>
            <FormCalendarInput
              label="START_DATE"
              onChange={ ( e ) => {
                formiks.setFieldValue( "start_date", dayjs( e ).format( "YYYY-MM-DD" ) );
                formiks.setFieldValue( "end_date", "" );
                formiks.setFieldValue( "exceptions", {} );

              } }
              value={ formiks.values?.start_date }
              placeholderText="YYYY/MM/DD"
              name="start_date"
              minDate={ new Date() }
              helperText={ formiks.touched.start_date && formiks?.errors?.start_date }
            />

          </div>
          <div>
            <FormCalendarInput
              label="END_DATE"
              onChange={ ( e ) => {
                formiks.setFieldValue( "end_date", dayjs( e ).format( "YYYY-MM-DD" ) );

              } }
              disabled={ !formiks.values?.start_date && false }
              value={ formiks.values?.end_date }
              placeholderText="YYYY/MM/DD"
              name="end_date"
              minDate={ formiks.values?.start_date }
              helperText={ formiks.touched.end_date && formiks?.errors?.end_date }
            />

          </div>
        </div>

        <h3>{ t( "AVAILABILITY" ) }</h3>
        <div className="timeIntervalSection">
          { days?.map( ( day, index ) => {
            let dayInt = day?.id === 7 ? 0 : day?.id //sunday is 0
            return (
              <TimeInterval
                day={ day }
                key={ index }
                time={ formiks?.values?.weekdays?.[ dayInt ] }
                handleDelete={ ( _, index ) => handleDelete( index, dayInt ) }
                handleAdd={ handleAdd( dayInt ) }
                timeChange={ ( index, event, type ) =>
                  TimeChange( index, event, type, dayInt )
                }
                handleCheckBox={ checkboxHandler( dayInt ) }
                checked={ Boolean( weekdays?.[ dayInt ]?.length ) }
                checkBoxValue={ day.id }
              />
            )
          } ) }
        </div>
        <div className="select-exceptions">
          <mui.ButtonBase
            onClick={ () => {

              Object.keys( weekdays ).length && setExceptionDialog( true );
            } }
          >
            { t( "Select exceptions" ) }
          </mui.ButtonBase>
          <DisplayTooltip
            arrow={ false }
            placement="right-start"
            title={ t( "SELECT_EXCEPTION_HINT" ) }
          >
            <mui.IconButton>
              <InfoSVG />
            </mui.IconButton>
          </DisplayTooltip>

        </div>
        { Object.keys( exceptions || {} ).map( ( day, index ) =>
        (
          <TimeInterval
            key={ index }
            checkBoxValue={ day }
            disabled
            day={ exceptions?.[ day ] }
            time={ exceptions?.[ day ]?.time }
            checked={ !exceptions?.[ day ]?.is_day_off }
          />
        )

        ) }
        <div className="button-container">
          <Button href="../schedule" name="CANCEL" width="15% !important" />
          <Button
            type="submit"
            loading={ saveLoading }
            disabled={ saveLoading }
            isSelected
            name={ !Object.keys( schedule ).length ? "ADD" : "SAVE" }
            width="15% !important"
          />
        </div>  </form> }


      { conflictDialog && (
        <SchedulePopUp
          open={ true }
          onClose={ () => setConflictDialog( false ) }
          onConfirm={ onConfirm }
          onCancel={ getScheduleData }
          title="MEETING_CONFLICT!"
        >
          <div>{ t( 'MEETING_CONFLICT_WITH_SCHEDULE' ) } </div>
          <div className="conflictMeetings">

            { conflict?.map( ( el ) => (
              <div key={ el.id } className="conflict-instance">
                <div className="entity">
                  <div>{ el.title } </div>
                  <div>
                    { dayjs( el.start_date_time ).format( "DD/MM/YYYY" ) }{ " " }
                    { `${ dayjs( el.start_date_time ).format(
                      "hh:mm A"
                    ) } - ${ dayjs( el.end_date_time ).format( "hh:mm A" ) }` }

                  </div>
                </div>
                <Button name="CANCEL" onClick={ () => setOpenCancelSingleEvent( {
                  open: true,
                  event: el?.id
                } ) } />
              </div>
            ) ) }
          </div>

          <div
            style={ {
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "20px",
            } }
          >
            <div className="ButtonsGrouping">
              <Button width="100%" name="CANCEL" onClick={ () => {
                setConflictDialog( false )
              } } />
              <Button
                width="100%"
                isSelected
                name={ "CONFIRM" }
                onClick={ () => {
                  onConfirm()
                } }
              />
            </div>
          </div>
        </SchedulePopUp > )
      }

      { exceptionDialog && (
        <ExceptionsHandle
          setExceptionDialog={ setExceptionDialog }
          schedule={ schedule }
          formiks={ formiks }
        /> ) }
      {
        openCancelSingleEvent?.open && (
          <CancelMeetingHandle
            onClose={ () => setOpenCancelSingleEvent( {
              open: false,
              event: null
            } ) }
            onSuccess={ ( cancelledEvent ) => {
              handleSuccessCancelEvent( cancelledEvent )
            } }
            id={ openCancelSingleEvent?.event }
          />
        )
      }
    </>
  );
};

export default SetSchedule;



