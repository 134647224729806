import React from "react";
import { Container, Tabs, Tab } from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import "./Media.scss";
import { useTranslation } from "react-i18next";
function Media() {
  let location = useLocation().pathname.split("/");
  const [t] = useTranslation("common");

  let navigate = useNavigate();
  const handleChange = (_, newValue) => navigate(newValue);

  return (
    <Container
      style={{
        borderRadius: "5px",
        backgroundColor: "white",
        marginTop: "20px",
      }}
      className="media"
    >
      <Tabs
        value={location[6]}
        onChange={handleChange}
        textColor="primary"
        aria-label="secondary tabs example"
        className="mediaTabs"
        scrollButtons="auto"
        variant="scrollable"
      >
        <Tab to="photos" value="photos" name="photos" label={t("PHOTOS")} />
        <Tab to="videos" value="videos" name="videos" label={t("VIDEOS")} />
        <Tab
          to="presentations"
          value="presentations"
          name="presentations"
          label={t("PRESENTATIONS")}
        />
      </Tabs>
      <Outlet />
    </Container>
  );
}

export default Media;
